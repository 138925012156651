import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter,Redirect } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { LoadingBar } from 'react-redux-loading-bar';
import { parseUrlFragmentAndQueryString } from '../../utils/locationUtils';
import { setInitialSelectedOfferId, saveUserCountry, setMinervaAddedClassId, setMinervaSelectedClassId, setMinervaSelectedClass } from '../../redux/actions';
import { getCurrentUser, getUserGeoIp } from '../../services/parse';
import PrivateRoute from '../../components/global/PrivateRoute';
import AccountV2 from '../AccountV2';
import Footer from '../../components/global/Footer';
import Header from '../../components/global/Header';
import Home from '../Home';
import InfoPopup from '../../components/global/InfoPopup';
import Login from '../Login';
import Logout from '../Logout';
import { setQuizQuestionAnswer, setExternalAnsweredQuestionId } from '../SignupQuiz/actions'
import NotFoundPage from '../NotFoundPage';
import PromoV2 from '../PromoV2';
import PromoPayment from '../PromoV2/PromoPayment';
import PromoComplete from '../PromoV2/PromoComplete';
import SignupRegister from '../SignupFunnel/SignupRegister';
import SignupPayment from '../SignupFunnel/SignupPayment';
import SignupComplete from '../SignupFunnel/SignupComplete';
import EmailLeadWall from '../EmailLeadWall';
import SignupQuiz from '../SignupQuiz';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { selectEnabledExperiment } from '../ExperimentsSetup/selectors';
// Minerva tryclasses funnel
import MinervaSignup from '../MinervaMemberships/MinervaSignup';
import MinervaClassLogin from '../MinervaMemberships/MinervaClassLogin';
import MinervaPaymentV2 from '../MinervaMemberships/MinervaPayment/indexV2';
import MinervaComplete from '../MinervaMemberships/MinervaComplete';
import BookClass from '../MinervaMemberships/MinervaComplete/components/BookClass/BookClass';
import ClassLayout from '../MinervaMemberships/PlanSelection/components/ClassLayout';
//import MinervaClassScheduling from '../MinervaMemberships/MinervaClassScheduling';
// Minerva Sawyer testing funnel
import Landing from '../MinervaMemberships/Sawyer/Landing';
import Signup from '../MinervaMemberships/Sawyer/Signup';
import FreeClass from '../MinervaMemberships/Sawyer/FreeClass';
// Minerva addclasses funnel
import AddClassesLogin from '../MinervaMemberships/Addclasses/AddClassesLogin';
import AddClassesPayment from '../MinervaMemberships/Addclasses/AddClassesPayment';
import SemesterSawyer from '../MinervaMemberships/Addclasses/AddClassesThankyou/SemesterSawyer';
import DropinSawyer from '../MinervaMemberships/Addclasses/AddClassesThankyou/DropinSawyer';
import { isFeatureEnabled } from '../../featureFlags';

// components that are rarely accessed and don't need to be loaded at the beginning
const Cancel = lazy(() => import('../Cancel'));
const Completion = lazy(() => import('../Completion'));
const CompletionGift = lazy(() => import('../CompletionGift'));
const Gift = lazy(() => import('../Gift'));
const GirlScoutCouncilRequest = lazy(() => import('../GirlScoutCouncilRequest'));
const GirlScoutCouncilRequestComplete = lazy(() => import('../GirlScoutCouncilRequestComplete'));
const GirlScoutsBPInfo = lazy(() => import('../GirlScoutsBPInfo'));
const HowToRedeem = lazy(() => import('../HowToRedeem'));
const ResetPassword = lazy(() => import('../ResetPassword'));
const ChangePassword = lazy(() => import('../ChangePassword'));
const BulkPurchase = lazy(() => import('../BulkPurchase'));
const BulkPurchaseComplete = lazy(() => import('../BulkPurchaseComplete'));
const GympassPage = lazy(() => import('../Partners/Gympass'));
const CaprikonLandingPage = lazy(() => import('../Partners/Caprikon'));
const CaprikonRegisterPage = lazy(() => import('../Partners/Caprikon/Register'));
const CaprikonPaymentPage = lazy(() => import('../Partners/Caprikon/Payment'));

const renderLoader = () => <h1>Loading</h1>;

const { detect, detectDevice } = require('../../services/detect-browser');
const deviceInfo = {
  browser: detect(),
  device: detectDevice()
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isScrolling: false,
      isAppPopupOpen: false,
      isNoSessionPopupOpen: false,
      pathname: props.location.pathname,
      userLocation: '',
      newResetPassword: false,
    };

    this._timeout = null;
    this.checkLoginStatus = this.checkLoginStatus.bind(this);
    this.closeNoSessionPopupOpen = this.closeNoSessionPopupOpen.bind(this);

    sessionStorage.setItem('current_page', '');
    sessionStorage.setItem('last_page', '');
  }

  componentDidMount() {
    setInterval(() => {
      if (!this.state.isNoSessionPopupOpen) {
        this.checkLoginStatus();
      }
    }, 5000);

    const url = window.location.href;
    if (url.includes('offer-register')) {
      const params = window.location.search;
      this.props.history.push(`/promo${params}`);
    }

    // Check user's location due to GDPR
    getUserGeoIp().then((data) => {
      this.setState({
      userLocation: data.country
    })
    const { saveUserCountry } = this.props;
    saveUserCountry(data.country)
    const newResetPassword = isFeatureEnabled('codesparkUnification');
    this.setState({ newResetPassword })
   })

    this.unlisten = this.props.history.listen((location, action) => {
     const lastPage = sessionStorage.getItem('current_page');
     sessionStorage.setItem('current_page', location.pathname);
     sessionStorage.setItem('last_page', lastPage);
    });
  }

  setIncomingQuizQuestionAnswer = () => {
    const { questionId, answer: answerParam } = parseUrlFragmentAndQueryString(window.location);
    const { setQuizQuestionAnswer, setExternalAnsweredQuestionId } = this.props;
    const answer = parseInt(answerParam);
    const isValidAnswer = typeof answer === 'number' && !Number.isNaN(answer);

    if (questionId && isValidAnswer) {
      setQuizQuestionAnswer({
        questionId,
        answer,
      });
      setExternalAnsweredQuestionId(questionId);
    }
  }

  // Routing Lego funnel to the Home page
  routeToHomePage = () => {
    if(getCurrentUser()) {
      this.props.history.push('/my-account')
    } else {
      this.props.history.replace('/')
    }
  }

  // Route logged-in traffic to the payment page on the default flow
  routeAccountCreationScreen = () => {
    const { enabledPageVariant } = this.props;
    const receivedOptimizelyVariantEnabled = enabledPageVariant === 'receivedOptimizelyVariant';
    const accountCreationPage = <SignupRegister {...this.state} deviceInfo={deviceInfo} receivedOptimizelyVariantEnabled={receivedOptimizelyVariantEnabled} />
    
    if(getCurrentUser()) {
      return <Redirect to="/signup-payment" />
    } else {
      return accountCreationPage;
    }
  }

  // Route Class funnels to thefoos landing page
  routeClassesFunnels = () => {
    window.location.href = process.env.REACT_APP_CODESPARK_BASE_URL;
  }

  componentDidUpdate(nextProps, prevState) {
    if (nextProps.location.pathname !== prevState.pathname) {
      return ({ pathname: nextProps.location.pathname });
    }
    NotificationManager.remove({ id: 1 });
  }

  checkPrivateRoute() {
    let pathname = this.props.location.pathname;
    if (pathname === '/my-account' ||
    pathname === '/checkout' ||
    pathname === '/complete' ||
    pathname === '/dont-go/:page') {
      return true;
    } else {
      return false;
    }
  }

  checkLoginStatus() {
    let isPrivate = this.checkPrivateRoute();
    if (getCurrentUser() === null && isPrivate) {
      this.setState({ isNoSessionPopupOpen: true });
    }
  }

  closeNoSessionPopupOpen() {
    this.setState({ isNoSessionPopupOpen: false });
    this.props.history.push('/login');
  }

  loadLandingPage = () => {
    const { enabledPageVariant } = this.props;
    // Due to potential redirect below, we have to setup incoming query params here
    this.setIncomingQuizQuestionAnswer();
    // mapping of a/b testing routes to their experiment id
    const variantToRouteMapping = {};

    const variantRoute = variantToRouteMapping[enabledPageVariant];
    if (variantRoute) {
      return variantRoute;
    }
    // after above checking, if there's no variant return the email wall
    if (getCurrentUser()) {
      const { location } = window;
      // if user navigated back after created an account send them to thefoos LP
      const currentUrl = location.pathname;
      let lastPage = sessionStorage.getItem('last_page');
      const currentPage = sessionStorage.getItem('current_page');
      if (currentUrl !== currentPage ) lastPage = currentPage;
      if (lastPage ==='/choose-plan') {
        window.location.href = process.env.REACT_APP_CODESPARK_BASE_URL
      } else {
        return (
          <Redirect
            to={{
              pathname: '/choose-plan',
              search: location.search,
            }}
          />
        );
      }
    } else {
      return <EmailLeadWall />;
    }
  }
  
  render() {
    const { enabledPageVariant } = this.props;
    const receivedOptimizelyVariantEnabled = enabledPageVariant === 'receivedOptimizelyVariant';
    const { newResetPassword } = this.state;
    const resetPasswordPath = newResetPassword ? '/request-password-reset' : '/reset-password';

    return(
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_CAPTCHA_V3_KEY}
        useRecaptchaNet="false"
        useEnterprise="false"
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined // optional, default undefined
        }}
      >

      <div className="app-container" ref="app-container">
        <Header
          isScrolling={this.state.isScrolling}
          locationPath={this.props.location.pathname}
        />
        <LoadingBar />

        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <div id="main-content">
          <Switch>
            <Route exact path="/" render={this.loadLandingPage} />
            <Route path='/email-wall' render={() => <EmailLeadWall />} />
            <Route path='/quiz' render={() => <SignupQuiz enabledVariant={enabledPageVariant} />} />
            <Route path='/choose-plan' render={() => (<Home {...this.state} deviceInfo={deviceInfo} receivedOptimizelyVariantEnabled={receivedOptimizelyVariantEnabled} />)} />
            <Route path='/login' render={() => (<Login {...this.state} deviceInfo={deviceInfo} />)} />
            <Route path='/logout' render={() => (<Logout {...this.state} deviceInfo={deviceInfo} />)} />
            <Route path='/signup-register' render={this.routeAccountCreationScreen}/>
            <Route path='/signup-thankyou' render={() => (<SignupComplete {...this.state} deviceInfo={deviceInfo} />)} />
            <Route path='/promo' render={() => (<PromoV2 {...this.state} deviceInfo={deviceInfo} />)} />
            <Route path='/promo-thankyou' render={() => (<PromoComplete {...this.state} deviceInfo={deviceInfo} />)} />
            
            {/* Minerva tryclasses funnel */}
            <Route path="/tryclasses" render={this.routeClassesFunnels} /> 
           
            <Route path="/tryclasses-signup" render={() => (<MinervaSignup {...this.state} deviceInfo={deviceInfo} />)}/>
            <Route path="/tryclasses-login" render={() => <MinervaClassLogin {...this.state} deviceInfo={deviceInfo} />} />
            <Route path="/tryclasses-select" render={() => <ClassLayout {...this.state} deviceInfo={deviceInfo} />} />
            {/* <Route path="/tryclasses-thankyou" render={() => <BookClass {...this.state} deviceInfo={deviceInfo} />} /> */}
            <Route
                path="/tryclasses-thankyou"
                render={() => <BookClass {...this.state} deviceInfo={deviceInfo} />}
              />
            <Route path="/tryclasses-thankyouapp" render={() => <MinervaComplete {...this.state} deviceInfo={deviceInfo} />} />
            {/* <Route path="/tryclasses-schedule" render={() => <MinervaClassScheduling {...this.state} deviceInfo={deviceInfo} />} /> */}
            
            {/* Minerva Sawyer funnel */}
            <Route path="/tryclassesfree" render={() => <Landing {...this.state} deviceInfo={deviceInfo} />} />
            <Route path="/tryclassesfree-signup" render={() => <Signup {...this.state} deviceInfo={deviceInfo} />} />
            <Route path="/tryclassesfree-schedule" render={() => <FreeClass {...this.state} deviceInfo={deviceInfo} />} /> 

            {/* Minerva addclasses funnel */}
            <Route path="/addclasses" render={this.routeClassesFunnels} />
            <Route path="/addclasses-login" render={() => <AddClassesLogin {...this.state} deviceInfo={deviceInfo} />} />
            <Route path="/addclasses-payment" render={() => <AddClassesPayment {...this.state} deviceInfo={deviceInfo} />} />
            {/* <Route path="/addclasses-thankyou-semester" render={() => <SemesterSawyer {...this.state} deviceInfo={deviceInfo} />} /> */}
            <Route
              path="/addclasses-thankyou-semester"
              render={() => <SemesterSawyer {...this.state} deviceInfo={deviceInfo} />}
            />
            {/* <Route path="/addclasses-thankyou-dropin" render={() => <DropinSawyer {...this.state} deviceInfo={deviceInfo} />} /> */}
            <Route
              path="/addclasses-thankyou-dropin"
              render={() => <DropinSawyer {...this.state} deviceInfo={deviceInfo} />}
            />

            <Route path='/how-to-redeem' 
              render={() => (
                <Suspense fallback={renderLoader()}>
                  <HowToRedeem />
                </Suspense>
              )}
            />
            <Route path='/gift' 
              render={() => (
                <Suspense fallback={renderLoader()}>
                  <Gift {...this.state} deviceInfo={deviceInfo} />
                </Suspense> 
              )} 
            />
            <Route path='/girl-scouts-councils-request' 
              render={() => (
                <Suspense fallback={renderLoader()}>
                  <GirlScoutCouncilRequest {...this.state} deviceInfo={deviceInfo} />
                </Suspense>
              )} 
            />
            <Route path='/girl-scouts-councils-request-complete' 
              render={() => (
                <Suspense fallback={renderLoader()}>
                  <GirlScoutCouncilRequestComplete {...this.state} deviceInfo={deviceInfo} />
                </Suspense>
              )} 
            />
            <Route path='/girl-scouts-parents' 
              render={() => (
                <Suspense fallback={renderLoader()}>
                  <GirlScoutsBPInfo {...this.state} deviceInfo={deviceInfo} />
                </Suspense>
              )} 
            />
            <Route path='/volume-purchase' 
              render={() => (
                <Suspense fallback={renderLoader()}>
                  <BulkPurchase {...this.state} deviceInfo={deviceInfo} />
                </Suspense>
              )} 
            />
            <Route path='/complete' 
              render={() => (
                <Suspense fallback={renderLoader()}>
                  <Completion {...this.state} deviceInfo={deviceInfo} />
                </Suspense>
              )} 
            />
            <Route path='/volume-purchase-complete' 
              render={() => (
                <Suspense fallback={renderLoader()}>
                  <BulkPurchaseComplete {...this.state} deviceInfo={deviceInfo} />
                </Suspense>
              )} 
            />
            <Route path='/gift-complete' 
              render={() => (
                <Suspense fallback={renderLoader()}>
                  <CompletionGift {...this.state} deviceInfo={deviceInfo} />
                </Suspense>
              )} 
            />
            <Route path='/gympass' 
              render={() => (
                <Suspense fallback={renderLoader()}>
                  <GympassPage {...this.state} deviceInfo={deviceInfo} />
                </Suspense>
              )} 
            />
            {/* route WeGrow landing and register pages to the home page */}
            <Route path='/wegrowsa' 
              render={this.routeToHomePage}
            />
            <Route path='/wegrowsa-register' 
              render={this.routeToHomePage}
            />
            {/* route Lego landing and register pages to the home page */}
            <Route path="/legovip" render={this.routeToHomePage} />
            <Route path="/legovip-register" render={this.routeToHomePage} />

            <Route path="/caprikon" 
              render={() => (
                <Suspense fallback={renderLoader()}>
                  <CaprikonLandingPage {...this.state} deviceInfo={deviceInfo} />
                </Suspense>
              )} 
            />
            <Route path="/caprikon-register" 
              render={() => (
                <Suspense fallback={renderLoader()}>
                  <CaprikonRegisterPage {...this.state} deviceInfo={deviceInfo} />
                </Suspense>
              )} 
            />
            <Route path={resetPasswordPath}
              render={() => (
                <Suspense fallback={renderLoader()}>
                  <ResetPassword {...this.state} deviceInfo={deviceInfo} />
                </Suspense>
              )} 
            />

            { newResetPassword && (
              <Route path='/reset-password'
                render={() => (
                  <Suspense fallback={renderLoader()}>
                    <ChangePassword {...this.state} deviceInfo={deviceInfo} />
                  </Suspense>
                )}
              />
            )}

            
            <PrivateRoute path='/my-account'>
              <AccountV2 {...this.state} deviceInfo={deviceInfo} />
            </PrivateRoute>
            <PrivateRoute path='/dont-go/:page'>
              <Suspense fallback={renderLoader()}>
                <Cancel {...this.state} deviceInfo={deviceInfo} />
              </Suspense>
            </PrivateRoute>
            <PrivateRoute path='/signup-payment'>
              <SignupPayment {...this.state} deviceInfo={deviceInfo} />
            </PrivateRoute>
            <PrivateRoute path='/promo-payment'>
              <PromoPayment {...this.state} deviceInfo={deviceInfo} />
            </PrivateRoute>
            {/* <PrivateRoute path="/tryclasses-payment">
              <MinervaPayment {...this.state} deviceInfo={deviceInfo} />
            </PrivateRoute> */}
            <PrivateRoute path="/tryclasses-payment">
              <MinervaPaymentV2 {...this.state} deviceInfo={deviceInfo} />
            </PrivateRoute>
            <PrivateRoute path="/tryclasses-paymentapp">
              <MinervaPaymentV2 {...this.state} deviceInfo={deviceInfo} />
            </PrivateRoute>
             {/* route WeGrow payment page to /my-account */} 
            <PrivateRoute path='/wegrowsa-payment'>
              <Redirect to="/my-account" />
            </PrivateRoute>
            {/* route Lego payment page to /my-account */} 
            <PrivateRoute path="/legovip-payment"> <Redirect to="/my-account" /></PrivateRoute> 
           
            <PrivateRoute path="/caprikon-payment">
              <Suspense fallback={renderLoader()}>
                <CaprikonPaymentPage {...this.state} deviceInfo={deviceInfo} />
              </Suspense>
            </PrivateRoute>

            <Route component={NotFoundPage} />
          </Switch>
        </div>
        <Footer {...this.state} locationPath={this.props.location.pathname} />

        <NotificationContainer ref="notification-container" />
        <InfoPopup
          isOpen={this.state.isNoSessionPopupOpen}
          closePopup={this.closeNoSessionPopupOpen}
          infoText={`It seems like you have logged out of your account. You will be prompted to log back in.
                    If you find this is an error, please contact us.`}
        />
      </div>

      </GoogleReCaptchaProvider>

    );
  }
}

const pageVariants = [
  'receivedOptimizelyVariant',
  'quizVariant1',
  'quizVariant2',
  'quizHomepage',
];
const mapStateToProps = state => {
  const enabledPageVariant = selectEnabledExperiment(state, pageVariants);
  return {
    enabledPageVariant,
  }
}

const mapDispatchToProps = {
  setInitialSelectedOfferId,
  setQuizQuestionAnswer,
  setExternalAnsweredQuestionId,
  saveUserCountry,
  // Minerva add on class for addclasses funnel
  setMinervaAddedClassId,
  // Minerva selected class for tryclasses funnel
  setMinervaSelectedClassId,
  setMinervaSelectedClass,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
