import React, { useRef } from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import AllPlansIncludeVariant1 from '../components/AllPlansInclude/Variant1';
import AllPlansIncludeVariant2 from '../components/AllPlansInclude/Variant2';
import Faqs from '../components/Faqs';
import { Helmet } from 'react-helmet';
import SubscriptionBlock from '../../../components/elements/SubscriptionBlock';

function PlanSelectorRedesign({ nextLocation }) {
  const planSelectorPageRedesignVariant1 = useSelector(
    state => state.experiments.planSelectorPageRedesignVariant1
  );
  const planSelectorPageRedesignVariant2 = useSelector(
    state => state.experiments.planSelectorPageRedesignVariant2
  );
  // CVR-921
  const subscriptionBlockRef = useRef();

  const scrollToSubscriptionBlock = () => {
    subscriptionBlockRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  // END CVR-921

  return (
    <>
      <Helmet>
        <title>Sign up | codeSpark Academy</title>
      </Helmet>

      <Box className="signup-plan-redesign">
        <Box ref={subscriptionBlockRef}>
          <SubscriptionBlock nextLocation={nextLocation} useRedesign />
        </Box>

        {planSelectorPageRedesignVariant1 && (
          <AllPlansIncludeVariant1 nextLocation={nextLocation} />
        )}
        {planSelectorPageRedesignVariant2 && (
          <AllPlansIncludeVariant2
            nextLocation={nextLocation}
            scrollToSubscriptionBlock={scrollToSubscriptionBlock}
          />
        )}

        <Faqs />
      </Box>
    </>
  );
}

export default PlanSelectorRedesign;
