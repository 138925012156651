import React, { useEffect, lazy, Suspense } from 'react';
import { connect } from 'react-redux';

import { trackPageMixpanel } from '../../../services/functions';
import './PromoCompleteWrapper.scss';
import { sendFacebookEvent } from '../../../services/facebook-capi';
import { sendAnalyticsEvent } from '../../../services/analytics';
import { getPageUrl } from '../../../utils/locationUtils';
import { gtmPageviewEvent } from '../../../services/google-tag-manager';

const PromoSplashComponent = lazy(() => import('../PromoSplashComponent'));
const renderLoader = () => <h1>Loading</h1>;

function PromoCompletion(props) {
  const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;

  useEffect(() => {
    trackPageMixpanel('WebPaymentsV3 Page: Promo Checkout Complete');
    sendFacebookEvent({
      event_name: 'Visit',
      event_source_url: window.location.href,
      custom_data: {screen: 'Promo Checkout Complete'}
    });
    sendAnalyticsEvent('web_onboarding_screen_thank_you', {
      'app': 'web-site',
      'page_URL': getPageUrl(window.location),
    }, true);
    gtmPageviewEvent({
      pageName: 'WebPaymentsV3 Page: Promo Checkout Complete',
      componentProps: {
        ...props,
        location: { pathname: props.pathname },
      },
    });
  }, [props]);

  return (
    <div className="promo-completed-container">
      <div className="promo-splash grid-x">
        <Suspense fallback={renderLoader()}>
          <PromoSplashComponent />
        </Suspense>
        <div className="signup-complete-section text-center small-12 medium-12 large-6">
          <div className="step-progress-image text-center">
            <img src={`${imgURL}/accounts/progress_steps_complete.svg`} alt="progress line" />
          </div>
          <div className="center-container">
            <h3 className="top-title">Start playing in 3 easy steps</h3>
            <div className="numbered-step"><h2>1</h2></div>
            <h2 className="step-heading">Download the app</h2>
            <div className="app-store-badges">
              <a
                className="apple"
                href="https://itunes.apple.com/us/app/codespark-academy-foos-coding/id923441570?mt=8"
                target="_blank"
                rel="noopener noreferrer"
                data-event-category="app-download"
                data-event-action="signup-complete"
                data-event-label="ios"
                data-on="click"
              >
                <img src={`${imgURL}/global/icons/apple_store_button.svg`} alt="Apple app store link" />
              </a>
              <a
                className="google"
                href="http://play.google.com/store/apps/details?id=org.codespark.thefoos&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                target="_blank"
                rel="noopener noreferrer"
                data-event-category="app-download"
                data-event-action="signup-complete"
                data-event-label="android-google"
                data-on="click"
              >
                <img src={`${imgURL}/global/icons/google_store_button.svg`} alt="Google play link" />
              </a>
            </div>
            <p className="instructions">Search “codeSpark” in the App Store, Google Play or Amazon Appstore. To play online, visit <a href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/play`}>codespark.com/play</a></p>
            <div className="numbered-step"><h2>2</h2></div>
            <h2 className="step-heading">Sign in</h2>
            <p className="instructions">Use your new account email & password to sign in</p>
            <div className="numbered-step"><h2>3</h2></div>
            <h2 className="step-heading">Let your kids take over!</h2>
            <p className="instructions">You’re all set to start learning with codeSpark Academy and the Foos!</p>
          </div>
          <div className="bottom-caption">
            <p>Need help? <a href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/contact`}>Contact us</a> or visit our <a href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/frequently-asked-questions`}>FAQ page</a></p>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  webOffer: state.appState.webOffer
});

export default connect(mapStateToProps)(PromoCompletion);
