import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Textbox from '../../../../../components/elements/Textbox';
import secureIcon from '../../../../../lib/icons/secure.svg';
import { getDurationMonths } from '../../../../../utils/planUtils';
import ExpressCheckout from '../ExpressCheckout';
import { useStyles } from './styles';
import { usePayment } from '../../providers';

function PaymentForm(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    processPayment,
    isProcessingPayment,
    selectedOffer,
  } = usePayment();
  const threeOndemandClassesEnabled = useSelector(state => state.experiments.threeOndemandClasses);

  return (
    <React.Fragment>
      <Grid container direction="row">
        <Grid item xs={12} md={6}>
          {/* <Typography className={classes.standardCheckoutLabel} variant="body1">
            EXPRESS CHECKOUT
          </Typography> */}
          <ExpressCheckout />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className={classes.standardCheckoutLabel} variant="body1">
            STANDARD CHECKOUT
          </Typography>
          <div className="credit-card-fields">
            <div className="input-fields cardholder-name">
              <div className="form-control small-12 cell">
                <Textbox id="cardholder-name" extraClass="hosted-field minerva-payment" type="payment" />
              </div>
            </div>
            <div className="input-fields">
              <div className="small-12 cell">
                <Textbox id="card-number" extraClass="hosted-field minerva-payment" type="payment" />
              </div>
            </div>
            <div className="input-fields grid-x grid-margin-x align-justify">
              <div className="small-12 medium-4 cell">
                <Textbox id="expiration-date" extraClass="hosted-field minerva-payment" type="payment" />
              </div>
              <div className="small-12 medium-4 cell">
                <Textbox
                  id="cvv"
                  extraClass="hosted-field minerva-payment"
                  type="payment"
                  placeholder="Security Code"
                />
              </div>
              <div className="small-12 medium-4 cell">
                <Textbox id="postal-code" extraClass="hosted-field minerva-payment" type="payment" />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.secureContainer}>
        <img className={classes.secureImg} src={secureIcon} alt="secure" />
        <Typography variant="body1">Secure payment processed by Braintree, a Paypal company</Typography>
      </Grid>
      <Grid container className={classes.actionContainer}>
        <Grid item xs={12} md={6} className={classes.backButtonContainer}>
          <Button
            className={`${classes.backButton} bot-backButton`}
            fullWidth
            disableElevation
            id="back-button"
            variant="contained"
            onClick={() => history.push('/tryclasses')}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12} md={6} className={classes.backButtonContainer}>
          <Button
            disableElevation
            fullWidth
            id="submit-payment"
            color="primary"
            variant="contained"
            disabled={isProcessingPayment}
            className={`${classes.submitButton} bot-submitButton`}
            onClick={() => processPayment('card')}
          >
            Save Payment
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.pageSubTextContainer}
      >
        <Typography variant="body1">
        {threeOndemandClassesEnabled
            ? `$${selectedOffer.price} billed every ${getDurationMonths(
                selectedOffer.subscriptionDuration
              )} months after ${selectedOffer.trialDurationDays}-day promo period.`
            : `$${selectedOffer.price} monthly after ${selectedOffer.trialDurationDays}-day promo period.`}
        </Typography>
        <Typography variant="body1">No commitment, cancel anytime.</Typography>
      </Grid>
    </React.Fragment>
  );
}

export default memo(PaymentForm);
