/**
 * Generic analytics functions so we can swap out 3rd party analtyics more easily.
 * i.e. we can change from Mixpanel to Metarouter or add GA
 */
import mixpanel from 'mixpanel-browser';
import { COOKIES_KEY_PLAN_TAG } from '../constants/cookies';
import { DEFAULT_TAG } from '../contexts/subscriptionPlans';
import { featureFlagClient, isFlagEnabled, isVariantEnabled } from '../featureFlags';
import { cookies } from '../utils/cookieUtils';
import { getPageUrl } from '../utils/locationUtils';

// turn off Metarouter using the .env files, prop is 'REACT_APP_WANT_METAROUTER'
const WANT_METAROUTER = process.env.REACT_APP_WANT_METAROUTER ? JSON.parse(process.env.REACT_APP_WANT_METAROUTER): false;

/**
 * Get all variation ids from a campaign
 * @returns {array} - an array of all variation ids
 */
 export const getExperimentVariationIds = () => {
  // feature flag client has not initialized yet or errored
  if (!featureFlagClient.readyEventEmitted) {
    return null;
  }

  const toggles = featureFlagClient.getAllToggles();
  const variationIds = toggles
    .filter(toggle => isFlagEnabled(toggle) && isVariantEnabled(toggle.variant))
    .map(toggle => ({ id: toggle.name, variant: toggle.variant.name }));

  return variationIds;
};

/**
 * Send an analtyics event
 * @param {string} eventName
 * @param {*} props - an object with event properties
 * @param {boolean} shouldSendVariantID - a bollean to indicate if we should append the experiment variant id
 */
export const sendAnalyticsEvent = (eventName, props = {}, shouldSendVariantID = true) => {
  // append variation id if the event wants it, and if it exists
  if (shouldSendVariantID) {
    const variationIds = getExperimentVariationIds();
    if (variationIds) {
      props.unleash_ab_test_ids = variationIds;
    }
  }

  const planTag = cookies.get(COOKIES_KEY_PLAN_TAG) || DEFAULT_TAG;
  props.planTag = planTag;
  mixpanel.track(eventName, props);

  // also send to Metarouter
  // note: analytics is a global prop. Metarouter script is embedded in index.html
  if(window.analytics && WANT_METAROUTER) {
    window.analytics.track(eventName, props);
  }
};

/**
 * Send a payment rejected error analtyics event
 * @param {string} errorMessage - The error message user received to send with data event
 */
export const sendAnalyticsPaymentRejectedEvent = (errorMessage) => {
  sendAnalyticsEvent('web_payment_method_rejected', {
    'app': 'web-site',
    'reason': errorMessage,
  }, true);
}

/**
 * Send a purchase completed analytics event
 * @param {Object} offer - Selected offer that was purchased
 * @param {string} paymentType - Payment method used
 * @param {Object} extraProps - Any extra data attributes to send
 */
export const sendAnalyticsPurchaseCompleteEvent = (offer, paymentType, extraProps) => {
  sendAnalyticsEvent('web_subscription_purchase_completed', {
    app: 'web-site',
    plan_selected: getAnalyticsPlanType(offer),
    coupon: null,
    payment_selected: getAnalyticsPaymentType(paymentType),
    trial_length_days: offer.trialDurationDays,
    ...extraProps
  });
};

/**
 * Send a page view event
 * @param {string} eventName - The page view event name
 */
export const sendPageViewEvent = (eventName) => {
  sendAnalyticsEvent(eventName, {
    app: 'web-site',
    page_URL: getPageUrl(window.location)
  })
}

/**
 * Gets the value to send with the event for user optin
 * @param {string} errorMessage - The error message user received to send with data event
 * @returns {('Y'|'N')}
 */
export const getAnalyticsOptInValue = (optIn) => optIn ? 'Y' : 'N';

/**
 * Formatted names for analytics for the payment methods
 * @param {string} paymentType
 * @returns
 */
export const getAnalyticsPaymentType = (paymentType) => {
  switch (paymentType) {
    case 'card':
      return 'Credit Card';
    case 'applePay':
      return 'Apple Pay';
    case 'googlePay':
      return 'Google Pay';
    case 'paypal':
      return 'PayPal';
  }
}

export const getAnalyticsPlanType = (plan) => plan.planDataKey;
