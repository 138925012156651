import React, { memo, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStyles } from '../../styles';

function SignupCompleteLayout(props) {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Box className={classes.pageContainer}>
        <Container maxWidth="md" className={classes.containerWrapper}>
          {props.headerGreeting}
          {props.contentSlot}
        </Container>
      </Box>
    </React.Fragment>
  );
}

export default memo(SignupCompleteLayout);
