import {
  SET_EXTERNAL_ANSWERED_QUESTION_ID,
  SET_QUIZ_QUESTIONS,
  SET_QUIZ_QUESTION_ANSWER,
} from './constants';
import * as questionsData from './data/questionsData';

export const initialQuizState = {
  quiz: {
    questions: [],
    answers: {},
    externalAnsweredQuestionId: null,
  },
};

// App state is not split up so setting it here as an object and then merging
// it back into the main app reducer to keep reducer file from getting too large
export const quizReducers = {
  [SET_QUIZ_QUESTIONS]: (state, action) => {
    state.quiz.questions = questionsData[action.payload];
  },
  [SET_QUIZ_QUESTION_ANSWER]: (state, action) => {
    const { questionId, answer } = action.payload;
    state.quiz.answers[questionId] = answer;
  },
  [SET_EXTERNAL_ANSWERED_QUESTION_ID]: (state, action) => {
    state.quiz.externalAnsweredQuestionId = action.payload;
  },
};
