import React, { useEffect } from 'react';
import { Container, Box, makeStyles } from '@material-ui/core';
import ClassHeader from './ClassHeader';
import ClassCompare from './ClassCompare';
import ClassFooter from './ClassFooter';
import ClassAccordion from './ClassAccordion';
import { useSelector } from 'react-redux';
import ClassCompareVariant from './variants/ClassCompareVariant';
import { sendAnalyticsEvent } from '../../../../services/analytics';
import { useSendEventWhenFFReady } from '../../../../hooks/useAnalyticsEvent';
import { gtmPageviewEvent } from '../../../../services/google-tag-manager';

const imgURL = `${process.env.REACT_APP_CLOUDFRONT_URL}/accounts/minervapayment`;

const useClassLayoutStyles = makeStyles((theme) => ({
  layoutWrapper: {
    [theme.breakpoints.up('lg')]: {
      backgroundImage: `url(${imgURL}/foohead-pattern.svg)`,
      backgroundRepeat: 'repeat',
      padding: '80px 310px',
    },
  },
  layoutContainer: {
    padding: '60px',
    background: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: '48px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '45px 24px',
    },
    [theme.breakpoints.up('lg')]: {
      borderRadius: '20px',
    },
  },
}));

export default function ClassLayout(props) {
  const sendEventWhenFFReady = useSendEventWhenFFReady();
  const classes = useClassLayoutStyles();
  const threeOndemandClassesEnabled = useSelector(state => state.experiments.threeOndemandClasses);

  useEffect(() => {
    window.scrollTo(0, 0);

    sendAnalyticsEvent('Memberships v2 Page: Plan Selection');

    gtmPageviewEvent({
      pageName: 'Memberships v2 Page: Plan Selection Page',
      componentProps: {
        ...props,
        location: { pathname: props.pathname },
      },
    });
  }, []);

  useEffect(() => {
    if (!threeOndemandClassesEnabled) {
      sendEventWhenFFReady('web_onboarding_screen_choose_plan');
    }
  }, [threeOndemandClassesEnabled, sendEventWhenFFReady]);

  const headerMap = [
    'STEP 1 OF 4',
    'Select a plan',
    'All class packages include unlimited codeSpark app access and a free trial.',
  ];

  const footerMap = [
    'Upgrade or cancel anytime.',
    <a href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/terms`}>
      Terms and Conditions <span style={{ color: 'black' }}>apply</span>
    </a>,
  ];


  if (threeOndemandClassesEnabled) {
    return <ClassCompareVariant />
  }

  return (
    <>
      <Box className={classes.layoutWrapper}>
        <Container className={classes.layoutContainer}>
          <ClassHeader items={headerMap} />
          <ClassCompare />
          <ClassFooter items={footerMap} />
        </Container>
      </Box>
      <ClassAccordion />
    </>
  );
}
