import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import CodeSparkMuiTypography from '../../../../components/elements/CodeSparkMuiTypography';
import { CtaNextButton } from './Variant2';

const imgURL = `${process.env.REACT_APP_CLOUDFRONT_URL}/accounts`;

const valueProps = [
  'Unlimited coding challenges',
  'New skills EVERY WEEK',
  '100s of educational puzzles',
  'Unlimited access to creativity suite',
  'Access for up to 3 children',
];

const useVariant1Styles = makeStyles(theme => ({
  allPlansIncludeSection: {
    padding: '5rem 11rem',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F0F8FF',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0 0',
    },
  },
  widthWrapper: {
    maxWidth: '50rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  checkmarkIcon: {
    minWidth: '2.625rem',
  },
  header: {
    color: '#26282A',
    textAlign: 'center',
  },
  contentWrapper: {
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '.5rem',
    },
  },
  imageWrapper: {
    borderRadius: '.625rem',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
    },
  },
  valuePropsContainer: {
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      alignItems: 'normal',
    },
  },
  valueProps: {
    padding: '0 1.5rem 2rem',
  },
  valuePropText: {
    color: '#53565A',
    fontFamily: 'Montserrat',
    fontSize: '1.125rem',
    lineHeight: 'initial',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },
  },
  ctaContainer: {
    marginTop: '1.25rem',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}));

export default function AllPlansIncludeVariant1({ nextLocation }) {
  const classes = useVariant1Styles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box component="section" className={classes.allPlansIncludeSection}>
      <Box className={classes.widthWrapper}>
        <CodeSparkMuiTypography variant="h2" className={classes.header}>
          All Plans Include:
        </CodeSparkMuiTypography>

        <Box className={classes.contentWrapper}>
          <Grid container spacing={isMobile ? 0 : 8} className={classes.valuePropsContainer}>
            <Grid item xs={12} sm={6}>
              <Box className={classes.imageWrapper}>
                <img
                  src={`${imgURL}/all_plans_include_child_ipad.png`}
                  alt="Child holding iPad with codeSpark"
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} className={classes.valueProps}>
              <List>
                {valueProps.map((valueProp, i) => {
                  return (
                    <ListItem key={`valueProp_${i}`} disableGutters>
                      <ListItemIcon className={classes.checkmarkIcon}>
                        <img
                          src={`${imgURL}/checkmark_dark_blue.svg`}
                          aria-hidden="true"
                          alt="checkmark"
                        />
                      </ListItemIcon>

                      <ListItemText disableTypography>
                        <CodeSparkMuiTypography className={classes.valuePropText}>
                          {valueProp}
                        </CodeSparkMuiTypography>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>

              <CtaNextButton className={classes.ctaContainer} to={nextLocation}>
                Start Free Trial
              </CtaNextButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
