import React, { useState, lazy, Suspense, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ClassAccordion from './ClassAccordion';

const ManagePlan = lazy(() => import('./ManagePlanComponent'));
const ManageAccount = lazy(() => import('./ManageAccountComponent'));
const BillingHistory = lazy(() => import('./BillingHistoryComponent'));
const NotificationSettings = lazy(() => import('./NotificationSettingsComponent'));
const PlayInstructions = lazy(() => import('./PlayInstructionsComponent'));

const renderLoader = () => <p>Loading</p>;

export default function AccountAccordion(props) {
  const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;

  const hasPlan = props.subDetails.plan || props.subDetails.classesPlan ? true : false;

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    if (panel === 'appPlan' && !isExpanded) {
      setExpanded('panel1');
      return;
    } else if (panel === 'classPlan' && !isExpanded) {
      setExpanded('panel1');
      return;
    } else {
      setExpanded(isExpanded ? panel : false);
    }
  };

  return (
    <div className="account-links">
      <div className="account-content">
        <Accordion
          expanded={expanded === 'panel1' || expanded === 'appPlan' || expanded === 'classPlan'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={hasPlan ? 'manage-plans-header' : ''}
          >
            <img className="tab-icon" src={`${imgURL}/accounts/credit_card_tabicon.svg`} alt="Manage plan icon" />
            <h4>Manage Plans</h4>
            {expanded === 'panel1' || expanded === 'appPlan' || expanded === 'classPlan' ? (
              <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
            ) : (
              <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
            )}
          </AccordionSummary>

          {props.subDetails.plan && (
            <AccordionDetails className="mobile-sub-accordion">
              <Accordion
                expanded={expanded === 'appPlan'}
                onChange={handleChange('appPlan')}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ backgroundColor: 'white' }}
                >
                  <h4 style={{ marginLeft: '2.2rem' }}>{props.subDetails.plan.planName}</h4>
                  {expanded === 'appPlan' ? (
                    <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
                  ) : (
                    <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Suspense fallback={renderLoader()}>
                    <div className="account-detail-item">
                      <ManagePlan
                        subDetails={props.subDetails}
                        annualPlan={props.annualPlan}
                        monthlyPlan={props.monthlyPlan}
                        annualSavings={props.annualSavings}
                        userId={props.user.objectId}
                        hasActiveSubscription={props.hasActiveSubscription}
                        hasExpirationDate={props.hasExpirationDate}
                        planExpiresAt={props.planExpiresAt}
                      />
                    </div>
                  </Suspense>
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          )}
          {props.subDetails.classesPlan && (
            <AccordionDetails className="mobile-sub-accordion">
              <Accordion
                expanded={expanded === 'classPlan'}
                onChange={handleChange('classPlan')}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ backgroundColor: 'white' }}
                >
                  <h4 style={{ marginLeft: '2.2rem' }}>{props.subDetails.classesPlan.planName}</h4>
                  {expanded === 'classPlan' ? (
                    <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
                  ) : (
                    <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Suspense fallback={renderLoader()}>
                    <div className="account-detail-item">
                      <ClassAccordion
                        subDetails={props.subDetails}
                        annualPlan={props.annualPlan}
                        monthlyPlan={props.monthlyPlan}
                        annualSavings={props.annualSavings}
                        userId={props.user.objectId}
                        hasActiveSubscription={props.hasActiveSubscription}
                        hasExpirationDate={props.hasExpirationDate}
                        planExpiresAt={props.planExpiresAt}
                      />
                    </div>
                  </Suspense>
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          )}

          {!hasPlan && (
            <AccordionDetails>
              <Suspense fallback={renderLoader()}>
                <div className="account-detail-item">
                  <ManagePlan
                    subDetails={props.subDetails}
                    annualPlan={props.annualPlan}
                    monthlyPlan={props.monthlyPlan}
                    annualSavings={props.annualSavings}
                    userId={props.user.objectId}
                    hasActiveSubscription={props.hasActiveSubscription}
                    hasExpirationDate={props.hasExpirationDate}
                    planExpiresAt={props.planExpiresAt}
                  />
                </div>
              </Suspense>
            </AccordionDetails>
          )}
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
            <img className="tab-icon" src={`${imgURL}/accounts/profile_tabicon.svg`} alt="Manage accounts icon" />
            <h4>Manage Account</h4>
            {expanded === 'panel2' ? (
              <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
            ) : (
              <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Suspense fallback={renderLoader()}>
              <div className="account-detail-item">
                <ManageAccount subDetails={props.subDetails} hasActiveSubscription={props.hasActiveSubscription} />
              </div>
            </Suspense>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
            <img className="tab-icon" src={`${imgURL}/accounts/reciept_tabicon.svg`} alt="Billing history icon" />
            <h4>Billing History</h4>
            {expanded === 'panel3' ? (
              <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
            ) : (
              <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
            )}
          </AccordionSummary>
          <AccordionDetails className="billing-history">
            <Suspense fallback={renderLoader()}>
              <BillingHistory
                paymentHistory={props.paymentHistory}
                storeName={props.subDetails.plan?.store}
                hasActiveSubscription={props.hasActiveSubscription}
              />
            </Suspense>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary aria-controls="panel4a-content" id="panel4a-header">
            <img className="tab-icon" src={`${imgURL}/accounts/notifications_tabicon.svg`} alt="Notifications icon" />
            <h4>Notification Settings</h4>
            {expanded === 'panel4' ? (
              <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
            ) : (
              <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
            )}
          </AccordionSummary>
          <AccordionDetails className="notification-settings notifications-container">
            <p className="notification-settings-email">
              <span className="bold">Account email:</span>&nbsp; {props.subDetails.customerData?.email}
            </p>
            <Suspense fallback={renderLoader()}>
              <NotificationSettings />
            </Suspense>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary aria-controls="panel5a-content" id="panel5a-header">
            <img className="tab-icon" src={`${imgURL}/accounts/question_tabicon.svg`} alt="Play icon" />
            <h4>How to Play</h4>
            {expanded === 'panel5' ? (
              <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
            ) : (
              <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Suspense fallback={renderLoader()}>
              <PlayInstructions />
            </Suspense>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
