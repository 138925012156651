/**
 * Accepts a string with templates with variable substitution and a key-value object
 * with the replacements. This is similar to how python's str.format works and allows us
 * to define strings and dynamically replace the variables
 * @param {string} string - template string with variables to replace
 * @param {Object} replacements - object where the keys are the variables in the template string to replace
 * @returns {string} string with the templates removed and replaced with the values in replacements
 */
export const templateStringFormat = (string = '', replacements) => {
  const templateRegex = /\{(\w+)\}/g;
  return string.replace(templateRegex, (match, p1) => replacements[p1]);
};
