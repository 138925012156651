import React, { useEffect, useState } from 'react';
import './FooterWrapper.scss';

function Footer(props) {
  const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;
  const baseURL = process.env.REACT_APP_CODESPARK_BASE_URL;
  const [isFullBleed, setIsFullBleed] = useState(false);

  useEffect(() => {
    if (
      props.locationPath === '/promo' ||
      props.locationPath === '/promo-payment' ||
      props.locationPath === '/promo-thankyou' ||
      props.locationPath === '/my-account' ||
      props.locationPath === '/tryclasses' ||
      props.locationPath === '/tryclasses-signup' ||
      props.locationPath === '/tryclasses-login' ||
      props.locationPath === '/tryclasses-schedule' ||
      props.locationPath === '/tryclasses-payment' ||
      props.locationPath === '/tryclasses-paymentapp' ||
      props.locationPath === '/tryclasses-thankyou' ||
      props.locationPath === '/tryclassesfree' ||
      props.locationPath === '/tryclassesfree-signup' ||
      props.locationPath === '/tryclassesfree-schedule' ||
      props.locationPath === '/tryclasses-thankyouapp' ||
      props.locationPath === '/tryclasses-select' ||
      props.locationPath === '/addclasses' ||
      props.locationPath === '/addclasses-login' ||
      props.locationPath === '/addclasses-thankyou-dropin' ||
      props.locationPath === '/addclasses-thankyou-semester' ||
      props.locationPath === '/addclasses-payment'
    ) {
      setIsFullBleed(true);
    } else {
      setIsFullBleed(false);
    }
  }, [props.locationPath]);

  return (
    <div className={`funnel-footer ${isFullBleed ? '' : 'no-bleed'}`}>
      <div className="app-icon-block grid-x grid-padding-x align-justify align-middle">
        <div className="app-store-badges small-12 medium-4">
          <a
            className="apple"
            href="https://itunes.apple.com/us/app/codespark-academy-foos-coding/id923441570?mt=8"
            target="_blank"
            rel="noopener noreferrer"
            data-event-category="app-download"
            data-event-action="global-footer"
            data-event-label="ios"
          >
            <img src={`${imgURL}/global/icons/apple_store_button.svg`} alt="Apple app store link" />
          </a>
          <a
            className="google"
            href="http://play.google.com/store/apps/details?id=org.codespark.thefoos&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            target="_blank"
            rel="noopener noreferrer"
            data-event-category="app-download"
            data-event-action="global-footer"
            data-event-label="android-google"
          >
            <img src={`${imgURL}/global/icons/google_store_button.svg`} alt="Google play link" />
          </a>
        </div>
        <div className="logo-footer small-12 medium-4 text-center">
          <img src={`${imgURL}/global/logo/codespark-by-begin-white.png`} alt="codeSpark Academy" />
        </div>
        <div className="social-links small-12 medium-4">
          <a
            href="https://www.facebook.com/codesparkinc/"
            target="_blank"
            rel="noopener noreferrer"
            data-event-category="social"
            data-event-action="global-footer"
            data-event-label="facebook"
          >
            <img src={`${imgURL}/global/icons/facebook_footer_icon.svg`} alt="Facebook social link" />
          </a>
          <a
            href="http://twitter.com/codesparkapp"
            target="_blank"
            rel="noopener noreferrer"
            data-event-category="social"
            data-event-action="global-footer"
            data-event-label="twitter"
          >
            <img src={`${imgURL}/global/icons/twitter_footer_icon.svg`} alt="Twitter social link" />
          </a>
          <a
            href="https://www.instagram.com/codesparkapp/"
            target="_blank"
            rel="noopener noreferrer"
            data-event-category="social"
            data-event-action="global-footer"
            data-event-label="instagram"
          >
            <img src={`${imgURL}/global/icons/instagram_footer_icon.svg`} alt="Instagram social link" />
          </a>
        </div>
      </div>
      <div className="footer-crumb grid-x grid-padding-x">
        <div className="copyright small-12 medium-2 large-2">
          <b>&copy; codeSpark {new Date().getFullYear()}</b>
        </div>
        <div className="center small-12 medium-2 large-1">
          <a href={`${baseURL}/terms`}>Terms</a>
        </div>
        <div className="privacy small-12 medium-2 large-1">
          <a href={`${baseURL}/privacy`}>Privacy</a>
        </div>
        <div className="small-12 medium-3 large-4 center">
          <a href={`${baseURL}/privacy/#ccpa`}>Do Not Sell My Personal Information</a>
        </div>
        <div className="compliance small-12 medium-3 large-4">Updated for CCPA compliance effective Jan 1, 2020</div>
      </div>
    </div>
  );
}

export default Footer;
