import React from 'react';
import { Box, styled } from '@material-ui/core';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { colors } from '../../../../../theme/constants';
import CodeSparkMuiTypography from '../../../CodeSparkMuiTypography';
import { PlanCard as BasePlanCard } from '../SubscriptionPlanCard';
import { createPlanBotClass } from '../botUtils';

const imgURL = `${process.env.REACT_APP_CLOUDFRONT_URL}/global`;

const PlanCardContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: '22.125rem',
  width: 'calc(50% - 2rem)',
  flex: '0 0 auto',
  marginLeft: '1rem',
  marginRight: '1rem',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    width: 'calc(50% - 1rem)',
  },
}));

const PlanCard = styled(BasePlanCard)(({ theme }) => ({
  boxShadow: `0 0 0 0.125rem ${colors.secondary.lightGrey}`,
  alignItems: 'center',
  padding: '2rem 0.375rem',
  textAlign: 'center',
  '&.selected': {
    boxShadow: `0 0 0 0.125rem ${colors.primary.blue}`,
    backgroundColor: colors.primary.blue1,
    filter: `drop-shadow(0 0.625rem 0.5rem rgba(0, 0, 0, 0.2))`,
  },

  [theme.breakpoints.down('sm')]: {
    padding: '1.125rem 0.5rem',
  },
}));

const PlanCardTitle = styled(CodeSparkMuiTypography)(({ theme }) => ({
  color: colors.primary.blue,

  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}));

const PlanCardEyebrowHeader = styled(CodeSparkMuiTypography)(({ theme }) => ({
  color: colors.primary.darkBlue,
  textTransform: 'uppercase',
  marginBottom: '0.25rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
    letterSpacing: '0.0156rem',
    lineHeight: '0.625rem',
    marginBottom: '0.75rem',
  },
}));

const PlanPrice = styled(CodeSparkMuiTypography)({
  fontWeight: 'bold',
  color: colors.primary.darkBlue,
});

const PlanOriginalPrice = styled(CodeSparkMuiTypography)(({ theme }) => ({
  color: '#C61D1D',
  marginRight: '0.625rem',
  [theme.breakpoints.down('sm')]: {
    marginRight: '0.1875rem',
  },
}));

const PlanTrial = styled(CodeSparkMuiTypography)(({ theme }) => ({
  color: '#53565A',
  fontWeight: 'bold',
  marginTop: '0.5rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
  },
}));

const BillingLegal = styled(CodeSparkMuiTypography)(({ theme }) => ({
  color: '#53565A',
  marginTop: '0.375rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
  },
}));

const GlitchWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: 10,
  top: '-3.1875rem',
  right: 0,

  [theme.breakpoints.down('sm')]: {
    width: '5.0625rem',
    top: '-2rem',
    right: '-0.375rem',
  },
}));

const FlagWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-2.375rem',
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: colors.primary.yellow,
  padding: '0.3125rem 0.9375rem',
  borderRadius: '0.625rem 0.625rem 0 0',
  [theme.breakpoints.down('sm')]: {
    left: '0.5rem',
    transform: 'none',
    top: '-2rem',
  },
}));

const FlagText = styled(CodeSparkMuiTypography)(({ theme }) => ({
  color: colors.primary.black,
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
  },
}));

export default function SubscriptionPlanCard({
  title,
  eyebrowHeader,
  priceDisplay,
  comparisonPlanPriceDisplay,
  billingLegal,
  showGlitch,
  percentChange,
  selected,
  onClick,
  trial,
  plan,
}) {
  const { differentPlanTrials } = useSelector(state => state.experiments);

  return (
    <PlanCardContainer>
      {showGlitch && (
        <GlitchWrapper>
          <img src={`${imgURL}/peeking-foos.svg`} alt="" />
        </GlitchWrapper>
      )}

      {percentChange && (
        <FlagWrapper>
          <FlagText variant="body1">SAVE {percentChange}</FlagText>
        </FlagWrapper>
      )}

      <PlanCard
        cursor="pointer"
        className={classNames(createPlanBotClass(plan), {
          selected,
        })}
        onClick={onClick}
      >
        {eyebrowHeader && (
          <PlanCardEyebrowHeader variant="body3">{eyebrowHeader}</PlanCardEyebrowHeader>
        )}

        <PlanCardTitle variant="h4">{title}</PlanCardTitle>

        <Box mt={{ xs: '0.75rem', md: '1rem' }} display="flex">
          {comparisonPlanPriceDisplay && (
            <PlanOriginalPrice variant="body1">
              <s>{comparisonPlanPriceDisplay}</s>
            </PlanOriginalPrice>
          )}
          <PlanPrice variant="body1">{priceDisplay}</PlanPrice>
        </Box>

        {differentPlanTrials && (
          <PlanTrial variant="body2" parseText>{trial}</PlanTrial>
        )}

        <BillingLegal variant="body3">
          <i>{billingLegal}</i>
        </BillingLegal>
      </PlanCard>
    </PlanCardContainer>
  );
}
