import { createReducer } from '@reduxjs/toolkit';
import { SET_EXPERIMENT_STATUS } from './constants';

// various experiments features that we can toggle will be set here
export const initialState = {
    receivedOptimizelyVariant: false,
    planSelectorPageRedesignVariant1: false,
    planSelectorPageRedesignVariant2: false,
    planSelectorAllPlansIncludeVariant1: false,
    planSelectorAllPlansIncludeSlider: false,
    planSelectorSelectPlanButtons: false,
    quizVariant1: false,
    quizVariant2: false,
    quizHomepage: false,
    updatedTrialLabels: false,
    thirdPlan: false,
    lifetimePlan: false,
    threeOndemandClasses: false,
    redesignOndemandClasses: false,
    premiumOndemandClasses: false,
    premiumVsStandardDesign: false,
    premiumOndemandClassesTrials: false,
    differentPlanTrials: false,
};

const experimentsReducer = createReducer(initialState, {
    [SET_EXPERIMENT_STATUS]: (state, action) => {
        const { id, status } = action.payload;

        state[id] = status;
    }
})

export default experimentsReducer;
