import React, { useState } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  makeStyles,
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import CodeSparkMuiTypography from '../CodeSparkMuiTypography';

const AccordionStyled = styled(MuiAccordion)({
  borderTop: '.0625rem solid rgba(33, 55, 91, 0.3);',
  borderBottom: 0,
  boxShadow: 'none',
  backgroundColor: 'inherit',
  '&:last-child': {
    borderBottom: '.0625rem solid rgba(33, 55, 91, 0.3);',
  },
  '&:first-child': {
    borderTop: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
});

const AccordionDetails = styled(MuiAccordionDetails)({
  padding: '0 0 1.875rem',
});

const AccordionSummary = styled(MuiAccordionSummary)({
  padding: '.875rem 0',
  '&.Mui-expanded': {
    paddingBottom: '.5rem',
  },
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIcon': {
    padding: '1rem 1rem 1rem 0',
    marginRight: 0,
    '&.Mui-expanded': {
      padding: '1rem 0 1rem 1rem',
    },
  },
});

const useStyles = makeStyles(theme => ({
  summary: {
    color: '#53565A',
  },
  detail: {
    color: '#000000',
    '& a': {
      fontFamily: 'inherit',
    },
  },
}));

function AccordionItem({ question, answer: Answer, onChange, expanded, panel }) {
  const classes = useStyles();
  const ExpandedIcon = expanded ? RemoveIcon : AddIcon;
  // In many cases, the answer has links and other elements in it so we should
  // also accept a component
  const answer = typeof Answer === 'function' ? <Answer /> : Answer;

  return (
    <AccordionStyled expanded={expanded} onChange={onChange(panel)} square>
      <AccordionSummary expandIcon={<ExpandedIcon className={classes.icon} />}>
        <CodeSparkMuiTypography variant="h3" className={classes.summary}>
          {question}
        </CodeSparkMuiTypography>
      </AccordionSummary>

      <AccordionDetails>
        <CodeSparkMuiTypography variant="body2" className={classes.detail}>
          {answer}
        </CodeSparkMuiTypography>
      </AccordionDetails>
    </AccordionStyled>
  );
}

function Accordion({ items }) {
  const [expanded, setExpanded] = useState();

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : undefined);
  };

  return items.map((item, i) => {
    return (
      <AccordionItem
        key={i}
        expanded={expanded === i}
        onChange={handleChange}
        panel={i}
        {...item}
      />
    );
  });
}

export default Accordion;
