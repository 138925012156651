import React, { lazy, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Popup from 'reactjs-popup';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import __isEmpty from 'lodash/isEmpty';
import __includes from 'lodash/includes';
import sha1 from 'js-sha1';
import mixpanel from 'mixpanel-browser';

import {
  createBraintreeInstance,
  createBraintreeHostedFields,
  createPaypalInstance,
  createApplePayInstance,
  createGooglePayInstance,
} from '../../../services/braintree';
import { getCurrentUser, subscribe } from '../../../services/parse';
import { notify, trackPageMixpanel } from '../../../services/functions';
import { sendFacebookEvent } from '../../../services/facebook-capi';
import {
  sendAnalyticsEvent,
  sendAnalyticsPaymentRejectedEvent,
  sendAnalyticsPurchaseCompleteEvent,
} from '../../../services/analytics';
import './PromoPaymentWrapper.scss';
import Textbox from '../../../components/elements/Textbox';
import { getPageUrl } from '../../../utils/locationUtils';
import { gtmEvent, gtmPageviewEvent } from '../../../services/google-tag-manager';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

const PromoSplashComponent = lazy(() => import('../PromoSplashComponent'));
const InfoPopup = lazy(() => import('../../../components/global/InfoPopup'));
const PriceBreakdownComponent = lazy(() => import('./PriceBreakdownComponent.js'));
const PromoTermsComponent = lazy(() => import('./PromoTermsComponent.js'));
const renderLoader = () => <h1>Loading</h1>;

const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;
const { googlePayClient } = window;

class PromoPaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: JSON.parse(getCurrentUser()),
      paymentType: '',
      payerName: '',
      hostedFieldsInstance: null,
      paypalInstance: null,
      textboxError: '',
      paymentErrorCodes: null,
      isProcessingPopupOpen: false,
      disableCheckoutButton: true,
      currentTab: 0,
      isNotCoupon: true,
      webOffer: !__isEmpty(props.webOffer) ? props.webOffer : JSON.parse(sessionStorage.getItem('webOffer')),
      showApplePay: false,
      gPayButton: null,
      showGooglePay: false,
      applePayInstance: null,
      googlePaymentInstance: null,
      captchaToken: '',
    };

    this.onTextboxValueChange = this.onTextboxValueChange.bind(this);
    this.setTextBoxErrorClass = this.setTextBoxErrorClass.bind(this);
    this.sendPayload = this.sendPayload.bind(this);

    this.braintreeInstance = null;
  }

  componentDidMount() {
    this.setUpBraintreeInstance();
    this.setPaymentErrorCodes();

    document.getElementById('payment-options').scrollIntoView({ behavior: 'smooth' });
    // Temp remove Apple Pay
    /*
    if (
      window.ApplePaySession &&
      window.ApplePaySession.supportsVersion(3) &&
      window.ApplePaySession.canMakePayments()
    ) {
      // This device supports version 3 of Apple Pay
      this.setState({ showApplePay: true });
    }
    */
    gtmPageviewEvent({
      pageName: 'promo-payment',
      componentProps: this.props,
      callback: () => {
        trackPageMixpanel('WebPaymentsV3 Page: Promo Checkout');
        sendFacebookEvent({
          event_name: 'Visit',
          event_source_url: window.location.href,
          custom_data: { screen: 'Checkout' },
        });
        sendAnalyticsEvent(
          'web_onboarding_screen_payment',
          {
            'app': 'web-site',
            'page_URL': getPageUrl(window.location),
          },
          true
        );
      },
    });
    gtmEvent({ action: 'Initiate Checkout' });

    if (!__isEmpty(this.props.promoCouponCode) && this.props.promoCouponCode.toUpperCase().substr(0, 2) === 'CS') {
      this.setState({ isNotCoupon: false });
    }

    if (this.state.webOffer?.descriptionKey === 'Lifetime') {
      this.setState({ isNotCoupon: false });
    }
  }

  closeCheckoutPopup = () => {
    this.setState({ isProcessingPopupOpen: false });
  };

  onTextboxValueChange(elemName, value) {
    this.setState({ payerName: value });
  }

  onChangeTabs(tabIndex) {
    if (tabIndex === this.state.currentTab) {
      return;
    }

    this.setState({ currentTab: tabIndex });
    if (tabIndex === 0) {
      this.setState({ paymentType: 'card' }, () => {
        this.setUpHostedFieldsInstance(this.braintreeInstance);
      });
    } else {
      this.setState({ paymentType: 'other' });
      this.setUpPaypalInstance(this.braintreeInstance);
      this.setUpGooglePayInstance(this.braintreeInstance);
      this.setUpApplePayInstance(this.braintreeInstance);
    }
  }

  setUpBraintreeInstance() {
    createBraintreeInstance().then((instance) => {
      this.setUpHostedFieldsInstance(instance);
      this.setUpPaypalInstance(instance);
      this.setUpGooglePayInstance(instance);
      this.setUpApplePayInstance(instance);
      this.braintreeInstance = instance;
    });
  }

  setUpPaypalInstance(clientInstance) {
    createPaypalInstance(
      {
        client: clientInstance,
      },
      (paypalErr, paypalInstance) => {
        if (paypalErr) {
          console.log('Error with paypal', paypalErr);
          notify(
            'error',
            'Hmm. There seems to be a problem with loading PayPal. Please refresh the page and try again.',
            5000
          );
          return;
        }

        this.setState({ paypalInstance });
        if (this.state.paypalInstance !== null) {
          this.setState({ disableCheckoutButton: false });
        }
      }
    );
  }

  setUpApplePayInstance(clientInstance) {
    createApplePayInstance(
      {
        client: clientInstance,
      },
      (applePayErr, applePayInstance) => {
        if (applePayErr) {
          console.error('Error creating applePayInstance', applePayErr);
          notify(
            'error',
            'Hmm. There seems to be a problem with loading Apple Pay. Please refresh the page and try again.',
            5000
          );
          return;
        }

        this.setState({ applePayInstance });
      }
    );
  }

  setUpGooglePayInstance(clientInstance) {
    const { googlePayClient } = window;
    createGooglePayInstance(
      {
        client: clientInstance,
        googlePayVersion: 2,
        googleMerchantId: 'BCR2DN4T7D2KPKYX',
      },
      (error, googlePaymentInstance) => {
        if (error) {
          console.error('Error creating applePayInstance', error);
          notify(
            'error',
            'Hmm. There seems to be a problem with loading Google Pay. Please refresh the page and try again.',
            5000
          );
          return;
        }
        googlePayClient
          .isReadyToPay({
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
            existingPyamentMethodsRequired: true,
          })
          .then((isReadyToPay) => {
            if (isReadyToPay.result) {
              const googlePayBtn = googlePayClient.createButton({
                buttonColor: 'default',
                buttonType: 'long',
              });
              this.setState({
                googlePaymentInstance,
                showGooglePay: true,
                gPayButton: googlePayBtn,
              });
            } else {
              notify('Sorry. You are unable to use Google Pay at this time', 3000);
            }
          });
      }
    );
  }

  setUpHostedFieldsInstance(clientInstance) {
    // braintree requires styles to be passed instead of css file
    const fieldStyles = {
      label: {
        'font-size': '1rem',
      },
      input: {
        color: 'rgba(137, 137, 137, 0.9)',
        'font-size': '18px',
      },
      '::-webkit-input-placeholder': {
        color: 'rgba(137, 137, 137, 0.9)',
      },
      ':-moz-placeholder': {
        color: 'rgba(137, 137, 137, 0.9)',
      },
      '::-moz-placeholder': {
        color: 'rgba(137, 137, 137, 0.9)',
      },
      ':-ms-input-placeholder': {
        color: 'rgba(137, 137, 137, 0.9)',
      },
    };

    createBraintreeHostedFields(
      {
        client: clientInstance,
        styles: fieldStyles,
        fields: {
          number: {
            selector: '#card-number',
            maxlength: 19,
          },
          cvv: {
            selector: '#cvv',
          },
          expirationDate: {
            selector: '#expiration-date',
            placeholder: 'MM/YYYY',
          },
          postalCode: {
            selector: '#postal-code',
          },
        },
      },
      (hostedFieldsErr, hostedFieldsInstance) => {
        if (hostedFieldsErr) {
          console.log('Braintree hosted fields error', hostedFieldsErr);
          notify(
            'error',
            'Hmm. There seems to be a problem with loading the payment screen. Please refresh the page and try again.',
            3000
          );
          return;
        }

        hostedFieldsInstance.on('focus', (e) => {
          // get the parent and add the transition class
          e.fields[e.emittedBy].container.classList.add('focused');
        });

        hostedFieldsInstance.on('blur', (e) => {
          // get the parent and remove the transition class
          e.fields[e.emittedBy].container.classList.remove('focused');
        });

        // we want to show the card type's logo
        hostedFieldsInstance.on('cardTypeChange', (e) => {
          if (__includes(e.fields[e.emittedBy].container.classList, this.state.cardType)) {
            e.fields[e.emittedBy].container.classList.remove(this.state.cardType);
          }

          if (e.cards.length === 1) {
            this.setState({ cardType: e.cards[0].type }, () => {
              e.fields[e.emittedBy].container.classList.add(this.state.cardType);
            });
          } else {
            this.setState({ cardType: '' });
          }
        });

        this.setState({ hostedFieldsInstance });
        if (this.state.hostedFieldsInstance !== null) {
          this.setState({ disableCheckoutButton: false });
        }
      }
    );
  }

  setTextBoxErrorClass(value) {
    this.setState({ textboxError: value });
  }

  setPaymentErrorCodes() {
    import('../../../components/forms/PaymentInfo/paymentErrorCodes.json').then((contents) => {
      const errorCodes = this.state.errorCodes;

      this.setState({
        paymentErrorCodes: {
          ...errorCodes,
          ...contents,
        },
      });
    });
  }

  showFormProcessing() {
    this.setState({ isProcessingPopupOpen: true });
  }

  removeFormProcessing() {
    this.setState({ isProcessingPopupOpen: false });
  }

  detectPaymentError(tokenizeError) {
    this.removeFormProcessing();
    let error = '';

    switch (tokenizeError.code) {
      case 'HOSTED_FIELDS_FIELDS_EMPTY':
        error = 'Please fill out the form.';
        break;
      case 'HOSTED_FIELDS_FIELDS_INVALID':
        error = 'Please verify the credit card you entered is correct.'; // tokenizeError.details.invalidFieldKeys
        break;
      case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE':
        error = 'This payment method already exists.';
        break;
      case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
        error = 'Please verify the credit card you entered is correct.';
        break;
      case 'PAYPAL_POPUP_CLOSED':
        error = 'Payment could not be completed since PayPal window closed.';
        break;
      case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
        error = 'An error seems to have occurred during payment processing. Please try again';
        console.error('Failed token error when processing payment ', tokenizeError);
        break;
      case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
        error = 'An error seems to have occurred during payment processing. Please try again';
        console.error('Failed token network error when processing payment ', tokenizeError);
        break;
      default:
        error = 'An error seems to have occurred during payment processing. Please try again';
        console.error('Processing payment error ', tokenizeError);
    }
    if (__isEmpty(this.state.textboxError)) {
      notify('error', error, 5000);
    }
  }

  processPayment() {
    const paymentType = sessionStorage.getItem('paymentType');
    // paying by credit card
    if (paymentType === 'card') {
      return this.state.hostedFieldsInstance.tokenize(this.sendPayload);
    }
    // other payment options
    if (paymentType === 'applePay') {
      this.handleApplePayEvents();
    }

    if (paymentType === 'paypal') {
      return this.state.paypalInstance.tokenize({ flow: 'vault' }, this.sendPayload);
    }

    if (paymentType === 'googlePay') {
      var paymentDataRequest = this.state.googlePaymentInstance.createPaymentDataRequest({
        transactionInfo: {
          currencyCode: 'USD',
          totalPriceStatus: 'FINAL',
          totalPrice: this.state.webOffer.price.toString(),
        },
      });

      const { googlePayClient } = window;
      return googlePayClient
        .loadPaymentData(paymentDataRequest)
        .then((paymentData) => {
          return this.state.googlePaymentInstance.parseResponse(paymentData);
        })
        .then((result) => {
          this.sendPayload(null, result);
        })
        .catch((error) => {
          console.error('Error with google payment', error);
          if (error.statusCode === 'CANCELED') {
            notify('error', 'Unable to complete purchase because Google Pay was cancelled', 3000);
          } else {
            notify('error', 'Sorry Google Pay is unable to process payment at this time', 3000);
          }
        });
    }
  }

  // triggers the apple pay interface
  handleApplePayEvents() {
    var paymentRequest = this.state.applePayInstance.createPaymentRequest({
      total: {
        label: 'codeSpark Academy',
        amount: this.state.webOffer.price.toString(),
      },
    });
    var session = new window.ApplePaySession(3, paymentRequest);
    this.validatingApplePayEvent(session, this.state.applePayInstance);
    return session.begin();
  }

  // function for validating apple pay
  validatingApplePayEvent(appleSession, appleInstance) {
    var _performTransaction = (details, callback) => {
      appleInstance
        .tokenize({
          token: details.payment.token,
        })
        .then((payload) => {
          appleSession.completePayment(window.ApplePaySession.STATUS_SUCCESS);
          this.sendPayload(null, payload);
        })
        .catch((tokenizeError) => {
          notify('error', 'There was an error processing your payment', 3000);
          appleSession.completePayment(window.ApplePaySession.STATUS_FAILURE);
          return;
        });
    };

    appleSession.onvalidatemerchant = (event) => {
      appleInstance.performValidation(
        {
          validationURL: event.validationURL,
          displayName: 'codespark Academy',
        },
        (validationErr, merchantSession) => {
          if (validationErr) {
            console.error('Error validating merchange:', validationErr);
            notify('error', 'Sorry there seems to be an issue with Apple Pay.', 3000);
            return;
          }
          appleSession.completeMerchantValidation(merchantSession);
        }
      );
    };

    appleSession.onpaymentauthorized = (event) => {
      _performTransaction(event, (transaction) => {
        if (transaction.approved) {
          console.log('apple transaction approved');
        } else {
          console.error('apple transaction error');
        }
      });
    };
  }
  updateCaptcha = (value) => {
    if (value) {
      this.setState({ captchaToken: value });
    }
  };

  async sendPayload(tokenizeError, payload) {
    if (tokenizeError) {
      this.detectPaymentError(tokenizeError);
    } else {
      // subscribe the user
      this.showFormProcessing();
      const isResubscribe = this.props.isResubscribe;

      let utmParamsObject = sessionStorage.getItem('utmParamsObject');
      // example: "{\"attributionData\":{\"utm\":{},\"iterable\":{\"iterable_campaign\":\"1396648\",\"iterable_template\":\"1939065\"}}}"
      if (utmParamsObject) {
        utmParamsObject = JSON.parse(utmParamsObject);
      }
      // split fullName so we can pass the
      const fullName = this.state.payerName.split(' ');
      const firstName = fullName[0];
      const lastName = fullName[fullName.length - 1];
      let promoCode = this.state.webOffer.promoCode || this.props.promoCouponCode;

      const {
        googleReCaptchaProps: { executeRecaptcha },
      } = this.props;
      let captchaToken = await executeRecaptcha('paymentForm');
      this.updateCaptcha(captchaToken);

      subscribe({
        paymentMethodToken: payload.nonce,
        webOfferId: this.state.webOffer.id,
        promoCode: promoCode,
        isResubscribe: isResubscribe,
        userId: this.state.user.objectId,
        customerOptions: {
          email: this.state.user.email,
          firstName: firstName,
          lastName: lastName,
        },
        utmParamsObject: utmParamsObject,
        captchaToken: captchaToken,
      })
        .then((results) => {
          this.removeFormProcessing();

          if (isResubscribe) {
            mixpanel.people.set({
              Email: this.state.user.email,
              productId: this.state.webOffer.planId,
              currency: 'USD',
              price: this.state.webOffer.price,
              subscriber: true,
              subscriptionId: results.subscriptionData.id,
              'Account Status': 'Active Subscriber',
              store: 'WebStore',
              coupon: promoCode,
            });
            mixpanel.identify(this.state.user.objectId);
            trackPageMixpanel('WebPaymentsV3 Action: Resubscribe');
            trackPageMixpanel('WebPaymentsV3 Action: Promo Code', {
              code: this.state.webOffer.promoCode || this.props.promoCouponCode,
            });
          } else {
            mixpanel.people.set_once('parseAccountId', this.state.user.objectId);
            mixpanel.people.set({
              Email: this.state.user.email,
              productId: this.state.webOffer.planId,
              currency: 'USD',
              price: this.state.webOffer.price,
              subscriber: true,
              subscriptionId: results.subscriptionData.id,
              'Account Status': 'Active Trialist',
              store: 'WebStore',
              coupon: promoCode,
            });
            mixpanel.identify(this.state.user.objectId);
            trackPageMixpanel('WebPaymentsV3 Action: Subscribe');
            mixpanel.track('WebPaymentsV3 Action: Promo Code', {
              code: this.state.webOffer.promoCode || this.props.promoCouponCode,
            });

            sendAnalyticsPurchaseCompleteEvent(this.state.webOffer, this.state.paymentType, {
              coupon: promoCode,
            });
          }

          const shaEmail = sha1(results.customer.email);
          const amountCharged =
            this.state.webOffer.trialDurationDays > 0 ? 0.0 : sessionStorage.getItem('amountChargedToday');
          gtmEvent({
            action: 'promoRedemption',
            'subscriptionID': results.subscriptionData.id,
            'sha1EmailAddress': shaEmail,
            'userID': this.state.user.objectId,
            'subscriptionPlan': this.state.webOffer.descriptionKey,
            'subscriptionType': this.state.webOffer.subscriptionType,
            'subscriptionListPrice': this.state.webOffer.originalPrice,
            'transactionAmount': amountCharged,
            'trialLengthDays': this.state.webOffer.trialDurationDays,
            'promoCode': this.state.webOffer.promoCode || this.props.promoCouponCode,
            'promoType': 'offer',
          });

          this.props.history.push('/promo-thankyou');
        })
        .catch((error) => {
          // error trying to subscribe
          this.removeFormProcessing();
          console.error('Error while trying to subscribe', error);

          let parsedError = error.message;
          if (typeof parsedError === 'string') {
            parsedError = JSON.parse(parsedError);
          }
          if (parsedError.messageKey === 'WP_AlreadyHaveSubscription') {
            mixpanel.track('WebPaymentsV3 Error: Register - Multiple Subscriptions');
            notify('error', 'It seems you already have a subscription. Click here to view it.', 0, () => {
              this.props.history.push('/my-account');
            });
          } else if (__isEmpty(parsedError) || parsedError.errors?.messageKey === 'WP_CardNotValid') {
            const errorCode = parsedError.errors?.parseError;
            if (errorCode) {
              const errorMessage = this.state.paymentErrorCodes[`${errorCode}`];
              notify('error', `${errorMessage}`, 8000);
              sendAnalyticsPaymentRejectedEvent(errorMessage);
            } else {
              notify('error', 'Sorry, there was a problem processing your payment. Please try again later', 8000);
            }
          } else if (parsedError.messageKey === 'WP_PaymentInvalid') {
            const errorMessage =
              'Unfortunately, this type of card is not accepted at this time. Please try another card.';

            sendAnalyticsPaymentRejectedEvent(errorMessage);
            notify('error', errorMessage, 8000);
          } else {
            notify(
              'error',
              'Sorry, we are unable to process your payment at this time. Please contact customer support for assistance.',
              8000
            );
          }
        });
    }
  }

  submitForm(e, paymentType) {
    e.preventDefault();
    this.setState({ paymentType });
    sessionStorage.setItem('paymentType', paymentType);

    // show error if name is not given
    if (
      (!__isEmpty(this.state.textboxError) || __isEmpty(this.state.payerName)) &&
      this.state.paymentType === 'card' &&
      !this.props.isUpdate
    ) {
      notify('error', 'Please fill out your name', 5000);
      return;
    }

    this.processPayment();
  }

  render() {
    return (
      <div className="promo-payment-container">
        <Helmet>
          <title>Promo Checkout Page | codeSpark Academy</title>
        </Helmet>
        <div className="promo-splash grid-x">
          <Suspense fallback={renderLoader()}>
            <PromoSplashComponent />
          </Suspense>
          <div className="payment-section small-12 medium-12 large-6">
            <div className="step-progress-image">
              <img src={`${imgURL}/accounts/progress_steps_2.svg`} alt="progress line" />
            </div>
            <h2 className="payment-header text-center">Provide your payment details</h2>
            <div className="payment-plan-info">
              <Suspense fallback={renderLoader()}>
                <PriceBreakdownComponent webOffer={this.state.webOffer} />
              </Suspense>
              <div className="payment-options" id="payment-options">
                <Tabs onSelect={(tabIndex) => this.onChangeTabs(tabIndex)}>
                  <TabList>
                    <Tab>
                      <span className="tab-header">Pay by Credit Card</span>
                      <p>
                        <img
                          className="payment-logo"
                          src={`${process.env.REACT_APP_CLOUDFRONT_URL}/accounts/credit-card-block.png`}
                          alt="credit card icons"
                        />
                      </p>
                    </Tab>
                    <Tab>
                      <span className="tab-header-other">Other Payment Options</span>
                    </Tab>
                  </TabList>
                  <TabPanel className="panel">
                    <div>
                      <div className="input-fields">
                        <label htmlFor="card-number">Credit Card Details</label>
                        <div className="small-12 cell">
                          <Textbox id="card-number" extraClass="hosted-field promo-payment" type="payment" />
                        </div>
                      </div>
                      <div className="input-fields grid-x grid-margin-x align-justify">
                        <div className="small-12 medium-6 cell">
                          <label htmlFor="expiration-date">Expiration Date</label>
                          <Textbox id="expiration-date" extraClass="hosted-field promo-payment" type="payment" />
                        </div>
                        <div className="small-12 medium-6 cell">
                          <label htmlFor="postal-code">Postal Code</label>
                          <Textbox id="postal-code" extraClass="hosted-field promo-payment" type="payment" />
                        </div>
                        <div className="small-12 medium-6 cell">
                          <label htmlFor="cvv">
                            Security Code
                            <Popup
                              trigger={<i className="fa fa-question-circle cvv"></i>}
                              position="bottom center"
                              on={['hover', 'focus']}
                            >
                              <p className="tooltip-payment">
                                CVV stands for Credit Card Verification Value. The CVV is a 3 or 4 digit code embossed
                                or imprinted on the signature panel on the reverse side of Visa, MasterCard and Discover
                                cards and on the front of American Express cards.
                              </p>
                            </Popup>
                          </label>
                          <Textbox id="cvv" extraClass="hosted-field promo-payment" type="payment" />
                        </div>
                      </div>
                      <div className="input-fields cardholder-name">
                        <label htmlFor="cardholder-name">Name on Card</label>
                        <div className="small-12 cell">
                          <Textbox
                            type="text"
                            name="name-associated-with-the-payment"
                            isRequired={true}
                            value={this.state.payerName}
                            onChange={this.onTextboxValueChange}
                            setTextBoxErrorClass={this.setTextBoxErrorClass}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="cta-button-promo-payment text-center" onClick={(e) => this.submitForm(e, 'card')}>
                      <button
                        type="submit"
                        data-event-category="button"
                        data-event-action="promo-step2-submit-payment"
                        data-event-label="Start your free trial"
                        data-on="click"
                        disabled={this.state.disableCheckoutButton}
                      >
                        Join Now
                        <img
                          className="basic-arrow"
                          src={`${imgURL}/global/icons/basic_arrow_white.svg`}
                          alt="Action arrow"
                        />
                      </button>
                    </div>
                    {this.state.isNotCoupon && (
                      <Suspense fallback={renderLoader()}>
                        <PromoTermsComponent webOffer={this.state.webOffer} />
                      </Suspense>
                    )}
                    <p className="secure-claim text-center">
                      <i className="fa fa-lock" aria-hidden="true"></i>Secure payment processed by Braintree, a PayPal
                      company.
                    </p>
                  </TabPanel>
                  <TabPanel className="panel">
                    {this.state.showApplePay && (
                      <div
                        onClick={(e) => this.submitForm(e, 'applePay')}
                        className="apple-pay-button apple-pay-button-black apple-image"
                      />
                    )}

                    {this.state.showGooglePay && (
                      <div
                        onClick={(e) => this.submitForm(e, 'googlePay')}
                        className="google-image"
                        dangerouslySetInnerHTML={{ __html: this.state.gPayButton && this.state.gPayButton.innerHTML }}
                      />
                    )}
                    {/* <div onClick={(e) => this.submitForm(e, 'paypal')}>
                      <img
                        className="paypal-image"
                        src={`${imgURL}/accounts/checkout_paypal.png`}
                        alt="Pay with Paypal"
                      />
                    </div> */}
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
          <Suspense fallback={renderLoader()}>
            <InfoPopup
              isOpen={this.state.isProcessingPopupOpen}
              closePopup={this.closeCheckoutPopup}
              ignoreClose={true}
            >
              <div className="image-holder">
                <img
                  src={`${process.env.REACT_APP_CLOUDFRONT_URL}/global/loading_running.gif`}
                  aria-hidden="true"
                  alt="Loading..."
                />
              </div>
              <p className="popup-title">
                Your order is processing. Please do not refresh the page or attempt multiple clicks in order to avoid
                multiple charges.
              </p>
            </InfoPopup>
          </Suspense>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isResubscribe: state.appState.isResubscribe,
  referralToSave: state.appState.referralToSave,
  promoCouponCode: state.appState.promoCouponCode,
});

export const WithGoogleRecaptchaPromoPaymentForm = withGoogleReCaptcha(PromoPaymentForm);
export default withRouter(connect(mapStateToProps)(WithGoogleRecaptchaPromoPaymentForm));
