import { NotificationManager } from 'react-notifications';
import mixpanel from 'mixpanel-browser';

export const notify = (type, message, timeOut, onClick) => {
  NotificationManager.remove({ id: 1 });

  setTimeout(() => {
    NotificationManager.create({
      id: 1,
      type: type,
      message: message,
      title: '',
      timeOut: timeOut,
      onClick: onClick
    });
  }, 450);
};

export const trackPageMixpanel = (pageInfo) => {
  mixpanel.track(pageInfo);
};
/**
 * Resets Mixpanel so we don't alias to an old id
 */
export const logoutMixpanel = () => {
  mixpanel.reset();
}
