import { makeStyles, createStyles } from '@material-ui/core/styles';

const csOrange = '#FF8654';
export const useStyles = makeStyles((theme) =>
  createStyles({
    secureContainer: {
        marginTop: '1.5rem',
        '& p': {
            fontFamily: 'Montserrat',
            [theme.breakpoints.down('md')]: {
                fontSize: '.65rem',
                margin: 'auto 0',
            },
        },
    },
    actionContainer: {
        margin: '1.5rem 0',
    },
    secureImg: {
        marginRight: '25px',
        [theme.breakpoints.down('md')]: {
            marginRight: '5px',
        },
    },
    backButtonContainer: {
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            marginBottom: '1rem',
        },
        [theme.breakpoints.up('md')]: {
            padding: '0 1rem',
        },
    },
    backButton: {
        color: '#21375B',
        borderRadius: '29px',
        height: '60px',
        backgroundColor: '#F2F8FD',
        '& .MuiButton-label': {
            fontFamily: 'QuicksandBold',
            fontSize: '1.2rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '1rem',
            },
        },
        '&:hover': {
            backgroundColor: '#F2F8FD',
        },
    },
    submitButton: {
        backgroundColor: '#0280E0',
        borderRadius: '29px',
        height: '60px',
        '& .MuiButton-label': {
            fontFamily: 'QuicksandBold',
            fontSize: '1.2rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '1rem',
            },
        },
        '&:hover': {
            backgroundColor: '#026cbd',
        },
    },
    progressLabel: {
        color: csOrange,
    },
    pageSubTextContainer: {
        '& p': {
            fontFamily: 'Montserrat',
            [theme.breakpoints.down('md')]: {
                fontSize: '.65rem',
            },
        },
        paddingBottom: '2rem',
    },
    planSelectionContainer: {
        '& h2': {
            fontSize: '2rem',
        },
        '& p.switch': {
            color: csOrange,
            fontFamily: 'Montserrat',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontWeight: 'bold',
            [theme.breakpoints.down('md')]: {
                fontSize: '.8rem',
            },
        }
    },
    planHeaderContainer: {
        marginBottom: '1.5rem',
    },
    planSelectContainer: {
        marginBottom: '.5rem',
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${csOrange}`,
        },
    },
    charge: {
        backgroundColor: '#FFCE00',
        '& .MuiTypography-body1': {
            fontWeight: '700',
        },
    },
    savingsAmount: {
        textDecoration: 'line-through',
    },
    chargeSubtext: {
        marginTop: '15px',
        '& p.MuiTypography-body1': {
            color: '#656565',
            fontFamily: 'Quicksand',
            fontSize: '14px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
        },
    },
    standardCheckoutLabel: {
        margin: '10px auto',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    costContainer: {
        minHeight: '170px',
        padding: '30px 200px',
        [theme.breakpoints.down('md')]: {
            padding: '30px 0',
        },
        '& .MuiTypography-body1': {
            fontSize: '1rem',
            fontFamily: 'Montserrat',
            [theme.breakpoints.down('md')]: {
                fontSize: '0.8rem',
            },
        },
        '& .lineItem': {
            paddingRight: '30px',
            width: '100%',
        },
    },
  }),
);
