import React, { memo } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { useStyles } from '../../styles';
import CsStepsImg from '../../../../../lib/images/cs-steps.png';

function HeaderGreeting(props) {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Typography variant='h1' className={classes.greetingHeader}>Thank you!</Typography>
            <Typography variant='h3'>Here's what to do next</Typography>
            <img src={CsStepsImg} alt="CodeSpark Academy Steps" />
        </Grid>
    );
}

export default memo(HeaderGreeting);
