import React from 'react';
import PropTypes from 'prop-types';
import __includes from 'lodash/includes';
import __isEmpty from 'lodash/isEmpty';
import __lowerCase from 'lodash/lowerCase';
import __toLower from 'lodash/toLower';
import { connect } from 'react-redux';

import { setEmailErrorClass, setPasswordErrorClass, setConfirmPasswordErrorClass } from '../../../redux/actions';
import './TextboxWrapper.scss';

const { notify } = require('../../../services/functions');

class Textbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.defaultValue !== undefined ? this.props.defaultValue : '',
      name: this.props.name,
      errorClass: '',
      errorMessage: ''
    };

    this.checkErrors = this.checkErrors.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isError) {
      this.setState({ errorClass: 'error' });
    }
  }

  onChange(e) {
    e.preventDefault();

    this.setState({
      value: this.state.name === 'redeem-promo' ? this.maskPromoCode(e.target.value) : e.target.value
    }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.name, this.state.value);
      }
    })
  }

  maskPromoCode(value) {
    //this is a coupon code
    if (value.toUpperCase().substr(0, 2) === 'CS' ||
        value.toUpperCase().substr(0, 2) === 'CV') {
      value = value.split('-').join('');
      return value.match(new RegExp('.{1,4}', 'g')).join('-');
    }

    return value;
  }

  checkErrors(e) {
    e.preventDefault();

    if (this.props.noValidate) {
      return;
    }

    let errorMsg = null;

    switch (this.props.type) {
      case 'email':
        errorMsg = this.checkErrorEmail(e.target);

        if (errorMsg === null) {
          this.props.setEmailErrorClass('');
        } else {
          this.props.setEmailErrorClass('error');
        }
        break;
      case 'password':
        errorMsg = this.checkErrorPassword(e.target);
        let isConfirm = __includes(this.props.name, 'confirm');
        let field = isConfirm ? 'confirmPassword' : 'password'

        if (errorMsg === null) {
          this.props.setPasswordErrorClass('');
          this.props.setConfirmPasswordErrorClass('');
        } else {
          if (field === 'confirmPassword') {
            this.props.setConfirmPasswordErrorClass('error');
          } else {
            this.props.setPasswordErrorClass('error');
          }
        }
        break;
      case 'text':
        errorMsg = this.checkErrorText(e.target);
        if (this.props.setTextBoxErrorClass) {
          if (errorMsg === null) {
            this.props.setTextBoxErrorClass('');
          } else {
            this.props.setTextBoxErrorClass('error');
          }
        }
        break;
      default:
        break;
    }

    let targetName = e.target.name;
    let targetValue = e.target.value;

    this.setState({
      errorClass: errorMsg !== null ? 'error' : '',
      errorMessage: errorMsg !== null ? errorMsg : ''
    }, () => {
      if (this.state.errorClass === 'error') {
        notify('error', this.state.errorMessage, 5000);
      } else if (this.props.onBlur && !__isEmpty(targetValue)) {
        this.props.onBlur(targetName, targetValue);
      }
    });
  }

  checkErrorEmail(input) {
    //make sure it's a valid email address with regex
    let emailExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!__isEmpty(input.value) && !emailExp.test(__toLower(input.value))) {
      return 'Please enter a valid email address';
    }

    //test for any captial letters
    let captialLetter = /^[A-Z]*$/;

    if (!__isEmpty(input.value) && input.value.match(captialLetter) !== null) {
      return 'Please do not use capital letters';
    }

    return null;
  }

  checkErrorPassword(input) {
    //check length
    let min = 6;
    let max = 32;
    let errorClass = '';

    if (!__isEmpty(input.value) && (input.value.length < min || input.value.length > max)) {
      return 'Please enter a password with 6 - 32 characters';
    } else {
      //if the user tried to submit an empty password field,
      //clear out the error after typing one
      this.props.setPasswordErrorClass('');
    }

    //check confirm
    if (this.props.isConfirm && input.value !== this.props.confirmText) {
      return 'Passwords do not match';
    } else {
      //if the user tried to submit an empty confirm password field,
      //clear out the error after typing one
      this.props.setConfirmPasswordErrorClass('');
    }

    return null;
  }

  checkErrorText(input) {
    if (this.props.isRequired && __isEmpty(input.value)) {
      return 'Please fill out your ' + __lowerCase(input.placeholder || input.name);
    }

    return null;
  }

  render() {
    return (
      <div data-type={this.props.type} className={`textbox-wrapper ${this.state.errorClass}`}>
        {
          this.props.type === 'payment' ? (
            <div id={this.props.id} className={this.props.extraClass} placeholder={this.props.placeholder}></div>
          ) : (
            <input
              type={this.props.type}
              placeholder={this.props.placeholder}
              onChange={e => this.onChange(e)}
              onBlur={this.checkErrors}
              value={this.state.value}
              name={this.props.name}
              min={this.props.min}
              max={this.props.max}
              onFocus={this.props.onFocus}
              className={this.props.extraClass}
            />
          )
        }
      </div>
    );
  }
}

Textbox.propTypes = {
  type: PropTypes.string.isRequired, //payment (for braintree) & all html5 input types
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isConfirm: PropTypes.bool,
  confirmText: PropTypes.string,
  noValidate: PropTypes.bool,
  id: PropTypes.string,
  extraClass: PropTypes.string,
  isError: PropTypes.bool,
  isRequired: PropTypes.bool,
  setTextBoxErrorClass: PropTypes.func, //not email/password - used to check submission of empty textboxes
};

const mapDispatchToProps = { setEmailErrorClass, setPasswordErrorClass, setConfirmPasswordErrorClass };

export default connect(null, mapDispatchToProps)(Textbox);
