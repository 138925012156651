import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import LoadingBar from 'react-redux-loading-bar';
import MediaQuery from 'react-responsive';

import __isEmpty from 'lodash/isEmpty';

import './AccountV2.scss';
import { setResubscribe } from '../../redux/actions';
import {
  getCurrentUser,
  getUserSubscriptionDetails,
  getUserPaymentHistory,
  getCurrentUserID,
  getMinervaOffers,
  getWebOfferByPlanId,
} from '../../services/parse';
import { isAnnualPlan, isMonthlyPlan } from '../../utils/planUtils';
import { DEFAULT_TAG, SubscriptionPlanContext } from '../../contexts/subscriptionPlans';
import TabComponent from './TabComponent.js';
import AccordionComponent from './AccordionComponent.js';


class AccountV2 extends React.Component {
  static contextType = SubscriptionPlanContext;

  constructor(props) {
    super(props);
    const user = JSON.parse(getCurrentUser());

    this.state = {
      user,
      subDetails: {},
      paymentHistory: [],
      monthlyPlan: {},
      annualPlan: {},
      hasActiveSubscription: false,
      hasExpirationDate: false,
      planExpiresAt: null,
      canUpgrade: false,
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.getSubscriptionDetails();
    this.getPaymentHistory();
  }

  async getSubscriptionDetails() {
    this.props.showLoading();
    this.getCurrentPlans();
    const minervaOffers = await getMinervaOffers('all');

    const userSubscriptionDetails = await getUserSubscriptionDetails(this.state.user.objectId);
    const { plan } = userSubscriptionDetails;
    this.setState({ subDetails: userSubscriptionDetails });
    if (!__isEmpty(plan)) {
      this.setState({ hasActiveSubscription: true });
      if (plan.store === 'WebStore') {
        const webOffer = await getWebOfferByPlanId(plan.data.planId);
        if (webOffer) {
          let isMinervaOffer = false;
          for (const key in minervaOffers) {
            if (minervaOffers[key].id === webOffer.id) {
              isMinervaOffer = true;
            }
          }
          this.setState({ canUpgrade: !isMinervaOffer });
        }
      }
    }

    if (plan?.termExpiresAt || plan?.compExpiresAt || plan?.giftExpiresAt) {
      this.setState({ hasExpirationDate: true });
    }

    if (plan?.termExpiresAt) {
      this.setState({ planExpiresAt: plan?.termExpiresAt });
    }

    if (plan?.compExpiresAt) {
      this.setState({ planExpiresAt: plan.compExpiresAt });
    }

    if (plan?.giftExpiresAt) {
      this.setState({ planExpiresAt: plan.giftExpiresAt });
    }

    this.props.hideLoading();
    this.getCurrentPlans();
  }

  getPaymentHistory() {
    const userId = this.state.user.objectId;
    getUserPaymentHistory(userId)
      .then((data) => {
        this.setState({ paymentHistory: data.paymentHistory });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getCurrentPlans() {
    const { setPlanTag } = this.context;

    setPlanTag(DEFAULT_TAG);
  }

  getMonthlyAndAnnualPlans() {
    const { plans } = this.context;
    const monthlyPlan = plans.find(isMonthlyPlan) || {};
    const annualPlan = plans.find(isAnnualPlan) || {};

    return { monthlyPlan, annualPlan };
  }

  calculateAnnualSavings() {
    const { monthlyPlan, annualPlan } = this.getMonthlyAndAnnualPlans();
    return monthlyPlan.price * 12 - annualPlan.price;
  }

  onChangeTabs(tabIndex) {
    if (tabIndex === this.state.currentTab) {
      return;
    }
    this.setState({ currentTab: tabIndex });
  }

  render() {
    const {
      user,
      subDetails,
      paymentHistory,
      hasActiveSubscription,
      hasExpirationDate,
      planExpiresAt,
      canUpgrade,
    } = this.state;
    const { monthlyPlan, annualPlan } = this.getMonthlyAndAnnualPlans();

    const loading = __isEmpty(this.state.subDetails);
    const annualSavings = this.calculateAnnualSavings();

    return (
      <div className="my-account-v2-container">
        <Helmet>
          <title>Account Info | codeSpark Academy</title>
        </Helmet>
        <LoadingBar className="global-loading-bar" />
        <div id="infoContainer" className={`info-container ${loading ? 'blurry' : ''}`}>
          <h1 className="heading">My Account</h1>
          <MediaQuery maxWidth={640}>
            <AccordionComponent
              user={user}
              subDetails={subDetails}
              paymentHistory={paymentHistory}
              annualPlan={annualPlan}
              monthlyPlan={monthlyPlan}
              annualSavings={annualSavings}
              hasActiveSubscription={hasActiveSubscription}
              hasExpirationDate={hasExpirationDate}
              planExpiresAt={planExpiresAt}
            />
          </MediaQuery>
          <MediaQuery minWidth={640}>
            <TabComponent
              loading={loading}
              user={user}
              subDetails={subDetails}
              paymentHistory={paymentHistory}
              annualPlan={annualPlan}
              monthlyPlan={monthlyPlan}
              annualSavings={annualSavings}
              hasActiveSubscription={hasActiveSubscription}
              hasExpirationDate={hasExpirationDate}
              planExpiresAt={planExpiresAt}
              canUpgrade={canUpgrade}
            />
          </MediaQuery>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  showLoading,
  hideLoading,
  setResubscribe,
};

export default withRouter(connect(null, mapDispatchToProps)(AccountV2));
