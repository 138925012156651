/**
 * All business logic for the signup complete page.
 * Transfer all business logic to the service.js file.
 * API integration stuff and data manipulation.
 */

import { sendAnalyticsEvent, sendAnalyticsPurchaseCompleteEvent, sendPageViewEvent } from '../../../../services/analytics';
import { hasOndemandClasses } from '../../utils';
import { gtmEvent } from '../../../../services/google-tag-manager';

/**
 * Google tag manager event.
 */
const GTM_EVENT = 'virtualPageView';
/**
 * Mix panel page tracking.
 */
const MINERVA_ONDEMAND_PAYMENT_PAGE = 'Memberships v2 Page: On-demand Classes Checkout Complete';
const MINERVA_LIVECLASSES_PAYMENT_PAGE = 'Memberships v2 Page: Live Classes Checkout Complete';

/**
 * Sends analytics event on page load.
 * @returns {void}
 */
export function sendPageAnalyticsEvent(selectedMinervaOfferId, selectedOffer) {
  if (selectedOffer) {
    const paymentMethod = sessionStorage.getItem('paymentMethod');
    const page =
      hasOndemandClasses(selectedOffer)
        ? MINERVA_ONDEMAND_PAYMENT_PAGE
        : MINERVA_LIVECLASSES_PAYMENT_PAGE;

    sendAnalyticsEvent(page, {
      productId: selectedMinervaOfferId,
      productDuration: selectedOffer.subscriptionDuration,
      paymentMethod,
    });

    sendAnalyticsPurchaseCompleteEvent(selectedOffer, paymentMethod);
  }
}

/**
 * Send GTM event.
 * @param {{deviceType:string, deviceOS, string, pathName:string}} object
 * @returns {void}
 */
export function sendGTMEvent({
  deviceType,
  deviceOS,
  pathName,
  selectedOffer,
}) {
  const page =
    hasOndemandClasses(selectedOffer)
      ? MINERVA_ONDEMAND_PAYMENT_PAGE
      : MINERVA_LIVECLASSES_PAYMENT_PAGE;

  gtmEvent({
    action: GTM_EVENT,
    deviceType,
    deviceOS,
    'page': {
      'title': page,
      'url': pathName,
    },
  });
}
