import { Box, Grid, styled, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { colors } from '../../../../../theme/constants';
import CodeSparkMuiTypography from '../../../CodeSparkMuiTypography';
import { ValuePropIcon } from '../../../ValueProp';

const imgURL = `${process.env.REACT_APP_CLOUDFRONT_URL}/global`;

const ValueProp = ({ icon, srLabel }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const iconSize = isMobile ? 'small' : 'medium';
  return (
    <>
      <ValuePropIcon size={iconSize} icon={icon} noMargins />
      <CodeSparkMuiTypography variant="srOnly">{srLabel}</CodeSparkMuiTypography>
    </>
  );
};

const IncludedIcon = () => (
  <ValueProp icon={`${imgURL}/checkmark-updated-blue.svg`} srLabel="Included" />
);

const NotIncludedIcon = () => (
  <ValueProp icon={`${imgURL}/minus-gray.svg`} srLabel="Not included" />
);

const DisplayIcon = ({ isIncluded }) => {
  return isIncluded ? <IncludedIcon /> : <NotIncludedIcon />;
};

const PlansComparisonContainer = styled(Box)(({ theme }) => ({
  marginTop: '2.5rem',
  maxWidth: '42.6875rem',
  width: '100%',
  color: colors.primary.black,

  [theme.breakpoints.down('sm')]: {
    marginTop: '1.5rem',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const PlansComparisonRow = styled(Grid)(({ theme }) => ({
  '&:not(:last-of-type) > .valuePropBorder': {
    [theme.breakpoints.up('md')]: {
      borderBottom: `1px solid ${colors.primary.grey2}`,
    }
  },
  [theme.breakpoints.down('sm')]: {
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${colors.primary.grey2}`,
    },
  },
}));

const PlanComparisonValueProp = styled(Grid)(
  ({ theme }) => ({
    padding: '1.25rem 1.125rem',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      height: '3rem',
      borderBottom: 0,
      padding: 0,
    },
  })
);

const PlanComparisonValuePropText = styled(CodeSparkMuiTypography)(({ theme }) => ({
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    width: '9.9375rem',
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
  },
}));

export default function PlansComparison({ valueProps, planOptionsData }) {
  const leftSidePlan = planOptionsData[0];
  const rightSidePlan = planOptionsData[1];

  return (
    <PlansComparisonContainer>
      {valueProps.map(({ key, text }, i) => {
        const isLeftPlanChecked = leftSidePlan.valuePropsMapping?.[key];
        const isRightPlanChecked = rightSidePlan.valuePropsMapping?.[key];

        return (
          <PlansComparisonRow container key={key} justifyContent="center">
            <Grid container item xs={2} justifyContent="center" alignItems="center">
              <DisplayIcon isIncluded={isLeftPlanChecked} />
            </Grid>
            <PlanComparisonValueProp
              container
              item
              xs={7}
              md={6}
              justifyContent="center"
              alignItems="center"
              className="valuePropBorder"
            >
              <PlanComparisonValuePropText variant="body2">{text}</PlanComparisonValuePropText>
            </PlanComparisonValueProp>
            <Grid container item xs={2} justifyContent="center" alignItems="center">
              <DisplayIcon isIncluded={isRightPlanChecked} />
            </Grid>
          </PlansComparisonRow>
        );
      })}
    </PlansComparisonContainer>
  );
}
