import { useEffect } from 'react';
import React from 'react';
import { Chip } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import '../../MinervaSignup/MinervaSignup.scss';
import { getMinervaClassAddOns } from '../../../../services/parse';
import { useSelector } from 'react-redux';

function SemesterSawyer() {
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 3000);

    const sawyerWidget = document.getElementById('sawyer');

    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://hisawyer.com/embed/XxNqCVmS6hh8oma3GuUPwXXqZsQvG7UX.js';
    scriptTag.async = true;
    scriptTag.id = 'SA_XxNqCVmS6hh8oma3GuUPwXXqZsQvG7UX';
    scriptTag.setAttribute('data-sawyertools', 'sawyertools');
    sawyerWidget.appendChild(scriptTag);

    return () => {
      sawyerWidget.removeChild(scriptTag);
    };
  }, []);

  const selectedMinervaOffer = useSelector((state) => state.appState.minervaAddedClassId);

  useEffect(() => {
    let selectedAddOnPlan;
    getMinervaClassAddOns().then((plans) => {
      selectedAddOnPlan = plans.find((x) => x.get('planId') === selectedMinervaOffer);
      if (selectedMinervaOffer) {
        mixpanel.track('Memberships v2.1 Page: Checkout Complete', {
          productId: selectedMinervaOffer,
          productDuration: selectedAddOnPlan?.attributes.subscriptionDuration,
          paymentMethod: sessionStorage.getItem('paymentMethod'),
        });
      }
    });
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="minerva-signup-page" style={{ maxWidth: '100%' }}>
          <div className="stepBadge-container">
            <Chip label="STEP 3 OF 3" className="stepBadge" />
          </div>
          <div className="minerva-signup-title">
            <h2>Book your free class</h2>
          </div>
          <div className="course-promo">
            Use the promo code <strong style={{ backgroundColor: 'rgba(255, 206, 0, 1)' }}>COURSE</strong>
          </div>
          <div className="minerva-class-subtitle">
            You’ll get a copy of these instructions in your email. If you have trouble, contact us at&nbsp;
            <a href="mailto:classes@codespark.com">classes@codespark.com</a>
          </div>
          <div id="sawyer"></div>
        </div>
      </div>
    </>
  );
}

export default withRouter(SemesterSawyer);
