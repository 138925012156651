import queryString from 'query-string';
import Cookies from 'universal-cookie';

const COOKIES_KEY_ROBOT_E2E = 'robot-e2e';

export function botClassName(name = 'default') {
  return `bot-${name}`;
}

export function isRobotE2E() {
  const cookies = new Cookies();
  const parsedQuery = queryString.parse(window.location.search);

  const isRobotUTMSource = parsedQuery.utm_source === COOKIES_KEY_ROBOT_E2E;
  const hasE2ECookie = !!cookies.get(COOKIES_KEY_ROBOT_E2E);

  return isRobotUTMSource || hasE2ECookie;
}
