import React from 'react';

import { Container, Grid, Divider, Hidden, Typography } from '@material-ui/core';
import { Stats, ContainerTitle, TrustPilot } from '../../common';
import { useStyles } from './styles';
function Desktop() {
  const classes = useStyles();
  return (
    <Hidden only={['xs', 'sm']}>
      <Container maxWidth="xs" style={{ display: 'flex', marginLeft: 0 }} className={classes.rightColumn}>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            marginBottom: '60px',
          }}
        >
          <Grid container justifyContent="center" direction="column">
            <img
              src="https://thefoos-com2.s3.amazonaws.com/accounts/minervapayment/image_Glitch_anticipation+1%402x.svg"
              alt="glitch"
            />
          </Grid>
          <Grid className={classes.contentContainer}>
            <ContainerTitle />
            <Grid>
              <Divider />
            </Grid>
            <Grid container direction="column" className={classes.awardsContainer}>
              <Grid item className={classes.item}>
                <Grid container direction="column" alignItems="center" justifyContent="center">
                  <img
                    src="https://thefoos-com2.s3.amazonaws.com/accounts/minervapayment/parents_choice_gold.svg"
                    alt="parents choice gold"
                  />
                  <Typography variant="body1" className={classes.parentsLabel}>
                    Parents' Choice Gold Award Winner
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className={classes.item}>
                <Stats label={'12.6M'} description={'games and stories created in 2021'} />
              </Grid>
              <Grid item className={classes.item}>
                <Stats label={'55%'} description={'increase in problem-solving confidence'} />
              </Grid>
              <Grid item className={classes.item}>
                <Stats label={'120K'} description={'educators worldwide use codeSpark'} />
              </Grid>
              <Grid item className={classes.item}>
                <TrustPilot />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Hidden>
  );
}

export default Desktop;
