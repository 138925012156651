import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import NotFound from '../NotFoundPage';
import { QuizIntro, QuizQuestion, FadeWrapper, QuizSummary } from './components';
import { minHeightAdjustmentProps } from './components/Layout';
import { useSelector } from 'react-redux';
import {
  selectAnswers,
  selectCurrentQuestion,
  selectQuestions,
  selectTotalAnsweredQuestions,
} from './selectors';
import { selectEnabledExperiment } from '../ExperimentsSetup/selectors';
import { ScrollToTop } from '../../components/elements/Layout';

// Check if user refreshed page on a quiz question or summary page and send them back to next
// question since we are not currently storing the saved answers in any local/session storage
// to avoid strange errors
function NextQuestionWrapper({ path, ...props }) {
  const { questionNumber: questionNumberParam } = useParams();
  const questionNumber = Number(questionNumberParam);
  const location = useLocation();
  const question = useSelector(state => selectCurrentQuestion(state, questionNumber));
  const answeredQuestions = useSelector(selectAnswers);
  const totalAnsweredQuestions = useSelector(selectTotalAnsweredQuestions);
  const questions = useSelector(selectQuestions);
  const nextQuestionIndex = questions.findIndex(question => !(question.id in answeredQuestions));

  // For summary page, if user refreshes there, send them back to beginning of quiz
  if (location.pathname === '/quiz/summary') {
    return questions.length === totalAnsweredQuestions ? (
      <FadeWrapper {...props} />
    ) : (
      <Redirect to={`/quiz/questions/${nextQuestionIndex + 1}`} />
    );
  }

  // if user refreshes on a quiz number that is not the first one and a valid question,
  // redirect them to question 1
  const shouldRedirectToStart = totalAnsweredQuestions === 0 && questionNumber !== 1 && question;
  if (shouldRedirectToStart) {
    return <Redirect to={`/quiz/questions/1`} />;
  }

  return <FadeWrapper {...props} />;
}

const useStyles = makeStyles({
  quizWrapper: {
    // use minHeight to move the footer out of the viewport unless user scrolls
    minHeight: '100vh',
    // need flex on every item needing 100% height since height 100% doesn't work
    // as expected with minHeight
    display: 'flex',
    flexDirection: 'column',
  },
});

const FadeWrapperComponent = props => <Box {...minHeightAdjustmentProps} {...props} />;

const QUIZ_VARIANTS = ['quizVariant1', 'quizVariant2', 'quizHomepage'];
function SignupQuiz() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const enabledQuizVariant = useSelector(state => selectEnabledExperiment(state, QUIZ_VARIANTS));

  // if experiment test is not turned on or no variant is turned on, then return 404
  // to prevent errors
  if (!enabledQuizVariant) {
    return <NotFound />;
  }

  return (
    <Box className={classes.quizWrapper}>
      <ScrollToTop />

      <Switch>
        <Route exact path={path}>
          <FadeWrapper
            onFade={history.push}
            WrapperComponent={FadeWrapperComponent}
            render={props => <QuizIntro variant={enabledQuizVariant} {...props} />}
          />
        </Route>

        <Route exact path={`${path}/questions/:questionNumber`}>
          <NextQuestionWrapper
            onFade={history.push}
            WrapperComponent={FadeWrapperComponent}
            render={props => <QuizQuestion variant={enabledQuizVariant} {...props} />}
          />
        </Route>

        <Route exact path={`${path}/summary`}>
          <NextQuestionWrapper
            onFade={history.push}
            WrapperComponent={FadeWrapperComponent}
            render={props => <QuizSummary variant={enabledQuizVariant} {...props} />}
          />
        </Route>

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Box>
  );
}

export default SignupQuiz;
