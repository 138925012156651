import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CodeSparkMuiTypography from '../../../components/elements/CodeSparkMuiTypography';
import ThemedMuiButton from '../../../components/elements/ThemedMuiButton';
import { selectAnswers, selectCoderType, selectQuestions } from '../selectors';
import getSummaryData, { variant2SummaryData } from '../data/summaryData';
import { QuizContentWrapper } from './Layout';
import { MobileSlider } from '../../../components/elements/Slider';
import { useSendEventWhenFFReady } from '../../../hooks/useAnalyticsEvent';

const imgURL = `${process.env.REACT_APP_CLOUDFRONT_URL}/accounts`;

const useRecommendationStyles = makeStyles(theme => ({
  recommendation: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  resultsBanner: {
    backgroundColor: '#FFDB47',
    padding: '.25rem .5rem',
    borderRadius: '.3125rem',
  },
  resultsBannerText: {
    color: '#21375B',
  },
  recommendationText: {
    color: '#ffffff',
    marginTop: '2.25rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '.875rem',
    },
  },
  coderType: {
    color: '#FFDB47',
    display: 'inline-block',
    marginTop: '.5rem',
    fontFamily: 'inherit',
  },
  coderTypeDescription: {
    color: '#ffffff',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '.5rem',
    },
  },
  ctaContainer: {
    marginTop: '2rem',
  },
  fooImageWrapper: {
    width: '20.4375rem',
    maxWidth: '100%',
    '& > img': {
      width: '100%',
    },
  },
}));

function Recommendation({ coderType, description, image, goToNext }) {
  const classes = useRecommendationStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid className={classes.recommendation} spacing={2} container>
      <Grid xs={12} md={6} className={classes.leftContainer} item>
        <Box className={classes.resultsBanner}>
          <CodeSparkMuiTypography variant="h6" className={classes.resultsBannerText}>
            The Results Are In!
          </CodeSparkMuiTypography>
        </Box>
        <CodeSparkMuiTypography variant="h2" className={classes.recommendationText}>
          Our Recommendation:
          <br />
          <span className={classes.coderType}>{coderType}</span>
        </CodeSparkMuiTypography>

        <CodeSparkMuiTypography variant="body1" className={classes.coderTypeDescription}>
          {description}
        </CodeSparkMuiTypography>

        {!isMobile && (
          <Box className={classes.ctaContainer}>
            <ThemedMuiButton color="primary" onClick={goToNext}>
              Start Free Trial
            </ThemedMuiButton>
          </Box>
        )}
      </Grid>

      <Grid xs={12} md={6} className={classes.rightContainer} item>
        <Box className={classes.fooImageWrapper}>
          <img src={`${imgURL}/${image}`} alt={coderType} />
        </Box>

        {isMobile && (
          <Box className={classes.ctaContainer}>
            <ThemedMuiButton color="primary" onClick={goToNext}>
              Start Free Trial
            </ThemedMuiButton>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

Recommendation.propTypes = {
  coderType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  goToNext: PropTypes.func.isRequired,
};

const useHowLearnCode = makeStyles(theme => ({
  howLearnCodeContainer: {
    padding: '2rem 5.5625rem',
    backgroundColor: '#ffffff',
    borderRadius: '.625rem',
    marginTop: '4rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 0',
      marginTop: '2rem',
    },
  },
  title: {
    color: '#894DFA',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
      maxWidth: '16.8125rem',
    },
  },
  text: {
    width: '27.1875rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  body: {
    margin: '1rem 0 2rem',
    color: '#000000',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
      margin: '1.5rem 0',
    },
  },
  modeTitle: {
    color: '#22252A',
    textAlign: 'center',
  },
  modeImageWrapper: {
    borderRadius: '.625rem',
    marginTop: '.75rem',
    overflow: 'hidden',
    '& > img': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
      borderRadius: 0,
    },
  },
  learningPoints: {
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
      marginTop: '1rem',
    },
  },
  listBulletIconWrapper: {
    minWidth: '1.625rem',
    marginTop: '.3125rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: '1.125rem',
    },
  },
  bulletIcon: {
    fontSize: '.75rem',
  },
}));

function HowLearnCode({ coderType, strategy, modes, bullets }) {
  const classes = useHowLearnCode();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className={classes.howLearnCodeContainer}>
      <CodeSparkMuiTypography variant="h3" className={classes.title}>
        See How {coderType} Learns Code
      </CodeSparkMuiTypography>

      <CodeSparkMuiTypography variant="body2" className={`${classes.text} ${classes.body}`}>
        {strategy}
      </CodeSparkMuiTypography>

      {!isMobile && (
        <Grid spacing={4} container>
          {modes.map(({ image, title }, i) => {
            return (
              <Grid xs={12} md={6} key={`mode_${i}`} item>
                <CodeSparkMuiTypography variant="h5" className={classes.modeTitle}>
                  {title}
                </CodeSparkMuiTypography>
                <Box className={classes.modeImageWrapper}>
                  <img src={`${imgURL}/${image}`} alt={title} />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      )}

      {isMobile && (
        <MobileSlider>
          {modes.map(({ image, title }, i) => {
            return (
              <Box key={`mode_${i}`}>
                <CodeSparkMuiTypography variant="h5" className={classes.modeTitle}>
                  {title}
                </CodeSparkMuiTypography>
                <Box className={classes.modeImageWrapper}>
                  <img src={`${imgURL}/${image}`} alt={title} />
                </Box>
              </Box>
            );
          })}
        </MobileSlider>
      )}

      <List
        className={`${classes.learningPoints} ${classes.text}`}
        aria-label={`${coderType} bullet points`}
        disablePadding
      >
        {bullets.map((bullet, i) => (
          <ListItem key={`bullet_${i}`} alignItems="flex-start" disableGutters>
            <ListItemIcon
              classes={{
                alignItemsFlexStart: classes.listBulletIconWrapper,
              }}
            >
              <FiberManualRecordIcon
                classes={{
                  root: classes.bulletIcon,
                }}
                htmlColor="#894DFA"
              />
            </ListItemIcon>

            <CodeSparkMuiTypography variant="body2">{bullet}</CodeSparkMuiTypography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

HowLearnCode.propTypes = {
  coderType: PropTypes.string.isRequired,
  strategy: PropTypes.string.isRequired,
  bullets: PropTypes.arrayOf(PropTypes.string).isRequired,
  modes: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
};

function setupOrderedQuestionsAndAnswers(questions, answers) {
  // keep in separate arrays for mixpanel data type support
  const orderedAnswers = [];
  const answerCoderTypes = [];
  const questionIds = [];

  questions.forEach(({ id, options }) => {
    const answerIndex = answers[id];
    const { label, coderType } = options[answerIndex];

    questionIds.push(id);
    orderedAnswers.push(label);
    answerCoderTypes.push(coderType);
  });

  return {
    orderedAnswers,
    answerCoderTypes,
    questionIds,
  };
}
function QuizSummaryVariant1({ goToNext }) {
  const sendEventWhenFFReady = useSendEventWhenFFReady();
  const questions = useSelector(selectQuestions);
  const answers = useSelector(selectAnswers);
  const recommendedCoderType = useSelector(state => selectCoderType(state));
  const coderTypeFormatted = recommendedCoderType
    .split('_')
    .map(word => capitalize(word))
    .join(' ');
  const coderTypeDisplayText = `The ${coderTypeFormatted}`;
  const { description, image, learningStrategy, modes, modeBullets } =
    getSummaryData(recommendedCoderType);

  useEffect(() => {
    const { questionIds, orderedAnswers, answerCoderTypes } = setupOrderedQuestionsAndAnswers(
      questions,
      answers
    );

    sendEventWhenFFReady('web_onboarding_quiz_completed', {
      result: coderTypeFormatted,
      questions: questionIds,
      answers: orderedAnswers,
      answerCoderTypes,
    });
  }, [sendEventWhenFFReady]);

  return (
    <>
      <Recommendation
        coderType={coderTypeDisplayText}
        description={description}
        image={image}
        goToNext={goToNext}
      />

      <HowLearnCode
        coderType={coderTypeDisplayText}
        strategy={learningStrategy}
        modes={modes}
        bullets={modeBullets}
      />
    </>
  );
}

QuizSummaryVariant1.propTypes = {
  goToNext: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  text: {
    color: '#ffffff',
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  subtitle: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '1.125rem',
      lineHeight: '1.625rem',
      letterSpacing: '.0156rem',
    },
  },
  content: {
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '.5rem',
    },
  },
  valueProp: {
    marginTop: '1rem',
    '&:first-of-type': {
      marginTop: 0,
    },
  },
  valuePropHeader: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
  },
  valuePropValue: {
    marginTop: '.625rem',
  },
}));

function QuizSummaryVariant2() {
  const sendEventWhenFFReady = useSendEventWhenFFReady();
  const questions = useSelector(selectQuestions);
  const answers = useSelector(selectAnswers);
  const { title, subtitle, valueProps } = variant2SummaryData;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const { questionIds, orderedAnswers } = setupOrderedQuestionsAndAnswers(questions, answers);

    sendEventWhenFFReady('web_onboarding_quiz_completed', {
      questionIds,
      orderedAnswers,
    });
  }, [sendEventWhenFFReady]);

  return (
    <>
      <Grid spacing={isMobile ? 1 : 2} container>
        <Grid xs={12} item>
          <CodeSparkMuiTypography variant="h2" className={`${classes.title} ${classes.text}`}>
            {title}
          </CodeSparkMuiTypography>
        </Grid>

        <Grid xs={12} md={6} item>
          <CodeSparkMuiTypography
            variant="subtitle1"
            className={`${classes.subtitle} ${classes.text}`}
          >
            {subtitle}
          </CodeSparkMuiTypography>
        </Grid>
      </Grid>

      <Grid spacing={isMobile ? 2 : 4} className={classes.content} container>
        <Grid xs={12} md={6} item>
          <Box>
            <img src={`${imgURL}/Gracie_ipad_video_capture.png`} alt="Gracie ipad game" />
          </Box>
        </Grid>

        <Grid xs={12} md={6} item>
          {valueProps.map(({ header, value }, i) => (
            <Box className={classes.valueProp} key={`valueProp_${i}`}>
              <CodeSparkMuiTypography
                variant="h4"
                className={`${classes.valuePropHeader} ${classes.text}`}
              >
                {header}
              </CodeSparkMuiTypography>
              <CodeSparkMuiTypography
                variant="body1"
                className={`${classes.valuePropValue} ${classes.text}`}
              >
                {value}
              </CodeSparkMuiTypography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

const useQuizSummaryStyles = makeStyles(theme => ({
  sectionWrapper: {
    backgroundImage: `url(${imgURL}/plan_selector_bg_variant_desktop.png)`,
    backgroundColor: '#21375B',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${imgURL}/plan_selector_bg_variant_mobile.png)`,
    },
  },
  sectionContainer: {
    maxWidth: '68rem',
    width: '100%',
  },
}));

const variantComponentMapping = {
  quizVariant1: QuizSummaryVariant1,
  quizVariant2: QuizSummaryVariant2,
  quizHomepage: QuizSummaryVariant1,
};
function QuizSummary({ onFadeOut, variant }) {
  const classes = useQuizSummaryStyles();
  const Component = variantComponentMapping[variant];

  const handleGoToNext = () => {
    onFadeOut('/choose-plan');
  };

  return (
    <QuizContentWrapper className={classes.sectionWrapper}>
      <Box className={classes.sectionContainer}>
        <Component goToNext={handleGoToNext} />

        <Box textAlign="center" mt="2rem">
          <ThemedMuiButton color="primary" onClick={handleGoToNext}>
            Start Free Trial
          </ThemedMuiButton>
        </Box>
      </Box>
    </QuizContentWrapper>
  );
}

QuizSummary.propTypes = {
  onFadeOut: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

export default QuizSummary;
