const queryString = require('query-string');

/**
 * Returns url with the hash if hash exists
 * Example - /#select-plan-section
 * @param {Object} location - window.location or react router's location
 * @param {string} location.hash - location hash
 * @param {string} location.pathname - location pathname
 * @returns {string}
 */
export const getPageUrl = ({ hash = '', pathname }) => `${pathname}${hash}`;

/**
 * Basic parse to handle some of our routes where there is a fragment before the query string
 * due to this structure not being parsed by query-string lib or the window.location object
 * Example - /#select-plan-section?cta_id=1
 * @param {Object} location - window.location or react router's location
 * @param {string} location.hash - location hash
 * @param {string} location.search - location search query params
 * @returns {Object} - parsed query string object
 */
export const parseUrlFragmentAndQueryString = ({ hash, search }) => {
  if (hash) {
    const [, query = ''] = hash.split('?');

    return queryString.parse(query);
  }

  return queryString.parse(search);
};

/**
 * Check if host is localhost. Taken from react service worker
 * @returns {boolean}
 */
export const isLocalhost = () =>
  window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
