import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import __isEmpty from 'lodash/isEmpty';
import __forEach from 'lodash/forEach';
import __startsWith from 'lodash/startsWith';
import __toLower from 'lodash/toLower';
import mixpanel from 'mixpanel-browser';
import { Chip } from '@material-ui/core';
import { trackPageMixpanel } from '../../../services/functions';
import '../MinervaSignup/MinervaSignup.scss';
import './SawyerSignup.scss';
import { register, getUserByEmail } from '../../../services/parse';
import { notify } from '../../../services/functions';
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { gtmPageviewEvent, gtmEvent } from '../../../services/google-tag-manager';

export default function MinervaSignup(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  let history = useHistory();
  //const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;
  const selectedOffer = JSON.parse(sessionStorage.getItem('selectedMinervaOffer'));
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmTerms, setConfirmTerms] = useState(true);
  const [offersSignup, setOffersSignup] = useState(true);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');

  useEffect(() => {
    trackPageMixpanel('Memberships V1.4 Page: Register');

    gtmPageviewEvent({
      pageName: 'Memberships v1 Page: Register',
      componentProps: {
        ...props,
        location: { pathname: props.pathname },
      },
    });
  }, []);

  const togglePassword = () => {
    setShowPassword(!showPassword);

    const field = document.getElementById('password-field');
    if (field.type === 'password') {
      field.type = 'text';
    } else {
      field.type = 'password';
    }
  };

  const sendMixpanelEvent = (event) => {
    let eventName = '';
    let eventParams = {};
    switch (event) {
      case 'openFaqs':
        eventName = 'Memberships v1 Page: FAQ';
        eventParams = { productId: selectedOffer?.planId, productionDuration: selectedOffer?.subscriptionDuration };
        break;
      default:
        break;
    }
    mixpanel.track(eventName, eventParams);
  };

  const openFaqs = () => {
    document.getElementById('faq-list').classList.remove('hide-faqs');
    document.getElementById('faq-list').classList.add('show-faqs');
    sendMixpanelEvent('openFaqs');
  };

  const closeFaqs = () => {
    document.getElementById('faq-list').classList.remove('show-faqs');
    document.getElementById('faq-list').classList.add('hide-faqs');
  };

  const showEmailErrors = (message) => {
    document.getElementById('register-email').classList.add('has-error');
    document.getElementById('register-email-error').classList.remove('hide-error');
    setErrorEmail(message);
  };

  const removeEmailErrors = () => {
    document.getElementById('register-email').classList.remove('has-error');
    document.getElementById('register-email-error').classList.add('hide-error');
  };

  const showPasswordErrors = (message) => {
    document.getElementById('password-field').classList.add('has-error');
    document.getElementById('register-password-error').classList.remove('hide-error');
    setErrorPassword(message);
  };

  const removePasswordErrors = () => {
    document.getElementById('password-field').classList.remove('has-error');
    document.getElementById('register-password-error').classList.add('hide-error');
  };

  const checkEmailString = () => {
    //make sure it's a valid email address with regex
    let emailExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!__isEmpty(email) && !emailExp.test(__toLower(email))) {
      return 'Please enter a valid email address';
    }

    //test for any captial letters
    let captialLetter = /^[A-Z]*$/;
    if (!__isEmpty(email) && email.match(captialLetter) !== null) {
      return 'Please do not use capital letters';
    }

    return true;
  };

  const checkEmail = () => {
    const validate = checkEmailString();

    if (validate !== true) {
      return showEmailErrors(validate);
    }

    //if the user has an account, prompt them to log in
    if (!__isEmpty(email)) {
      getUserByEmail(email)
        .then((results) => {
          //don't show duplicate errors for login message
          let showLoginNote = true;
          let notifications = document.getElementsByClassName('message');

          __forEach(notifications, (message) => {
            if (__startsWith(message.textContent, 'It looks like you have an account')) {
              showLoginNote = false;
              return false;
            }
          });
          if (!__isEmpty(results) && showLoginNote) {
            //we have a user, so make a persistent notification show
            // notify('warning', 'It looks like you have an account. Click here to log in.', 10000, () => {
            //   history.push('/tryclassesfree-login');
            // });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // unset any error styling if validation clears
    removeEmailErrors();
  };

  const checkPassword = () => {
    //check length
    let min = 6;
    let max = 32;
    if (!__isEmpty(password) && (password.length < min || password.length > max)) {
      return showPasswordErrors('Please enter a password with 6 - 32 characters');
    }

    // make sure any errors are removed if validations pass
    removePasswordErrors();
  };

  const sendMixpanelStatus = () => {
    mixpanel.track('Memberships v1 Action: Register', {
      status: 'Incomplete',
      productId: selectedOffer?.planId,
      productionDuration: selectedOffer?.subscriptionDuration,
    });
  };

  const submitRegistration = async(e) => {
    e.preventDefault();
    // reCaptcha v3
    const captchaToken = await executeRecaptcha('tryClassesRegistration');
    setCaptchaToken(captchaToken);

    if (__isEmpty(email)) {
      sendMixpanelStatus();
      return showEmailErrors('Please enter an email');
    }

    const validate = checkEmailString();

    if (validate !== true) {
      sendMixpanelStatus();
      return showEmailErrors('email is not valid');
    }

    if (__isEmpty(password)) {
      sendMixpanelStatus();
      return showPasswordErrors('Please enter a password');
    }

    if (!confirmTerms) {
      notify('error', 'We cannot register you without accepting our Terms and Privacy Policy', 5000);
      sendMixpanelStatus();
      return;
    }

    // this.showLoading();
    let utmParamsObject = sessionStorage.getItem('utmParamsObject');
    // example: "{\"attributionData\":{\"utm\":{},\"iterable\":{\"iterable_campaign\":\"1396648\",\"iterable_template\":\"1939065\"}}}"
    if (utmParamsObject) {
      utmParamsObject = JSON.parse(utmParamsObject);
    }

    register({
      username: email,
      password: password,
      utmParamsObject: utmParamsObject,
      confirmPromoEmails: offersSignup,
      minerva_lead: true,
      captchaToken: captchaToken,
    })
      .then((user) => {
        mixpanel.people.set_once('parseAccountId', user.id);
        mixpanel.alias(user.id);
        // sets the newly created user to Pretrial status
        mixpanel.people.set({ 'Account Status': 'Pretrial' });
        mixpanel.track('Memberships v1 Action: Register', {
          email: email,
          attributionSource: utmParamsObject,
          status: 'success',
          productId: selectedOffer?.planId,
          productionDuration: selectedOffer?.subscriptionDuration,
        });

        // Google Tag Manager for 'parentRegistration'
        const gtmRegData = {
          action: 'parentRegistration',
          'userID': user.id,
          'emailAddress': email,
          'userType': 'parent',
          'subscriptionPlan': selectedOffer?.subscriptionDuration,
          'subscriptionType': 'recurring',
          'subscriptionListPrice': selectedOffer?.price,
          'trialLengthDays': selectedOffer?.trialDurationDays,
        };
        // gtmEvent(gtmRegData);

        sessionStorage.setItem('justRegistered', true);
        sessionStorage.setItem('gtmRegData', JSON.stringify(gtmRegData));

        // CVR variant for Begin's testing - switching order of account creation and plan selection
        if (props.receivedOptimizelyVariantEnabled) {
          history.push('/tryclasses-signup');
        } else {
          //history.push('/tryclasses-schedule');
          history.push('/tryclassesfree-schedule');
        }
      })
      .catch((response) => {
        console.log('response', response);

        mixpanel.track('Memberships v1 Page: Register', {
          status: 'Failed',
          productId: selectedOffer?.planId,
          productionDuration: selectedOffer?.subscriptionDuration,
        });
        let error = !__isEmpty(response.data) ? response.data.error : response.message;
        notify('error', error, 5000);
      });
  };

  return (
    <div className="wrapper">
      <div className="minerva-signup-page">
        <div className="stepBadge-container">
          <Chip label="STEP 1 OF 2" className="stepBadge" />
        </div>
        <div className="minerva-signup-title">
          <h2>
            Create your
            <br className="break-line" /> account
          </h2>
        </div>

        <div className="minerva-signup-form">
          <form>
            <div className="small-12 medium-6 input-fields">
              <input
                type="text"
                name="email"
                placeholder="Email"
                id="register-email"
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => checkEmail()}
              />
              <p id="register-email-error" className="error-message right hide-error">
                {errorEmail}
              </p>
            </div>

            <div className="small-12 medium-6 input-fields">
              <input
                type="password"
                name="password"
                id="password-field"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                onBlur={checkPassword}
              />
              <span
                id="password-field"
                className={`field-icon ${showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}`}
                onClick={togglePassword}
              ></span>
              <p id="register-password-error" className="error-message right hide-error">
                {errorPassword}
              </p>
            </div>

            <div className="checkbox-block">
              <div className="options-text">
                <input
                  name="offersSignup"
                  type="checkbox"
                  onChange={(e) => setOffersSignup(e.target.checked)}
                  checked={offersSignup}
                />
                <span>Yes! Please send me exclusive offers and updates on new app features.</span>
              </div>
              <div className="options-text">
                <input
                  name="confirmTerms"
                  type="checkbox"
                  onChange={(e) => setConfirmTerms(e.target.checked)}
                  checked={confirmTerms}
                />
                <span>
                  I understand and agree to the{' '}
                  <a href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/terms`} className="bold">
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/privacy`} className="bold">
                    Privacy Policy.
                  </a>
                </span>
              </div>
            </div>
            <div id="register-button" className="cta-button large" onClick={(e) => submitRegistration(e)}>
              <button id="registerButton" style={{ fontFamily: 'Quicksand' }}>
                Next
              </button>
            </div>
          </form>
        </div>
        <div className="terms-and-conditions">
          <a href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/terms`}>Terms and Conditions </a>apply
        </div>
      </div>
    </div>
  );
}
