import React, { memo } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from '../../styles';

function TakeOver(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
      className={classes.stepContainer}
    >
      <Grid item className={classes.stepNumber}>
        <Avatar>3</Avatar>
      </Grid>
      <Grid item>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item className={classes.stepsDetailContainer}>
            <Typography variant="h3" className="stepTitle">
              Learn to code with videos!
            </Typography>
            <Typography variant="body1">
              You will be receiving the pre-recorded classes via email within 1 business day.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(TakeOver);
