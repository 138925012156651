import __isEmpty from 'lodash/isEmpty';
import __find from 'lodash/find';
import __map from 'lodash/map';
import { isFeatureEnabled } from '../featureFlags';

const Parse = require('parse');
Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_JS_KEY);
Parse.serverURL = process.env.REACT_APP_PARSE_URL;

export function parseToJson(parseItem) {
  if (parseItem.toJSON) {
    return parseItem.toJSON()
  }

  console.warn('Not a valid parse object', parseItem);
  return parseItem;
}

export const oldLogin = (credentials) => {
  return new Promise((resolve, reject) => {
    Parse.User.logIn(credentials.username.toLowerCase(), credentials.password, {
      success: (user) => {
        //user is already saved by Parse in local storage so we will use that one
        //throughout the project
        resolve(user);
      },
      error: (user, error) => {
        reject(error);
        // if (error.message === 'username is required.') {
        // } else if (error.message === 'password is required.') {
        //     reject(error);
        // } else {
        //     Parse.User.logIn(credentials.username.toLowercase(), credentials.password, {
        //         success: (user) => {
        //             resolve(user);
        //         },
        //         error: (user, error) => {
        //             reject(error);
        //         }
        //     });
        // }
      },
    });
  });
};


export const login = credentials => {
  const useNewLogin = isFeatureEnabled('codesparkUnification');

  if (!useNewLogin) return oldLogin(credentials);

  return new Promise((resolve, reject) => {
    Parse.Cloud.run('UserLogin', credentials).then(sessionToken => { 
        Parse.User.become(sessionToken).then(user => {
            resolve(user);
        })
        .catch(error => {
          console.error('Parse.User.become error', error);
          reject(error);
        });
    })
    .catch(error => {
      console.error('Parse.Cloud UserLogin error', error);
      reject(error);
    });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    localStorage.clear();
    Parse.User.logOut();
    resolve('success');
  });
};

export const oldRegister = (accountInfo) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('CreateNewWebUser', {
      username: accountInfo.username,
      password: accountInfo.password,
      referral: accountInfo.referral,
      child_age: accountInfo.age,
      child_gender: accountInfo.gender,
      child_experience: accountInfo.experience,
      attributionSource: accountInfo.attributionSource,
      confirmPromoEmails: accountInfo.confirmPromoEmails,
      promoCode: accountInfo.promoCode,
      utmParamsObject: accountInfo.utmParamsObject,
      partner: accountInfo.partner,
      minerva_lead: accountInfo.minerva_lead,
      captchaToken: accountInfo.captchaToken,
    })
      .then((success) => {
        //log the user after creating the account
        login({
          username: accountInfo.username,
          password: accountInfo.password,
        })
          .then((user) => {
            resolve(user);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const register = accountInfo => {
  const useNewRegister = isFeatureEnabled('codesparkUnification');

  if (!useNewRegister) return oldRegister(accountInfo);

  return new Promise((resolve, reject) => {
    Parse.Cloud.run('CreateWebUser', accountInfo).then(({
      success,
      sessionToken,
     }) => { 
        if (!success) {
          reject();
        } else {
          Parse.User.become(sessionToken).then(user => {
              resolve(user);
          })
          .catch(error => {
            console.error('Parse.User.become error', error);
            reject(error);
          });
        }
    })
    .catch(error => {
      console.error('Parse.Cloud CreateWebUser error', error);
      reject(error);
    });
  });
};

export const subscribe = (options) => {
  return new Promise((resolve, reject) => {
    const params = {
      paymentMethodToken: options.paymentMethodToken,
      webOfferId: options.webOfferId,
      isResubscribe: options.isResubscribe,
      hasClasses: options.hasClasses,
      customerOptions: options.customerOptions || {},
      userId: options.userId || null,
      utmParamsObject: options.utmParamsObject || null,
      captchaToken: options.captchaToken,
    };
    if (!__isEmpty(options.partner)) {
      params.partner = options.partner;
    }
    if (!__isEmpty(options.promoCode)) {
      if (
        options.promoCode.substr(0, 2) === 'CS' ||
        options.promoCode.substr(0, 2) === 'CV' ||
        options.promoCode.substr(0, 3) === 'EDU'
      ) {
        params.couponCode = options.promoCode;
      } else {
        params.promoCode = options.promoCode;
      }
    }
    Parse.Cloud.run('PurchaseSubscription', params)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
    // if (options.isResubscribe) {
    //   Parse.Cloud.run('Resubscribe', params)
    //     .then((results) => {
    //       resolve(results);
    //     }).catch((error) => {
    //       reject(error);
    //     });
    // } else {
    //   Parse.Cloud.run('PurchaseWebSubscription', params)
    //     .then((results) => {
    //       resolve(results);
    //     }).catch((error) => {
    //       reject(error);
    //     });
    // }
  });
};

export const updateSubcriptionPlanToAnnual = (planData) => {
  return new Promise((resolve, reject) => {
    const params = {
      parseUserId: planData.userId,
      oldSubscriptionId: planData.oldSubscriptionId,
      newPlanId: planData.newPlanId,
      paymentMethodToken: null,
    };
    Parse.Cloud.run('UpgradeSubscription', params)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const setCurrentUser = (userData) => {
  // user is already saved by Parse in local storage so we will use that one
  return localStorage.setItem('Parse/' + process.env.REACT_APP_PARSE_APP_ID + '/currentUser', userData);
};

export const getCurrentUser = () => {
  // user is already saved by Parse in local storage so we will use that one
  return localStorage.getItem('Parse/' + process.env.REACT_APP_PARSE_APP_ID + '/currentUser');
};

export const getCurrentUserID = () => {
  const user = JSON.parse(localStorage.getItem('Parse/' + process.env.REACT_APP_PARSE_APP_ID + '/currentUser'));
  return user.objectId;
};

// Returns the currently logged in user as a Parse.User
export const getCurrentParseUser = () => {
  return Parse.User.current();
};

export const getUserByEmail = (email) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetUserExistsByUsername', {
      username: email,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDefaultSubscriptionPlans = (params = null) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetDefaultPlans', params)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getJSONAnnualAndMonthlyPlans = (params = null) =>
  getDefaultSubscriptionPlans(params).then(offers => offers.map(parseToJson));

/**
 * Will return actively offered plans by default ('active' in the backend)
 * @filters: { status: 'all' | 'inactive }
 * @param {*} status
 * @returns
 */
export const getMinervaOffers = (status = null) => {
  const data = status ? { status } : null;
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetMinervaOffers', data)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const verifyBeginPasswordReset = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('VerifyBeginPasswordReset', params)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const getMinervaClassAddOns = (status = null) => {
  const data = status ? { status } : null;
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetMinervaClassAddOns', data)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// v2.1
export const GetMinervaClassAddOns = () => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetMinervaClassAddOns')
      .then((results) => {
        const betterResults = __map(results, (result) => {
          return result.toJSON();
        });
        resolve(betterResults);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const SubscribeToMinervaClasses = (options) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('SubscribeToMinervaClasses', options)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserSubscriptionStatus = (userId, subscriptionId) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('CheckWebSubscriptionStatus', {
      userId,
      subscriptionId,
    })
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserSubscriptionDetails = (userId) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetCustomerDetails', {
      customerId: userId,
    })
      .then((results) => {
        const customer = results.customer;
        const data = {
          classesPlan: results.classesSubscription || null,
          plan: results.activeSubscription || null,
          planName: results.planName,
          planDescription: results.planDescription,

          firstName: customer !== null ? customer.firstName : '',
          lastName: customer !== null ? customer.lastName : '',
          // shipping: results.shippingAddress,
          paymentInfo: results.paymentInfo || {},
          customerData: results.customer || {},
          pendingPlan: results.pendingSubscription || {},
          id: customer !== null ? customer.id : '',
          isExpired: results.isExpired,
          hasUsedTrial: results.hasUsedTrial,
          isGifted: results.isGifted,
          partner: results.partner,
        };

        // calculate if the customer is currently on the trial period
        if (!__isEmpty(results.activeSubscription)) {
          const subData = results.activeSubscription.data;
          const hasTrialPeriod =
            subData &&
            subData.trialDuration !== undefined &&
            subData.trialDuration !== null &&
            subData.trialDuration > 0;

          if (hasTrialPeriod) {
            const today = new Date();
            const firstBillingDate = new Date(subData.firstBillingDate);
            const isTrialActive = hasTrialPeriod && firstBillingDate > today;

            data['isTrialActive'] = isTrialActive;
          }
        }

        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserPaymentHistory = (userId) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetPaymentHistory', {
      customerId: userId,
    })
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const validatePartnerData = (data, partner) => {
  return new Promise((resolve, reject) => {
    const params = { partner, data };
    Parse.Cloud.run('ValidatePartnerData', params)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const registerPartnerUser = (userInfo) => {
  return new Promise((resolve, reject) => {
    const params = {
      username: userInfo.username,
      password: userInfo.password,
      data: userInfo.data,
      child_age: userInfo.age,
      child_gender: userInfo.gender,
      child_experience: userInfo.experience,
    };
    Parse.Cloud.run('RegisterGympassUser', params)
      .then((success) => {
        //log the user after creating the account
        login({
          username: userInfo.username,
          password: userInfo.password,
        })
          .then((user) => {
            resolve(user);
          })
          .catch((error) => {
            console.log('login error', error);
            reject(error);
          });
      })
      .catch((error) => {
        console.log('register error', error);
        reject(error);
      });
  });
};

export const getWebOfferById = (offerId) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetWebOffers')
      .then((results) => {
        const offer = __find(results.offers, (offer) => {
          return offer.id === offerId;
        });
        resolve(offer);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Returns the web offer for the given planId
 * @param {string} planId
 * @returns {object|undefined}
 */
export const getWebOfferByPlanId = async (planId) => {
  const offers = await Parse.Cloud.run('GetWebOffers');

  return __find(offers.offers, (offer) => {
    return offer.planId === planId;
  });
};

export const getWebOfferByPromo = (promoCode) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetWebOfferByPromoCode', {
      promoCode,
    })
      .then((results) => {
        // formatting the web offer that comes back so that it is easier for the react app to display the object
        let webOffer = {};

        // in order to grab the braintree plans we have to run the GetWebOffers function in parse.
        // we may need to revisit that function at some point to see if there's an easier way to fetch the data we need without grabbing everything
        if (!__isEmpty(results.webOffer)) {
          getWebOfferById(results.webOffer.id).then((offer) => {
            webOffer = {
              id: offer.id,
              descriptionKey: offer.descriptionKey,
              isActive: offer.isActive,
              objectId: offer.objectId,
              offerSubtitle: offer.offerSubtitle,
              offerTitle: offer.offerTitle,
              originalPrice: offer.originalPrice,
              planId: offer.planId,
              plan: offer.plan,
              price: offer.price,
              promoCode: offer.promoCode,
              subscriptionDuration: offer.subscriptionDuration,
              subscriptionType: offer.subscriptionType,
              trialDurationDays: offer.trialDurationDays,
            };
            resolve(webOffer);
          });
        } else {
          resolve(webOffer);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCouponCode = (couponCode) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('FindWebOfferByCouponCode', {
      code: couponCode.toUpperCase(),
    })
      .then((results) => {
        let codeOffer = results;

        // in order to grab the braintree plans we have to run the GetWebOffers function in parse.
        // we may need to revisit that function at some point to see if there's an easier way to fetch the data we need without grabbing everything
        if (!__isEmpty(results.webOffer) && results.webOffer.get('descriptionKey') !== 'Gift') {
          getWebOfferById(results.webOffer.id).then((offer) => {
            codeOffer.webOffer = offer;
            resolve(codeOffer);
          });
        } else {
          resolve(results);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const redeemComp = (userId, couponCode) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('RedeemComp', {
      userId,
      couponCode: couponCode.toUpperCase(),
      customerOptions: {}, //this is required to be defined for now
    })
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const redeemVolumeLicense = (userId, couponCode) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('RedeemVolumeLicense', {
      userId,
      couponCode: couponCode.toUpperCase(),
      customerOptions: {}, // this is required to be defined for now
    })
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const oldUpdateUserEmail = (email) => {
  return new Promise((resolve, reject) => {
    const user = getCurrentParseUser();

    // only call iterable function if username/email was updated
    if (user.get('username') !== email) {
      Parse.Cloud.run('IterableUpdateEmail', {
        oldEmail: user.get('username'),
        newEmail: email,
      });
    }

    Parse.Cloud.run('ChangeUsername', {
      newUsername: email,
    })
      .then(() => {
        resolve('success');
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateUserEmail = async (email) => {
  const useNewUpdateUserEmail = isFeatureEnabled('codesparkUnification');

  if (!useNewUpdateUserEmail) return oldUpdateUserEmail(email);

  const params = { newUsername: email };

  return new Promise((resolve, reject) => {
    Parse.Cloud.run('UpdateUserEmail', params)
      .then((updatedUser) => {
        const sessionToken = updatedUser.getSessionToken();
        return Parse.User.become(sessionToken);
      })
      .then((user) => {
        resolve(user);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateUserPassword = (password) => {
  return new Promise((resolve, reject) => {
    const user = JSON.parse(getCurrentUser());

    Parse.Cloud.run('ChangePassword', {
      userId: user.objectId,
      newPassword: password,
    })
      .then(() => {
        resolve('success');
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updatePaymentMethod = (data) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('UpdateCreditCard', {
      customerId: data.userId,
      paymentNonce: data.nonce,
    })
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const cancelSubscription = (data) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('CancelSubscription', {
      subscriptionId: data.subscriptionId,
      cancelReason: data.cancelReason,
      email: data.email,
    })
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const oldResetPassword = email => {
  return new Promise((resolve, reject) => {
    Parse.User.requestPasswordReset(email)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resetPassword = email => {
  const useNewResetPassword = isFeatureEnabled('codesparkUnification');

  if (!useNewResetPassword) return oldResetPassword(email);

  const params = { email };

  return new Promise((resolve, reject) => {
    Parse.Cloud.run('RequestBeginPasswordReset', params)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPlushCount = () => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetPlushieInventoryQuantity')
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deductPlushCount = () => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('DeductPlushieInventoryQuantity')
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const submitGirlScoutInfo = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('BulkPurchaseGirlScoutsInfo', params)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const codingEventRequest = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('CodingEventRequest', params)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDefaultBulkPurchaseCampaign = () => {
  return new Promise((resolve, reject) => {
    const params = {};
    Parse.Cloud.run('GetBulkPurchaseCampaign', params)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const bulkPurchase = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('BulkPurchase', params)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const subscribeToNewsletter = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('SubscribeToNewsletter', params)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchEmailPreferences = () => {
  const user = JSON.parse(getCurrentUser());
  const params = { email: user.email };

  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetSubscribedEmailLists', params)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateEmailPreferences = (marketingMessageChannel, parentNewsletterMessages) => {
  const params = {
    marketingMessageChannel,
    parentNewsletterMessages,
  };

  return new Promise((resolve, reject) => {
    Parse.Cloud.run('IterableSetEmailPreferences', params)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserGeoIp = () => {
  const geoAPI = process.env.REACT_APP_GEOIP_API;

  return new Promise((resolve, reject) => {
    fetch(geoAPI)
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.error('error', error);
        reject(error);
      });
  });
};

/**
 * Cancel a Minerva class add-on
 * @returns {*}
 */
export const cancelMinervaClassAddon = () => {
  const params = {
  };
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('CancelMinervaClasses', params)
      .then((results) => {
        resolve(results);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Sends user events
 * @param {string} email
 * @param {string} eventName
 * @param {{subscription_platform:string;email:string;optInStatus:boolean;}} dataFields
 * @returns {Promise<*>}
 */
export const trackUserEvent = async (email, eventName, dataFields) => {
  return await Parse.Cloud.run('TrackUserEvent', {
    email,
    eventName,
    dataFields,
  });
};
