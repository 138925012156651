function createData(info, ...additionalInfo) {
  return { info, additionalInfo };
}

export const classTopData = [
  createData('BEST FOR SUPERCODERS', 'BEST FOR SUPERCODERS'),
  createData('Weekly Live Classes', 'Pre-recorded Classes'),
  createData('$49.99/month', '$19.99/month'),
  createData('TRY FREE', 'TRY FREE'),
];

export const classBottomData = [
  createData('30 day FREE trial', '7 day FREE trial'),
  createData('4 live classes/month', 'N/A'),
  createData('Pre-recorded class library', 'Pre-recorded class library'),
  createData('codeSpark app access', 'codeSpark app access'),
  createData('Printable activity sheets included', 'Printable activity sheets included'),
];
