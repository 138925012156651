import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import __includes from 'lodash/includes';
import {
  createBraintreeInstance,
  createBraintreeHostedFields,
  createPaypalInstance,
  createApplePayInstance,
  createGooglePayInstance,
} from '../../../../services/braintree';
import { notify } from '../../../../services/functions';
import {
  getCurrentUser,
  parseToJson,
} from '../../../../services/parse';
import {
  determinePaymentError,
  processUserSubscription,
  onLoadPageAnalytics,
} from './service';
import { fieldStyles, fieldSettings } from './config';
import { sendFacebookEvent } from '../../../../services/facebook-capi';
import { useSelector } from 'react-redux';
import { hasOndemandClasses } from '../../utils';

const MinervaPaymentContext = React.createContext();

export function usePayment() {
  return useContext(MinervaPaymentContext);
}

export function MinervaPaymentProvider({ children }) {
  const history = useHistory();
  const [offerPlans, setOfferPlans] = useState([]);
  const [user, setUser] = useState(null);
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState(null);
  const [braintreeInstance, setBraintreeInstance] = useState(null);
  const [payPalInstance, setPaypalInstance] = useState(null);
  const [googlePayInstance, setGooglePayInstance] = useState(null);
  const [applePayInstance, setApplePayInstance] = useState(null);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const selectedMinervaOfferId = useSelector((state) => state.appState.minervaSelectedClassId);
  let selectedOffer = useSelector((state) => state.appState.minervaSelectedClass);

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserData();
    setupPaymentsInstance();
    onLoadPageAnalytics();
  }, []);

  const getUserData = async () => {
    const user = await JSON.parse(getCurrentUser());

    setUser(user);
  };

  const setupPaymentsInstance = async () => {
    const braintreeInstance = await createBraintreeInstance();
    setBraintreeInstance(braintreeInstance);
    await setUpBraintreeHostedFields(braintreeInstance);
    await setUpPaypalInstance(braintreeInstance);
    // await setUpApplePayInstance(braintreeInstance);
    await setUpGooglePayInstance(braintreeInstance);
  };

  const sendPayload = async (error, payload) => {
    if (error) {
      const errorMessage = determinePaymentError(error);
      notify('error', errorMessage, 5000);
      setIsProcessingPayment(false);
    }

    if (!error) {
      const subscription = await processUserSubscription({
        user,
        selectedOffer,
        payload,
      });

      setIsProcessingPayment(false);
      sendFacebookEvent({
        event_name: 'StartTrial',
        event_source_url: window.location.href,
        custom_data: {
          'subscriptionID': subscription.subscriptionData.id,
          timestamp: Math.floor(Date.now() / 1000),
          source: 'codespark',
          campaign: 'minerva',
        },
      });

      if (subscription) {
        const webOffer = parseToJson(subscription.webOffer);
        if (hasOndemandClasses(webOffer)) {
          history.push('/tryclasses-thankyouapp');
        } else {
          history.push('/tryclasses-thankyou');
        }
      }
    }
  };

  /**
   * Process the payment
   * @param {string} paymentMethod credit card, paypal, apple pay, google pay
   * @returns {void}
   */
  const processPayment = async (paymentMethod) => {
    sessionStorage.setItem('paymentMethod', paymentMethod);
    setIsProcessingPayment(true);
    if (paymentMethod === 'card') {
      await hostedFieldsInstance.tokenize(sendPayload);
    }

    if (paymentMethod === 'paypal') {
      await payPalInstance.tokenize({ flow: 'vault' }, sendPayload);
    }

    if (paymentMethod === 'applePay') {
      try {
        if (
          window.ApplePaySession &&
          window.ApplePaySession.supportsVersion(3) &&
          window.ApplePaySession.canMakePayments()
        ) {
          const paymentRequest = await applePayInstance.createPaymentRequest({
            total: {
              label: 'codeSpark Academy',
              amount: selectedOffer?.price,
            },
          });
          const session = new window.ApplePaySession(3, paymentRequest);
          await validatingApplePayEvent(session, applePayInstance);
          return session.begin();
        }

        throw new Error('Apple Pay is not supported');
      } catch (error) {
        notify(
          'error',
          'Hmm. There seems to be a problem with loading Apple Pay. Make sure to add cards to apple pay to make purchases on this mac.',
          5000
        );
      } finally {
        setIsProcessingPayment(false);
      }
    }

    if (paymentMethod === 'googlePay') {
      try {
        const paymentDataRequest = await googlePayInstance.createPaymentDataRequest({
          transactionInfo: {
            currencyCode: 'USD',
            totalPriceStatus: 'FINAL',
            totalPrice: selectedOffer?.price,
          },
        });
        const paymentData = await window.googlePayClient.loadPaymentData(paymentDataRequest);
        const result = await googlePayInstance.parseResponse(paymentData);
        await sendPayload(null, result);
      } catch (error) {
        console.error('Error with google payment', error);
        if (error.statusCode === 'CANCELED') {
          notify('error', 'Unable to complete purchase because Google Pay was cancelled', 3000);
        } else {
          notify('error', 'Sorry Google Pay is unable to process payment at this time', 3000);
        }
      } finally {
        setIsProcessingPayment(false);
      }
    }
  };

  const setUpApplePayInstance = async (clientInstance) => {
    createApplePayInstance(
      {
        client: clientInstance,
      },
      (applePayErr, applePayInstance) => {
        if (applePayErr) {
          console.error('Error creating applePayInstance', applePayErr);
          notify(
            'error',
            'Hmm. There seems to be a problem with loading Apple Pay. Please refresh the page and try again.',
            5000
          );
          return;
        }
        console.log('Apple Pay instance created', applePayInstance);
        setApplePayInstance(applePayInstance);
      }
    );
  };

  const setUpGooglePayInstance = async (clientInstance) => {
    createGooglePayInstance(
      {
        client: clientInstance,
        googlePayVersion: 2,
        googleMerchantId: 'BCR2DN4T7D2KPKYX',
      },
      (error, googlePaymentInstance) => {
        if (error) {
          console.error('Error creating googlePayInstance', error);
          notify(
            'error',
            'Hmm. There seems to be a problem with loading Google Pay. Please refresh the page and try again.',
            5000
          );
          return;
        }
        window.googlePayClient
          .isReadyToPay({
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
            existingPyamentMethodsRequired: true,
          })
          .then((isReadyToPay) => {
            if (isReadyToPay.result) {
              setGooglePayInstance(googlePaymentInstance);
            } else {
              notify('Sorry. You are unable to use Google Pay at this time', 3000);
            }
          });
      }
    );
  };

  const setUpPaypalInstance = async (clientInstance) => {
    createPaypalInstance(
      {
        client: clientInstance,
      },
      (paypalErr, paypalInstance) => {
        if (paypalErr) {
          console.log('Error with paypal', paypalErr);
          notify(
            'error',
            'Hmm. There seems to be a problem with loading PayPal. Please refresh the page and try again.',
            5000
          );
          return;
        }

        setPaypalInstance(paypalInstance);
      }
    );
  };

  const validatingApplePayEvent = async (appleSession, appleInstance) => {
    const _performTransaction = (details, callback) => {
      appleInstance
        .tokenize({
          token: details.payment.token,
        })
        .then((payload) => {
          appleSession.completePayment(window.ApplePaySession.STATUS_SUCCESS);
          sendPayload(null, payload);
        })
        .catch((error) => {
          console.error('Error with apple pay', error);
          notify('error', 'There was an error processing your payment', 3000);
          appleSession.completePayment(window.ApplePaySession.STATUS_FAILURE);
          return;
        });
    };

    appleSession.onvalidatemerchant = (event) => {
      appleInstance.performValidation(
        {
          validationURL: event.validationURL,
          displayName: 'codespark Academy',
        },
        (validationErr, merchantSession) => {
          if (validationErr) {
            console.error('Error validating merchange:', validationErr);
            notify('error', 'Sorry there seems to be an issue with Apple Pay.', 3000);
            return;
          }
          appleSession.completeMerchantValidation(merchantSession);
        }
      );
    };

    appleSession.onpaymentauthorized = (event) => {
      _performTransaction(event, (transaction) => {
        if (transaction.approved) {
          console.log('apple transaction approved');
        } else {
          console.error('apple transaction error');
        }
      });
    };
  };

  // @todo: find a way to decouple this logic
  const setUpBraintreeHostedFields = async (clientInstance) => {
    await createBraintreeHostedFields(
      {
        client: clientInstance,
        styles: fieldStyles,
        fields: fieldSettings,
      },
      (hostedFieldsErr, hostedFieldsInstance) => {
        // transfer business logic to a dedicated file
        if (hostedFieldsErr) {
          console.log('Braintree hosted fields error', hostedFieldsErr);
          notify(
            'error',
            'Hmm. There seems to be a problem with loading the payment screen. Please refresh the page and try again.',
            3000
          );
          return;
        }

        hostedFieldsInstance.on('focus', (e) => {
          // get the parent and add the transition class
          e.fields[e.emittedBy].container.classList.add('focused');
        });

        hostedFieldsInstance.on('blur', (e) => {
          // get the parent and remove the transition class
          e.fields[e.emittedBy].container.classList.remove('focused');
        });

        // we want to show the card type's logo
        hostedFieldsInstance.on('cardTypeChange', (e) => {
          console.log(e);
          console.log(e.fields);
          console.log(e.cards);
          if (e.fields && e.cards && __includes(e.fields[e.emittedBy].container.classList, e.cards[0].type)) {
            e.fields[e.emittedBy].container.classList.remove(e.cards[0].type);
          }

          if (e.cards.length === 1) {
            e.fields[e.emittedBy].container.classList.add(e.cards[0].type);
          } else {
          }
        });

        setHostedFieldsInstance(hostedFieldsInstance);
      }
    );
  };

  return (
    <MinervaPaymentContext.Provider
      value={{
        selectedMinervaOfferId,
        selectedOffer,
        offerPlans,
        isProcessingPayment,
        processPayment,
      }}
    >
      {children}
    </MinervaPaymentContext.Provider>
  );
}
