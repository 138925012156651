import React from 'react';
import { FlagProvider, UnleashClient, useFlag, useFlagsStatus, useVariant } from '@unleash/proxy-client-react';

const config = {
  url: process.env.REACT_APP_UNLEASH_URL,
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
  appName: 'codeSpark web',
  environment: process.env.NODE_ENV,
  disableRefresh: true,
};

// Create client separately and expose for analytics use outside of react
export const featureFlagClient = new UnleashClient(config);

export const FeatureFlagsProvider = ({ children }) => {
  return <FlagProvider unleashClient={featureFlagClient}>{children}</FlagProvider>;
};

export const isFlagEnabled = flag => flag.enabled;
export const isVariantEnabled = variant => variant && variant.enabled;

export const getEnabledExperiments = flags =>
  flags.filter(flag => isFlagEnabled(flag) && isVariantEnabled(flag.variant));

export const getVariantPayload = ({ payload }) => {
  if (!payload) {
    return undefined;
  }

  let variantPayload;
  if (payload.type === 'json') {
    variantPayload = JSON.parse(payload.value);
  }

  return variantPayload;
};

/**
 * Get all feature flags from unleash
 * @returns {array} - an array feature flag objects
 */
const getFeatureFlags = () => {
  const toggles = featureFlagClient.getAllToggles();

  return toggles;
};

/**
 * Checks if a feature flag is enabled.
 * @param {string} featureFlag name of feature flag.
 * @returns {boolean} true if the flag is enabled, false otherwise.
 */
export const isFeatureEnabled = featureFlag => {
  const toggles = getFeatureFlags();

  const flag = toggles?.find(toggle => toggle.name === featureFlag)

  return !!flag && isFlagEnabled(flag);
}

/**
 * Checks if a flag is enabled.
 * @param {string} flagName the name of the flag to check.
 * @returns {boolean} true if the flag is enabled, false otherwise.
 */
export const useCodeSparkFlag = (flagName) => {
  const { flagsError, flagsReady } = useFlagsStatus();
  const isEnabled = useFlag(flagName);

  // bail if something goes wrong or flags are not ready.
  if (flagsError || !flagsReady) return false;

  return isEnabled;
}

/**
 * Checks if a flag is enabled and returns the variant object.
 * @param {string} flagName the name of the flag to check.
 * @returns {string|object} the variant object if the flag is enabled, false otherwise.
 */
export const useCodeSparkVariant = (flagName) => {
  const { flagsError, flagsReady } = useFlagsStatus();
  const variant = useVariant(flagName);

  // bail if something goes wrong or flags are not ready.
  if (flagsError || !flagsReady) return false;

  return variant;
}
