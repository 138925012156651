import React, { memo } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useStyles } from '../../styles';

function Signin(props) {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            className={classes.stepContainer}
        >
            <Grid item className={classes.stepNumber}>
                <Avatar>2</Avatar>
            </Grid>
            <Grid item>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item className={classes.stepsDetailContainer}>
                        <Typography variant='h3' className='stepTitle'>Sign in</Typography>
                        <Typography variant='body1'>Use your new account email and password to sign in.</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default memo(Signin);