import moment from 'moment';
import { isRobotE2E } from '../utils/e2eBotUtils';
import { getExperimentVariationIds } from './analytics';

export function gtmEvent({ action, withTimeout = false, callback, ...extraAttributes } = {}) {
  if (withTimeout) {
    setTimeout(() => {
      pushToDatalayerWrap({ action, ...extraAttributes });
      if (callback) callback();
    }, 0);
  } else {
    pushToDatalayerWrap({ action, ...extraAttributes });
  }
}

function pushToDatalayerWrap({ action, ...otherAttributes } = {}) {
  pushToDatalayer(
    'event',
    action,
    getAllAttributes(otherAttributes)
  );
}

function pushToDatalayer() {
  const win = window || {};
  const dl = win.dataLayer;
  if (dl) dl.push(arguments);
}

function getAllAttributes({ pageName, componentProps = {}, ...extraAttributes } = {}) {
  const allAttributes = {
    app_name: 'web-site',
    timestamp: moment().unix(),
    is_e2e_bot: isRobotE2E(),
    ...extraAttributes,
  };

  let experimentVariantIds = getExperimentVariationIds();
  if (experimentVariantIds) {
    experimentVariantIds = experimentVariantIds.reduce((acc, curr, i) => {
      const comb = acc + curr.id + '-' + curr.variant;
      if (i === experimentVariantIds.length - 1) return comb;
      return comb + ';';
    }, '');
  } else {
    experimentVariantIds = '';
  }
  allAttributes.unleash_ab_test_ids = experimentVariantIds;

  if (pageName) allAttributes.screen_name = pageName;
  if (componentProps.location) allAttributes.pageUrl = componentProps.location.pathname;
  if (componentProps.deviceInfo) {
    allAttributes.deviceType = componentProps.deviceInfo.device;
    allAttributes.deviceOS = componentProps.deviceInfo.browser.os;
  }
  return allAttributes;
}

export function gtmPageviewEvent({ withTimeout = true, ...extraAttributes } = {}) {
  gtmEvent({
    action: 'screen_view',
    withTimeout,
    ...extraAttributes,
  });
}

export function gtmPurchaseEvent({
  purchaseType = 'sub',
  currency = 'USD',
  amountValue,
  amountPaid,
  subId,
  planId,
  coupon,
  userId,
  subType,
  subName,
  trialPeriod,
  ...otherData
} = {}) {
  gtmEvent({
    action: isSubscriptionPurchase({ purchaseType }) ? 'subscription_purchase_completed' : 'one_time_purchase_completed',
    ecommerce: {
      currency,
      // - this value should be the total the user spent for
      //   everything in their shopping cart
      // - for now, just doing the membership price
      value: amountValue,
      affiliation: 'codeSpark Website',
      transaction_id: subId,
      coupon,
      // - items should have an object for every item the user purchased
      //   in their shopping cart. like addons + sub
      // - for now, only doing the sub
      items: [{
        item_name: subName,
        item_id: planId,
        subscrption_id: subId,
        price: amountPaid,
        item_brand: 'codeSpark',
        item_category: 'customer_id=' + userId,
        item_category2: 'trial_period_days=' + trialPeriod,
        item_category3: 'is_e2e_bot=' + isRobotE2E(),
        item_category4: null,
        item_variant: subType,
        // - quantity is hard-coded to 1 for now
        quantity: 1
      }],
      purchaseType,
    },
    ...otherData,
  });
}

function isSubscriptionPurchase({ purchaseType } = {}) {
  return purchaseType === 'sub' || purchaseType === 'resubscribe';
}

export function gtmOtpEvent(props) {
  gtmPurchaseEvent({
    purchaseType: 'otp',
    ...props,
  });
}
