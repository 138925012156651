import { Box, Fade, makeStyles } from '@material-ui/core';
import { snakeCase } from 'lodash';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import QuizAnswerValueProp from './QuizAnswerValueProp';
import ThemedMuiButton from '../../../components/elements/ThemedMuiButton';
import NotFound from '../../NotFoundPage';
import { setQuizQuestionAnswer } from '../actions';
import {
  selectCurrentQuestion,
  selectQuestionAnswer,
  selectTotalQuestions,
  totalVisibleQuestions,
} from '../selectors';
import FooImage from './FooImage';
import { FADE_TIMEOUT } from '../constants';
import { ContentWidth, QuizContentWrapper, TextPadding } from './Layout';
import { ColumnCenter } from '../../../components/elements/Layout';
import CodeSparkMuiTypography from '../../../components/elements/CodeSparkMuiTypography';
import { sendAnalyticsEvent } from '../../../services/analytics';
import { botClassName } from '../../../utils/e2eBotUtils';

const useQuestionOptionsStyles = makeStyles(theme => ({
  optionLabel: {
    width: '100%',
    border: '.125rem solid #C7CBD1',
    borderRadius: '.625rem',
    backgroundColor: '#ffffff',
    padding: '1rem',
    marginLeft: 0,
    marginRight: 0,
    textAlign: 'left',
    justifyContent: 'flex-start',
    '&:not(:first-of-type)': {
      marginTop: '1rem',
      [theme.breakpoints.down('sm')]: {
        marginTop: '0.5rem',
      },
    },
    '& span': {
      color: '#22252A',
    },
    '&:hover': {
      backgroundColor: '#ffffff',
    },
    '&.selected': {
      border: '.125rem solid #015798',
      backgroundColor: '#015798',
      boxShadow: '.25rem .25rem .25rem rgba(0, 0, 0, 0.25)',

      '& span': {
        color: '#ffffff',
      },
    },
  },
  focusedOption: {
    border: '.125rem solid #015798',
    '&:not(.selected)': {
      '& .MuiTouchRipple-child': {
        backgroundColor: '#015798',
      },
    },
  },
}));

function QuestionOptions({ options, questionId, text, onSelectAnswer }) {
  const classes = useQuestionOptionsStyles();
  const selectedOption = useSelector(state => selectQuestionAnswer(state, questionId));

  return (
    <ColumnCenter width="100%" aria-label={`${text} options`}>
      {options.map((option, i) => {
        const isSelected = selectedOption === i;

        return (
          <ThemedMuiButton
            key={`question_${questionId}_option_${i}`}
            className={`${botClassName('quiz-option')} ${classes.optionLabel} ${
              isSelected ? 'selected' : ''
            }`}
            onClick={() => onSelectAnswer(i)}
            aria-label={`Select ${option.label} option`}
            focusVisibleClassName={classes.focusedOption}
            disableElevation
          >
            <CodeSparkMuiTypography component="span" variant="body1">
              {option.label}
            </CodeSparkMuiTypography>
          </ThemedMuiButton>
        );
      })}
    </ColumnCenter>
  );
}

QuestionOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      coderType: PropTypes.string,
      valueProp: PropTypes.string,
    })
  ).isRequired,
  questionId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onSelectAnswer: PropTypes.func.isRequired,
};

const useQuizQuestionStyles = makeStyles(theme => ({
  quizWrapper: {
    backgroundColor: '#F0F8FF',
  },
  questionText: {
    textAlign: 'center',
    color: '#22252A',
  },
  progressWrapper: {
    position: 'relative',
  },
  backButton: {
    position: 'absolute',
    left: 0,
    padding: 0,
    background: 'transparent',
    minWidth: 0,
    color: '#015798',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  questionProgress: {
    fontFamily: 'Quicksand',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#8E9091',
  },
}));

function QuizQuestionVariant1({ onFadeOut }) {
  const { questionNumber: questionNumberParam } = useParams();
  const questionNumber = Number(questionNumberParam);
  const dispatch = useDispatch();
  const totalQuestions = useSelector(totalVisibleQuestions);
  const question = useSelector(state => selectCurrentQuestion(state, questionNumber));
  const classes = useQuizQuestionStyles();
  const quizHomepage = useSelector(state => state.experiments.quizHomepage);

  if (!question) {
    return <NotFound />;
  }

  const { text, options, image, id } = question;
  const handleNext = optionIndex => {
    dispatch(
      setQuizQuestionAnswer({
        questionId: id,
        answer: optionIndex,
      })
    );

    const nextPath =
      questionNumber === totalQuestions
        ? '/quiz/summary'
        : `/quiz/questions/${Number(questionNumber) + 1}`;

    setTimeout(() => {
      sendAnalyticsEvent('web_onboarding_quiz_question_answered', {
        app: 'web-site',
        question_name: snakeCase(id),
        screen_name: 'quiz',
      });
      onFadeOut(nextPath);
    }, 500);
  };

  const handleBack = () => {
    const isFirstQuestion = questionNumber === 1;
    const firstPage = quizHomepage ? '/email-wall' : '/quiz';
    const nextPath = isFirstQuestion ? firstPage : `/quiz/questions/${Number(questionNumber) - 1}`;

    onFadeOut(nextPath);
  };

  return (
    <QuizContentWrapper className={classes.quizWrapper}>
      <ContentWidth className={classes.progressWrapper}>
        <Box className={classes.progressWrapper}>
          <ThemedMuiButton className={classes.backButton} onClick={handleBack}>
            <ChevronLeftIcon />
          </ThemedMuiButton>

          <CodeSparkMuiTypography variant="subtitle3" className={classes.questionProgress}>
            Question {questionNumber}/{totalQuestions}
          </CodeSparkMuiTypography>
        </Box>

        <TextPadding>
          <CodeSparkMuiTypography variant="h3" className={classes.questionText}>
            {text}
          </CodeSparkMuiTypography>
        </TextPadding>

        <QuestionOptions
          questionId={id}
          options={options}
          text={text}
          onSelectAnswer={handleNext}
        />
      </ContentWidth>

      <FooImage image={image} width="10.875rem" />
    </QuizContentWrapper>
  );
}

QuizQuestionVariant1.propTypes = {
  onFadeOut: PropTypes.func.isRequired,
};

// Copy and duplicating Variant1 here so it is easier to remove the losing variant
// if test does well
// The losing variant (or both) will be removed
function QuizQuestionVariant2({ onFadeOut, visible = true }) {
  const location = useLocation();
  const { questionNumber: questionNumberParam } = useParams();
  const questionNumber = Number(questionNumberParam);
  const dispatch = useDispatch();

  const question = useSelector(state => selectCurrentQuestion(state, questionNumber));
  const totalQuestions = useSelector(selectTotalQuestions);
  const classes = useQuizQuestionStyles();

  const [showValueProp, setShowValueProp] = useState(false);
  // Keep track of the fade in and out for the animation between the value props and question
  // states. We have to be careful since there is a separate fade animation between the
  // pages specified in the router
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleExitTransition = useCallback(() => {
    setIsTransitioning(false);
  }, []);

  // reset the showValueProp when location changes so user always sees the question
  // when going back
  useEffect(
    () => () => {
      if (showValueProp) {
        setShowValueProp(false);
      }
    },
    [location, showValueProp]
  );

  if (!question) {
    return <NotFound />;
  }

  const { text, options, image, id } = question;
  const handleSelectOption = optionIndex => {
    dispatch(
      setQuizQuestionAnswer({
        questionId: id,
        answer: optionIndex,
      })
    );

    setTimeout(() => {
      sendAnalyticsEvent('web_onboarding_quiz_question_answered', {
        app: 'web-site',
        question_name: snakeCase(id),
      });

      setShowValueProp(true);
      setIsTransitioning(true);
    }, 500);
  };

  const handleTransition = nextPath => {
    setShowValueProp(false);
    onFadeOut(nextPath);
  };

  const handleNext = () => {
    const nextPath =
      questionNumber === totalQuestions
        ? '/quiz/summary'
        : `/quiz/questions/${Number(questionNumber) + 1}`;

    handleTransition(nextPath);
  };

  const handleBack = () => {
    if (showValueProp) {
      setShowValueProp(false);
      setIsTransitioning(true);
      return;
    }

    const isFirstQuestion = questionNumber === 1;
    const nextPath = isFirstQuestion ? '/quiz' : `/quiz/questions/${Number(questionNumber) - 1}`;

    handleTransition(nextPath);
  };

  return (
    <QuizContentWrapper className={classes.quizWrapper}>
      <ContentWidth>
        <Box className={classes.progressWrapper}>
          <ThemedMuiButton className={classes.backButton} onClick={handleBack}>
            <ChevronLeftIcon />
          </ThemedMuiButton>

          <CodeSparkMuiTypography variant="subtitle3" className={classes.questionProgress}>
            Question {questionNumber}/{totalQuestions}
          </CodeSparkMuiTypography>
        </Box>

        {/* keep in separate Fades for different timeouts */}
        <Fade
          in={showValueProp && !isTransitioning}
          timeout={FADE_TIMEOUT}
          onExited={handleExitTransition}
          unmountOnExit
        >
          <Box>
            <QuizAnswerValueProp
              questionId={id}
              options={options}
              image={image}
              goToNext={handleNext}
            />
          </Box>
        </Fade>

        {/* Check for visible because when quiz moves from question to question,
            we don't want the question to prematurely show before the page fade transition
            is complete. Cannot rely on isTransitioning because timeout is 0 here to adjust
            for the FadeWrapper animation. Probably a much less janky way to do this */}
        <Fade
          in={!showValueProp && visible && !isTransitioning}
          timeout={0}
          onExited={handleExitTransition}
          unmountOnExit
        >
          <ColumnCenter>
            <TextPadding>
              <CodeSparkMuiTypography variant="h3" className={classes.questionText}>
                {text}
              </CodeSparkMuiTypography>
            </TextPadding>
            <QuestionOptions
              questionId={id}
              options={options}
              text={text}
              onSelectAnswer={handleSelectOption}
            />
          </ColumnCenter>
        </Fade>
      </ContentWidth>
    </QuizContentWrapper>
  );
}

QuizQuestionVariant2.propTypes = {
  onFadeOut: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

const variantComponentMapping = {
  quizVariant1: QuizQuestionVariant1,
  quizVariant2: QuizQuestionVariant2,
  quizHomepage: QuizQuestionVariant1,
};
// If productionalizing, remove this and just return the winning variant
function QuizQuestion({ variant, ...props }) {
  const QuizComponent = variantComponentMapping[variant];

  return <QuizComponent variant={variant} {...props} />;
}

export default QuizQuestion;
