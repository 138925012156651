import React from 'react';
import { Typography, createTheme, ThemeProvider, makeStyles } from '@material-ui/core';
import ParsedContent from '../ParsedContent';

// Create a contained typography theme because the current codebase already uses mui Typography
// everywhere and we don't want it to break
const theme = createTheme();

const typographyTheme = createTheme(theme, {
  typography: {
    fontFamily: ['"Montserrat"', 'sans-serif'],
    fontSize: '16px',
    h1: {
      fontFamily: 'Quicksandbold',
      fontSize: '3rem',
      lineHeight: '3.75rem',
      letterSpacing: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.75rem',
        lineHeight: '2.5rem',
      },
    },
    h2: {
      fontFamily: 'Quicksandbold',
      fontSize: '2.25rem',
      lineHeight: '2.875rem',
      letterSpacing: '.0313rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
      },
    },
    h3: {
      fontFamily: 'Quicksandbold',
      fontSize: '1.75rem',
      lineHeight: '2.5rem',
      letterSpacing: '.0313rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
      },
    },
    h4: {
      fontFamily: 'Quicksandbold',
      fontSize: '1.5rem',
      lineHeight: '2.125rem',
      letterSpacing: '.0313rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
        lineHeight: '1.6875rem',
      },
    },
    h5: {
      fontFamily: 'Quicksandbold',
      fontSize: '1.25rem',
      lineHeight: '1.875rem',
      letterSpacing: '.0313rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
    },
    h6: {
      fontFamily: 'Quicksandbold',
      fontSize: '1rem',
      lineHeight: '1.375rem',
      letterSpacing: '.0156rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
    },
    subtitle1: {
      fontFamily: 'Montserrat',
      fontWeight: 'normal',
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      letterSpacing: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
      },
    },
    subtitle2: {
      fontFamily: 'Montserrat',
      fontWeight: 'normal',
      fontSize: '1.125rem',
      lineHeight: '1.625rem',
      letterSpacing: '.0156rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
        lineHeight: '1.625rem',
      },
    },
    body1: {
      fontFamily: 'Montserrat',
      fontWeight: 'normal',
      fontSize: '1.125rem',
      lineHeight: '1.875rem',
      letterSpacing: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: '.875rem',
        lineHeight: '1.375rem',
      },
    },
    body2: {
      fontFamily: 'Montserrat',
      fontWeight: 'normal',
      fontSize: '1rem',
      lineHeight: '1.625rem',
      letterSpacing: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: '.875rem',
        lineHeight: '1.375rem',
      },
    },
  },
});

const useAdditionalVariantStyles = makeStyles(theme => ({
  subtitle3: {
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: '1.375rem',
    letterSpacing: '.0156rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },
  },
  body3: {
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    fontSize: '.875rem',
    lineHeight: '1.375rem',
    letterSpacing: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '.875rem',
      lineHeight: '1.375rem',
    },
  },
  finePrint: {
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    fontSize: '.75rem',
    lineHeight: '1.125rem',
    letterSpacing: '.0156rem',
  },
  legal: {
    fontFamily: 'Quicksandbold',
    fontSize: '.6875rem',
    lineHeight: 'normal',
    letterSpacing: '.0156rem',
  },
}));

// material ui v4 does not support custom variants in the theme
const materialTypographyVariants = new Set([
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'button',
  'caption',
  'overline',
  'srOnly',
]);

const customTypographyVariants = new Set(['subtitle3', 'body3', 'finePrint', 'legal']);

function CodeSparkMuiTypography({ variant, className, children, parseText, ...props }) {
  const variantClasses = useAdditionalVariantStyles();

  const isMaterialVariant = materialTypographyVariants.has(variant);
  // default to body1 for material ui if it is a custom variant and just overwrite
  // its styling
  const defaultVariant = 'body1';
  const variantToUse = isMaterialVariant ? variant : defaultVariant;

  let classNames = className ?? '';
  if (!isMaterialVariant && customTypographyVariants.has(variant)) {
    classNames += ` ${variantClasses[variant]}`;
    classNames = classNames.trim();
  }

  return (
    <ThemeProvider theme={typographyTheme}>
      <Typography variant={variantToUse} className={classNames} {...props}>
        {parseText ? <ParsedContent content={children} /> : children}
      </Typography>
    </ThemeProvider>
  );
}

export default CodeSparkMuiTypography;
