import React from 'react';
import { withRouter } from 'react-router-dom';
import Awards from './components/Awards';
import { Desktop as DesktopAwards } from './components/Awards/screens';
import MinervaPaymentLayout from './components/MinervaPaymentLayout';
import HeaderInfo from './components/HeaderInfo';
import PaymentInfo from './components/PaymentInfo';
import PaymentForm from './components/PaymentForm';
import { MinervaPaymentProvider } from './providers';
import { usePageViewEvent } from '../../../hooks/useAnalyticsEvent';

function MinervaPayment(props) {
    usePageViewEvent('web_onboarding_screen_payment');

    return (
        <MinervaPaymentProvider>
            <MinervaPaymentLayout
                headerInfo={<HeaderInfo />}
                paymentInfo={<PaymentInfo />}
                paymentForm={<PaymentForm />}
                awards={<Awards />}
                desktopAwards={<DesktopAwards />}
            />
        </MinervaPaymentProvider>
    );
}

export default withRouter(MinervaPayment);