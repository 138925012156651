import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar'

import appReducer from './reducers';
import languageProviderReducer from '../containers/LanguageProvider/reducer';
import experimentsReducer from '../containers/ExperimentsSetup/reducer';


export default combineReducers({
  appState: appReducer,
  loadingBar: loadingBarReducer,
  languageProvider: languageProviderReducer,
  experiments: experimentsReducer,
});
