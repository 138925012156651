import React, { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import { usePayment } from '../../providers';

function HeaderInfo(props) {
  const classes = useStyles();
  const { selectedMinervaOfferId, selectedOffer } = usePayment();

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Chip label="STEP 3 OF 4" className={classes.stepBadge} />
      <Typography variant="h1" className={classes.headerText}>
        Save payment info
      </Typography>
      <Typography variant="body1" className={classes.headerSubText}>
        Your plan comes with a FREE {selectedMinervaOfferId === 'minerva_with_multi_classes' && 'class and '}
        {selectedOffer.trialDurationDays}-day trial of the app and class library. Pay
        nothing today!
      </Typography>
    </Grid>
  );
}

export default memo(HeaderInfo);
