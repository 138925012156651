import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    imgContainer: {
      maxWidth: '100%',
    },
    fooImgContainer: {
      maxWidth: '267px',
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        margin: 'initial',
        maxWidth: '100%',
        background: 'white',
      },
    },
    rewardImgContainer: {
      [theme.breakpoints.down('md')]: {
        textAlign: 'right',
      },
      maxWidth: '100%',
    },
    footerTitle: {
      fontFamily: 'Quicksand',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      padding: '30px 150px',
      color: 'rgba(33, 55, 91, 1)',
      textAlign: 'center',
      [`${theme.breakpoints.up('lg')},${theme.breakpoints.down('xs').replace('@media', '')}`]: {
        padding: 0,
      },
    },
    footerSubtitle: {
      textAlign: 'center',
      fontFamily: 'Montserrat',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '22px',
      color: ' rgba(0, 0, 0, 1)',
      [theme.breakpoints.up('lg')]: {
        maxWidth: '200px',
        margin: '0 auto',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '200px',
        textAlign: 'left',
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
        width: '140px',
      },
    },
    awardContainer: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      [`${theme.breakpoints.up('lg')},${theme.breakpoints.down('xs').replace('@media', '')}`]: {
        marginBottom: '30px',
      },
    },
    numberContainer: {
      fontFamily: 'Montserrat',
      fontWeight: '800',
      fontSize: '18px',
      lineHeight: '22px',
      color: 'rgba(255, 255, 255, 1)',
      letterSpacing: '0.25px',
      textAlign: 'center',
      background: 'rgba(33, 55, 91, 1)',
      width: '73px',
      borderRadius: '8px',
      margin: '0 auto',
    },
    bottomSub: {
      width: '150px',
      margin: '0 auto',
      textAlign: 'center',
    },
    awardWrapper: {
      background: 'rgba(242, 248, 253, 1)',
      padding: '0px',
      borderRadius: '20px',
      [theme.breakpoints.down('md')]: {
        padding: '40px 25px',
        borderRadius: '0px',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '40px 25px',
      },
    },
    trustpilotWrapper: {
      textAlign: 'center',
      [`${theme.breakpoints.up('lg')},${theme.breakpoints.down('xs').replace('@media', '')}`]: {
        marginTop: '21px',
      },
    },
    footerContainer: {
      padding: '45px 0',
      [theme.breakpoints.down('md')]: {
        padding: '0',
      },
    },
    divider: {
      margin: '30px 0',
    },
    tabletContainer: {
      marginBottom: '2.6rem',
    },
  })
);
