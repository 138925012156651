import React from 'react';
import { withRouter } from 'react-router-dom';
import AddClassesPaymentLayout from './components/AddClassesPaymentLayout';
import HeaderInfo from './components/HeaderInfo';
import PaymentInfo from './components/PaymentInfo';
import PaymentForm from './components/PaymentForm';
import AwardsModule from './components/AwardsModule';
import { AddClassesPaymentProvider } from './providers';

function AddClassesPayment(props) {
  return (
    <AddClassesPaymentProvider>
      <AddClassesPaymentLayout
        headerInfo={<HeaderInfo />}
        paymentInfo={<PaymentInfo />}
        paymentForm={<PaymentForm />}
        awardsModule={<AwardsModule />}
      />
    </AddClassesPaymentProvider>
  );
}

export default withRouter(AddClassesPayment);
