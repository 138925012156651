import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import SlickSlider from 'react-slick';

const defaultSlickSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 600,
  draggable: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: 'carousel-content',
  dotsClass: 'sliderDots',
};

const useStyles = makeStyles({
  sliderWrapper: {
    width: '100%',
    marginBottom: '1rem',
  },
  sliderDots: {
    '& li': {
      margin: 0,
    },
    '& li.slick-active button:before': {
      color: '#012F74',
      fontSize: '.5rem',
      opacity: 1,
    },

    '& li button:before': {
      color: '#E7E8EA',
      fontSize: '.5rem',
      opacity: 1,
    },
  },
});

export function MobileSlider({ children, dotsClass = defaultSlickSettings.dotsClass, ...settings }) {
  const classes = useStyles();

  return (
    <Box className={classes.sliderWrapper}>
      <SlickSlider
        {...defaultSlickSettings}
        {...settings}
        dotsClass={`slick-dots ${classes[dotsClass]}`}
      >
        {children}
      </SlickSlider>
    </Box>
  );
}
