import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      marginLeft: 10,
      fontSize: 14,
      fontFamily: 'Montserrat',
      maxWidth: '250px',
    },
  }),
);