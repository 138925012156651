/**
 * Business Logic for the payment provider
 */

import __isEmpty from 'lodash/isEmpty';
import mixpanel from 'mixpanel-browser';
import sha1 from 'js-sha1';
import { subscribe } from '../../../../services/parse';
import { notify, trackPageMixpanel } from '../../../../services/functions';
import { detect, detectDevice } from '../../../../services/detect-browser';
import { addCustomer } from '../../../../gateways/HubSpotApiGateway';
import { tokenizedErrorMessages, errorCodes as errorMessagesByCode } from './config';
import { gtmPageviewEvent, gtmEvent } from '../../../../services/google-tag-manager';
/**
 * Determine error from braintree
 * @param {*} tokenizeError
 * @returns {string}
 */
export function determinePaymentError(tokenizeError) {
  if (tokenizedErrorMessages[tokenizeError.code]) {
    return tokenizedErrorMessages[tokenizeError.code];
  }

  return 'An error seems to have occurred during payment processing. Please try again.';
}

/**
 * Send analytics event
 */
export function onLoadPageAnalytics() {
  const deviceInfo = {
    browser: detect(),
    device: detectDevice(),
  };

  gtmPageviewEvent({
    pageName: 'Memberships v1 Page: Checkout',
    componentProps: {
      deviceInfo,
      location: window.location,
    },
  });
}

/**
 * Mix Panel event handler
 * @param {string} eventName
 * @param {*} eventData
 */
export function sendMixPanelEvent(eventName, eventData) {
  mixpanel.track(eventName, eventData);
}

/**
 *
 * @param {object} user User Details
 * @param {string} offerObjectId Offer Object Id
 * @param {*} payload
 */
export async function processUserSubscription({ user, selectedOffer, payload }) {
  try {
    let utmParamsObject = sessionStorage.getItem('utmParamsObject');

    if (utmParamsObject) {
      utmParamsObject = JSON.parse(utmParamsObject);
    }

    const subscription = await subscribe({
      paymentMethodToken: payload.nonce,
      webOfferId: selectedOffer?.objectId,
      hasClasses: true,
      isResubscribe: false,
      userId: user.objectId,
      customerOptions: {
        email: user.email,
      },
      utmParamsObject,
    });

    mixpanel.people.set_once('parseAccountId', user.objectId);
    mixpanel.people.set({
      Email: user.email,
      productId: subscription.webOffer.get('planId'),
      currency: 'USD',
      price: subscription.webOffer.get('price'),
      subscriber: true,
      subscriptionId: subscription.subscriptionData.id,
      'Account Status': 'Active Trialist',
      store: 'WebStore',
    });
    mixpanel.identify(user.objectId);

    // //Google Tag Manager for 'trialStartPurchaseComplete'
    const shaEmail = sha1(subscription.customer.email);
    gtmEvent({
      action: 'trialStartPurchaseComplete',
      'subscriptionID': subscription.subscriptionData.id,
      'sha1EmailAddress': shaEmail,
      'userID': user.objectId,
      'transactionAmount': parseFloat('0.00').toFixed(2),
      'subscriptionListPrice': subscription.webOffer.get('price'),
      'subscriptionPlan': subscription.webOffer.get('descriptionKey'),
      'trialLengthDays': subscription.webOffer.get('trialDurationDays'),
      'emailAddress': subscription.customer.email,
    });

    const hubspotData = {
      email: user.email,
      productId: subscription.webOffer.get('planId'),
      productDuration: subscription.webOffer.get('subscriptionDuration'),
    };

    if (sessionStorage.getItem('userDetails')) {
      const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
      hubspotData.fullName = userDetails.fullName;
      hubspotData.phoneNumber = userDetails.phoneNumber;
    }

    await addCustomer(hubspotData);
    sessionStorage.removeItem('userDetails');
    return subscription;
  } catch (error) {
    // Reuse existing error handlers from index
    console.error('Error while trying to subscribe', error);
    let parsedError = error.message;
    if (typeof parsedError === 'string') {
      parsedError = JSON.parse(parsedError);
    }

    if (parsedError.messageKey === 'WP_AlreadyHaveSubscription') {
      trackPageMixpanel('WebPaymentsV3 Error: Register - Multiple Subscriptions');
      notify('error', "You're already subscribed. Please contact classes@codespark.com for help.");
    } else if (__isEmpty(parsedError) || parsedError.errors?.messageKey === 'WP_CardNotValid') {
      const errorCode = parsedError.errors?.parseError;
      if (errorCode) {
        const errorMessage = errorMessagesByCode[`${errorCode}`];
        notify('error', `${errorMessage}`, 8000);
      } else {
        notify('error', 'Sorry, there was a problem processing your payment. Please try again later', 8000);
      }
    } else if (parsedError.messageKey === 'WP_PaymentInvalid') {
      notify('error', 'Unfortunately, this type of card is not accepted at this time. Please try another card.', 8000);
    } else {
      notify(
        'error',
        'Sorry, we are unable to process your payment at this time. Please contact customer support for assistance.',
        8000
      );
    }
  }
}

/**
 * Offer is determined by what the user selected in the scheduling page.
 * None of these work for me - option navigates to /tryclasses-paymentapp
 * @param {string} path page pathabstract
 * @returns {string}
 */
// export function determineSelectedClass(path) {
//   const classObj = JSON.parse(sessionStorage.getItem('selectedMinervaOffer'));
//   const classPlanId = classObj.planId;
//   let selectedClass;
//   if (classPlanId === 'minerva_monthly_ondemand_a1') {
//     return (selectedClass = 'onDemandClass');
//   } else if (classPlanId === 'minerva_with_multi_classes') {
//     return (selectedClass = 'liveClass');
//   } else {
//     console.error('Could not determine offer by path, this should not happen');
//     console.log('Handler error');
//   }
// }

/**
 * Determines the Monthly or Annual Plan
 * @param {*} plans Raw plan data - from getDefaultSubscriptionPlans
 * @returns {<{newUserMonthlyOffer:object,newUserAnnualOffer:object}>} offers
 */
export function existingPlanHandler(plans) {
  if (window.google_optimize !== undefined) {
    const variant = window.google_optimize.get(process.env.REACT_APP_EXPERIMENT_ID);
    let offers = {};
    if (variant === '1') {
      if (plans.alternateOffer.get('subscriptionDuration') === 'monthly') {
        offers.newUserMonthlyOffer = plans.alternateOffer;
      } else {
        offers.newUserMonthlyOffer = plans.newUserMonthlyOffer;
      }
      if (plans.alternateOffer.get('subscriptionDuration') === 'annual') {
        offers.newUserAnnualOffer = plans.alternateOffer;
      } else {
        offers.newUserAnnualOffer = plans.newUserAnnualOffer;
      }
    } else {
      offers.newUserMonthlyOffer = plans.newUserMonthlyOffer;
      offers.newUserAnnualOffer = plans.newUserAnnualOffer;
    }

    return offers;
  }

  return {};
}
