import React from 'react';
import Modal from 'react-modal';

import './InfoPopupWrapper.scss';
import Button from '../../elements/Button';

function InfoPopup(props) {
  return (
    <Modal
      isOpen={props.isOpen}
      className="tiny global-popup info-popup long-text"
      overlayClassName="global-popup-overlay"
      shouldCloseOnOverlayClick={false}
      appElement={document.getElementById('root')}
    >
      <div className="grid-x grid-padding-x info-popup-component">
        {
          props.infoText ? (
            <div>
              <p className="popup-title">{props.infoText}</p>
              <Button text="Ok" type="popup" onClick={props.closePopup} />
            </div>
          ) : props.infoHTML ? (
            <div>
              <p className="popup-title" dangerouslySetInnerHTML={{ __html: props.infoHTML }}></p>
              <Button text="Ok" type="popup" onClick={props.closePopup} />
            </div>
          ) : (
                props.children
              )
        }
      </div>
      {
        !props.ignoreClose &&
        <button className="close-button" aria-label="Close modal" onClick={() => props.closePopup()}>
          <i className="fa fa-times"></i>
        </button>
      }
    </Modal>
  );
}

export default InfoPopup;
