import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';

function ContainerTitle() {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center">
      <Typography variant="body1" className={classes.title}>
        Expert-guided online classes from the #1 kids coding app
      </Typography>
    </Grid>
  );
}

export default ContainerTitle;
