import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, styled } from '@material-ui/core';
import { colors } from '../../../../../theme/constants';
import { botClassName } from '../../../../../utils/e2eBotUtils';
import { getPlanTagTrialLabelData } from '../../../../../planLabelData';
import CodeSparkMuiTypography from '../../../CodeSparkMuiTypography';
import { ColumnCenter } from '../../../Layout';
import ThemedMuiButton from '../../../ThemedMuiButton';
import SubscriptionPlansContainerVariant from '../SubscriptionPlansContainer';
import PlansInclude from './PlansInclude';
import {
  PageHeader,
  SelectPlanContainer as BaseSelectPlanContainer,
  SelectPlanSection,
  SelectPlanWidthContainer as BaseSelectPlanWidthContainer,
  SubHeader,
} from '../pageComponents';
import { BOT_CTA_BUTTON_NAME } from '../botUtils';
import SubscriptionPlanCard from './SubscriptionPlanCard';

const PremiumOndemandTitle = styled(CodeSparkMuiTypography)({
  color: colors.primary.darkBlue,
  textAlign: 'center',
});

const SelectPlanBelowText = styled(CodeSparkMuiTypography)(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '17.875rem',
  },
}));

export const PremiumOndemandClassesSubscriptionPlans = ({
  variant,
  planOptionsData,
  handleSelectPlan,
  selectedOffer,
  valueProps,
}) => {
  const { differentPlanTrials } = useSelector(state => state.experiments);
  const planTrialLabels = selectedOffer ? getPlanTagTrialLabelData(selectedOffer) : {};
  const { trialQuantity, trialInterval } = planTrialLabels;
  const selectPlanHeader = differentPlanTrials
    ? 'Select a plan below and start your free trial'
    : `Select a plan below and start your ${trialQuantity}-${trialInterval} free trial`;

  return (
    <>
      <PremiumOndemandTitle variant="h2">All Plans Include:</PremiumOndemandTitle>

      <PlansInclude valueProps={valueProps} />

      <SelectPlanBelowText variant="h4">
        {selectPlanHeader}
      </SelectPlanBelowText>

      <SubscriptionPlansContainerVariant variant={variant}>
        {planOptionsData.map(planData => {
          const { plan: { planId } } = planData;
          return (
            <SubscriptionPlanCard
              key={planId}
              selected={planId === selectedOffer?.planId}
              onClick={() => handleSelectPlan(planId)}
              {...planData}
            />
          );
        })}
      </SubscriptionPlansContainerVariant>
    </>
  );
};

export const PremiumOndemandClassesContainer = styled(ColumnCenter)(({ theme }) => ({
  backgroundColor: colors.primary.white,
  padding: '2.5rem 0',
  borderRadius: '0.625rem',
  color: colors.primary.black,
  marginTop: '1.5rem',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,
    padding: '2rem 1.5rem',
  },
}));

export const SelectPlanContainer = styled(BaseSelectPlanContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export const SelectPlanWidthContainer = styled(BaseSelectPlanWidthContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

export const StyledSelectedPlanLegal = styled(CodeSparkMuiTypography)(({ theme }) => ({
  marginTop: '1.5rem',
  color: '#666666',
  textAlign: 'center',
  '& > span': {
    display: 'inline-block',
    fontFamily: 'inherit',
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: '1rem',
    maxWidth: '19.4375rem',
  },
}));

export function SelectedPlanLegal({ selectedOffer }) {
  return (
    <StyledSelectedPlanLegal variant="subtitle3">
      ${selectedOffer.price.toFixed(2)} charged after {selectedOffer.trialDurationDays}-day free
      trial.{' '}
      <span>
        <b>Cancel anytime.</b>
      </span>
    </StyledSelectedPlanLegal>
  );
}

export default function V3({
  pageCopy,
  variant,
  planOptionsData,
  handleSelectPlan,
  selectedOffer,
  nextLocation,
  ctaLabel,
}) {
  const { differentPlanTrials } = useSelector(state => state.experiments);
  const { header: pageCopyDesignHeader, subHeader, valueProps } = pageCopy;
  const header = differentPlanTrials ? 'Start Your Free Trial!' : pageCopyDesignHeader;

  return (
    <SelectPlanSection>
      <SelectPlanContainer>
        <SelectPlanWidthContainer alignItems="center">
          <PageHeader>{header}</PageHeader>

          {subHeader && <SubHeader align="center">{subHeader}</SubHeader>}

          <PremiumOndemandClassesContainer>
            <PremiumOndemandClassesSubscriptionPlans
              variant={variant}
              planOptionsData={planOptionsData}
              handleSelectPlan={handleSelectPlan}
              selectedOffer={selectedOffer}
              valueProps={valueProps}
            />

            <Box mt={{ xs: '1rem', md: '2rem' }}>
              <Link to={nextLocation}>
                <ThemedMuiButton color="primary2" className={botClassName(BOT_CTA_BUTTON_NAME)}>
                  {ctaLabel}
                </ThemedMuiButton>
              </Link>
            </Box>

            {selectedOffer && <SelectedPlanLegal selectedOffer={selectedOffer} />}
          </PremiumOndemandClassesContainer>
        </SelectPlanWidthContainer>
      </SelectPlanContainer>
    </SelectPlanSection>
  );
}
