import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const imgURL = `${process.env.REACT_APP_CLOUDFRONT_URL}/accounts`;

function FooImage({ image, ...props }) {
  return (
    <Box mt="2rem" {...props}>
      <img src={`${imgURL}/${image}`} alt="Quiz foo character" />
    </Box>
  );
}

FooImage.propTypes = {
  image: PropTypes.string.isRequired,
};

export default FooImage;
