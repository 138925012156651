import React from 'react';
import { useMediaQuery, useTheme, makeStyles } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons//AccessTime';
import { zeroPad } from 'react-countdown';

const CountdownClock = ({ minutes, seconds }) => {
  return <Clock minutes={minutes} seconds={seconds} />;
};

const useClockStyles = makeStyles((theme) => ({
  clockContainer: {
    background: 'rgba(255, 219, 71, 1)',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem',
    fontSize: '18px',
    padding: '2px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      textAlign: 'center',
    },
  },
  offerText: {
    marginLeft: '0.8rem',
    marginRight: '0.5rem',
  },
  clockRight: {
    marginRight: '0.8rem',
  },
}));

function Clock({ minutes, seconds }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('600'));
  const classes = useClockStyles();

  return (
    <span className={classes.clockContainer}>
      {!isMobile && <i className={classes.offerText}>Limited offer!</i>}
      <AccessTimeIcon />
      &nbsp;
      <strong>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </strong>
      {!isMobile && <i className={classes.clockRight} />}
    </span>
  );
}

export default CountdownClock;
