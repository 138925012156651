import React, { lazy, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import __merge from 'lodash/merge';
import sha1 from 'js-sha1';
import mixpanel from 'mixpanel-browser';
import { Box, CircularProgress } from '@material-ui/core';

import { getCurrentUser } from '../../../services/parse';
import { trackPageMixpanel } from '../../../services/functions';
import { gtmEvent, gtmPurchaseEvent, gtmPageviewEvent } from '../../../services/google-tag-manager';
import '../SignupRegister/SignupRegister.scss';
import './SignupPayment.scss';
import {sendFacebookEvent} from '../../../services/facebook-capi';
import { getPlanTrialLabelData } from '../../../planLabelData';
import { SubscriptionPlanContext } from '../../../contexts/subscriptionPlans';
import {
  getHighestPricedPlan,
  getLowestPricedPlan,
  getPercentSavings,
  getPlanMSRPSavings,
  hasMSRPPrice,
  hasTrial,
  isAnnualOnlyPlan,
  isAnnualPlan,
  isMinervaPlan,
  isRecurring,
} from '../../../utils/planUtils';
import {
  getPlanDataKeyExperimentOverrides,
  getPlanDisplayName,
} from '../../../components/elements/SubscriptionBlock/variants/plansDisplayData';
import { usePageViewEvent } from '../../../hooks/useAnalyticsEvent';
import { selectEnabledExperiment } from '../../ExperimentsSetup/selectors';
import { PAYMENT_PAGE_KEY } from '../../ExperimentsSetup/constants';

const PaymentForm = lazy(() => import('../../../components/forms/PaymentForm'));
const PaymentBreakdown = lazy(() => import('../../../components/forms/PaymentBreakdown'));
const renderLoader = () => <h1>Loading</h1>;

class SignupPayment extends React.Component {
  static contextType = SubscriptionPlanContext;

  constructor(props) {
    super(props);

    this.state = {
      user: JSON.parse(getCurrentUser()),
    };

    this.sendTrackingData = this.sendTrackingData.bind(this);
    this.handlePlanSelectChange = this.handlePlanSelectChange.bind(this);
    this.submittedAndComplete = this.submittedAndComplete.bind(this);
  }

  componentDidMount() {
    // grab variantID for google optimize from sessionStorage and pass it to mixpanel
    const variant = sessionStorage.getItem('variantId');
    gtmPageviewEvent({
      pageName: 'signup-payment',
      callback: () => {
        sendFacebookEvent({
          event_name: 'Onboarding Screen',
          event_source_url: window.location.href,
          custom_data: {screen: 'Checkout'}
        });
        trackPageMixpanel('WebPaymentsV3 Page: Checkout', {
          'Optimize experiment variant': `${process.env.REACT_APP_EXPERIMENT_ID} - variant ${variant}`
        });
        sendFacebookEvent({
          event_name: 'Visit',
          event_source_url: window.location.href,
          custom_data: {screen: 'Checkout'}
        });
      },
    });

    window.scrollTo(0, 0);
  }

  getPlanName = plan => {
    const { enabledPageDesignExperiment } = this.props;
    const { planDataKey } = plan;

    // Get override planDataKeys for plans that have different copy depending on the experiment
    const overrides = getPlanDataKeyExperimentOverrides(enabledPageDesignExperiment);
    const planDataKeyOverride = overrides[planDataKey];

    return getPlanDisplayName(
      {
        ...plan,
        planDataKey: planDataKeyOverride || planDataKey,
      },
      PAYMENT_PAGE_KEY
    );
  };

  getPlanOptions() {
    const { plans } = this.context;

    return plans.map(plan => {
      const { planId } = plan;

      const planDisplayName = this.getPlanName(plan);
      return {
        value: planId,
        text: `codeSpark ${planDisplayName} Plan`,
      };
    });
  }

  handlePlanSelectChange(planId, analyticsOptions) {
    const { setSelectedOffer } = this.context;
    setSelectedOffer(planId, analyticsOptions);
  }

  sendTrackingData(plan = {}) {
    if (sessionStorage.getItem('justRegistered') !== null) {
      // if just registering
      let gtmRegData = JSON.parse(sessionStorage.getItem('gtmRegData'));
      gtmRegData = __merge(gtmRegData, {
        'subscriptionPlan': plan.subscriptionDuration,
        'subscriptionType': 'recurring',
        'subscriptionListPrice': plan.price,
        'trialLengthDays': plan.trialDurationDays
      });
      const { event: gtmAction, ...otherGtmData } = gtmRegData;
      sessionStorage.removeItem('justRegistered');
      sessionStorage.removeItem('gtmRegData');
      gtmEvent({
        action: gtmAction,
        ...otherGtmData
      });
    }
  }

  submittedAndComplete(results) {
    // mixpanel
    const { selectedOffer } = this.context;
    mixpanel.people.set_once('parseAccountId', this.state.user.objectId);
    mixpanel.people.set({
      Email: this.state.user.email,
      productId: results.webOffer.get('planId'),
      currency: 'USD',
      price: results.webOffer.get('price'),
      subscriber: true,
      subscriptionId: results.subscriptionData.id,
      'Account Status': 'Active Trialist',
      store: 'WebStore'
    });
    mixpanel.identify(this.state.user.objectId);
    trackPageMixpanel('WebPaymentsV3 Action: Subscribe');

    sendFacebookEvent({
      event_name: 'StartTrial',
      event_source_url: window.location.href,
      custom_data: {
        'subscriptionID': results.subscriptionData.id,
        timestamp: Math.floor(Date.now() / 1000),
        source: 'codespark',
        campaign: 'signup'
      }
    });

    //Google Tag Manager
    // note: we wait for this one to finish before redirecting
    const amountCharged = this.props.isResubscribe ? results.webOffer.get('price') : '0.00';
    const shaEmail = sha1(results.customer.email);

    gtmPurchaseEvent({
      purchaseType: 'sub',
      subId: results.subscriptionData.id,
      planId: selectedOffer.planId,
      subName: selectedOffer.planName,
      subType: selectedOffer.subscriptionDuration,
      userId: this.state.user.objectId,
      amountPaid: amountCharged,
      amountValue: results.webOffer.get('price'),
      subName: results.webOffer.get('descriptionKey'), //create a way to see yearly and lifetime when pricing page is up
      trialPeriod: results.webOffer.get('trialDurationDays'),
      email: results.customer.email,
      // - old datamodel fields
      subscriptionID: results.subscriptionData.id,
      sha1EmailAddress: shaEmail,
      userID: this.state.user.objectId,
      transactionAmount: amountCharged,
      subscriptionListPrice: results.webOffer.get('price'),
      subscriptionPlan: results.webOffer.get('descriptionKey'),
      trialLengthDays: results.webOffer.get('trialDurationDays'),
      emailAddress: results.customer.email,
    });
    this.props.history.push('/signup-thankyou');
  }

  getHeader() {
    const { shouldUseUpdatedLabels, differentPlanTrials } = this.props;
    const { selectedOffer } = this.context;

    if (differentPlanTrials) {
      return 'Sign up for your FREE trial of codeSpark!';
    }

    if (!hasTrial(selectedOffer)) {
      return '';
    }

    const planLabelData = getPlanTrialLabelData(selectedOffer);
    const { freeTrialPeriod, trialQuantity, trialInterval } = planLabelData;
    return `Sign up for your ${
      shouldUseUpdatedLabels ? freeTrialPeriod : `FREE ${trialQuantity} ${trialInterval} trial`
    } of codeSpark!`;
  }

  shouldUseMSRP() {
    const { plans } = this.context;

    // If both plans are minerva or both plans are regular plans, then we should
    // use the lowest priced plan to calculate the savings
    const allBasicPlans = plans.every(plan => !isMinervaPlan(plan));
    const allMinervaPlans = plans.every(isMinervaPlan);

    // If we have an experiment where there is a minerva plan (premium with classes) with an msrp
    // and a nonminerva plan, then we should use the msrp price to calculate savings instead of the lowest
    // priced plan.
    const planWithMSRP = plans.find(hasMSRPPrice);
    if (planWithMSRP && !allBasicPlans && !allMinervaPlans) {
      return true;
    }

    return false;
  }

  calculatePercentSavings() {
    const { selectedOffer, plans } = this.context;
    const shouldUseMSRPPrice = this.shouldUseMSRP();

    if (shouldUseMSRPPrice) {
      const planWithMSRP = plans.find(hasMSRPPrice);
      return getPlanMSRPSavings(planWithMSRP)
    }

    if (isAnnualPlan(selectedOffer)) {
      const baseComparisonPlan = getLowestPricedPlan(plans);
      return getPercentSavings(baseComparisonPlan, selectedOffer);
    }

    const annualPlan = this.getAnnualPlan();
    return getPercentSavings(selectedOffer, annualPlan);
  }

  getAnnualPlan() {
    const { plans } = this.context;
    // there could be many types of annual plans (premium, regular) but we
    // should use the regular annual plan for calculations over the premium if it exists
    return plans.find(isAnnualOnlyPlan) || plans.find(isAnnualPlan);
  }

  isComparisonPlanAnnual() {
    const { plans } = this.context;
    const baseComparisonPlan = getLowestPricedPlan(plans);
    const annualPlan = this.getAnnualPlan();
    return baseComparisonPlan?.planId === annualPlan?.planId;
  }

  hideSavingsBlurb() {
    const { plans, selectedOffer } = this.context;
    const annualPlan = this.getAnnualPlan();
    const comparisonPlanIsAnnual = this.isComparisonPlanAnnual();

    const plansHaveSameDuration = plans.every(
      plan => plan.subscriptionDuration === plans[0].subscriptionDuration
    );
    // For example, if plans are all annual types (annual vs premium annual), then
    // we do not show the savings
    if (plansHaveSameDuration) {
      return true;
    }

    // Catch all for plans that are monthly, biannual, quarterly. We want to show the
    // savings to push them to annual
    if (!comparisonPlanIsAnnual) {
      return false;
    }

    const plansAreAllRecurring = plans.every(isRecurring);
    // If we only have an annual plan and a nonrecurring plan (lifetime plan)
    if (comparisonPlanIsAnnual && !plansAreAllRecurring) {
      // then we don't want to show any savings copy if they selected the annual plan
      if (selectedOffer.planId === annualPlan?.planId) {
        return true;
      }

      // We do however, show lifetime specific copy if they select lifetime
      return false;
    }

    return false;
  }

  getUpsellPlan() {
    const { plans, selectedOffer } = this.context;

    // If there is an annual only plan, use it, otherwise use any other premium annual plan
    const annualPlan = plans.find(isAnnualOnlyPlan) || plans.find(isAnnualPlan);
    const highestPricedPlan = getHighestPricedPlan(plans);

    // If selected plan is the most expensive plan already, do not show upsell popup
    if (selectedOffer.planId === highestPricedPlan.planId) {
      return null;
    }

    // Return the annual plan if selected offer any duration but cheaper than the annual plan
    // to upsell users to the annual plan.
    // Monthly, quarterly, biannual
    if (selectedOffer.price < annualPlan?.price) {
      return annualPlan;
    }

    // Do not show upsell popup to users if the upsell plan is the lifetime plan.
    // This was not covered in a/b testing.
    if (!isRecurring(highestPricedPlan)) {
      return null;
    }

    // Do not show upsell popup to users if selected plan and plan to upsell has the same
    // duration as that was not covered in previous a/b testing.
    if (selectedOffer.subscriptionDuration === highestPricedPlan.subscriptionDuration) {
      return null;
    }

    return highestPricedPlan;
  }

  render() {
    const { shouldUseUpdatedLabels } = this.props;
    const { plans } = this.context;

    const planOptions = this.getPlanOptions();
    const { selectedOffer } = this.context;

    // if we change the plans while user already has saved planTag via a/b test,
    // we should just show a loader so the rest of the page does not error
    if (!plans.length || !selectedOffer) {
      return (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      );
    }

    const percentSavings = this.calculatePercentSavings();
    const planLabelData = selectedOffer ? getPlanTrialLabelData(selectedOffer) : {};
    const comparisonPlanIsAnnual = this.isComparisonPlanAnnual();

    return (
      <div className="signup-container payment">
        <Helmet>
          <title>Sign up | codeSpark Academy</title>
        </Helmet>
        {
          !this.props.isResubscribe && (
            <h2 className="header text-center">
              {this.getHeader()}
            </h2>
          )
        }
        <div className="form-container">
          <div className="step-progress grid-x align-center align-middle">
            <div className="step completed small-1 large-1">
              <i className="fa fa-check"></i>
              <p className="step-subheading">Choose plan</p>
            </div>
            <div className="line-progress small-4 large-4"></div>
            <div className="step completed small-1 large-1">
              <i className="fa fa-check"></i>
              <p className="step-subheading">Account setup</p>
            </div>
            <div className="line-progress stage-2 small-4 large-4"></div>
            <div className="step is-active small-2 large-2">
              <h2>3</h2>
              <p className="step-subheading">Payment</p>
            </div>
          </div>
          <h2 className="create-header text-center">Provide your payment details</h2>
          <div className="registration-form payment-plan-info">
            <Suspense fallback={renderLoader()}>
              <PaymentBreakdown
                planOptions={planOptions}
                selectedOffer={selectedOffer}
                sendTrackingData={this.sendTrackingData}
                onPlanChange={this.handlePlanSelectChange}
                planLabelData={planLabelData}
                // CVR-857
                shouldUseUpdatedLabels={shouldUseUpdatedLabels}
                // END CVR-857
                percentSavings={percentSavings}
                hideSavingsBlurb={this.hideSavingsBlurb()}
                comparisonPlanIsAnnual={comparisonPlanIsAnnual}
                switchOffer={comparisonPlanIsAnnual ? getHighestPricedPlan(plans) : this.getAnnualPlan()}
                isMSRPSavings={this.shouldUseMSRP()}
                getPlanName={this.getPlanName}
              />
              <PaymentForm
                upsellPlan={this.getUpsellPlan()}
                selectedOffer={selectedOffer}
                returnSubmittedForm={this.submittedAndComplete}
                onPlanChange={this.handlePlanSelectChange}
              />
            </Suspense>
          </div>
        </div>
      </div>
    )
  }
}

const pageDesignExperiments = ['premiumVsStandardDesign'];
const mapStateToProps = state => {
  const { appState, experiments } = state;
  return {
    isResubscribe: appState.isResubscribe,
    shouldUseUpdatedLabels: experiments.updatedTrialLabels,
    differentPlanTrials: experiments.differentPlanTrials,
    enabledPageDesignExperiment: selectEnabledExperiment(state, pageDesignExperiments),
  };
};

export default withRouter(connect(mapStateToProps)((props) => {
  usePageViewEvent('web_onboarding_screen_payment');
  return <SignupPayment {...props} />
}));
