import * as React from 'react';
import { Grid, Table, TableBody, TableCell as MuiTableCell, TableRow, makeStyles } from '@material-ui/core';
import { classBottomData } from '../data/classInfoData';
import { styled } from '@material-ui/styles';

const TableCellStyled = styled(MuiTableCell)({
  background: '#6606C6',
  fontFamily: 'Montserrat',
  padding: '10px',
  color: 'white',
  border: '1px solid #FFFFFF',
  fontSize: '18px',
  lineHeight: '30px',
  width: '50%',
  maxWidth: '50%',
});

const useClassTableStyles = makeStyles((theme) => ({
  lastLeftRow: {
    borderRadius: '0 0 0 11px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  lastRightRow: {
    borderRadius: '0 0  11px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  row: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  rightColumn: {
    background: '#F0F8FF',
    color: 'black',
  },
}));

export default function ClassDetailsTable() {
  const classes = useClassTableStyles();
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Table className={classes.tableBottom}>
          <TableBody>
            {classBottomData.map((row, idx) => (
              <TableRow key={idx}>
                <TableCellStyled
                  align="center"
                  component="th"
                  scope="row"
                  className={idx === 4 ? classes.lastLeftRow : classes.row}
                >
                  {row.info}
                </TableCellStyled>
                <TableCellStyled
                  align="center"
                  component="th"
                  scope="row"
                  className={`${classes.rightColumn} ${idx === 4 ? classes.lastRightRow : classes.row}`}
                >
                  {row.additionalInfo}
                </TableCellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
