import React, { memo } from 'react';
import { Mobile, Tablet } from './screens';

function Awards() {
  return (
    <>
      <Mobile />
      <Tablet />
    </>
  );
}

export default memo(Awards);
