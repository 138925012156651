import React from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';

function TrustPilot() {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <img
        src="https://thefoos-com2.s3.amazonaws.com/accounts/minervapayment/trustpilot-Logo%402x.svg"
        alt="trustpilot logo"
        className={classes.image}
      />
      <img
        src="https://thefoos-com2.s3.amazonaws.com/accounts/minervapayment/trustpilot-4.5star%402x.svg"
        alt="trustpilot 4.5 star"
        className={classes.image}
      />
    </Grid>
  );
}

export default TrustPilot;
