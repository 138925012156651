import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import CodeSparkMuiTypography from '../../../../components/elements/CodeSparkMuiTypography';
import {
  ColumnCenter,
  SectionPaddingSide,
  SectionPaddingVertical,
} from '../../../../components/elements/Layout';
import { MobileSlider } from '../../../../components/elements/Slider';
import ThemedMuiButton from '../../../../components/elements/ThemedMuiButton';
import { getAllPlansIncludeData } from './data';
import ButtonContainer from '../../../../components/elements/ThemedMuiButton/ButtonContainer';
import { selectEnabledExperiment } from '../../../ExperimentsSetup/selectors';
import { usePlan } from '../../../../contexts/subscriptionPlans';
import { EXPERIMENT_PAGE_DESIGN_FLAGS } from '../../../ExperimentsSetup/constants';

const imgURL = `${process.env.REACT_APP_CLOUDFRONT_URL}`;

const useVariant2Styles = makeStyles(theme => ({
  allPlansIncludeSection: ({ useLightBlueBg }) => ({
    backgroundColor: useLightBlueBg ? '#F2F8FD' : '#ffffff',
  }),
  header: {
    color: '#21375B',
    textAlign: 'center',
  },
  content: {
    marginTop: '2.625rem',
    width: '100%',
  },
  imageWrapper: {
    '& > img': {
      width: '100%',
    },
  },
  includeText: {
    marginTop: '-0.125rem',
  },
  threeColumnIncludeText: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
  ctaContainer: {
    marginTop: '2rem',
  },
  // CVR-909
  checkmarkIcon: {
    minWidth: '2rem',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      minWidth: '2.625rem',
    },

    '& > img': {
      width: '1.25rem',

      [theme.breakpoints.down('sm')]: {
        width: '1.5625rem',
      },
    },

    '& > .slider-icon': {
      position: 'absolute',
      left: 0,
      top: '-0.75rem',
    },
    '& > .hidden': {
      opacity: 0,
      visibility: 'hidden',
      transition: 'all 0.3s ease',
    },
    '& > .active': {
      opacity: 1,
      visibility: 'visible',
      transition: 'all 0.3s ease',
    },
  },
  sliderValueProp: {
    fontFamily: 'Quicksand',
    fontWeight: 'normal',
    color: '#8e8e8e',
    '&.active': {
      fontWeight: 'bold',
      transition: 'all 0.3s ease',
      color: '#21375B',
    },
  },
  // END CVR-909
}));

const experiments = ['planSelectorAllPlansIncludeVariant1', ...EXPERIMENT_PAGE_DESIGN_FLAGS];
export default function AllPlansIncludeVariant2({ nextLocation, scrollToSubscriptionBlock }) {
  const enabledVariant = useSelector(state => selectEnabledExperiment(state, experiments));
  const { header, data, ctaColor, ctaLabel } = getAllPlansIncludeData(enabledVariant);

  // CVR-909
  // iterating on the plan selector page
  const allPlansIncludeSliderEnabled = useSelector(
    state => state.experiments.planSelectorAllPlansIncludeSlider
  );
  // CVR-921
  const shouldUseSelectPlanButtons = useSelector(
    state => state.experiments.planSelectorSelectPlanButtons
  );
  // END CVR-921

  const classes = useVariant2Styles({ useLightBlueBg: allPlansIncludeSliderEnabled });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <SectionPaddingVertical component="section" className={classes.allPlansIncludeSection}>
      <ColumnCenter>
        <SectionPaddingSide>
          <CodeSparkMuiTypography variant="h2" className={classes.header}>
            {header}
          </CodeSparkMuiTypography>

          {!allPlansIncludeSliderEnabled && (
            <Box className={classes.content}>
              <AllPlansGalleryView allPlansIncludeData={data} />
            </Box>
          )}
        </SectionPaddingSide>

        {allPlansIncludeSliderEnabled && (
          <Box className={classes.content}>
            {!isMobile && (
              <SectionPaddingSide>
                <AllPlansGalleryView
                  allPlansIncludeData={data}
                  valueIcon={`${imgURL}/accounts/checkmark_dark_blue.svg`}
                />
              </SectionPaddingSide>
            )}

            {isMobile && <AllPlansSliderView allPlansIncludeData={data} />}
          </Box>
        )}

        {!shouldUseSelectPlanButtons && (
          <CtaNextButton className={classes.ctaContainer} to={nextLocation} color={ctaColor}>
            {ctaLabel}
          </CtaNextButton>
        )}

        {/* CVR-921 */}
        {shouldUseSelectPlanButtons && isMobile && (
          <ButtonContainer>
            <ThemedMuiButton color={ctaColor} onClick={scrollToSubscriptionBlock}>
              {ctaLabel}
            </ThemedMuiButton>
          </ButtonContainer>
        )}
        {/* END CVR-921 */}
      </ColumnCenter>
    </SectionPaddingVertical>
  );
}

function AllPlansGalleryView({ allPlansIncludeData, valueIcon }) {
  const classes = useVariant2Styles();
  const mdGridSize = 12 / allPlansIncludeData.length;
  const isThreeColumn = mdGridSize === 3;
  const valuePropTypography = isThreeColumn ? 'h6' : 'h5';

  return (
    <Grid container spacing={4}>
      {allPlansIncludeData.map(({ image, text }, i) => {
        return (
          <Grid item xs={12} md={mdGridSize} key={`plans_include_${i}`}>
            <Box className={classes.imageWrapper}>
              <img src={image} alt={text} />
            </Box>

            <Box display="flex" mt="1rem" justifyContent={'center'}>
              {valueIcon && (
                <Box className={classes.checkmarkIcon}>
                  <img src={valueIcon} aria-hidden="true" alt="checkmark" />
                </Box>
              )}

              <CodeSparkMuiTypography
                variant={valuePropTypography}
                className={classNames(classes.includeText, {
                  [classes.threeColumnIncludeText]: !isThreeColumn,
                })}
              >
                {text}
              </CodeSparkMuiTypography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}

const SLIDER_SPEED_MS = 2000;
function AllPlansSliderView({ allPlansIncludeData }) {
  const initialSlide = 0;
  const [activeSlide, setActiveSlide] = useState(initialSlide);

  const classes = useVariant2Styles();

  const handleSlideChange = (prev, next) => {
    setActiveSlide(next);
  };

  return (
    <>
      <MobileSlider
        beforeChange={handleSlideChange}
        initialSlide={initialSlide}
        autoplaySpeed={SLIDER_SPEED_MS}
        autoplay
      >
        {allPlansIncludeData.map(({ image, text }, i) => {
          return (
            <Box key={`plans_include_${i}`} px="1.5rem" height="100%">
              <Box className={classes.imageWrapper}>
                <img src={image} alt={text} />
              </Box>
            </Box>
          );
        })}
      </MobileSlider>

      <SectionPaddingSide pt="1rem">
        <ColumnCenter>
          <ValuePropsListVariant
            activeIndex={activeSlide}
            allPlansIncludeData={allPlansIncludeData}
          />
        </ColumnCenter>
      </SectionPaddingSide>
    </>
  );
}

function ValuePropsListVariant({ allPlansIncludeData, activeIndex }) {
  const classes = useVariant2Styles();

  return (
    <List disablePadding>
      {allPlansIncludeData.map(({ text }, i) => {
        const isActive = i === activeIndex;
        return (
          <ListItem key={`plans_include_mobile_text_${i}`} disableGutters>
            <ListItemIcon className={classes.checkmarkIcon}>
              {/* TODO create a checkmark component that accepts colors */}
              <img
                src={`${imgURL}/accounts/checkmark_dark_blue.svg`}
                aria-hidden="true"
                alt="checkmark"
                className={classNames('slider-icon', {
                  hidden: !isActive,
                  active: isActive,
                })}
              />
              <img
                src={`${imgURL}/global/checkmark_light_grey.svg`}
                aria-hidden="true"
                alt="checkmark"
                className={classNames('slider-icon', {
                  hidden: isActive,
                  active: !isActive,
                })}
              />
            </ListItemIcon>

            <ListItemText disableTypography>
              <CodeSparkMuiTypography
                variant="h6"
                className={classNames(classes.sliderValueProp, {
                  active: isActive,
                })}
              >
                {text}
              </CodeSparkMuiTypography>
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
}

export function CtaNextButton({ className, to, children, color }) {
  return (
    <Box className={className}>
      <Link to={to}>
        <ThemedMuiButton color={color}>{children}</ThemedMuiButton>
      </Link>
    </Box>
  );
}
