import React from 'react';

import { Grid } from '@material-ui/core';
import { useStyles } from './styles';

function Glitch() {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      className={classes.container}
    >
      <img
        src="https://thefoos-com2.s3.amazonaws.com/accounts/minervapayment/image_Glitch_anticipation+1%402x.svg"
        alt="glitch"
      />
    </Grid>
  );
}

export default Glitch;
