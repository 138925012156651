import classNames from 'classnames';
import { botClassName } from '../../../../utils/e2eBotUtils';

export const BOT_CTA_BUTTON_NAME = 'select-plan-btn';

export const createPlanBotClass = ({ subscriptionDuration, planDataKey }) => {
  // Also include the planDataKey in case there are two plans with the same duration. QA says having
  // the duration is convenient but if there is two plans with the same duration, they can use the key
  return classNames(
    botClassName(subscriptionDuration),
    planDataKey !== subscriptionDuration && botClassName(planDataKey)
  );
};
