/*
 * LanguageProvider reducer
 */

import { createReducer } from '@reduxjs/toolkit';

import { DEFAULT_LOCALE } from '../../services/constants'; // eslint-disable-line

const initialState = {
  locale: DEFAULT_LOCALE
};

const languageProviderReducer = createReducer(initialState, {
  CHANGE_LOCALE: (state, action) => {
    state.locale = action.payload;
  }
});

export default languageProviderReducer;
