import React, { memo } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStyles } from './styles';

function MinervaPaymentLayout(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <Box className={classes.pageContainer}>
        <Container maxWidth="md" className={classes.containerWrapper}>
          {props.headerInfo}
          {props.paymentInfo}
          {props.paymentForm}
        </Container>
        {props.desktopAwards}
      </Box>
      {props.awards}
    </React.Fragment>
  );
}

export default memo(MinervaPaymentLayout);
