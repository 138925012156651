import React, { useState, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { Link as RouterLink } from 'react-router-dom';
import { Chip, Grid, Button, Box, Typography, Link } from '@material-ui/core';
import __isEmpty from 'lodash/isEmpty';
import __toLower from 'lodash/toLower';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { notify } from '../../../../services/functions';
import { login, getUserSubscriptionDetails, getCurrentUser } from '../../../../services/parse';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
const LOGIN_PAGE = 'Memberships v2.1 Page: Login';

export default function AddClassesLogin(props) {
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [submittedEmail, setSubmittedEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorType, setErrorType] = useState(''); // 'no-account || no-subscription' || ''

  const validateForm = () => {
    if (email === '' || password === '') {
      notify('error', 'Please enter your email and password.', 5000);
      return false;
    }

    const emailExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!__isEmpty(email) && !emailExp.test(__toLower(email))) {
      notify('error', 'Please enter a valid email address');
      return false;
    }

    return true;
  };

  const submitLogin = async () => {
    setErrorType('');
    setSubmittedEmail('');
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    setSubmittedEmail(email);
    try {
      const user = await login({ username: email, password });
      const subscription = await getUserSubscriptionDetails(user.id);

      if (subscription.isExpired || __isEmpty(subscription.plan)) {
        setErrorType('no-subscription');
        setEmail('');
        setPassword('');
        setLoading(false);
        localStorage.removeItem(`Parse/${process.env.REACT_APP_PARSE_APP_ID}/currentUser`);
        return;
      }

      history.push('/addclasses-payment');
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorType('no-account');
    }
  };

  const selectedOffer = useSelector((state) => state.appState.minervaAddedClassId);

  useEffect(() => {
    window.scrollTo(0, 0);
    mixpanel.track(LOGIN_PAGE);

    //performance.navigation.type != 1 checking if it is a page refresh
    //only route to /addclasses if no selected offer and it is NOT a page refresh
    if (performance.navigation.type != 1 && __isEmpty(selectedOffer)) {
      history.replace('/addclasses');
    }

    if (getCurrentUser()) {
      history.replace('/addclasses-payment');
    }
  }, []);

  return (
    <div className="wrapper">
      <div className="minerva-signup-page">
        <div className="stepBadge-container">
          <Chip label="STEP 1 OF 3" className="stepBadge" />
        </div>
        <div className="minerva-signup-title">
          <h2>Log in to your account</h2>
        </div>
        <div className="minerva-login">Use your existing subscriber account to add classes.</div>
        <div className="minerva-signup-form">
          {errorType && (
            <Box className={classes.alertWrapper}>
              <Box className={classes.alertBanner}>
                {errorType === 'no-account' && (
                  <Typography variant="body1">
                    No account found for <span className={classes.alertEmail}>{submittedEmail}</span>.{' '}
                    <Link component={RouterLink} to={'/tryclasses'} className={classes.alertLink}>
                      Signup
                    </Link>
                  </Typography>
                )}
                {errorType === 'no-subscription' && (
                  <Typography variant="body1">
                    Account <span className={classes.alertEmail}>{submittedEmail}</span> has no active subscription.{' '}
                    <Link component={RouterLink} to={'/tryclasses'} className={classes.alertLink}>
                      Signup
                    </Link>
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          <form>
            <div className="small-12 medium-6 input-fields">
              <input
                type="text"
                name="email"
                placeholder="Email address"
                id="login-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="small-12 medium-6 input-fields">
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password-field"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                id="password-field"
                className={`field-icon ${showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}`}
                onClick={() => setShowPassword(!showPassword)}
              ></span>
              <Box>
                <Link className={classes.forgotPasswordLink} component={RouterLink} to={'/reset-password'}>
                  Forgot your password?
                </Link>
              </Box>
            </div>

            <div id="register-button">
              <Grid container spacing={3} className="bottom-buttons">
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    className="back-button bot-back-button"
                    onClick={() => history.push('/addclasses')}
                  >
                    <div className="button-text">BACK</div>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.nextButton}
                    onClick={() => submitLogin()}
                    disabled={loading}
                  >
                    <div className="button-text">NEXT</div>
                    {loading && <i className="fa fa-refresh fa-spin" style={{ marginLeft: '10px', color: 'gray' }} />}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
        <div className="terms-and-conditions">
          <a href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/terms`}>Terms and Conditions </a>apply
        </div>
      </div>
    </div>
  );
}
