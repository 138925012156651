import { isVariantEnabled, useCodeSparkVariant } from '../featureFlags';

const DEFAULT_CONFIG_KEY = 'base';
export const generateVariantData =
  (config, defaultVariant = DEFAULT_CONFIG_KEY) =>
  variant =>
    config[variant] || config[defaultVariant];

export const generateExperimentSetupMapping = mapping => (experimentName, variant) => {
  const variantPlanMapping = mapping[experimentName];

  if (variantPlanMapping) {
    return variantPlanMapping[variant];
  }
};

export const isControl = variant => isVariantEnabled(variant) && variant.name === 'control';
export const isVariantNumber = (variant, num) =>
  isVariantEnabled(variant) && variant.name === `variant${num}`;
export const isVariant = variant => !isControl(variant);

export function RenderUnleashVariant({
    control = null,
    featureId,
    variantName,
    children,
  }) {
    const variant = useCodeSparkVariant(featureId);

    // wait for variant to be ready
    if (!variant) return null;

    if (variant.name === variantName) {
      return children;
    }

    return control;
}

