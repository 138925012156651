import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ColumnCenter } from '../../../components/elements/Layout';
import ThemedMuiButton from '../../../components/elements/ThemedMuiButton';
import { selectQuestionAnswer } from '../selectors';
import FooImage from './FooImage';
import { TextPadding } from './Layout';
import CodeSparkMuiTypography from '../../../components/elements/CodeSparkMuiTypography';

const useStyles = makeStyles({
  valueProp: {
    fontFamily: 'Quicksand',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

const ValueProp = ({ children, ...props }) => (
  <TextPadding>
    <CodeSparkMuiTypography variant="subtitle1" {...props}>
      {children}
    </CodeSparkMuiTypography>
  </TextPadding>
);

function QuizAnswerValueProp({ questionId, options, image, goToNext }) {
  const classes = useStyles();

  const selectedOptionIdx = useSelector(state => selectQuestionAnswer(state, questionId));
  const selectedOption = options[selectedOptionIdx];

  return (
    <ColumnCenter width="100%">
      <ValueProp className={classes.valueProp}>{selectedOption.valueProp}</ValueProp>

      <ThemedMuiButton color="primary" onClick={goToNext}>
        Next
      </ThemedMuiButton>

      <FooImage image={image} />
    </ColumnCenter>
  );
}

QuizAnswerValueProp.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      coderType: PropTypes.string,
      valueProp: PropTypes.string,
    })
  ).isRequired,
  questionId: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  goToNext: PropTypes.func.isRequired,
};

export default QuizAnswerValueProp;
