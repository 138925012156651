import { COLLABORATOR, CREATIVE, PROBLEM_SOLVER, TINKERER } from '../constants';

export const quizVariant1 = [
  {
    id: 'screenTimeAtHome',
    text: 'How does your child use screen time at home?',
    image: 'Glitch_laptop.svg',
    options: [
      {
        label: 'Watching how-to videos & experimenting',
        coderType: TINKERER,
      },
      {
        label: 'Watching (or making!) movies',
        coderType: CREATIVE,
      },
      {
        label: 'Playing puzzle games',
        coderType: PROBLEM_SOLVER,
      },
      {
        label: 'Gaming, always gaming',
        coderType: COLLABORATOR,
      },
    ],
  },
  {
    id: 'learnBest',
    text: 'How does your child learn best?',
    image: 'Hachi_student.svg',
    options: [
      {
        label: "When there's a hands-on activity",
        coderType: TINKERER,
      },
      {
        label: 'When they get to use their imagination',
        coderType: CREATIVE,
      },
      {
        label: 'When they get to ask "why?" and dig for answers',
        coderType: PROBLEM_SOLVER,
      },
      {
        label: 'When they get to work with a team towards a shared goal',
        coderType: COLLABORATOR,
      },
    ],
  },
  {
    id: 'goToActivity',
    text: "What's your child's go-to play activity?",
    image: 'Calamity_with_pets.svg',
    options: [
      {
        label: 'Building things',
        coderType: TINKERER,
      },
      {
        label: 'Drawing/ coloring',
        coderType: CREATIVE,
      },
      {
        label: 'Solving puzzles',
        coderType: PROBLEM_SOLVER,
      },
      {
        label: 'Playing pretend',
        coderType: COLLABORATOR,
      },
    ],
  },
  {
    id: 'growsUp',
    text: 'When your child grows up, they want to be...',
    image: 'teacher_foo.svg',
    options: [
      {
        label: 'An inventor—they love exploring & getting their hands dirty.',
        coderType: TINKERER,
      },
      {
        label: 'An artist—they love creating & expressing themselves.',
        coderType: CREATIVE,
      },
      {
        label: 'A scientist—they love experimenting & coming up with solutions.',
        coderType: PROBLEM_SOLVER,
      },
      {
        label: 'A teacher—they love learning & helping others.',
        coderType: COLLABORATOR,
      },
    ],
  },
];

export const quizHomepage = [
  quizVariant1[0],
  {
    ...quizVariant1[1],
    id: 'getAnswers',
    text: 'How does your child get answers?',
  },
  ...quizVariant1.slice(2)
]

export const quizVariant2 = [
  {
    id: 'screenTimeAtHome',
    text: 'How does your child use screen time at home?',
    image: 'Glitch_on_couch.svg',
    options: [
      {
        label: 'As entertainment',
        valueProp:
          'codeSpark has tons of interactive games, stories, and fun puzzles to keep kids entertained & engaged.',
      },
      {
        label: 'As an educational tool',
        valueProp:
          'codeSpark is used by over 130,000+ educators in schools worldwide. Kids can use their creativity and flex their skills while they learn.',
      },
      {
        label: 'To explore their unique interests',
        valueProp:
          'With fun, relatable characters and content that appeals to their unique perspectives and interests, kids feel that coding is meant for them and see themselves as coders.',
      },
      {
        label: 'To play games',
        valueProp:
          'codeSpark builds skills through interactive challenges, fun games and puzzles. Kids can use those skills to code their very own games and stories!',
      },
    ],
  },
  {
    id: 'whyInterested',
    text: 'Why are you interested in codeSpark?',
    image: 'Glitch_playing_on_computer.svg',
    options: [
      {
        label: "I know coding is important for my child's future.",
        valueProp:
          'codeSpark prepares kids for the future with 21st century skills like STEM, computational thinking, pattern recognition, and more.',
      },
      {
        label: 'My child has expressed an interest in coding and computers.',
        valueProp:
          'codeSpark builds critical thinking skills essential for early literacy and mathematics.',
      },
      {
        label: 'I want my child to learn how to build video games, not just play them.',
        valueProp:
          'In 2021, kids created 12.6 million games and stories on codeSpark Academy! Whether gaming, creating or telling stories, kids are always learning and playing with codeSpark.',
      },
      {
        label: 'It looks like something my child would enjoy.',
        valueProp: 'codeSpark is so fun, kids don’t realize they’re learning!',
      },
    ],
  },
  {
    id: 'otherSkills',
    text: 'In addition to coding, what other skills do you want your child to learn?',
    image: 'Glitch_both_hands_raised.svg',
    options: [
      {
        label: 'Creative Problem-Solving',
        valueProp: 'Problem-solving strategies are built into every codeSpark game and puzzle.',
      },
      {
        label: 'STEM Skills',
        valueProp:
          'codeSpark is a fun introduction to STEM, building a foundation for school & life.',
      },
      {
        label: 'Computational Thinking',
        valueProp:
          'codeSpark is proven to increase computational thinking skills by 40%  after just 3 sessions.',
      },
      {
        label: 'Persistence & Resilience',
        valueProp:
          'Your kid-coder learns persistence through challenges that level up as their skills grow.',
      },
    ],
  },
];
