import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import __isEmpty from 'lodash/isEmpty';
import __forEach from 'lodash/forEach';
import __startsWith from 'lodash/startsWith';
import __toLower from 'lodash/toLower';
import mixpanel from 'mixpanel-browser';
import { Chip, Grid, Button } from '@material-ui/core';
import { register, getUserByEmail, getCurrentUser } from '../../../services/parse';
import { notify } from '../../../services/functions';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';
import './MinervaSignup.scss';
import { sendAnalyticsEvent } from '../../../services/analytics';
import { usePageViewEvent } from '../../../hooks/useAnalyticsEvent';
import { gtmPageviewEvent, gtmEvent } from '../../../services/google-tag-manager';

const MP_ERROR_EVENTS = {
  EMAIL: 'email',
  PASSWORD: 'password',
  CAPTCHA: 'captcha',
};

export default function MinervaSignup(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  let history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fullName, setFullName] = useState('');
  const [confirmTerms, setConfirmTerms] = useState(true);
  const [offersSignup, setOffersSignup] = useState(true);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [fullNameError, setFullNameError] = useState('');

  const selectedMinervaOfferId = useSelector((state) => state.appState.minervaSelectedClassId);
  let selectedOffer = useSelector((state) => state.appState.minervaSelectedClass);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (__isEmpty(selectedMinervaOfferId)) {
      history.replace('/tryclasses');
    }

    if (getCurrentUser()) {
      history.replace('/tryclasses-payment');
    }

    if (selectedOffer) {
      sendAnalyticsEvent('Memberships v2 Page: Register', {
        productId: selectedMinervaOfferId,
        productionDuration: selectedOffer?.subscriptionDuration,
      });
    }

    gtmPageviewEvent({
      pageName: 'Memberships v2 Page: Register',
      componentProps: {
        ...props,
        location: { pathname: props.pathname },
      },
    });
  }, []);

  usePageViewEvent('web_onboarding_screen_login_setup');

  const togglePassword = () => {
    setShowPassword(!showPassword);

    const field = document.getElementById('password-field');
    if (field.type === 'password') {
      field.type = 'text';
    } else {
      field.type = 'password';
    }
  };

  const showEmailErrors = (message) => {
    document.getElementById('register-email').classList.add('has-error');
    document.getElementById('register-email-error').classList.remove('hide-error');
    setErrorEmail(message);
  };

  const removeEmailErrors = () => {
    document.getElementById('register-email').classList.remove('has-error');
    document.getElementById('register-email-error').classList.add('hide-error');
  };

  const showPasswordErrors = (message) => {
    document.getElementById('password-field').classList.add('has-error');
    document.getElementById('register-password-error').classList.remove('hide-error');
    setErrorPassword(message);
  };

  const removePasswordErrors = () => {
    document.getElementById('password-field').classList.remove('has-error');
    document.getElementById('register-password-error').classList.add('hide-error');
  };

  const checkEmailString = () => {
    //make sure it's a valid email address with regex
    let emailExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!__isEmpty(email) && !emailExp.test(__toLower(email))) {
      return 'Please enter a valid email address';
    }

    //test for any captial letters
    let captialLetter = /^[A-Z]*$/;
    if (!__isEmpty(email) && email.match(captialLetter) !== null) {
      return 'Please do not use capital letters';
    }

    return true;
  };

  const checkEmail = () => {
    const validate = checkEmailString();

    if (validate !== true) {
      return showEmailErrors(validate);
    }

    //if the user has an account, prompt them to log in
    if (!__isEmpty(email)) {
      getUserByEmail(email)
        .then((results) => {
          //don't show duplicate errors for login message
          let showLoginNote = true;
          let notifications = document.getElementsByClassName('message');

          __forEach(notifications, (message) => {
            if (__startsWith(message.textContent, 'It looks like you have an account')) {
              showLoginNote = false;
              return false;
            }
          });
          if (!__isEmpty(results) && showLoginNote) {
            //we have a user, so make a persistent notification show
            notify('warning', 'It looks like you have an account. Click here to log in.', 10000, () => {
              history.push('/tryclasses-login');
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // unset any error styling if validation clears
    removeEmailErrors();
  };

  const checkPassword = () => {
    //check length
    let min = 6;
    let max = 32;
    if (!__isEmpty(password) && (password.length < min || password.length > max)) {
      return showPasswordErrors('Please enter a password with 6 - 32 characters');
    }

    // make sure any errors are removed if validations pass
    removePasswordErrors();
  };

  const checkFullName = () => {
    if (!fullName) {
      return setFullNameError('Please enter your full name');
    }
    return setFullNameError('');
  };

  const checkPhoneNumber = () => {
    const phoneExp = /^[0-9]*$/;
    if (!__isEmpty(phoneNumber) && phoneNumber.length !== 10) {
      return setPhoneError('Please enter a 10 digit phone number');
    }

    if (!__isEmpty(phoneNumber) && phoneNumber.match(phoneExp) === null) {
      return setPhoneError('Please enter a valid phone number');
    }

    return setPhoneError('');
  };

  /**
   * @param {string} errorType - the type of error to send
   * email | password | captcha | Incomplete
   * @returns {void}
   */
  const sendMixpanelStatus = (errorType = 'Incomplete') => {
    mixpanel.track('Memberships v2 Action: Register', {
      error: errorType,
      productId: selectedMinervaOfferId,
      productionDuration: selectedOffer?.subscriptionDuration,
    });
  };

  const submitRegistration = async (e) => {
    e.preventDefault();
    // reCaptcha v3 doesn't require checkbox
    const captchaToken = await executeRecaptcha('tryClassesRegistration');
    if (__isEmpty(email)) {
      sendMixpanelStatus(MP_ERROR_EVENTS.EMAIL);
      return showEmailErrors('Please enter an email');
    }

    const validate = checkEmailString();

    if (validate !== true) {
      sendMixpanelStatus(MP_ERROR_EVENTS.EMAIL);
      return showEmailErrors('email is not valid');
    }

    if (__isEmpty(password)) {
      sendMixpanelStatus(MP_ERROR_EVENTS.PASSWORD);
      return showPasswordErrors('Please enter a password');
    }

    if (!confirmTerms) {
      notify('error', 'We cannot register you without accepting our Terms and Privacy Policy', 5000);
      sendMixpanelStatus();
      return;
    }

    let utmParamsObject = sessionStorage.getItem('utmParamsObject');
    // example: "{\"attributionData\":{\"utm\":{},\"iterable\":{\"iterable_campaign\":\"1396648\",\"iterable_template\":\"1939065\"}}}"
    if (utmParamsObject) {
      utmParamsObject = JSON.parse(utmParamsObject);
    }

    register({
      username: email,
      password: password,
      utmParamsObject: utmParamsObject,
      confirmPromoEmails: offersSignup,
      minerva_lead: true,
      captchaToken: captchaToken,
    })
      .then((user) => {
        mixpanel.people.set_once('parseAccountId', user.id);
        mixpanel.alias(user.id);
        // sets the newly created user to Pretrial status
        mixpanel.people.set({ 'Account Status': 'Pretrial' });
        mixpanel.track('Memberships v2 Action: Register', {
          email: email,
          attributionSource: utmParamsObject,
          status: 'success',
          productId: selectedMinervaOfferId,
          productionDuration: selectedOffer?.subscriptionDuration,
        });

        // Google Tag Manager for 'parentRegistration'
        const gtmRegData = {
          action: 'parentRegistration',
          'userID': user.id,
          'emailAddress': email,
          'userType': 'parent',
          'subscriptionPlan': selectedOffer?.subscriptionDuration,
          'subscriptionType': 'recurring',
          'subscriptionListPrice': selectedOffer?.price,
          'trialLengthDays': selectedOffer?.trialDurationDays,
        };
        // gtmEvent(gtmRegData);

        sessionStorage.setItem('justRegistered', true);
        sessionStorage.setItem('gtmRegData', JSON.stringify(gtmRegData));
        sessionStorage.setItem('userDetails', JSON.stringify({ email, fullName, phoneNumber }));

        // CVR variant for Begin's testing - switching order of account creation and plan selection
        if (props.receivedOptimizelyVariantEnabled) {
          history.push('/tryclasses-signup');
        } else {
          // single plan selection only has 'minerva_with_1class_e1'
          history.push('/tryclasses-payment');
          //history.push('/tryclasses-schedule'); class scheduling page, temp commented out.
        }
      })
      .catch((response) => {
        console.log('response', response);

        sendAnalyticsEvent('Memberships v2 Page: Register', {
          status: 'Failed',
          productId: selectedMinervaOfferId,
          productionDuration: selectedOffer?.subscriptionDuration,
        });
        let error = !__isEmpty(response.data) ? response.data.error : response.message;
        notify('error', error, 5000);
      });
  };

  const handleNameChange = (input) => {
    var letters = /^[a-z ]*$/i;
    if (!input.match(letters)) {
      alert('Please input letters only');
      document.getElementById('register-fullName').value = '';
    } else {
      setFullName(input);
    }
  };

  return (
    <div className="wrapper">
      <div className="minerva-signup-page">
        <div className="stepBadge-container">
          <Chip label="STEP 2 OF 4" className="stepBadge" />
        </div>
        <div className="minerva-signup-title">
          <h2>
            Create your
            <br className="break-line" /> account
          </h2>
        </div>
        <div className="minerva-login">
          Already have an account?{' '}
          <a href="/tryclasses-login" style={{ textDecoration: 'none' }}>
            Log in
          </a>
        </div>
        <div className="minerva-signup-form">
          <form>
            <div className="small-12 medium-6 input-fields">
              <input
                type="text"
                name="email"
                placeholder="Email address"
                id="register-email"
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => checkEmail()}
              />
              <p id="register-email-error" className="error-message right hide-error">
                {errorEmail}
              </p>
            </div>

            <div className="small-12 medium-6 input-fields">
              <input
                type="password"
                name="password"
                id="password-field"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                onBlur={checkPassword}
              />
              <span
                id="password-field"
                className={`field-icon ${showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}`}
                onClick={togglePassword}
              ></span>
              <p id="register-password-error" className="error-message right hide-error">
                {errorPassword}
              </p>
            </div>

            <div className="checkbox-block">
              <div className="options-text">
                <input
                  name="offersSignup"
                  type="checkbox"
                  onChange={(e) => setOffersSignup(e.target.checked)}
                  checked={offersSignup}
                />
                <span>Yes! Please send me exclusive offers and updates on new app features.</span>
              </div>
              <div className="options-text">
                <input
                  name="confirmTerms"
                  type="checkbox"
                  onChange={(e) => setConfirmTerms(e.target.checked)}
                  checked={confirmTerms}
                />
                <span>
                  I understand and agree to the{' '}
                  <a href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/terms`} className="bold">
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/privacy`} className="bold">
                    Privacy Policy.
                  </a>
                </span>
              </div>
            </div>
            <div id="register-button">
              <Grid container spacing={3} className="bottom-buttons">
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    className="back-button bot-back-button"
                    onClick={() => history.push('/tryclasses-select')}
                  >
                    <div className="button-text">BACK</div>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    className="next-button bot-next-button"
                    onClick={(e) => submitRegistration(e)}
                  >
                    <div className="button-text">NEXT</div>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
        <div className="terms-and-conditions">
          <a href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/terms`}>Terms and Conditions </a>apply
        </div>
      </div>
    </div>
  );
}
