import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';

function ParentsChoiceAward() {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" justifyContent='center'>
      <img
        src="https://thefoos-com2.s3.amazonaws.com/accounts/minervapayment/parents_choice_gold.svg"
        alt="parents choice gold"
      />
      <Typography variant="body1" className={classes.label}>
        Parents' Choice Gold Award Winner
      </Typography>
    </Grid>
  );
}

export default ParentsChoiceAward;
