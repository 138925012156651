import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import __isEmpty from 'lodash/isEmpty';
import __upperFirst from 'lodash/upperFirst';
import mixpanel from 'mixpanel-browser';
import { gtmPageviewEvent } from '../../services/google-tag-manager';
import './LoginWrapper.scss';
import { getCurrentUser, login, getUserSubscriptionDetails } from '../../services/parse';
import { notify } from '../../services/functions';
import { showUserNoUpgrade, setResubscribe } from '../../redux/actions';
import { sendFacebookEvent } from '../../services/facebook-capi';
import { isFeatureEnabled } from '../../featureFlags';
const { trackPageMixpanel } = require('../../services/functions');
const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;

class Login extends React.Component {
  constructor(props) {
    super(props);

    if (!__isEmpty(getCurrentUser())) {
      this.props.history.push('/my-account');
    }

    this.state = {
      email: props.emailToSave || '',
      password: '',
      user: {}
    }

    this.loginUser = this.loginUser.bind(this);
  }

  componentDidMount() {
    gtmPageviewEvent({
      pageName: 'WebPaymentsV3 Page: Login',
      componentProps: this.props,
      callback: () => {
        trackPageMixpanel('WebPaymentsV3 Page: Login');
        sendFacebookEvent({
          event_name: 'Visit',
          event_source_url: window.location.href,
          custom_data: {screen: 'Login'}
        });
      }
    });

    const props = this.props.location;
    if (props.state !== undefined && props.state.fromRegHasEmail) {
      notify('info', 'It looks like you have an account. Log in below.', 5000);
    }
  }

  setEmail = (e) => {
    this.setState({ email: e.target.value });
  }

  setPasswordToSubmit = (e) => {
    this.setState({ password: e.target.value });
  }

  submitForm(e) {
    e.preventDefault();
    this.loginUser();
  }

  loginUser() {
    // toggle gift user early resubscribe on/off
    const canGiftSubsResubEarly = true;

    login({
      username: this.state.email,
      password: this.state.password
    }).then((user) => { // user variable only returns id
      mixpanel.identify(user.id);
      this.setState({ user: user.attributes });

      //Get user subscription data to judge where the user should go next
      getUserSubscriptionDetails(user.id).then((user) => {
        let hasActiveSubscription = !user.isExpired && !__isEmpty(user.plan);

        if (canGiftSubsResubEarly && user.isExpired === false && user.isGifted && !user.isLifetime) {
          // not expired and gifted and not lifetime gifted
          // let them through and we can set the subscription start to after they expire
          this.props.setResubscribe(true);
          this.goToNextPage(false);
        } else {
          // the next page will figure out if they can resubscribe or not
          this.goToNextPage(hasActiveSubscription);
        }
      }).catch((error) => {
        //throw a hard fail so that users we can't get details for can't trick the system
        console.log(error);
        // this.removeFormProcessing();
        notify('error', 'Sorry, something went wrong. Please try again or contact us if this persists.', 5000);
        trackPageMixpanel('WebPaymentsV3 Error: Login', { username: this.props.emailToSave, notes: 'Error getting user sub data' });
      });
    }).catch((error) => {
      // this.removeFormProcessing();
      trackPageMixpanel('WebPaymentsV3 Error: Login', { username: this.props.emailToSave });
      notify('error', __upperFirst(error.message.replace('.', '')), 5000);
    });
  }

  goToNextPage(hasActiveSubscription) {
    // expectedNextPage property only used in old signup flow
    // TODO - remove once we are allowed to sunset
    if (!__isEmpty(this.props.expectedNextPage)) {
      if (hasActiveSubscription) {
      //user is trying to buy a subscription with an active subscription
      //make sure warning/help popup shows on account page
      this.props.showUserNoUpgrade(true);
      this.props.history.push('/my-account');
      } else if (!hasActiveSubscription) {
        //user is trying to resubscribe with a promo code
        this.props.setResubscribe(true);
        this.props.history.push(this.props.expectedNextPage);
      } else {
        this.props.history.push(this.props.expectedNextPage);
      }
    }

    if (__isEmpty(this.props.expectedNextPage)) {
      //just a regular login
      this.props.history.push('/my-account');
    }
  }

  showFormProcessing() {
    //change the button style - this is an isolated event
    document.getElementById('login-submit').classList.add('processing', 'alt-gray');
    document.getElementById('login-submit').getElementsByClassName('button')[0].value = 'PROCESSING...';
  }

  removeFormProcessing() {
    //return submit button back to normal state
    document.getElementById('login-submit').classList.remove('processing', 'alt-gray');
    document.getElementById('login-submit').getElementsByClassName('button')[0].value = 'Continue';
  }

  render() {
    const useNewResetPassword = isFeatureEnabled('codesparkUnification');
    const resetPasswordPath = useNewResetPassword ? '/request-password-reset' : '/reset-password';

    return (
      <div className="login-wrapper-container">
        <Helmet>
          <title>Login | codeSpark Academy</title>
        </Helmet>
        <div className="form-container">
          <h2 className="create-header text-center">Log into your account</h2>
          <p className="login-link text-center">Don&apos;t have an account yet? &nbsp;
            {
              this.props.promoCouponCode ? (
                <Link to="/register">Sign up</Link>
              ) : (
                <Link to="/">Sign up</Link>
              )
            }
            </p>
          <div className="registration-form grid-x grid-padding-x">
            <form onSubmit={e => this.submitForm(e)}>
              <div className="input-fields small-12">
                <label>Your Email</label>
                <div className="textbox">
                  <input
                    type="text"
                    name="email"
                    onChange={this.setEmail}
                    value={this.state.email}
                  />
                </div>
              </div>
              <div className="input-fields small-12">
                <label>Your Password</label>
                <div className="textbox">
                  <input
                    type="password"
                    name="password"
                    onChange={this.setPasswordToSubmit}
                  />
                </div>
                <div className="input-helper">
                  <Link to={resetPasswordPath}>
                    <p>Forgot your password?</p>
                  </Link>
                </div>
              </div>
              <div className="cta-button large">
                <button
                  data-event-category="button"
                  data-event-action="signup-2 - register-user"
                  data-event-label="signup-02-register-user"
                  onClick={(e) => this.submitForm(e)}
                >
                  Continue
                  <img className="basic-arrow" src={`${imgURL}/global/icons/basic_arrow_white.svg`} alt="Action arrow" />
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="bottom-graphic-blurb grid-x grid-padding-x align-middle text-center">
          <div className="large-4 cell background-clouds">
            <img src={`${imgURL}/accounts/landing/cloud.png`} alt="clouds background" />
          </div>
          <h3 className="large-4 cell text-center">Welcome back!</h3>
          <div className="large-4 cell">
            <img src={`${imgURL}/accounts/landing/cloud-glitch.png`} alt="Foo in clouds" />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { showUserNoUpgrade, setResubscribe };

const mapStateToProps = state => ({
  emailToSave: state.appState.emailToSave,
  expectedNextPage: state.appState.expectedNextPage,
  promoCouponCode: state.appState.promoCouponCode
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
