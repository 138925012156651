import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

export const ColumnCenter = ({ reverse, ...props }) => (
  <Box
    display="flex"
    flexDirection={reverse ? 'column-reverse' : 'column'}
    alignItems="center"
    {...props}
  />
);
ColumnCenter.propTypes = {
  reverse: PropTypes.bool,
};

export const SectionPaddingSide = props => (
  <Box px={{ xs: '1.5rem', sm: '3rem', lg: '11rem' }} {...props} />
);

const verticalPadding = {
  xs: '2rem',
  sm: '2.5rem',
  lg: '5rem',
};
export const SectionPaddingVertical = props => (
  <Box pt={verticalPadding} pb={verticalPadding} {...props} />
);

// For react router when navigating
export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
