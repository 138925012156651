import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { usePlan } from '../../../contexts/subscriptionPlans';
import { getPlanTagTrialLabelData } from '../../../planLabelData';
import { useSelector } from 'react-redux';
import { generateVariantData } from '../../../utils/experimentUtils';
import { EXPERIMENT_LABEL_FLAGS } from '../../ExperimentsSetup/constants';
import { selectEnabledExperiment } from '../../ExperimentsSetup/selectors';
import { templateStringFormat } from '../../../utils/stringUtils';

const headerLabels = {
  base: 'Sign up for your FREE {trialQuantity} {trialInterval} trial of codeSpark!',
  updatedTrialLabels: 'Sign up for your {freeTrialPeriod} of codeSpark!',
  differentPlanTrials: 'Sign up for your FREE trial of codeSpark!',
}

const useEmailLeadPageContainerStyles = makeStyles(theme => ({
  emailLeadPageContainer: {
    width: '100%',
  },
  signupContainer: {
    paddingBottom: '200px',
  },
}));

export const EmailLeadPageContainer = ({ children }) => {
  const classes = useEmailLeadPageContainerStyles();
  const enabledExperiment = useSelector(state => selectEnabledExperiment(state, EXPERIMENT_LABEL_FLAGS));
  const { planTag } = usePlan();
  const planTagTrialLabels = getPlanTagTrialLabelData(planTag);

  const getHeaderLabel = generateVariantData(headerLabels);
  const header = getHeaderLabel(enabledExperiment)
  const formattedHeader = templateStringFormat(header, planTagTrialLabels);

  return (
    <section className={`registration-container ${classes.emailLeadPageContainer}`}>
      <h3 className="header text-center">
        {formattedHeader}
      </h3>
      <div className={`signup-container ${classes.signupContainer}`}>{children}</div>
    </section>
  );
};

const useEmailLeadContainerStyles = makeStyles(theme => ({
  emailLeadContainer: {
    [theme.breakpoints.down('xs')]: {
      // overwrite very high sass specificity
      width: 'auto !important',
      margin: '16px !important',
    },
  },
}));

export const EmailLeadContainer = ({ children, className, ...props }) => {
  const classes = useEmailLeadContainerStyles();
  return (
    <Box className={`form-container ${classes.emailLeadContainer} ${className}`} {...props}>
      {children}
    </Box>
  );
};
