const MONTHS_IN_YEAR = 12;
const planMonthlyDuration = {
  annual: 12,
  monthly: 1,
  quarterly: 3,
  '6Month': 6,
  '24Month': 24,
};

export function getOfferName({ offerObject } = {}) {
  const vals = [offerObject.subscriptionDuration, offerObject.subscriptionType];
  // - clear out empty values, then make string
  return vals.filter(i => i).join(' ');
}

export const getDurationMonths = subscriptionDuration => {
  return planMonthlyDuration[subscriptionDuration];
};

export const getPlanPaymentFrequencyPerYear = subscriptionDuration => {
  const duration = getDurationMonths(subscriptionDuration);
  return MONTHS_IN_YEAR / duration;
};

const roundPrice = price => Math.round(price * 100) / 100;

export const getPricePerMonth = ({ price, subscriptionDuration }) => {
  const duration = getDurationMonths(subscriptionDuration);
  const monthlyPrice = price / duration;
  return roundPrice(monthlyPrice);
};

/**
 * Gets the price per month and converts it to 2 fixed places to keep it consistent for ui display
 * @param {Object} plan - subscription plan
 * @returns {string} string price per month fixed to 2 places
 */
 export const getPricePerMonthFixed = plan => plan && getPricePerMonth(plan).toFixed(2);

export const getPricePerYear = ({ price, subscriptionDuration }) => {
  const multplier = getPlanPaymentFrequencyPerYear(subscriptionDuration);
  return price * multplier;
};

export const getPricePercentDecrease = (originalPrice, newPrice) => {
  const percentChange = Math.round(((originalPrice - newPrice) / originalPrice) * 100);
  return `${percentChange}%`;
};

export const getPercentSavings = (comparisonOffer, discountedOffer) => {
  const pricePerMonthDiscountOffer = getPricePerMonth(discountedOffer);
  // could be monthly or bi-annual offer
  const pricePerMonthComparisonOffer = getPricePerMonth(comparisonOffer);
  return getPricePercentDecrease(pricePerMonthComparisonOffer, pricePerMonthDiscountOffer);
};

export const getDollarSavings = (comparisonOffer, discountedOffer) => {
  const difference = getPricePerYear(comparisonOffer) - getPricePerYear(discountedOffer);
  const planFrequency = getPlanPaymentFrequencyPerYear(discountedOffer.subscriptionDuration);

  return roundPrice(difference / planFrequency);
};

export const getOfferFreeMonths = (comparisonOffer, discountedOffer) => {
  const pricePerYearComparisonOffer = getPricePerYear(comparisonOffer);
  const pricePerYearDiscountedOffer = getPricePerYear(discountedOffer);

  const priceDifference = pricePerYearComparisonOffer - pricePerYearDiscountedOffer;
  const pricePerMonth = getPricePerMonth(comparisonOffer);

  // TBD but using Math.round is fine for now
  return Math.round(priceDifference / pricePerMonth);
};

export const getPlanMSRPSavings = plan => {
  if (!hasMSRPPrice(plan)) {
    return null;
  }

  // When using msrp price for savings display, the calculation will be against itself
  // the original price vs the "discounted" real price
  const msrpPlanObject = {
    subscriptionDuration: plan.subscriptionDuration,
    // originalPrice is the msrp price
    price: plan.originalPrice,
  };
  return getPercentSavings(msrpPlanObject, plan);
};

export const MONTHLY_DURATION = 'monthly';
export const ANNUAL_DURATION = 'annual';
export const LIFETIME_DURATION = 'lifetime';
export const QUARTERLY_DURATION = 'quarterly';
// Multiple plans can have the same duration
const isPlanDuration = duration => plan => !!plan && plan.subscriptionDuration === duration;
export const isMonthlyPlan = isPlanDuration(MONTHLY_DURATION);
export const isAnnualPlan = isPlanDuration(ANNUAL_DURATION);
export const isLifetimePlan = isPlanDuration(LIFETIME_DURATION);
export const isQuarterlyPlan = isPlanDuration(QUARTERLY_DURATION);

const MONTHLY_PLAN = 'monthly';
const ANNUAL_PLAN = 'annual';
// Used to distinguish all the various types of plans we could have
// For example, monthly, annual, some other annual plan with another offer, etc...
// Using planDataKey for now because planId is different on alpha and prod
const isPlanType = planName => plan => !!plan && plan.planDataKey === planName;
export const isMonthlyOnlyPlan = isPlanType(MONTHLY_PLAN);
export const isAnnualOnlyPlan = isPlanType(ANNUAL_PLAN);

/**
 * Find a plan by id in plans array
 * @param {array} plans - list of plans to search in
 * @param {string} planId - plan id to search for
 * @returns {object}
 */
export const findPlanById = (plans, planId) => plans.find(plan => plan.planId === planId);

/**
 * Find the first plan by duration in plans array
 * @param {array} plans - list of plans to search in
 * @param {string} planId - plan id to search for
 * @returns {object}
 */
export const findPlanByDuration = (plans, duration) =>
  plans.find(plan => plan.subscriptionDuration === duration);

/**
 * Get the offer billing term
 * @param {object} plan - the plan
 * @returns {'annually' | 'monthly' | 'every 6 months' | null}
 */
export const getPlanBillingTerm = plan => {
  if (!plan) {
    return null;
  }

  if (plan.descriptionKey.includes('Annual')) {
    return plan.descriptionKey.toLowerCase() + 'ly';
  }

  if (plan.descriptionKey.includes('Monthly')) {
    return plan.descriptionKey.toLowerCase();
  }

  const durationInMonths = getDurationMonths(plan.subscriptionDuration);
  return `every ${durationInMonths} months`;
};

/**
 * Get the offer formatted plan duration
 * @param {object} plan - the plan
 * @returns {'month' | 'year' | 'quarter' | '6 months' | '24 months' | undefined}
 */
export const getFormattedPlanDuration = plan => {
  const duration = plan?.subscriptionDuration;

  const durationTextMappings = {
    monthly: 'month',
    annual: 'year',
    quarterly: 'quarter',
    '6Month': '6 months',
    '24Month': '24 months',
  };

  return durationTextMappings[duration];
};

/**
 * Determines whether plan has a trial
 * @param {object} plan - the plan
 * @returns {true | false}
 */
export const hasTrial = plan => !!(plan && plan.trialDurationDays);

/**
 * Determines whether plan has a recurring charge
 * @param {object} plan - the plan
 * @returns {true | false}
 */
export const isRecurring = plan => !!(plan && plan.subscriptionType === 'recurring');

/**
 * Determines whether plan has a MSRP price by comparing there original price is different from the actual price
 * @param {object} plan - the plan
 * @returns {true | false}
 */
export const hasMSRPPrice = (plan = {}) => plan.originalPrice && plan.originalPrice !== plan.price;

/**
 * Determines whether plan has minerva classes
 * Currently hacky way using the planDataKey to check if it contains the word minerva in it
 * but it should probably be stored on the plan in the database
 * TODO - ask Chris to create extra column to store this info
 * @param {object} plan - the plan
 * @returns {true | false}
 */
export const isMinervaPlan = (plan = {}) => plan.planDataKey.includes('minerva');

/**
 * Returns the plan with the lowest price regardless of duration.
 * @param {array} planOptions - plan options to check
 * @returns {object}
 */
export const getLowestPricedPlan = planOptions =>
  [...planOptions].sort((a, b) => a.price - b.price)[0];

/**
 * Returns the plan with the highest price regardless of duration.
 * @param {array} planOptions - plan options to check
 * @returns {object}
 */
export const getHighestPricedPlan = planOptions =>
  [...planOptions].sort((a, b) => b.price - a.price)[0];
