import React from 'react';
import { styled } from '@material-ui/core';
import { generateVariantData } from '../../../../utils/experimentUtils';
import { SubscriptionPlansContainer } from './pageComponents';

// JF Dec 2, 2022: There are many current designs being tested
const BasePlansContainer = styled(SubscriptionPlansContainer)(({ theme }) => ({
  marginTop: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
  },
}));

const PremiumPlansContainer = styled(SubscriptionPlansContainer)(({ theme }) => ({
  marginTop: '1.5rem',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    marginTop: '1rem',
  },
}));

const LifetimePlansContainer = styled(SubscriptionPlansContainer)(({ theme }) => ({
  alignItems: 'flex-end',
  marginTop: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    marginTop: '1rem',
  },
}));

const RedesignOndemandClassesPlansContainer = styled(SubscriptionPlansContainer)(({ theme }) => ({
  alignItems: 'stretch',
}));

const PremiumOndemandPlansContainer = styled(props => (
  <SubscriptionPlansContainer spacing={0} {...props} />
))(({ theme }) => ({
  marginTop: '4.875rem',
  width: 'calc(100% + 1rem)',
  marginLeft: '-0.5rem',
  marginRight: '-0.5rem',
  [theme.breakpoints.down('sm')]: {
    marginTop: '2.5rem',
    marginLeft: '-0.25rem',
    marginRight: '-0.25rem',
  },
}));

const PremiumVsStandardPlansContainer = styled(PremiumOndemandPlansContainer)(({ theme }) => ({
  marginTop: '2.25rem',
  justifyContent: 'space-between',
  padding: '0 2rem',
  [theme.breakpoints.down('sm')]: {
    marginTop: '1.75rem',
    padding: '0',
  },
}));

const componentToVariantMapping = {
  base: BasePlansContainer,
  thirdPlan: PremiumPlansContainer,
  lifetimePlan: LifetimePlansContainer,
  redesignOndemandClasses: RedesignOndemandClassesPlansContainer,
  premiumOndemandClasses: PremiumOndemandPlansContainer,
  premiumVsStandardDesign: PremiumVsStandardPlansContainer,
  premiumOndemandClassesTrials: PremiumOndemandPlansContainer,
};

const getSubscriptionPlanContainerComponent = generateVariantData(componentToVariantMapping);

export default function SubscriptionPlansContainerVariant({ variant, ...props }) {
  const Component = getSubscriptionPlanContainerComponent(variant);
  return <Component {...props} />;
}
