import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    chip: {
      backgroundColor: '#21375B',
      borderRadius: 8,
      width: '73px',
      marginBottom: 10,
    },
    number: {
      color: 'white',
      fontWeight: 800,
      fontFamily: 'Montserrat',
      fontSize: '18px',
    },
    description: {
      color: '#000000',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      width: '150px',
      textAlign: 'center',
    },
  }),
);