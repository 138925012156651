import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import CodeSparkMuiTypography from '../../../../../../components/elements/CodeSparkMuiTypography';
import {
  PlanCard,
  PlanCardBody,
  PlanCardPrice,
  PlanHeaderFlag,
  SavePercent,
} from '../../../../../../components/elements/SubscriptionBlock/variants/SubscriptionPlanCard';
import ThemedMuiButton from '../../../../../../components/elements/ThemedMuiButton';
import ButtonContainer from '../../../../../../components/elements/ThemedMuiButton/ButtonContainer';
import {
  BOT_CTA_BUTTON_NAME,
  createPlanBotClass,
} from '../../../../../../components/elements/SubscriptionBlock/variants/botUtils';
import { botClassName } from '../../../../../../utils/e2eBotUtils';

const useSubscriptionPlanStyles = makeStyles(theme => ({
  planCard: {
    [theme.breakpoints.down('sm')]: {
      border: `2px solid #CAD7E7`,
    },
  },
  header: {
    color: '#0280E0',
    fontSize: '1.5rem',
    lineHeight: '2.125rem',
  },
  billingLegal: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.375rem',
    },
  },
  planIncludes: {
    margin: '1rem 0 0 0',
    paddingLeft: 'inherit',
    '& b': {
      fontFamily: 'Quicksandbold',
    },
  },
}));

export default function SubscriptionPlanCard({
  planId,
  onSelectPlan,
  flagText,
  title,
  priceDisplay,
  billingLegal,
  planIncludes,
  percentChange,
  isHighlighted,
  duration,
  planDataKey,
}) {
  const classes = useSubscriptionPlanStyles();

  const savedPercent = parseFloat(percentChange) ? (
    <SavePercent percentChange={percentChange} />
  ) : (
    ''
  );

  return (
    <Grid item xs={12} md={4}>
      <PlanCard
        className={classNames(createPlanBotClass({ duration, planDataKey }), {
          highlighted: isHighlighted,
          [classes.planCard]: !isHighlighted,
        })}
      >
        {isHighlighted && <PlanHeaderFlag flagText={flagText} />}

        <PlanCardBody>
          <CodeSparkMuiTypography variant="h4" className={classes.header}>
            {title}
          </CodeSparkMuiTypography>

          <PlanCardPrice variant="h3">
            {priceDisplay} {savedPercent}
          </PlanCardPrice>

          <CodeSparkMuiTypography variant="body3" className={classes.billingLegal}>
            {billingLegal}
          </CodeSparkMuiTypography>

          <ul className={classes.planIncludes}>
            {planIncludes?.map((item, i) => (
              <li key={`payment_includes_${i}`}>
                <CodeSparkMuiTypography variant="body3" parseText>
                  {item}
                </CodeSparkMuiTypography>
              </li>
            ))}
          </ul>

          <ButtonContainer mt="1.5rem">
            <ThemedMuiButton
              color="primary"
              onClick={() => onSelectPlan(planId)}
              className={botClassName(BOT_CTA_BUTTON_NAME)}
              fullWidth
            >
              Choose Plan
            </ThemedMuiButton>
          </ButtonContainer>
        </PlanCardBody>
      </PlanCard>
    </Grid>
  );
}
