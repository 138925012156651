import { capitalize } from 'lodash';
import {
  BASE_KEY,
  PAYMENT_PAGE_KEY,
  PLAN_SELECTOR_PAGE_KEY,
} from '../../../../containers/ExperimentsSetup/constants';
import { getPlanTrialLabelData } from '../../../../planLabelData';
import {
  getDurationMonths,
  getLowestPricedPlan,
  getPricePercentDecrease,
  getPricePerMonth,
  getPricePerMonthFixed,
  hasMSRPPrice,
  isMonthlyPlan,
} from '../../../../utils/planUtils';

const MINERVA_ANNUAL_ONDEMAND_100_VS_STANDARD_QUARTERLY =
  'minerva_annual_ondemand_100_vs_standard_quarterly';

/**
 * TODO REMOVE THESE TWO EXTRA LINE NEEDED FOR SOME DEVOPS TESTING
 * TODO REMOVE THIS LINE
 * Generates an object with the mapping between page and plan names.
 * @param {string} base - the default plan name
 * @param {Object} pageNameMappings - the plan name mapped to each page. If none, it will use the base as the default name
 * @returns {Object} returns a standardized object with the plan name to page mapping
 */
function generatePlanNameMappings(base, pageNameMappings = {}) {
  return {
    [BASE_KEY]: base,
    [PLAN_SELECTOR_PAGE_KEY]: base,
    [PAYMENT_PAGE_KEY]: base,
    ...pageNameMappings,
  };
}
// Display name for different pages on the funnel cuz sometimes they differ
const planToPageToNameMapping = {
  annual_minerva_ondemand: generatePlanNameMappings('Premium Annual'),
  minerva_biannual_ondemand_65: generatePlanNameMappings('Half-Yearly'),
  minerva_annual_ondemand_120_30trial: generatePlanNameMappings('Yearly'),
  minerva_annual_ondemand_120_7trial: generatePlanNameMappings('App + On-Demand Classes'),
  Annual_7DayTrial_7999_restricted: generatePlanNameMappings('App Only'),
  minerva_annual_ondemand_100: generatePlanNameMappings('Annual'),
  minerva_quarterly_ondemand_41: generatePlanNameMappings('Quarterly'),
  quarterly_7trial_41_restricted: generatePlanNameMappings('App Only'),
  [MINERVA_ANNUAL_ONDEMAND_100_VS_STANDARD_QUARTERLY]: generatePlanNameMappings('App + Classes'),
  minerva_24month_ondemand_100: generatePlanNameMappings('App + Classes'),
  Annual_7DayTrial_6999_restricted: generatePlanNameMappings('App Only'),
  minerva_annual_ondemand_90_7trial: generatePlanNameMappings('Annual Plan', {
    [PAYMENT_PAGE_KEY]: 'App + Classes Annual',
  }),
  minerva_annual_ondemand_90_14trial: generatePlanNameMappings('Annual Plan', {
    [PAYMENT_PAGE_KEY]: 'App + Classes Annual',
  }),
  minerva_monthly_ondemand_15_7trial: generatePlanNameMappings('Monthly Plan', {
    [PAYMENT_PAGE_KEY]: 'App + Classes Monthly',
  }),
};

export function getPlanDisplayName({ planDataKey, descriptionKey }, pageKey = BASE_KEY) {
  return planToPageToNameMapping[planDataKey]?.[pageKey] || capitalize(descriptionKey);
}

const hasMultipleChildProfiles = ({ maxChildProfiles }) => maxChildProfiles > 1;

function getTrialText({ shouldUseUpdatedLabels, lifetimePlanEnabled, planLabelData }) {
  const { trialQuantity, trialLabel, trialIntervalCapitalize, trialLabelLower } = planLabelData;

  if (shouldUseUpdatedLabels) {
    return `Includes <b>${trialLabel}</b>`;
  }

  if (lifetimePlanEnabled) {
    return `Includes <b>${trialLabelLower}</b>!`;
  }

  return `Includes <b>${trialQuantity}-${trialIntervalCapitalize} Free trial</b>`;
}

function getMonthlyData({ plan, shouldUseUpdatedLabels, lifetimePlanEnabled }) {
  const { price } = plan;
  const planLabelData = getPlanTrialLabelData(plan);

  return {
    plan,
    title: `${getPlanDisplayName(plan)} Plan`,
    priceDisplay: `$${getPricePerMonthFixed(plan)}/mo`,
    // CVR-857 - new plan data labels
    trial: getTrialText({
      shouldUseUpdatedLabels,
      lifetimePlanEnabled,
      planLabelData,
    }),
    // END CVR-857
    billingLegal: `$${price} billed monthly`,
    billingLegal2: 'Auto-renews every month until canceled.',
  };
}

// Annual plan config is specific to the annual only plan
function getAnnualOnlyData({
  plan: annualPlan,
  baseComparisonPlan,
  shouldUseUpdatedLabels,
  thirdPlanEnabled,
  lifetimePlanEnabled,
}) {
  const { price } = annualPlan;
  const planLabelData = getPlanTrialLabelData(annualPlan);
  const pricePerMonth = getPricePerMonth(annualPlan);
  const hasComparisonPlan = annualPlan.planId !== baseComparisonPlan.planId;
  const basePlanPricePerMonth = hasComparisonPlan && getPricePerMonth(baseComparisonPlan);
  const percentChange =
    hasComparisonPlan && getPricePercentDecrease(basePlanPricePerMonth, pricePerMonth);

  const billingLegal = `$${price} billed annually`;

  return {
    plan: annualPlan,
    title: `${getPlanDisplayName(annualPlan)} Plan`,
    priceDisplay: `$${getPricePerMonthFixed(annualPlan)}/mo`,
    comparisonPlanPriceDisplay: hasComparisonPlan && `$${basePlanPricePerMonth}/mo`,
    // CVR-857 - new plan data labels
    trial: getTrialText({
      shouldUseUpdatedLabels,
      lifetimePlanEnabled,
      planLabelData,
    }),
    // END CVR-857
    billingLegal,
    billingLegal2: hasComparisonPlan
      ? `${billingLegal}. <b>Save ${percentChange}</b> compared to the ${baseComparisonPlan.subscriptionDuration} plan.`
      : billingLegal,
    percentChange,
    showGlitch: !thirdPlanEnabled && !lifetimePlanEnabled, // true for control
    flagText: hasComparisonPlan && `Save ${percentChange}`,
  };
}

function getPremiumData({ plan, baseComparisonPlan, shouldUseUpdatedLabels, thirdPlanEnabled }) {
  const { price } = plan;
  const planLabelData = getPlanTrialLabelData(plan);
  const pricePerMonth = getPricePerMonth(plan);
  const hasComparisonPlan = plan.planId !== baseComparisonPlan.planId;
  const basePlanPricePerMonth = hasComparisonPlan && getPricePerMonth(baseComparisonPlan);
  const percentChange =
    hasComparisonPlan && getPricePercentDecrease(basePlanPricePerMonth, pricePerMonth);

  return {
    plan,
    title: `${getPlanDisplayName(plan)} Plan`,
    priceDisplay: `$${getPricePerMonthFixed(plan)}/mo`,
    comparisonPlanPriceDisplay:
      hasComparisonPlan && `$${getPricePerMonthFixed(baseComparisonPlan)}/mo`,
    // CVR-857 - new plan data labels
    trial: getTrialText({ shouldUseUpdatedLabels, planLabelData }),
    // END CVR-857
    billingLegal: `$${price} billed annually`,
    percentChange,
    showGlitch: false,
    flagText: 'Includes Coding Classes!',
    includes: thirdPlanEnabled && 'Includes 12 prerecorded classes taught by experts.',
    isHighlighted: true,
  };
}

function getMinervaClassesData(...args) {
  const { plan } = args[0];
  const { price, subscriptionDuration } = plan;

  const durationInMonths = getDurationMonths(subscriptionDuration);
  const { trialQuantity, trialIntervalCapitalize, trialLabelLower } = getPlanTrialLabelData(plan);
  const billingDurationText = isMonthlyPlan(plan) ? 'month' : `${durationInMonths} months`;

  return {
    ...getPremiumData(...args),
    title: getPlanDisplayName(plan),
    billingLegal: `$${price.toFixed(2)} billed every ${billingDurationText}`,
    trial: `${trialQuantity}-${trialIntervalCapitalize} Free Trial`,
    planIncludes: [
      `<b>Includes ${trialLabelLower}!</b>`,
      'App + pre-recorded classes taught by experts',
    ],
  };
}

function getMinervaPremiumVsStandardData({ plan }) {
  const { price, subscriptionDuration, parentPortalEnabled } = plan;

  const durationInMonths = getDurationMonths(subscriptionDuration);

  const pricePerMonth = getPricePerMonth(plan);
  const msrpPlan = {
    subscriptionDuration,
    // originalPrice is the msrp price
    price: plan.originalPrice,
  };
  const msrpPricePerMonth = hasMSRPPrice(plan) && getPricePerMonth(msrpPlan);
  const percentChange =
    !!msrpPricePerMonth && getPricePercentDecrease(msrpPricePerMonth, pricePerMonth);

  return {
    plan,
    title: getPlanDisplayName(plan),
    priceDisplay: `$${getPricePerMonthFixed(plan)}/mo`,
    comparisonPlanPriceDisplay: msrpPricePerMonth && `$${getPricePerMonthFixed(msrpPlan)}/mo`,
    percentChange,
    showGlitch: false,
    eyebrowHeader: 'STANDARD',
    billingLegal: `$${price} billed every ${durationInMonths} months`,
    valuePropsMapping: {
      access: true,
      workbook: true,
      selfPacedLessons: false,
      parentPortal: parentPortalEnabled,
      progressReports: parentPortalEnabled,
      multipleChildProfiles: hasMultipleChildProfiles(plan),
    },
  };
}

function getLifetimeData({ plan }) {
  const { price } = plan;
  return {
    plan,
    title: `${getPlanDisplayName(plan)} Plan`,
    priceDisplay: `$${price}`,
    billingLegal: `One-time payment of $${price}. Never pay again!`,
    showGlitch: false,
    flagText: 'Pay once. Play forever.',
    isHighlighted: true,
  };
}

function getRestrictedAnnualData({ plan, baseComparisonPlan }) {
  return {
    ...getAnnualOnlyData({ plan, baseComparisonPlan }),
    title: getPlanDisplayName(plan),
    priceDisplay: `$${getPricePerMonthFixed(plan)}/mo`,
    planType: 'BASIC',
    planIncludes: [
      {
        value: 'Unlimited access to the codeSpark app',
      },
      {
        value: 'Printable worksheets',
      },
    ],
    isHighlighted: false,
  };
}

function getAnnualAndOndemandData({ plan }) {
  const { price } = plan;

  return {
    plan,
    title: getPlanDisplayName(plan),
    planType: 'PREMIUM',
    priceDisplay: `$${getPricePerMonthFixed(plan)}/mo`,
    billingLegal: `$${price} billed annually`,
    planIncludes: [
      {
        value: '10 pre-recorded classes taught by experts',
      },
      {
        value: 'Unlimited access to the codeSpark app',
        subnote: 'add up to 5 child profiles',
      },
      {
        value: 'Printable worksheets',
      },
    ],
    isHighlight: true,
    flagText: 'Most Popular',
  };
}

function redesignAnnualMinervaClassesData(...args) {
  return {
    ...getMinervaClassesData(...args),
    showGlitch: true,
  };
}

const planTypeToDataFunc = {
  monthly: getMonthlyData,
  annual: getAnnualOnlyData,
  annual_minerva_ondemand: getPremiumData,
  annual_minerva_ondemand_120: getPremiumData,
  minerva_quarterly_ondemand_35: redesignAnnualMinervaClassesData,
  minerva_biannual_ondemand_65: (...args) => ({
    ...getMinervaClassesData(...args),
    isHighlighted: false,
  }),
  minerva_annual_ondemand_120_30trial: (...args) => ({
    ...getMinervaClassesData(...args),
    flagText: 'BEST VALUE',
  }),
  minerva_annual_ondemand_120_7trial: getAnnualAndOndemandData,
  Annual_7DayTrial_7999_restricted: getRestrictedAnnualData,
  lifetime: getLifetimeData,
  minerva_annual_ondemand_100: (...args) => ({
    ...getMinervaClassesData(...args),
    showGlitch: true,
  }),
  minerva_quarterly_ondemand_41: getMinervaClassesData,
  quarterly_7trial_41_restricted: getMinervaPremiumVsStandardData,
  [MINERVA_ANNUAL_ONDEMAND_100_VS_STANDARD_QUARTERLY]: (...args) => {
    const { plan } = args[0];
    const data = getMinervaPremiumVsStandardData(...args);
    const { valuePropsMapping = {} } = data;
    return {
      ...data,
      title: getPlanDisplayName({
        ...plan,
        planDataKey: MINERVA_ANNUAL_ONDEMAND_100_VS_STANDARD_QUARTERLY,
      }),
      showGlitch: true,
      eyebrowHeader: 'PREMIUM',
      // Once we get planType set up in database, we can use that instead of hardcoding manually here
      // to determine whether plan has classes
      valuePropsMapping: {
        ...valuePropsMapping,
        selfPacedLessons: true,
      },
    };
  },
  Annual_7DayTrial_6999_restricted: (...args) => {
    const data = getMinervaPremiumVsStandardData(...args);
    return {
      ...data,
      billingLegal: `1 Year Plan. ${data.billingLegal}`,
    };
  },
  minerva_24month_ondemand_100: (...args) => {
    const data = getMinervaPremiumVsStandardData(...args);
    const { valuePropsMapping = {} } = data;
    return {
      ...data,
      showGlitch: true,
      eyebrowHeader: 'PREMIUM',
      // Once we get planType set up in database, we can use that instead of hardcoding manually here
      // to determine whether plan has classes
      valuePropsMapping: {
        ...valuePropsMapping,
        selfPacedLessons: true,
      },
      billingLegal: `2 Year Plan. ${data.billingLegal}`,
    };
  },
  minerva_annual_ondemand_90_7trial: redesignAnnualMinervaClassesData,
  minerva_monthly_ondemand_15_7trial: getMinervaClassesData,
  minerva_annual_ondemand_90_14trial: redesignAnnualMinervaClassesData,
};

// Allow plans used in multiple experiments to map to different keys so we can have different
// copy for each experiment - similar to FM-2363 for homer
export const planDataKeyOverrides = {
  premiumVsStandardDesign: {
    minerva_annual_ondemand_100: MINERVA_ANNUAL_ONDEMAND_100_VS_STANDARD_QUARTERLY,
  },
};

export const getPlanDataKeyExperimentOverrides = experiment =>
  planDataKeyOverrides[experiment] || {};

const DEFAULT_PLAN_TYPE_FUNC = planTypeToDataFunc.monthly;

export function getPlansDisplayData({
  planOptions,
  baseComparisonPlan,
  planDataKeyOverrides = {},
  ...enabledExperiments
}) {
  if (!baseComparisonPlan) {
    // if no base comparison plan is provided, use the lowest priced plan in the planOptions
    // Could be monthly, quarterly types of plans
    baseComparisonPlan = getLowestPricedPlan(planOptions);
  }

  return planOptions.map(plan => {
    const { planDataKey, subscriptionDuration } = plan;
    // Allowing overrides for different experiments that use the same plan
    const planDataKeyOverride = planDataKeyOverrides[planDataKey];
    // try to find the plan display data based on the specific planDataKey,
    // fallback to the duration and finally fallback to monthly if nothing is a match
    const func =
      planTypeToDataFunc[planDataKeyOverride] ||
      planTypeToDataFunc[planDataKey] ||
      planTypeToDataFunc[subscriptionDuration] ||
      DEFAULT_PLAN_TYPE_FUNC;

    return func({
      plan,
      baseComparisonPlan,
      // TODO use overrides in the future and remove the experiment checks within the data functions
      // to allow for more flexibility
      ...enabledExperiments,
    });
  });
}
