import React from 'react';
import __upperFirst from 'lodash/upperFirst';

import './ButtonWrapper.scss';

const Button = props => (
  <div type={props.type} className={`button-component-wrapper ${props.extraClass}`} id={props.id}>
    {
      props.type === 'form' ? (
        <input
          type="submit"
          className="button"
          value={props.text}
          data-on="click"
          data-event-category="Button - Form"
          data-event-action="Click"
          data-event-label={props.text}
          disabled={props.disabled}
        />
      ) : (
          <button
            className="button"
            onClick={props.onClick !== undefined ? () => props.onClick() : undefined}
            data-on="click"
            data-event-category={`Button - ${__upperFirst(props.type)}`}
            data-event-action="Click"
            data-event-label={props.text}
            disabled={props.disabled}
          >
            {props.text}
          </button>
        )
    }
  </div>
);

export default Button;
