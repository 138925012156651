import { Box, makeStyles } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import React from 'react';
import classNames from 'classnames';
import ThemedMuiButton from '../../../../components/elements/ThemedMuiButton';
import Accordion from '../../../../components/elements/Accordion';
import CodeSparkMuiTypography from '../../../../components/elements/CodeSparkMuiTypography';

// Taken from thefoos
const faqs = [
  {
    question: 'For which platforms is codeSpark Academy with The Foos available?',
    answer: () => `
        codeSpark Academy with the Foos is available on smart devices that run Apple and Android
        operating systems. You can also play on the web after creating an account and starting a
        trial.`,
  },
  {
    question: 'Why a subscription?',
    answer:
      () => `We want to continue teaching and inspiring kids around the world for a long time. The new
          subscription plan is the best way to deliver high-quality and super fun educational content
          that keeps children learning and creating month after month. In addition, we can avoid
          annoying in-game advertisements and in-app purchases.`,
  },
  {
    question: 'How do I cancel?',
    answer: () => (
      <>
        We&rsquo;re sorry to see you go! Our learning app has a ton of different content so please
        have your child explore for all seven days of the trial so they get a chance to solve
        puzzles, make games and learn about automation.
        <br />
        <br />
        If after your trial you&rsquo;d still like to cancel your subscription to codeSpark Academy
        then please follow these directions:
        <br />
        <br />
        <b>Apple App Store (iOS)</b> - cancel your subscription{' '}
        <a
          href="https://support.apple.com/en-us/HT202039"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .<br />
        <b>Google Play Store (Android)</b> - cancel your subscription{' '}
        <a
          href="https://support.google.com/payments/answer/6220303?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        . If the cancellation button does not appear, please contact us at{' '}
        <a href="mailto:support@codespark.com">support@codespark.com</a> with your Google Play ID
        from your original e-receipt (the number begins with &ldquo;GPA&rdquo;) so we can manually
        cancel for you.
        <br />
        <b>Our website</b> - cancel your subscription by{' '}
        <a href="/login" target="_blank">
          logging into your account
        </a>
        .
      </>
    ),
  },
  {
    question: 'How is codeSpark Academy girl-friendly?',
    answer: () => (
      <>
        Every girl is different, and codeSpark Academy created its girl characters with that thought
        in mind. Girl characters in the game range from queens to police officers to astronauts. We
        specifically avoided girl character tropes in games; our female Foos are never rescued and
        can solve their own problems (with the help of the kid programmer).
        <br />
        <br />
        Additionally, we continuously test codeSpark Academy with girl players in order to make sure
        that the characters and gameplay appeal to them. We&rsquo;re happy to say that our game is
        girl (and boy) approved!
      </>
    ),
  },
];

const useStyles = makeStyles(theme => ({
  faqSection: {
    padding: '5rem 11rem',
    [theme.breakpoints.down('sm')]: {
      padding: '2.8125rem 1.5rem 2rem',
    },
  },
  title: {
    color: '#21375B',
    textAlign: 'center',
  },
  faqs: {
    marginTop: '1rem',
  },
  viewAllButtonContainer: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'center',
  },
  viewAllButton: {
    color: '#21375B',
    fontWeight: 'bold',
    fontSize: '1.125rem',
    textTransform: 'Capitalize',
    '& > .MuiButton-label': {
      fontFamily: 'Quicksand',
    },
  },
}));

function Faqs({ className }) {
  const classes = useStyles();

  return (
    <Box component="section" className={classNames(classes.faqSection, className)}>
      <CodeSparkMuiTypography variant="h2" className={classes.title}>
        Frequently Asked Questions
      </CodeSparkMuiTypography>

      <Box className={classes.faqs}>
        <Accordion items={faqs} />
      </Box>

      <Box className={classes.viewAllButtonContainer}>
        <ThemedMuiButton
          variant="text"
          href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/frequently-asked-questions`}
          target="_blank"
          rel="noopener"
          endIcon={<ChevronRight style={{ fontSize: '1.875rem' }} />}
          className={classes.viewAllButton}
        >
          View All
        </ThemedMuiButton>
      </Box>
    </Box>
  );
}

export default Faqs;
