import { useEffect } from 'react';
import React from 'react';
import { Chip } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { sendPageAnalyticsEvent, sendGTMEvent } from '../../providers/service';
import '../../../MinervaSignup/MinervaSignup.scss';
import { useSelector } from 'react-redux';

function BookClass(props) {
  const selectedMinervaOfferId = useSelector((state) => state.appState.minervaSelectedClassId);
  let minervaOffer = useSelector((state) => state.appState.minervaSelectedClass);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 3000);

    sendPageAnalyticsEvent(selectedMinervaOfferId, minervaOffer);
    sendGTMEvent({
      deviceType: props.deviceInfo.device,
      deviceOS: props.deviceInfo.browser.os,
      pathName: props.pathname,
      selectedOffer: minervaOffer,
    });

    const sawyerWidget = document.getElementById('sawyer');

    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://hisawyer.com/embed/XxNqCVmS6hh8oma3GuUPwXXqZsQvG7UX.js';
    scriptTag.async = true;
    scriptTag.id = 'SA_XxNqCVmS6hh8oma3GuUPwXXqZsQvG7UX';
    scriptTag.setAttribute('data-sawyertools', 'sawyertools');
    sawyerWidget.appendChild(scriptTag);

    return () => {
      sawyerWidget.removeChild(scriptTag);
    };
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="minerva-signup-page" style={{ maxWidth: '100%' }}>
          <div className="stepBadge-container">
            <Chip label="STEP 4 OF 4" className="stepBadge" />
          </div>
          <div className="minerva-signup-title">
            <h2>Book your free class</h2>
          </div>
          <div className="minerva-class-subtitle">
            You’ll get a copy of these instructions in your email. If you have trouble, contact us at&nbsp;
            <a href="mailto:classes@codespark.com">classes@codespark.com</a>
          </div>
          <div id="sawyer"></div>
        </div>
      </div>
    </>
  );
}

export default withRouter(BookClass);
