import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Grid, Button, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { classTopData } from '../data/classInfoData';
import CodeSparkMuiTypography from '../../../../components/elements/CodeSparkMuiTypography';
import ClassDetailsTable from './ClassDetailsTable';
import { setMinervaSelectedClassId, setMinervaSelectedClass } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';
import { getMinervaOffers, parseToJson } from '../../../../services/parse';

const useClassCompareStyles = makeStyles((theme) => ({
  compareWrapper: {
    margin: '5px 0 20px 0',
  },
  leftTopBorder: {
    borderRadius: '11px 11px 0px 0px',
  },
  rightTopBorder: {
    borderRadius: '0px 11px 0px 0px',
  },
  bestHeader: {
    fontFamily: 'QuicksandBold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#FFCE00',
  },
  hidden: {
    display: 'none',
  },
  price: {
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: '0px',
    letterSpacing: '0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '.875rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '.75rem',
    },
  },
  leftPanel: {
    background: '#6606C6',
    color: 'white',
    border: 'none',
    width: '50%',
  },
  rightPanel: {
    background: '#F0F8FF',
    border: 'none',
  },
  ctaButton: {
    height: '34px',
    borderRadius: '100px',
    lineHeight: '17.5px',
    '& span': {
      fontFamily: 'QuicksandBold',
    },
  },
  yellow: {
    background: '#FFDB47',
    '&:hover': {
      background: '#FFDB47',
    },
  },
  orange: {
    background: '#FF9254',
    '&:hover': {
      background: '#FF9254',
    },
  },
}));

export default function ClassCompare(props) {
  const classes = useClassCompareStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [weeklyLiveClasses, setWeeklyLiveClasses] = useState('');
  const [onDemandClasses, setOnDemandClasses] = useState('');

  useEffect(() => {
    getMinervaOffers().then((plans) => {
      const weeklyLiveClass = plans.find((x) => x.get('planId') === 'minerva_with_multi_classes');
      const onDemandClass = plans.find((x) => x.get('planId') === 'minerva_monthly_ondemand_a1');
      setWeeklyLiveClasses(parseToJson(weeklyLiveClass));
      setOnDemandClasses(parseToJson(onDemandClass));
    });
  }, []);

  const handlePlanSelection = (planId) => {
    dispatch(setMinervaSelectedClassId(planId));
    if (planId === 'minerva_with_multi_classes') {
      dispatch(setMinervaSelectedClass(weeklyLiveClasses));
    } else {
      dispatch(setMinervaSelectedClass(onDemandClasses));
    }
    history.push('/tryclasses-signup');
  };

  return (
    <Grid container className={classes.compareWrapper}>
      <Grid item xs={12}>
        <Table>
          <TableBody className={classes.noBorder}>
            {classTopData.map((item, idx) => (
              <TableRow style={{ border: 'none', backgroundColor: 'transparent' }} key={idx}>
                <TableCell
                  align="center"
                  className={`${classes.leftPanel} ${idx === 0 && classes.leftTopBorder}`}
                  style={{ borderBottom: 'none' }}
                >
                  {idx === 3 ? (
                    <Button
                      variant="contained"
                      fullWidth
                      className={`${classes.ctaButton} bot-tryFreeButtonPlanSelectionPage ${classes.yellow}`}
                      onClick={() => handlePlanSelection('minerva_with_multi_classes')}
                    >
                      TRY FREE
                    </Button>
                  ) : (
                    <CodeSparkMuiTypography
                      variant="h3"
                      className={idx === 0 ? classes.bestHeader : idx === 2 ? classes.price : ''}
                    >
                      {item.info}
                    </CodeSparkMuiTypography>
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  className={`${classes.rightPanel} ${idx === 0 && classes.hidden} 
                  ${idx === 1 && classes.rightTopBorder}`}
                  style={{ borderBottom: 'none' }}
                >
                  {idx === 3 ? (
                    <Button
                      variant="contained"
                      fullWidth
                      className={`${classes.ctaButton} bot-tryFreeButtonPlanSelectionPage ${classes.orange}`}
                      onClick={() => handlePlanSelection('minerva_monthly_ondemand_a1')}
                    >
                      TRY FREE
                    </Button>
                  ) : (
                    <CodeSparkMuiTypography
                      variant="h3"
                      className={idx === 0 ? classes.noHeader : idx === 2 ? classes.price : ''}
                    >
                      {item.additionalInfo}
                    </CodeSparkMuiTypography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <ClassDetailsTable />
    </Grid>
  );
}
