import { createAction } from '@reduxjs/toolkit';

// registration question - email
export const saveEmail = createAction('SAVE_EMAIL');

// email lead wall - checkbox
export const saveCheckboxOptIn = createAction('SAVE_CHECKBOX_OPT_IN');

// user's geolocation
export const saveUserCountry = createAction('SAVE_USER_COUNTRY');

//registration question - referral
export const saveReferral = createAction('SAVE_REFERRAL');

//registration question - kid's age
export const saveRegisterKidAge = createAction('SAVE_KID_AGE');

//registration question - kid's gender
export const saveRegisterKidGender = createAction('SAVE_KID_GENDER');

//registration question - experience of kid
export const saveRegisterKidExperience = createAction('SAVE_KID_EXPERIENCE');

//if the user is wants to visit a page that requires login,
//save that page and redirect once the user logs in
export const saveExpectedNextPage = createAction('SAVE_EXPECTED_NEXT_PAGE');

export const saveWebOfferCode = createAction('SAVE_WEB_OFFER_CODE');

export const saveWebOffer = createAction('SAVE_WEB_OFFER');

export const saveRequiresShipping = createAction('SAVE_REQUIRES_SHIPPING');

export const saveShippingAddress = createAction('SAVE_SHIPPING_ADDRESS');

//when a user tries to start with a trial from the homepage
//send them to /account and show popup to give them instuctions
export const showUserNoUpgrade = createAction('SHOW_WARN_NO_UPGRADE');

//when the user enters a promo or coupon
export const savePromoCoupon = createAction('SAVE_PROMO_COUPON');

//when the user logs out
export const logoutUser = createAction('LOGOUT');

// sets the initial selected offer ID. All other selected offers are accessed through session storage
// but this is needed for when user selects offer from thefoos to fooaccounts
export const setInitialSelectedOfferId = createAction('SET_INITIAL_SELECTED_OFFER_ID');

// sets the monthly default offer ID
export const setMonthlyOfferId = createAction('SET_MONTHLY_OFFER_ID');

// sets the annual default offer ID
export const setAnnualOfferId = createAction('SET_ANNUAL_OFFER_ID');

// when a user resubscribes
export const setResubscribe = createAction('SET_RESUBSCRIBE');

export const showBothSubTypesToggle = createAction('SHOW_BOTH_SUB_TYPES_TOGGLE');

export const setEmailErrorClass = createAction('SET_EMAIL_ERROR_CLASS');

export const setPasswordErrorClass = createAction('SET_PASSWORD_ERROR_CLASS');

export const setConfirmPasswordErrorClass = createAction('SET_CONFIRM_PASSWORD_ERROR_CLASS');

export const setReferralErrorClass = createAction('SET_REFERRAL_ERROR_CLASS');

// Minerva added class - addclasses funnel
export const setMinervaAddedClassId = createAction('SET_MINERVA_ADDED_CLASS_ID');

// Minerva selected class - tryclasses funnel
export const setMinervaSelectedClassId = createAction('SET_MINERVA_SELECTED_CLASS_ID');
export const setMinervaSelectedClass = createAction('SET_MINERVA_SELECTED_CLASS');
