import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    alertWrapper: {
      padding: '0 20px',
    },
    alertBanner: {
      '& .MuiTypography-body1': {
        fontSize: '16px',
        width: '100%',
      },
      backgroundColor: '#FFCE00',
      display: 'flex',
      flexDirection: 'row',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '15 30',
      },
      [theme.breakpoints.up('sm')]: {
        padding: '15 30',
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {
        width: '100%',
        padding: '15 30',
      },
      [theme.breakpoints.up('lg')]: {
        width: '100%',
        padding: '15 30',
      },
    },
    forgotPasswordLink: {
      float: 'right',
      color: '#0280E0',
      letterSpacing: 0.5,
      fontWeight: 'bold',
    },
    nextButton: {
      fontFamily: 'Quicksand',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '18px',
      lineHeight: '22px',
      letterSpacing: '0.5px',
      textTransform: 'uppercase',
      color: 'white',
      background: '#0280e0',
      borderRadius: '29px',
      height: '53px',
      '&:hover': {
        backgroundColor: '#0280e0',
      }
    },
    alertEmail: {
      fontWeight: 700,
      fontSize: '18px',
    },
    alertLink: {
      color: '#0280e0',
      textDecoration: 'underline',
    },
  })
);
