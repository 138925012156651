import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    pageContainer: {
        backgroundColor: '#F2F8FD',
        '& h1, h2, h3': {
            fontFamily: 'QuicksandBold',
        },
        '& h1': {
            color: '#21375B',
            fontSize: '2.65rem',
        },
        '& h3': {
            fontSize: '1.5rem',
            lineHeight: '1.85rem',
            '& .heading': {
              paddingTop: '65px',
              marginBottom: '25px',
            }
        },
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          padding: '0 60px',
          backgroundImage: 'url(https://thefoos-com2.s3.amazonaws.com/accounts/minervapayment/foohead-pattern.svg)',
        }
    },
    containerWrapper: {
        backgroundColor: '#FFFFFF',
        [theme.breakpoints.up('md')]: {
            borderRadius: '20px',
            marginTop: '60px',
            marginBottom: '60px',
            padding: '0 60px',
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: 0,
        },
        [theme.breakpoints.down('md')]: {
          marginHorizontal: 'auto',
      }
    },
  }),
);
