import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
      fontFamily: 'Quicksand',
      fontSize: '16px',
      color: '#21375B',
      padding: '30px 0',
      letterSpacing: 0.5,
      textAlign: 'center',
      lineHeight: '24px',
    },
  })
);
