import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { selectEnabledExperiment } from '../../../../containers/ExperimentsSetup/selectors';
import { EXPERIMENT_PAGE_DESIGN_FLAGS } from '../../../../containers/ExperimentsSetup/constants';
import { botClassName } from '../../../../utils/e2eBotUtils';
import ThemedMuiButton from '../../ThemedMuiButton';
import { getPlanDataKeyExperimentOverrides, getPlansDisplayData } from './plansDisplayData';
import { getPlanOrder, sortPlans } from './utils';
import { getPageCopy, getSubscriptionPlanComponent } from './subscriptionBlockData';
import {
  Loader,
  PageHeader,
  SelectPlanContainer,
  SelectPlanSection,
  SelectPlanWidthContainer,
  SubHeader,
} from './pageComponents';
import SubscriptionPlansContainerVariant from './SubscriptionPlansContainer';
import CondensedPremiumRedesign from './V3';
import PremiumVsStandardDesign from './V4';
import { BOT_CTA_BUTTON_NAME } from './botUtils';

function SubscriptionBlockRedesign({ selectPlanOption, nextLocation, selectedOffer, planOptions }) {
  const designExperiments = ['thirdPlan', 'lifetimePlan', ...EXPERIMENT_PAGE_DESIGN_FLAGS];
  const activeDesignExperiment = useSelector(state =>
    selectEnabledExperiment(state, designExperiments)
  );
  const pageCopy = getPageCopy(activeDesignExperiment);
  const { header, subHeader, ctaLabel } = pageCopy;

  const {
    // CVR-857 - new plan data labels
    updatedTrialLabels: shouldUseUpdatedLabels,
    // END CVR-857 - new plan data labels
    // CVR-921
    planSelectorSelectPlanButtons: shouldUseSelectPlanButtons,
    // END CVR-921
    // CVR-942
    thirdPlan: thirdPlanEnabled,
    // END CVR-942
    // CVR-956
    lifetimePlan: lifetimePlanEnabled,
    // END CVR-956
    premiumOndemandClasses: premiumOndemandClassesEnabled,
    premiumVsStandardDesign: premiumVsStandardDesignEnabled,
    premiumOndemandClassesTrials: premiumOndemandClassesTrialsEnabled,
  } = useSelector(state => state.experiments);

  const hasThirdPlan = thirdPlanEnabled || lifetimePlanEnabled;
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const planOrder = getPlanOrder({ isMobile, enabledExperiment: activeDesignExperiment });
  const planOptionsData = useMemo(
    () =>
      getPlansDisplayData({
        planOptions: sortPlans(planOptions, planOrder),
        planDataKeyOverrides: getPlanDataKeyExperimentOverrides(activeDesignExperiment),
        shouldUseUpdatedLabels,
        thirdPlanEnabled,
        lifetimePlanEnabled,
      }),
    [
      planOptions,
      planOrder,
      lifetimePlanEnabled,
      thirdPlanEnabled,
      shouldUseUpdatedLabels,
      activeDesignExperiment,
    ]
  );

  if (!planOptions.length) {
    return <Loader id="select-plan-section" />;
  }

  const selectPlan = planId => {
    selectPlanOption(planId);
    if (shouldUseSelectPlanButtons) {
      history.push(nextLocation);
    }
  };

  const SubscriptionPlanCard = getSubscriptionPlanComponent(activeDesignExperiment);
  const hasHighlightedPlan = planOptionsData.find(plan => plan.isHighlight);

  const pageProps = {
    pageCopy,
    SubscriptionPlanCard,
    planOptionsData,
    handleSelectPlan: selectPlan,
    nextLocation,
    selectedOffer,
    variant: activeDesignExperiment,
    ctaLabel,
  };
  if (premiumOndemandClassesEnabled || premiumOndemandClassesTrialsEnabled) {
    return <CondensedPremiumRedesign {...pageProps} />;
  }

  if (premiumVsStandardDesignEnabled) {
    return <PremiumVsStandardDesign {...pageProps} />;
  }

  return (
    <SelectPlanSection>
      <SelectPlanContainer>
        <SelectPlanWidthContainer alignItems={hasThirdPlan ? 'center' : undefined}>
          <PageHeader>{header}</PageHeader>

          {subHeader && <SubHeader align={hasThirdPlan ? 'center' : 'left'}>{subHeader}</SubHeader>}

          <SubscriptionPlansContainerVariant variant={activeDesignExperiment}>
            {planOptionsData.map(planData => {
              const {
                plan: { planId },
              } = planData;
              return (
                <SubscriptionPlanCard
                  key={planId}
                  onClick={() => selectPlan(planId)}
                  selected={selectedOffer?.planId === planId}
                  // CVR-921
                  nextLocation={nextLocation}
                  ctaLabel={ctaLabel}
                  shouldUseSelectPlanButtons={shouldUseSelectPlanButtons}
                  // END CVR-921
                  // CVR-942
                  thirdPlanEnabled={thirdPlanEnabled}
                  // END CVR-942
                  // CVR-956
                  lifetimePlanEnabled={lifetimePlanEnabled}
                  // END CVR-956
                  hasHighlightedPlan={hasHighlightedPlan}
                  {...planData}
                />
              );
            })}
          </SubscriptionPlansContainerVariant>
        </SelectPlanWidthContainer>

        {!shouldUseSelectPlanButtons && (
          <Box mt="2rem">
            <Link to={nextLocation}>
              <ThemedMuiButton
                color="primary"
                className={botClassName(BOT_CTA_BUTTON_NAME)}
                fullWidth
              >
                {ctaLabel}
              </ThemedMuiButton>
            </Link>
          </Box>
        )}
      </SelectPlanContainer>
    </SelectPlanSection>
  );
}

export default SubscriptionBlockRedesign;
