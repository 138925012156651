import { ListItemIcon, ListItemText, styled } from '@material-ui/core';
import React from 'react';

const Icon = styled(({ noMargins, ...props }) => <ListItemIcon {...props} />)(
  ({ size, noMargins }) => {
    const iconImageStyles = {
      '& > img': {
        width: '100%',
      },
    };
    const iconSizeStyles = {
      medium: {
        fontSize: '1.5625rem',
        marginRight: noMargins ? 0 : '1rem',
        minWidth: '1.5625rem',
        maxWidth: '1.5626rem',
        ...iconImageStyles,
      },
      small: {
        fontSize: '1.25rem',
        marginRight: noMargins ? 0 : '0.875rem',
        minWidth: '1.25rem',
        maxWidth: '1.25rem',
        ...iconImageStyles,
      },
    };

    return iconSizeStyles[size];
  }
);

export const ValuePropIcon = ({ icon, size = 'medium', ...props }) => {
  return (
    <Icon size={size} {...props}>
      <img src={icon} aria-hidden="true" alt="" />
    </Icon>
  );
};

export const ValuePropText = ({ children, ...props }) => {
  return (
    <ListItemText disableTypography {...props}>
      {children}
    </ListItemText>
  );
};
