import { makeStyles } from '@material-ui/core';
import React from 'react';
import { botClassName } from '../../../utils/e2eBotUtils';

const useStyles = makeStyles({
  ctaButtonContainer: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
  },
  ctaButton: {
    width: '273px',
    height: '53px',
    background: '#0280e0',
    boxShadow: '0px 2px 5px rgba(37, 55, 88, 0.2)',
    borderRadius: '25px',
    textAlign: 'center',
    color: '#ffffff',
    fontSize: '18px',
    lineHeight: '23px',
    cursor: 'pointer',
  },
  disabled: {
    backgroundColor: '#D2D2D2',
    cursor: 'default',
  },
});

export default function CtaButton({ onClick, disabled, children }) {
  const classes = useStyles();

  return (
    <div className={classes.ctaButtonContainer}>
      <button
        className={`${botClassName('email-next-btn')} ${classes.ctaButton} ${
          disabled ? classes.disabled : ''
        }`}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  );
}
