import { useCallback, useEffect } from 'react';
import { useFlagsStatus } from '@unleash/proxy-client-react';
import { sendAnalyticsEvent, sendPageViewEvent } from '../services/analytics';

// Wait for unleash to load before sending page load events
export const usePageViewEvent = eventName => {
  const { flagsError, flagsReady } = useFlagsStatus();

  useEffect(() => {
    if (flagsError || flagsReady) {
      // Add small timeout so that the client.readyEventEmitted gets updated by the time
      // the event is sent
      setTimeout(() => {
        sendPageViewEvent(eventName);
      }, 0);
    }
  }, [flagsError, flagsReady, eventName]);
};

/**
 * Returns a cached function that can be used in useEffect to send events
 * once Unleash flags have loaded or errored
 *
 * @returns {function} cached function
 */
export const useSendEventWhenFFReady = () => {
  const { flagsError, flagsReady } = useFlagsStatus();

  return useCallback(
    (eventName, properties) => {
      if (flagsError || flagsReady) {
        // Add small timeout so that the client.readyEventEmitted gets updated by the time
        // the event is sent
        setTimeout(() => {
          sendAnalyticsEvent(eventName, properties);
        }, 0);
      }
    },
    [flagsError, flagsReady]
  );
};
