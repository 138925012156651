import { generateVariantData } from '../../../../utils/experimentUtils';

const imgURL = `${process.env.REACT_APP_CLOUDFRONT_URL}/accounts`;

export const allPlansIncludeData = [
  {
    text: '100s of Educational Puzzles',
    image: `${imgURL}/educational_puzzles.png`,
  },
  {
    text: 'Unlimited Coding Challenges',
    image: `${imgURL}/unlimited_coding_challenges.png`,
  },
  {
    text: 'Access For Up To 3 Children',
    image: `${imgURL}/access_up_to_children.png`,
  },
];

export const allPlansIncludeDataVariant1 = [
  {
    text: '100s of Educational Puzzles',
    image: `${imgURL}/educational_puzzles.png`,
  },
  {
    text: 'New skills EVERY Week',
    image: `${imgURL}/all_plans_include_child_ipad_2.png`,
  },
  {
    text: 'Unlimited Coding Challenges',
    image: `${imgURL}/unlimited_coding_challenges.png`,
  },
  {
    text: 'Access For Up To 3 Children',
    image: `${imgURL}/access_up_to_children.png`,
  },
];

const digitalWorkbooks = {
  text: 'Digital Workbooks',
  image: `${imgURL}/digital_workbooks.png`,
};
const updatedBaseData = allPlansIncludeDataVariant1.slice(0, 3).concat(digitalWorkbooks);

const orangeCta = 'primary';
const purpleCta = 'primary2';
export const getAllPlansIncludeData = generateVariantData({
  planSelectorAllPlansIncludeVariant1: {
    header: 'All Plans Include',
    data: allPlansIncludeDataVariant1,
    ctaColor: orangeCta,
    ctaLabel: 'Start Free Trial',
  },
  redesignOndemandClasses: {
    header: 'All Plans Include',
    data: [allPlansIncludeData[0], allPlansIncludeData[1], allPlansIncludeDataVariant1[1]],
    ctaColor: orangeCta,
    ctaLabel: 'Start Free Trial',
  },
  premiumOndemandClasses: {
    header: 'With your plan, you have access to:',
    data: allPlansIncludeDataVariant1,
    ctaColor: orangeCta,
    ctaLabel: 'Start Free Trial',
  },
  premiumVsStandardDesign: {
    header: 'All Plans Include',
    data: updatedBaseData,
    ctaColor: purpleCta,
    ctaLabel: 'Start Your 7-day Free Trial',
  },
  premiumOndemandClassesTrials: {
    header: 'All Plans Include',
    data: updatedBaseData,
    ctaColor: purpleCta,
    ctaLabel: 'Start Your Free Trial',
  },
  base: {
    header: 'All Plans Include',
    data: allPlansIncludeData,
    ctaColor: orangeCta,
    ctaLabel: 'Start Free Trial',
  },
});
