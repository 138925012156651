import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { useFlags, useFlagsStatus, useUnleashContext } from '@unleash/proxy-client-react';
import { isRobotE2E } from '../../utils/e2eBotUtils';
import { setExperimentStatus } from './actions';
import { generateExperimentSetupMapping } from '../../utils/experimentUtils';
import { getEnabledExperiments } from '../../featureFlags';
import { setCrossDomainCookie } from '../../utils/cookieUtils';

const FEATURE_ID_COOKIE = 'featureId';
export const EXPERIMENT_COOKIE = 'codeSparkExperimentID';
export const EXPERIMENT_OVERRIDE_COOKIE = 'variantOverride';

function useExperimentContextSetup() {
  const updateContext = useUnleashContext();

  const cookies = new Cookies();
  const variantOverrideCookie = cookies.get(EXPERIMENT_OVERRIDE_COOKIE);
  const existingExperimentId = cookies.get(EXPERIMENT_COOKIE);
  const featureIdCookie = cookies.get(FEATURE_ID_COOKIE);
  const experimentId = !!existingExperimentId ? existingExperimentId : uuidv4();

  useEffect(() => {
    const parsedQuery = queryString.parse(window.location.search);
    const variantOverride = parsedQuery.variantOverride || variantOverrideCookie;
    const featureIds = parsedQuery.featureId || featureIdCookie;

    // setup persistant experiment id for unleash stickiness across foos and fooaccounts
    // since unleash uses localstorage and that does not persist between subdomains
    updateContext({
      codeSparkExperimentID: experimentId,
      platformType: 'web',
      isRobotE2E: isRobotE2E(),
      variantOverride,
      pathname: window.location.pathname,
      featureIds,
    });

    setCrossDomainCookie(EXPERIMENT_COOKIE, experimentId);
    if (variantOverride) {
      setCrossDomainCookie(EXPERIMENT_OVERRIDE_COOKIE, variantOverride);
    }
    if (featureIds) {
      setCrossDomainCookie(FEATURE_ID_COOKIE, featureIds);
    }
  }, [experimentId, updateContext, variantOverrideCookie]);
}

function ExperimentsSetup({ children }) {
  useExperimentContextSetup();
  const dispatch = useDispatch();
  const flags = useFlags();
  const { flagsReady } = useFlagsStatus();

  const dispatchExperimentStatus = useCallback(
    (id, status) =>
      dispatch(
        setExperimentStatus({
          id,
          status,
        })
      ),
    [dispatch]
  );

  // Setup all the experiment flags (migration from optimizely to unleash)
  const getExperimentSetupFns = useCallback(
    generateExperimentSetupMapping({
      C124_Redesign_Selector_Page: {
        control: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant1', false);
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', false);
        },
        variant1: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant1', true);
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', false);
        },
        variant2: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant1', false);
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', true);
        },
      },
      C139_Monthly_And_Premium_Annual_Plan_Only: {
        control: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', true);
        },
        variant1: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', true);
          dispatchExperimentStatus('planSelectorSelectPlanButtons', true);
          dispatchExperimentStatus('thirdPlan', true);
        },
      },
      C140_No_Monthly_Plan: {
        control: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', true);
          dispatchExperimentStatus('planSelectorSelectPlanButtons', true);
          dispatchExperimentStatus('thirdPlan', true);
        },
        variant1: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', true);
          dispatchExperimentStatus('planSelectorSelectPlanButtons', true);
          dispatchExperimentStatus('lifetimePlan', true);
        },
      },
      C136_Modernized_Homepage_With_OnDemand_Classes: {
        control: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', true);
          dispatchExperimentStatus('planSelectorSelectPlanButtons', false);
          dispatchExperimentStatus('redesignOndemandClasses', false);
        },
        variant1: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', true);
          dispatchExperimentStatus('planSelectorSelectPlanButtons', true);
          dispatchExperimentStatus('redesignOndemandClasses', true);
        },
      },
      C141_LP_Only_Premium_Plans: {
        control: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', true);
          dispatchExperimentStatus('planSelectorSelectPlanButtons', true);
          dispatchExperimentStatus('thirdPlan', true);
        },
        variant1: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', true);
          dispatchExperimentStatus('planSelectorAllPlansIncludeSlider', true);
          dispatchExperimentStatus('premiumOndemandClasses', true);
        },
      },
      C142_Annual_VS_Low_Term_Non_Premium: {
        control: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', true);
          dispatchExperimentStatus('planSelectorSelectPlanButtons', true);
          dispatchExperimentStatus('thirdPlan', true);
        },
        variant1: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', true);
          dispatchExperimentStatus('planSelectorAllPlansIncludeSlider', true);
          dispatchExperimentStatus('premiumVsStandardDesign', true);
        },
      },
      C143_2_Year: {
        control: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', true);
          dispatchExperimentStatus('planSelectorSelectPlanButtons', true);
          dispatchExperimentStatus('thirdPlan', true);
        },
        variant1: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', true);
          dispatchExperimentStatus('planSelectorAllPlansIncludeSlider', true);
          dispatchExperimentStatus('premiumVsStandardDesign', true);
        },
      },
      C144_Trial_Incentive: {
        control: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', true);
          dispatchExperimentStatus('planSelectorAllPlansIncludeSlider', true);
          dispatchExperimentStatus('premiumOndemandClassesTrials', true);
        },
        variant1: () => {
          dispatchExperimentStatus('planSelectorPageRedesignVariant2', true);
          dispatchExperimentStatus('planSelectorAllPlansIncludeSlider', true);
          dispatchExperimentStatus('premiumOndemandClassesTrials', true);
          dispatchExperimentStatus('differentPlanTrials', true);
        },
      },
    }),
    [dispatchExperimentStatus]
  );

  useEffect(() => {
    if (flagsReady) {
      const enabledExperiments = getEnabledExperiments(flags);

      enabledExperiments.forEach(enabledExperiment => {
        const variant = enabledExperiment.variant.name;

        const experimentVariantSetupFn = getExperimentSetupFns(enabledExperiment.name, variant);

        if (experimentVariantSetupFn) {
          experimentVariantSetupFn();
        }
      });
    }
  }, [flags, getExperimentSetupFns, flagsReady]);

  return children;
}

export default ExperimentsSetup;
