import React, { useEffect } from 'react';
import AvailableCourses from './AvailableCourses';
import { trackPageMixpanel } from '../../../services/functions';
import { useHistory } from 'react-router-dom';
import Swiper from 'swiper/dist/js/swiper.min.js';

import './Landing.scss';
import '../MinervaLandingpage/Heading.scss';
import 'swiper/dist/css/swiper.css';
import Grid from '@material-ui/core/Grid';
import { ArrowForward } from '@material-ui/icons';

export default function MinervaMemberships(props) {
  const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;
  const history = useHistory();

  const data = [
    {
      title: 'REVIEW By CHRIS B.',
      content: `“That class size was small enough they got the one on one attention but enough kids to play each other’s game.”`,
    },
    {
      title: 'REVIEW By CHELSEA N.',
      content:
        '“My child is learning so much in the class! The teacher is very sweet and relatable. He looks forward to it every week!”',
    },
    {
      title: 'REVIEW By ARIEL S.',
      content: '“Best zoom class my son has taken…this was the only one that he was totally engaged all on his own.”',
    },
    {
      title: 'REVIEW By LAKEN A.',
      content:
        '“This class taught my children so much about the game and was a kid-appropriate introduction to coding.”',
    },
    {
      title: 'REVIEW By SARAH M.',
      content:
        '“It’s her favorite part of the day. She loves using her creativity to make things others will also use.”',
    },
  ];

  const handleClick = () => {
    history.push('/tryclassesfree-signup');
  };

  useEffect(() => {
    let slidesPerView = 5;

    new Swiper('.swiper-container', {
      pagination: '.swiper-pagination',
      slidesPerView: slidesPerView,
      centeredSlides: true,
      paginationClickable: true,
      spaceBetween: 30,
      initialSlide: 2,
      loop: true,
      breakpoints: {
        320: {
          slidesPerView: 1.5,
          spaceBetween: 50,
        },
        360: {
          slidesPerView: 1.5,
          spaceBetween: 35,
        },
        375: {
          slidesPerView: 1.5,
          spaceBetween: 50,
        },
        428: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        780: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        850: {
          slidesPerView: 3.5,
          spaceBetween: 40,
        },
      },
    });
    trackPageMixpanel('Memberships V1.4 Page: Homepage');
  }, []);

  return (
    <div className="minerva-landingpage-wrapper">
      <div>
        <Grid container className="loading-banner">
          <Grid item xs={12} sm={12} md={12} lg={6} className="loading-box">
            <div className="title">
              Learn to code a video <br className="line-break" />
              game in just 1 week!
            </div>
            <div className="desc">Interactive after-school coding classes for kids ages 5 to 10.</div>
            <div className="sawyer-btn" onClick={handleClick}>
              Try a Free Class! <ArrowForward style={{ width: 15, height: 15 }} />
            </div>
            <div className="terms-word">
              <span>
                <a href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/terms`}>Terms Apply</a>
              </span>
              <span className="span-right">Cancel Anytime</span>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} className="banner-img-container">
            <img src={`${imgURL}/accounts/iStock.png`} alt="" />
          </Grid>
        </Grid>

        <Grid container className="trustpilot">
          <Grid item xs={12} sm={6} md={6} lg={6} className="trustpilot-rating">
            <img
              src={`${imgURL}/accounts/classes/trustpilot-Logo.jpg`}
              alt="trustpilot"
              className="trustpilot-rating-left"
            />
            <img src={`${imgURL}/accounts/classes/stars-4.5.svg`} alt="star 4.5" className="trustpilot-rating-right" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} className="see-reviews">
            4.5 STAR AVERAGE RATING | <a href="https://www.trustpilot.com/review/codespark.com">SEE ALL REVIEWS</a>
          </Grid>
        </Grid>

        <div className="swiper-container">
          <div className="swiper-wrapper">
            {data.map((item, index) => {
              return (
                <div className="swiper-slide" key={index}>
                  <div className="item">
                    <div className="ititle">{item.title}</div>
                    <div className="all-star">
                      <img className="all-img" src={`${imgURL}/accounts/classes/stars-5.svg`} alt="" />
                    </div>
                    <div className="idesc">{item.content}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>

      <div className="awards-wrapper">
        <div className="awards-header">FEATURED ON</div>
        <div className="awards-container">
          <div className="awards-container-top">
            <img src={`${imgURL}/accounts/classes/media-cnn@2x.png`} alt="" className="img" />
            <img src={`${imgURL}/accounts/classes/media-yahoofi@2x.png`} alt="" className="img" />
            <img src={`${imgURL}/accounts/classes/media-usatoday@2x.png`} alt="" className="img" />
          </div>
          <div className="awards-container-bottom">
            <img src={`${imgURL}/accounts/classes/media-npr@2x.png`} alt="" className="img" />
            <img src={`${imgURL}/accounts/classes/media-techcrunch@2x.png`} alt="" className="img" />
          </div>
        </div>
        <div className="line"></div>
        <a
          href="https://www.kidsafeseal.com/certifiedproducts/codespark_thefoos_app.html"
          target="_blank"
          rel="noopener noreferrer"
          className="kidSafe-award"
        >
          <img
            className="single-award"
            alt="codeSpark Academy with The Foos - coding for kids is certified by the kidSAFE Seal Program."
            src="https://www.kidsafeseal.com/sealimage/1730524102862329251/codespark_thefoos_app_large_whitetm.png"
          />
        </a>
      </div>
      <br />
      <br />
      <div className="sawyer-classes-offering">
        <div className="content grid-x grid-padding-x ">
          <div className="small-12 medium-6 align-self-middle sawyer-classes-image-container">
            <img src={`${imgURL}/accounts/classes/foo-class@2x.png`} alt="classes" className="sawyer-classes-image" />
          </div>
          <div className="sawyer-classes-list cell small-12 medium-6">
            <h1 style={{ color: 'rgba(2, 128, 224, 1)', fontWeight: 'bold' }}>
              Unlock the code to your child's future.
            </h1>
            <h6>Just $19.99 / month for 1 LIVE class and learn-to-code app</h6>
            <ul className="sawyer-perks-list">
              <li>Anytime access to our award-winning app</li>
              <li>Live, virtual, small classes with experienced teachers</li>
              <li>Weekly lessons designed to build critical skills</li>
              <li>Unlimited access to creativity suite</li>
              <li>Research-backed curricula from MIT and Princeton</li>
            </ul>
          </div>
        </div>
      </div>

      <AvailableCourses />

      <div className="steps-wrapper">
        <div className="steps-image-container">
          <img src={`${imgURL}/flagship/home-page/mission-kids.png`} alt="Mission Value" />
        </div>
        <h1 style={{ color: 'rgba(2, 128, 224, 1)', fontWeight: 'bold' }}>Get your kid coding in 3 easy steps</h1>
        <div className="small-12 medium-6 cell steps-content">
          <div className="steps">
            <div className="circle">1</div>
            <div className="steps-column">
              <h4>Book a class! </h4>
              <p>Sessions are available Mon–Fri after school.</p>
            </div>
          </div>
          <div className="steps">
            <div className="circle">2</div>
            <div className="steps-column">
              <h4>Dive into coding</h4>
              <p>Kids build their own projects live in class and outside of class on our learn-to-code app.</p>
            </div>
          </div>
          <div className="steps">
            <div className="circle">3</div>
            <div className="steps-column">
              <h4>Grow their coding skills!</h4>
              <p>Explore game design, user testing and storytelling to build coding skills.</p>
            </div>
          </div>
        </div>

        <div className="sawyer-mission">
          <h1 style={{ fontWeight: 'bold' }}>The codeSpark advantage</h1>
          <div className="sawyer-mission-wrapper">
            <div className="sawyer-mission-container">
              <div className="sawyer-image-container">
                <img
                  src={`${imgURL}/accounts/classes/feature-learnbydoing.png`}
                  className="sawyer-mission-image"
                  alt=""
                ></img>
              </div>
              <div>
                <h4>Learn by building</h4>
                <p>
                  Kids code their own game from <br className="sawyer-advantage-content" /> scratch, based on curricula
                  from MIT
                  <br className="sawyer-advantage-content" /> and Princeton.
                </p>
              </div>
            </div>

            <div className="sawyer-mission-container">
              <div className="sawyer-image-container">
                <img
                  src={`${imgURL}/accounts/classes/feature-kidshelping.png`}
                  className="sawyer-mission-image"
                  alt=""
                ></img>
              </div>
              <div>
                <h4>Kids helping kids</h4>
                <p>
                  Guided by an experienced teacher,
                  <br className="sawyer-advantage-content" /> kids present to one another and share
                  <br className="sawyer-advantage-content" /> real-time feedback.
                </p>
              </div>
            </div>

            <div className="sawyer-mission-container">
              <div className="sawyer-image-container">
                <img src={`${imgURL}/accounts/classes/feature-AASL.png`} className="sawyer-mission-image" alt=""></img>
              </div>
              <div>
                <div>
                  <h4>Trusted by 120K</h4>
                  <h4>educators</h4>
                </div>
                <p>
                  Our award-winning coding platform is
                  <br className="sawyer-advantage-content" /> used in classrooms all over the world.
                </p>
              </div>
            </div>
          </div>
          <div className="sawyer-btn bottom">
            <button onClick={handleClick} className="sawyer-btn bottom">
              Try a Free Class!
            </button>
          </div>
          <br />
        </div>
      </div>

      <br />
      <h1 style={{ color: 'rgba(2, 128, 224, 1)', fontWeight: 'bold' }}>Frequently Asked Questions</h1>
      <div className="faq-wrapper">
        <div className="faq-container">
          <h2 style={{ fontSize: 28, fontFamily: 'Quicksand', fontWeight: 'bold' }}>How do I schedule classes?</h2>
          <p>
            After you complete your purchase, you will receive an email with instructions to complete class
            registration. You will receive a certain number of class credits to use towards courses every month.
          </p>
        </div>
        <div className="faq-container">
          <h2 style={{ fontSize: 28, fontFamily: 'Quicksand', fontWeight: 'bold' }}>
            How frequently are classes held?{' '}
          </h2>
          <p>Classes are held every week from Monday to Friday, with options ranging from 4pm to 7pm EST.</p>
        </div>
        <div className="faq-container">
          <h2 style={{ fontSize: 28, fontFamily: 'Quicksand', fontWeight: 'bold' }}>What is a class like? </h2>
          <p>
            Each class is 45 minutes in length, taught by an experienced school teacher in the subject matter. To ensure
            a high quality experience, classes are in small groups of no more than 6 students. It is interactive,
            collaborative, and project based!
          </p>
        </div>
        <div className="faq-container">
          <h2 style={{ fontSize: 28, fontFamily: 'Quicksand', fontWeight: 'bold' }}>
            What if we don’t like the classes?
          </h2>
          <p>
            If you are not enjoying the course, feel free to reach out to our customer support team
            <a href="mailto:classes@codespark.com"> classes@codespark.com</a> at any time. We'd be happy to change to
            another course or provide a refund!
          </p>
        </div>
      </div>
    </div>
  );
}
