import React, { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppleIcon from '@material-ui/icons/Apple';

import googleLogo from '../../../../../lib/icons/googleLogo.svg';
import ppLogo from '../../../../../lib/icons/ppLogo.svg';
import { useStyles } from './styles';
import { usePayment } from '../../providers';

function ExpressCheckout() {
  const classes = useStyles();
  const { processPayment, isProcessingPayment } = usePayment();
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.expressCheckoutContainer}
    >
      <Grid item className={classes.paymentBtnContainer}>
        {/* {window.ApplePaySession &&
          window.ApplePaySession.supportsVersion(3) &&
          window.ApplePaySession.canMakePayments() && (
            <Button
              fullWidth
              variant="contained"
              className={`${classes.applePayBtn} bot-applePayBtn`}
              disabled={isProcessingPayment}
              onClick={() => processPayment('applePay')}
            >
              Pay with <AppleIcon /> Pay
            </Button>
          )} */}
      </Grid>
      {/* <Grid item className={classes.paymentBtnContainer}>
                <Button
                    fullWidth
                    variant='contained'
                    className={`${classes.googlePayBtn} bot-googlePayBtn`}
                    disabled={isProcessingPayment}
                    onClick={() => processPayment('googlePay')}
                >
                    <img src={googleLogo} alt="glogo" /> Pay
                </Button>
            </Grid> */}
      {/* <Grid item className={classes.paymentBtnContainer}>
        <Button
          fullWidth
          variant="contained"
          className={`${classes.paypalBtn} bot-paypalBtn`}
          disabled={isProcessingPayment}
          onClick={() => processPayment('paypal')}
        >
          <span>Checkout with</span>
          <img className={classes.paypalIcon} src={ppLogo} alt="pplogo" />
        </Button>
      </Grid> */}
    </Grid>
  );
}

export default memo(ExpressCheckout);
