import React, { lazy, Suspense } from 'react'
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import __isEmpty from 'lodash/isEmpty';

import './SignupRegister.scss';
import { notify, trackPageMixpanel } from '../../../services/functions';
import { register } from '../../../services/parse';
import { sendFacebookEvent } from '../../../services/facebook-capi';
import { getAnalyticsOptInValue, sendAnalyticsEvent } from '../../../services/analytics';
import { connect } from 'react-redux';
import { SubscriptionPlanContext } from '../../../contexts/subscriptionPlans';
import { getPlanTagTrialLabelData, getPlanTrialLabelData } from '../../../planLabelData';
import { hasTrial } from '../../../utils/planUtils';
import { usePageViewEvent } from '../../../hooks/useAnalyticsEvent';
import { gtmPageviewEvent, gtmEvent } from '../../../services/google-tag-manager';
const BaseRegistrationForm = lazy(() => import('../../../components/forms/RegistrationForm'));
const renderLoader = () => <h1>Loading</h1>;

const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;

class SignupRegister extends React.Component {
  static contextType = SubscriptionPlanContext;

  constructor(props) {
    super(props);

    this.registerUser = this.registerUser.bind(this);
  }

  componentDidMount() {
    this.getAttributionSource();

    // grab variantID for google optimize from sessionStorage and pass it to mixpanel
    const variant = sessionStorage.getItem('variantId');
    gtmPageviewEvent({
      pageName: 'WebPaymentsV3 Page: Register',
      componentProps: this.props,
      callback: () => {
        trackPageMixpanel('WebPaymentsV3 Page: Register', {
          'Optimize experiment variant': `${process.env.REACT_APP_EXPERIMENT_ID} - variant ${variant}`
        });

        sendFacebookEvent({
          event_name: 'Onboarding Screen',
          event_source_url: window.location.href,
          custom_data: {screen: 'Register'}
        });

        sendFacebookEvent({
          event_name: 'Visit',
          event_source_url: window.location.href,
          custom_data: {screen: 'Register'}
        });
      },
    });

    window.scrollTo(0, 0);
  }

  getAttributionSource() {
    if (sessionStorage.attributionSource) {
      let attributionSource = sessionStorage.getItem('attributionSource').toString();
      if (attributionSource.includes('gclid') === true) {
        this.setState({ attributionSource: "googleadwords_web" });
      } else if (attributionSource.includes('fbclid') === true) {
        this.setState({ attributionSource: "facebookads_web" });
      } else if (attributionSource.includes('utm') === true) {
        this.setState({ attributionSource: attributionSource.split('utm')[1] });
      } else if (document.referrer) {
        this.setState({ attributionSource: "referral" });
      } else {
        this.setState({ attributionSource: 'undefined' });
      }
    } else {
      this.setState({ attributionSource: 'organic' });
    }
  }

  // showLoading() {
  //   document.getElementById("register-button").classList.add('processing');
  //   document.getElementById("registerButton").disabled = true;
  // }

  // hideLoading() {
  //   document.getElementById("register-button").classList.remove('processing');
  //   document.getElementById("registerButton").disabled = false;
  // }

  registerUser(params) {
    // this.showLoading();
    let utmParamsObject = sessionStorage.getItem('utmParamsObject');
    // example: "{\"attributionData\":{\"utm\":{},\"iterable\":{\"iterable_campaign\":\"1396648\",\"iterable_template\":\"1939065\"}}}"
    if (utmParamsObject) {
      utmParamsObject = JSON.parse(utmParamsObject);
    }

    register({
      username: params.username,
      password: params.password,
      attributionSource: params.attributionSource,
      utmParamsObject: utmParamsObject,
      confirmPromoEmails: params.offersSignup,
      captchaToken: params.captchaToken,
    }).then((user) => {
      // this.hideLoading();
      mixpanel.people.set_once('parseAccountId', user.id);
      mixpanel.alias(user.id);
      // sets the newly created user to Pretrial status
      mixpanel.people.set({ 'Account Status': 'Pretrial' });
      trackPageMixpanel('WebPaymentsV3 Action: Created User', {
        username: params.username,
        attributionSource: params.attributionSource
      });
      const optedInToEmail = getAnalyticsOptInValue(params.offersSignup);
      const pretickedOptIn = getAnalyticsOptInValue(params.initialOffersSignup);
      gtmEvent({
        action: 'login_created',
        pageName: 'WebPaymentsV3 Page: Register',
        user_id: user.id,
      });
      sendAnalyticsEvent('web_login_created', {
        'app': 'web-site',
        'opted_into_comms': optedInToEmail,
        'preticked_opt_in': pretickedOptIn,
      }, true);

      //save for the checkout page to combine with other data
      //Google Tag Manager
      const gtmRegData = {
        'event': 'parentRegistration',
        'userID': user.id,
        'emailAddress': params.emailToSave,
        'userType': 'parent',
      };
      sessionStorage.setItem('justRegistered', true);
      sessionStorage.setItem('gtmRegData', JSON.stringify(gtmRegData));

      //CVR variant for Begin's testing - switching order of account-creation and plan-selection
      this.props.receivedOptimizelyVariantEnabled ?
      this.props.history.push('/choose-plan') :
      this.props.history.replace('/signup-payment');
    }).catch((response) => {
      console.log('response', response);
      // this.hideLoading();
      trackPageMixpanel('WebPaymentsV3 Error: Registration Error', { Email: params.username });
      let error = !__isEmpty(response.data) ? response.data.error : response.message;
      if (typeof error !== 'string') error = 'Invalid email/password';
      notify('error', error, 5000);
    });
  }

  generateTrialHeaderText({
    shouldUseUpdatedLabels,
    freeTrialPeriod,
    trialQuantity,
    trialInterval,
  }) {
    return `Sign up for your ${
      shouldUseUpdatedLabels ? freeTrialPeriod : `FREE ${trialQuantity} ${trialInterval} trial`
    } of codeSpark!`;
  }

  getHeader() {
    const { shouldUseUpdatedLabels, differentPlanTrials } = this.props;
    const { planTag, selectedOffer } = this.context;

    // If different plan trials experiment is on, we just show a generic trial msg
    if (differentPlanTrials) {
      return 'Sign up for your FREE trial of codeSpark!';
    }

    // Default header using tag labels if user enters page without a selected offer
    if (!selectedOffer) {
      const tagTrialLabels = getPlanTagTrialLabelData(planTag);
      return this.generateTrialHeaderText({
        shouldUseUpdatedLabels,
        ...tagTrialLabels,
      });
    }

    // Some offers do not have trials so do not display a header
    if (!hasTrial(selectedOffer)) {
      return '';
    }

    // Display trial header based on what is offered in selected offer
    const selectedPlanTrialLabels = getPlanTrialLabelData(selectedOffer);
    return this.generateTrialHeaderText({
      shouldUseUpdatedLabels,
      ...selectedPlanTrialLabels,
    });
  }

  render() {
    const header = this.getHeader();

    return (
      <div className="registration-container">
        <Helmet>
          <title>Sign up | codeSpark Academy</title>
        </Helmet>
        <h2 className="header text-center">
          {header}
        </h2>
        <Suspense fallback={renderLoader()}>
          <BaseRegistrationForm
            sendRegisterUser={this.registerUser}
          />
        </Suspense>
        <div className="bottom-graphic-blurb grid-x grid-padding-x align-middle">
          <div className="large-4 cell">
            <img src={`${imgURL}/accounts/landing/cloud.png`} alt="clouds background" />
          </div>
          <h3 className="large-4 cell text-center">One day your kids will thank you for this head start!</h3>
          <div className="large-4 cell">
            <img src={`${imgURL}/accounts/landing/cloud-val.png`} alt="Foo in clouds" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ experiments }) => ({
  shouldUseUpdatedLabels: experiments.updatedTrialLabels,
  differentPlanTrials: experiments.differentPlanTrials,
});

export default withRouter(connect(mapStateToProps)((props) => {
  usePageViewEvent('web_onboarding_screen_login_setup');
  return <SignupRegister {...props} />
}));
