import React, { memo } from 'react';
import Countdown from 'react-countdown';
import { Box, Hidden, Grid, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { capitalize } from 'lodash';
import { useStyles } from './styles';
import { usePayment } from '../../providers';
import {
  getDollarSavings,
  getDurationMonths,
  getPercentSavings,
  hasTrial,
  QUARTERLY_DURATION,
} from '../../../../../utils/planUtils';
import { usePlan } from '../../../../../contexts/subscriptionPlans';
import classNames from 'classnames';
import { getPlanDisplayName } from '../../../../../components/elements/SubscriptionBlock/variants/plansDisplayData';

function generatePlanText({
  plan,
  codeSparkAppPrice,
  classesPrice,
  plans,
  showDiscount = false,
  baseComparisonPlanDuration,
}) {
  const ZERO_COST = '$0.00';

  const { price, trialDurationDays, subscriptionDuration } = plan;
  const planDurationInMonths = getDurationMonths(subscriptionDuration);
  const planHasTrial = hasTrial(plan);

  const codeSparkAppPriceDisplay = `$${codeSparkAppPrice}.00`;
  const classesPriceDisplay = `$${classesPrice}.00`;

  const baseComparisonPlan =
    showDiscount && plans.find(plan => plan.subscriptionDuration === baseComparisonPlanDuration);
  const percentSavings = baseComparisonPlan && getPercentSavings(baseComparisonPlan, plan);
  const savings = baseComparisonPlan && getDollarSavings(baseComparisonPlan, plan);

  return {
    charge: planHasTrial ? ZERO_COST : price,
    chargeSubtext: `$${price} billed every ${planDurationInMonths} months after ${trialDurationDays}-day trial`,
    items: [
      {
        item: 'codeSpark learn-to-code app',
        price: {
          amount: codeSparkAppPriceDisplay,
          charge: planHasTrial ? ZERO_COST : codeSparkAppPriceDisplay,
        },
      },
      {
        item: 'Pre-recorded class library',
        price: {
          amount: classesPriceDisplay,
          charge: planHasTrial ? ZERO_COST : classesPriceDisplay,
        },
      },
      ...showDiscount
        ? [
            {
              item: `${capitalize(getPlanDisplayName(plan))} payment discount`,
              price: {
                amount: `$${savings?.toFixed(2)}`,
                charge: `Save ${percentSavings}`,
                className: 'paymentDiscount',
              },
            },
          ]
        : [],
      {
        item: 'Coding workbook',
        price: {
          amount: null,
          charge: 'FREE',
        },
      },
    ],
    total: `$${price.toFixed(2)}`,
  };
}

function ClassAContainer() {
  const classes = useStyles();
  const { selectedMinervaOfferId, selectedOffer } = usePayment();
  const { plans } = usePlan();

  const classPlans = {
    'minerva_monthly_ondemand_a1': {
      charge: '$0.00',
      chargeSubtext: '$19.99/month after 7 day trial',
      items: [
        {
          'item': 'Pre-recorded class library',
          'price': {
            'amount': '$10.00',
            'charge': '$0.00',
          },
        },
        {
          'item': 'codeSpark learn-to-code app',
          'price': {
            'amount': '$9.99',
            'charge': '$0.00',
          },
        },
        {
          'item': 'Coding workbook',
          'price': { 'amount': null, charge: 'FREE' },
        },
      ],
    },
    'minerva_with_multi_classes': {
      charge: '$0.00',
      chargeSubtext: '$49.99/month after 30 day trial',
      items: [
        {
          'item': '4 LIVE online classes per month ',
          'price': {
            'amount': '$40.00',
            'charge': '$0.00',
          },
        },
        {
          'item': 'codeSpark learn-to-code app',
          'price': {
            'amount': '9.99',
            'charge': '$0.00',
          },
        },
        {
          'item': '1 FREE class',
          'price': {
            'amount': null,
            'charge': '$0.00',
          },
        },
        {
          'item': 'Pre-recorded class library',
          'price': {
            'amount': null,
            'charge': '$0.00',
          },
        },
        {
          'item': 'Coding workbook',
          'price': {
            'amount': null,
            'charge': 'FREE',
          },
        },
      ],
    },
    minerva_quarterly_ondemand_35: () => generatePlanText({
      plan: selectedOffer,
      codeSparkAppPrice: 15,
      classesPrice: 20,
    }),
    minerva_biannual_ondemand_65: () => generatePlanText({
      plan: selectedOffer,
      codeSparkAppPrice: 30,
      classesPrice: 40,
      showDiscount: true,
      plans,
      baseComparisonPlanDuration: QUARTERLY_DURATION,
    }),
    minerva_annual_ondemand_120_30trial: () => generatePlanText({
      plan: selectedOffer,
      codeSparkAppPrice: 60,
      classesPrice: 80,
      showDiscount: true,
      plans,
      baseComparisonPlanDuration: QUARTERLY_DURATION,
    }),
  };

  let selectedClass = classPlans[selectedMinervaOfferId];
  selectedClass = typeof selectedClass === 'function' ? selectedClass() : selectedClass;
  const arrLastIndex = selectedClass.items.length - 1;

  return (
    <Box className={classes.classAContainer}>
      <Grid container spacing={2} className={classes.breakDown}>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.includedHeader}>
            What's Included
          </Typography>
          <Grid container justifyContent="flex-start" alignItems="center" className={classes.costContainer}>
            {selectedClass.items.map((item, i) => (
              <Grid className="lineItem" key={i}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item style={{ display: 'flex' }}>
                    <Typography variant="body1">{item.item}</Typography>
                    {i === arrLastIndex && (
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginLeft: '8px',
                          backgroundColor: '#FFDB47',
                          padding: '2px 4px',
                        }}
                      >
                        <Hidden smDown>
                          <Typography variant="body1" style={{ fontSize: '14px', fontStyle: 'italic' }}>
                            Limited Offer!
                          </Typography>
                        </Hidden>
                        <AccessTimeIcon fontSize="small" className={classes.timeIcon} />
                        <Countdown
                          date={Date.now() + 599000}
                          renderer={({ minutes, seconds }) => (
                            <Typography variant="body1" className={classes.timer}>
                              0{minutes}:{seconds}
                            </Typography>
                          )}
                        />
                      </Box>
                    )}
                  </Grid>
                  <Grid item>
                    <Grid container direction="row">
                      <Typography
                        variant="body1"
                        className={classNames(classes.discountedPrice, classes[item.price.className])}
                      >
                        {item.price.amount}
                      </Typography>
                      <Typography variant="body1" className={classes.updatedPrice}>
                        {item.price.charge}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid className={`${classes.charge} lineItem`}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="body1">Today's Charge</Typography>
                </Grid>

                <Grid item>
                  <Grid container direction="row">
                    <Typography variant="body1" className={classes.discountedPrice}>{selectedClass.total}</Typography>
                    <Typography variant="body1" className={classes.updatedPrice}>{selectedClass.charge}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.chargeSubtext}>
            <Typography variant="body1">{selectedClass.chargeSubtext}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(ClassAContainer);
