import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import {
  ColumnCenter,
  SectionPaddingSide,
  SectionPaddingVertical,
} from '../../../components/elements/Layout';

export const ContentWidth = props => <Box width={{ xs: '100%', sm: '33rem' }} {...props} />;

export const TextPadding = props => <Box mt={{ xs: '1.5rem', md: '2rem' }} mb="2rem" {...props} />;

export const minHeightAdjustmentProps = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
};

export const QuizContentWrapper = ({ children, className }) => (
  <SectionPaddingVertical
    {...minHeightAdjustmentProps}
    pb='100vh'
    className={className}
  >
    <SectionPaddingSide>
      <ColumnCenter>{children}</ColumnCenter>
    </SectionPaddingSide>
  </SectionPaddingVertical>
);

QuizContentWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
