import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    stepBadge: {
      color: '#21375B',
      backgroundColor: 'rgba(255, 206, 0, 0.2)',
      fontFamily: 'Quicksand',
      fontWeight: '700',
      width: '120px',
      fontSize: '0.8rem',
      marginTop: '3rem',
    },
    headerText: {
      textAlign: 'center',
      margin: '15px 0px',
    },
    headerSubText: {
      fontFamily: 'Montserrat',
      fontSize: '1.25rem',
      textAlign: 'center',
    },
  })
);
