import { sample } from 'lodash';
import { createSelector } from 'reselect';

export const selectQuestions = state => state.appState.quiz.questions;
export const selectAnswers = state => state.appState.quiz.answers;
const selectAnsweredExternalQuestionId = state => state.appState.quiz.externalAnsweredQuestionId;
const selectCurrentQuestionIndexArg = (state, questionIndex) => questionIndex;
const selectQuestionIdArg = (state, questionId) => questionId;

const selectVisibleQuestions = createSelector(
  [selectQuestions, selectAnsweredExternalQuestionId],
  (questions, answeredExternalQuestionId) =>
    questions.filter(question => question.id !== answeredExternalQuestionId)
);

export const selectCurrentQuestion = createSelector(
  [selectVisibleQuestions, selectCurrentQuestionIndexArg],
  (questions, index) => questions[index - 1]
);

export const selectQuestionAnswer = createSelector(
  [selectAnswers, selectQuestionIdArg],
  (answers, questionId) => (questionId in answers ? answers[questionId] : null)
);

export const selectTotalQuestions = createSelector(
  [selectQuestions],
  questions => questions.length
);

export const totalVisibleQuestions = createSelector(
  [selectVisibleQuestions],
  questions => questions.length
);

export const selectTotalAnsweredQuestions = createSelector(
  [selectAnswers],
  answers => Object.keys(answers).length
);

export const selectCoderType = createSelector(
  [selectQuestions, selectAnswers],
  (questions, answers) => {
    // Organize all answers by answer coder type
    // { TINKERER: 3, CREATIVE: 1 }
    const answerCountByCoderType = Object.entries(answers).reduce((accum, current) => {
      const [questionId, selectedOptionIndex] = current;

      const question = questions.find(question => question.id === questionId);
      const { coderType } = question.options[selectedOptionIndex];

      if (accum[coderType]) {
        accum[coderType]++;
      } else {
        accum[coderType] = 1;
      }

      return accum;
    }, {});

    // Organize answer coder types by count. This is needed because if there is an equal
    // amount of answers for a coder type, we will randomize which coder they get
    // { TINKERER: 2, CREATIVE: 2 } -> { 2: [TINKERER, CREATIVE] }
    const coderTypeByAnswerCount = Object.entries(answerCountByCoderType).reduce(
      (accum, current) => {
        const [coderType, totalAnswerCount] = current;

        if (!accum[totalAnswerCount]) {
          accum[totalAnswerCount] = [];
        }

        accum[totalAnswerCount].push(coderType);

        return accum;
      },
      {}
    );

    const mostAnswers = Math.max(...Object.keys(coderTypeByAnswerCount));
    const coderTypesWithMostAnswers = coderTypeByAnswerCount[mostAnswers];

    // return a randomized coder type from answers if there is multiple same
    // max answers
    return sample(coderTypesWithMostAnswers);
  }
);
