import Cookies from 'universal-cookie';
import { isLocalhost } from './locationUtils';
/**
 * Get a cookie value
 * @param {string} cookieName - the cookie name
 * @returns {*}
 */
export const getCookieValue = (cookieName) => {
  let cookieValue = null;
  if (document.cookie) {
    let allCookies = document.cookie.split('; ');
    let cookieRow = allCookies.find(row => row.startsWith(`${cookieName}=`));
    if(cookieRow){
        cookieValue = cookieRow.split('=')[1];
    }
  } 
  return cookieValue;
}

export const cookies = new Cookies();

export const setCrossDomainCookie = (name, value, options = {}) => {
  cookies.set(name, value, {
    // using .codespark domain will allow access for all subdomains, which unfortunately also includes
    // the alpha and dev envs but it is only way for cookie to be accessed on the subdomains
    domain: isLocalhost() ? window.location.hostname : '.codespark.com',
    path: '/',
    ...options,
  });
};
