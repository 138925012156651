import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CodeSparkMuiTypography from '../../../../../../components/elements/CodeSparkMuiTypography';
import { getPlansDisplayData } from '../../../../../../components/elements/SubscriptionBlock/variants/plansDisplayData';
import {
  Loader,
  SelectPlanContainer,
  SelectPlanWidthContainer,
  SubscriptionPlansContainer,
} from '../../../../../../components/elements/SubscriptionBlock/variants/pageComponents';
import {
  getPlanOrder,
  sortPlans,
} from '../../../../../../components/elements/SubscriptionBlock/variants/utils';
import { usePlan } from '../../../../../../contexts/subscriptionPlans';
import {
  setMinervaSelectedClass,
  setMinervaSelectedClassId,
} from '../../../../../../redux/actions';
import { isQuarterlyPlan } from '../../../../../../utils/planUtils';
import { selectEnabledExperiment } from '../../../../../ExperimentsSetup/selectors';
import Faqs from '../../../../../Home/components/Faqs';
import StepChip from '../../../../MinervaPayment/components/StepChip';
import { ColumnCenter } from '../../../../../../components/elements/Layout';
import SubscriptionPlanCard from './SubscriptionPlanCard';
import { getPageCopy } from '../../../../../../components/elements/SubscriptionBlock/variants/subscriptionBlockData';
import { usePageViewEvent } from '../../../../../../hooks/useAnalyticsEvent';

const imgURL = `${process.env.REACT_APP_CLOUDFRONT_URL}/accounts/minervapayment`;

const useUpgradeOrCancelStyles = makeStyles(theme => ({
  termsLink: {
    fontFamily: 'inherit',
    [theme.breakpoints.up('md')]: {
      color: '#57AFF1',
      '&:hover': {
        color: '#8CC6F2',
      },
    },
  },
}));

function UpgradeOrCancel() {
  const classes = useUpgradeOrCancelStyles();

  return (
    <ColumnCenter mt="1rem">
      <CodeSparkMuiTypography component="span" variant="finePrint">
        Upgrade or cancel anytime.
      </CodeSparkMuiTypography>
      <CodeSparkMuiTypography component="span" variant="finePrint">
        <a
          href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/terms`}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.termsLink}
        >
          Terms and Conditions
        </a>{' '}
        apply
      </CodeSparkMuiTypography>
    </ColumnCenter>
  );
}

const useStyles = makeStyles(theme => ({
  layoutWrapper: {
    backgroundColor: '#ffffff',
    color: '#22252A',
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${imgURL}/foohead-pattern.svg)`,
      backgroundRepeat: 'repeat',
      color: '#ffffff',
    },
  },
  header: {
    marginTop: '1.5rem',
    color: '#ffffff',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      color: '#21375B',
      marginTop: '0.625rem',
    },
  },
  subHeader: {
    marginTop: '1.5rem',
    color: '#ffffff',
    textAlign: 'center',
    fontSize: '1.25rem',
    lineHeight: '1.9rem',
    '& > span': {
      fontFamily: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.625rem',
      color: '#000000',
      '& > span': {
        display: 'block',
      },
    },
  },
  widthContainer: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  subscriptionPlansContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    },
  },
  faqsContainer: {
    backgroundColor: '#F2F8FD',
  },
}));

export default function ClassCompareVariant() {
  const { plans: planOptions, setPlanTag } = usePlan();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const experiments = ['threeOndemandClasses'];
  const activeExperiment = useSelector(state => selectEnabledExperiment(state, experiments));
  const { header, subHeader } = getPageCopy(activeExperiment);

  usePageViewEvent('web_onboarding_screen_choose_plan');
  useEffect(() => {
    setPlanTag('3ondemandclassestest');
  }, []);

  const planOrder = getPlanOrder({ isMobile, enabledExperiment: activeExperiment });
  const planOptionsData = getPlansDisplayData({
    planOptions: sortPlans(planOptions, planOrder),
    baseComparisonPlan: planOptions.find(isQuarterlyPlan),
  });

  const handleSelectPlan = planId => {
    dispatch(setMinervaSelectedClassId(planId));
    const plan = planOptions.find(plan => plan.planId === planId);
    dispatch(setMinervaSelectedClass(plan));
    history.push('/tryclasses-signup');
  };

  if (!planOptions.length) {
    return <Loader className={classes.layoutWrapper} />;
  }

  return (
    <>
      <Box className={classes.layoutWrapper}>
        <SelectPlanContainer>
          <SelectPlanWidthContainer className={classes.widthContainer} alignItems="center">
            <StepChip label="STEP 1 OF 4" />

            <CodeSparkMuiTypography variant="h1" className={classes.header} parseText>
              {header}
            </CodeSparkMuiTypography>

            <CodeSparkMuiTypography
              variant={isMobile ? 's1' : 'h5'}
              className={classNames(classes.header, classes.subHeader)}
              parseText
            >
              {subHeader}
            </CodeSparkMuiTypography>

            <SubscriptionPlansContainer
              alignItems="flex-end"
              className={classes.subscriptionPlansContainer}
            >
              {planOptionsData.map(plan => {
                return (
                  <SubscriptionPlanCard
                    key={plan.planId}
                    onSelectPlan={handleSelectPlan}
                    {...plan}
                  />
                );
              })}
            </SubscriptionPlansContainer>
          </SelectPlanWidthContainer>

          <UpgradeOrCancel />
        </SelectPlanContainer>
      </Box>

      <Faqs className={classes.faqsContainer} />
    </>
  );
}
