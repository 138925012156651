import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logout } from '../../services/parse';
import { logoutMixpanel, trackPageMixpanel } from '../../services/functions';
import { logoutUser } from '../../redux/actions';
import { gtmPageviewEvent } from '../../services/google-tag-manager';

class Logout extends React.Component {
  componentDidMount() {
    trackPageMixpanel('WebPaymentsV2 Page: Logout');
    gtmPageviewEvent({
      pageName: 'WebPaymentsV2 Page: Logout',
      componentProps: this.props,
    });
    logout().then((success) => {
      logoutMixpanel();
      sessionStorage.clear();
      this.props.logoutUser();
      setTimeout(() => {
        this.props.history.push('/login');
      }, 0);
    });
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = { logoutUser };

export default withRouter(connect(null, mapDispatchToProps)(Logout));
