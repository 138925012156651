import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

const MinervaPaymentCompleteContext = React.createContext();

export function usePaymentComplete() {
  return useContext(MinervaPaymentCompleteContext);
}

export function MinervaPaymentCompleteProvider({ children }) {
  const selectedMinervaOfferId = useSelector((state) => state.appState.minervaSelectedClassId);
  let minervaOffer = useSelector((state) => state.appState.minervaSelectedClass);

  return (
    <MinervaPaymentCompleteContext.Provider value={{ minervaOffer, selectedMinervaOfferId }}>
      {children}
    </MinervaPaymentCompleteContext.Provider>
  );
}
