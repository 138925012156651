import { createReducer } from '@reduxjs/toolkit';
import { initialMinervaClassesState, minervaClassesReducers } from '../containers/MinervaMemberships/reducers';
import { initialQuizState, quizReducers } from '../containers/SignupQuiz/reducers';
import { isUS } from '../utils/geoIPUtils';

const initialState = {
  emailToSave: '',
  checkboxOptInToSave: false,
  userCountryToSave: '',
  referralToSave: '',
  ageToSave: '',
  genderToSave: '',
  experienceToSave: '',
  expectedNextPage: '',
  showNoUpgradePopup: false,
  webOfferCode: '',
  webOffer: '',
  requiresShipping: false,
  shippingAddress: false,
  promoCouponCode: '',
  // CVR-765
  initialSelectedOfferId: '',
  // END CVR-765
  monthlyOfferId: '',
  annualOfferId: '',
  emailErrorClass: '',
  passwordErrorClass: '',
  confirmPasswordErrorClass: '',
  referralErrorClass: '',
  isResubscribe: false,
  showBothSubTypes: false,
  // Minerva added class for the addclasses funnel
  minervaAddedClassId: '',
  // Minerva selected class for the tryclasses funnel
  ...initialMinervaClassesState,
  ...initialQuizState,
};

const appReducer = createReducer(initialState, {
  SAVE_EMAIL: (state, action) => {
    state.emailToSave = action.payload;
  },
  SAVE_CHECKBOX_OPT_IN: (state, action) => {
    state.checkboxOptInToSave = action.payload;
  },
  SAVE_USER_COUNTRY: (state, action) => {
    state.userCountryToSave = action.payload;

    // If user is in US, always set the optin to true in case user skips email wall
    if (isUS(action.payload)) {
      state.checkboxOptInToSave = true;
    }
  },
  SAVE_REFERRAL: (state, action) => {
    state.referralToSave = action.payload;
  },
  SAVE_KID_AGE: (state, action) => {
    state.ageToSave = action.payload;
  },
  SAVE_KID_GENDER: (state, action) => {
    state.genderToSave = action.payload;
  },
  SAVE_KID_EXPERIENCE: (state, action) => {
    state.experienceToSave = action.payload;
  },
  SAVE_EXPECTED_NEXT_PAGE: (state, action) => {
    state.expectedNextPage = action.payload;
  },
  SAVE_WEB_OFFER_CODE: (state, action) => {
    state.webOfferCode = action.payload;
  },
  SAVE_WEB_OFFER: (state, action) => {
    state.webOffer = action.payload;
  },
  SAVE_REQUIRES_SHIPPING: (state, action) => {
    state.requiresShipping = action.payload;
  },
  SAVE_SHIPPING_ADDRESS: (state, action) => {
    state.shippingAddress = action.payload;
  },
  SHOW_WARN_NO_UPGRADE: (state, action) => {
    state.showNoUpgradePopup = action.payload;
  },
  SAVE_PROMO_COUPON: (state, action) => {
    state.promoCouponCode = action.payload;
  },
  LOGOUT: (state, action) => {
    return initialState;
  },
  // CVR-765
  SET_INITIAL_SELECTED_OFFER_ID: (state, action) => {
    state.initialSelectedOfferId = action.payload;
  },
  // END CVR-765
  SET_MONTHLY_OFFER_ID: (state, action) => {
    state.monthlyOfferId = action.payload;
  },
  SET_ANNUAL_OFFER_ID: (state, action) => {
    state.annualOfferId = action.payload;
  },
  SET_RESUBSCRIBE: (state, action) => {
    state.isResubscribe = action.payload;
  },
  SHOW_BOTH_SUB_TYPES_TOGGLE: (state, action) => {
    state.showBothSubTypes = action.payload;
  },
  SET_EMAIL_ERROR_CLASS: (state, action) => {
    state.emailErrorClass = action.payload;
  },
  SET_PASSWORD_ERROR_CLASS: (state, action) => {
    state.passwordErrorClass = action.payload;
  },
  SET_CONFIRM_PASSWORD_ERROR_CLASS: (state, action) => {
    state.confirmPasswordErrorClass = action.payload;
  },
  SET_REFERRAL_ERROR_CLASS: (state, action) => {
    state.referralErrorClass = action.payload;
  },
  // Minerva added class for the addclasses funnel
  SET_MINERVA_ADDED_CLASS_ID: (state, action) => {
    state.minervaAddedClassId = action.payload;
  },
  // Minerva selected class for the tryclasses funnel
  ...minervaClassesReducers,
  ...quizReducers,
});

export default appReducer;
