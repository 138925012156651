import { Chip, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  stepChip: {
    backgroundColor: '#FFF0B3',
    width: '120px',
  },
  stepChipLabel: {
    fontFamily: 'QuicksandBold',
    color: '#21375B',
    fontSize: '0.875rem',
  },
}));

export default function StepChip(props) {
  const classes = useStyles();
  return (
    <Chip
      className={classes.stepChip}
      classes={{
        label: classes.stepChipLabel,
      }}
      {...props}
    />
  );
}
