import React from 'react';
import { Link } from 'react-router-dom';
import __isEmpty from 'lodash/isEmpty';
import mixpanel from 'mixpanel-browser';
import { BeginGlobalHeader } from '@learnwithhomer/begin-component-library';

import './Header.scss';

import { getCurrentUser } from '../../../services/parse';
import { useGetReferrerParams, useSubscribeToBeginHeaderEvents } from './hooks';
import { RenderUnleashVariant } from '../../../utils/experimentUtils';
import CodesparkLogo from '../CodesparkLogo';

const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: !__isEmpty(getCurrentUser())
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return ({ isLoggedIn: !__isEmpty(getCurrentUser()) });
  }

  // returns true if it's a girl scouts page, so we can show the GS logo
  isGirlScoutsPage(currentPage) {
    const girlScoutsPages = ['/girl-scouts-councils-request', '/girl-scouts-parents'];
    return girlScoutsPages.indexOf(currentPage) !== -1;
  }

  // Memberships v1 Page: Try For Free Top Nav button
  sendMixpanelEvent(module){
    const selectedOffer = JSON.parse(sessionStorage.getItem('selectedMinervaOffer'));
    mixpanel.track('Memberships v1 Page: Try For Free', {
      module: module,
      productId: selectedOffer?.planId,
      productionDuration: selectedOffer?.subscriptionDuration,
    });
  };

  render() {
    if (this.props.locationPath === '/' || this.props.locationPath === '/my-account') {
      return (
        <>
          <RenderUnleashVariant featureId="codeSparkBeginHeader" variantName="showHeader">
            {this.props.locationPath === '/my-account' ? (
              <BeginGlobalHeader  activeBrand="codespark" activeHref="https://codespark.com" urlParams={{ ...this.props.referrerParams }} />
            ) : null}
          </RenderUnleashVariant>
          <div className="global-header-wrapper not-fixed fixed-width">
            <div className="header-top-container single-link signup-funnel">
              <div className="logo-link">
                {this.state.isLoggedIn?
                <CodesparkLogo /> :
                <Link to="/"><CodesparkLogo /></Link>
              }
              </div>
              {
                this.state.isLoggedIn && (
                  <div className={`action-side not-mobile ${this.props.locationPath === '/my-account' ? '' : 'two-links'}`}>
                    <Link className="text-link" to="/logout">Logout</Link>
                  </div>
                )
              }
            </div>
          </div>
        </>
      );
    } else if (this.props.locationPath === '/gift') {
      return (
        <div className={`global-header-wrapper ${ this.props.isScrolling ? 'scrolling' : ''}`}>
          <div className="header-top-container single-link">
            <div className="logo-link with-padding">
              <Link to="/"><CodesparkLogo /></Link>
            </div>
            {
              this.state.isLoggedIn ? (
                <div className="action-side not-mobile">
                  <Link className="text-link" to="/my-account">My Account</Link>
                  <Link className="text-link" to="/logout">Logout</Link>
                </div>
              ) : (
                  <Link className="text-link not-mobile action-side" to="/promo">Redeem Code</Link>
                )
            }
          </div>
        </div>
      );
    } else if (this.isGirlScoutsPage(this.props.locationPath)) {
      return (
        <div className="global-header-wrapper not-fixed">
          <div className='logo-only'>
            <a href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/girl-scouts`}>
              <div className="gs-logo">
                <img src={`${imgURL}/global/girl-scouts/codespark_x_girlscouts_v2.png`} alt="codeSpark Academy" />
              </div>
            </a>
          </div>
        </div>
      );
    } else if (
      this.props.locationPath === '/signup-register' ||
      this.props.locationPath === '/signup-payment' ||
      this.props.locationPath === '/login' ||
      this.props.locationPath === '/choose-plan') {
      return (
         <div className="global-header-wrapper not-fixed">
          <div className="header-top-container single-link signup-funnel">
            <div className="logo-link">
             <Link to="/choose-plan"><CodesparkLogo /></Link>
            </div>
          </div>
        </div>
      );
    } else if (this.props.locationPath === '/signup-thankyou' ) {
      return (
        <div className="global-header-wrapper not-fixed fixed-width">
          <div className="header-top-container single-link signup-funnel myaccount-btn">
            <div className="logo-link myaccount-btn">
               <Link to="/my-account"><CodesparkLogo /></Link>
            </div>
            {this.state.isLoggedIn && (
              <div className='signup-thankyou-logo'>
                <Link className="text-link" to="/my-account">My Account</Link>
              </div>
            )}
          </div>
        </div>
      );
    } else if (this.props.locationPath === '/gympass') {
      return (
        <div className="global-header-wrapper not-fixed">
          <div className='logo-only'>
            <Link to="/gympass"><img src={`${imgURL}/global/logo/codespark_x_gympass3.png`} alt="codeSpark Academy x gympass" /></Link>
          </div>
        </div>
      )
    } else if (this.props.locationPath === '/promo' || this.props.locationPath === '/promo-payment' || this.props.locationPath === '/promo-thankyou') {
      return ( <div></div> )
    } else if (
      this.props.locationPath === '/tryclasses' ||
      this.props.locationPath === '/tryclasses-signup' ||
      this.props.locationPath === '/tryclasses-login' ||
      this.props.locationPath === '/tryclasses-schedule' ||
      this.props.locationPath === '/tryclasses-payment' ||
      this.props.locationPath === '/tryclasses-paymentapp' ||
      this.props.locationPath === '/tryclasses-thankyou' ||
      this.props.locationPath === '/tryclasses-thankyouapp' ||
      this.props.locationPath === '/tryclassesfree' ||
      this.props.locationPath === '/tryclassesfree-signup' ||
      this.props.locationPath === '/tryclassesfree-schedule' ||
      this.props.locationPath === '/addclasses'  ||
      this.props.locationPath === '/addclasses-payment'  ||
      this.props.locationPath === '/addclasses-thankyou-dropin' ||
      this.props.locationPath === '/addclasses-thankyou-semester'
    ) {
      return (
        <div className="global-header-wrapper minerva-header-container">
          <div className="logo-box">
            <CodesparkLogo alt="monster-logo" className="logo-image"/>
          </div>
          {/* <p className='class-expand'>Live online coding classes for kids</p> */}
        </div>
      );
    } else {
      return (
        <div className="global-header-wrapper fixed-width">
          <div className={`header-top-container ${this.state.isLoggedIn ? 'account-block' : 'logo-only'}`}>
            <div className="logo-link">
              {this.props.locationPath === '/complete'?
              <CodesparkLogo /> :
              <Link to="/"><CodesparkLogo /></Link>
            }
            </div>
            {
              (this.state.isLoggedIn &&
                (
                  this.props.locationPath !== '/checkout' &&
                  this.props.locationPath !== '/register/shipping' &&
                  this.props.locationPath !== '/complete'
                )
              ) &&
              <div className="action-side not-mobile">
                <Link className="text-link" to="/my-account">My Account</Link>
                <Link className="text-link" to="/logout">Logout</Link>
              </div>
            }
          </div>
        </div>
      );
    }
  }
}


export default props => {
  const referrerParams = useGetReferrerParams();
  useSubscribeToBeginHeaderEvents();
  return <Header {...props} referrerParams={referrerParams} />;
}

