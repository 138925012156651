export const SET_EXPERIMENT_STATUS = 'SET_EXPERIMENT_STATUS';

// full page and cross thefoos and fooaccounts experiment flags
export const EXPERIMENT_PAGE_DESIGN_FLAGS = [
  'redesignOndemandClasses',
  'premiumOndemandClasses',
  'premiumVsStandardDesign',
  'premiumOndemandClassesTrials',
];

// Experiments where the page copies changes due to different trial lengths or copy updates
export const EXPERIMENT_LABEL_FLAGS = ['updatedTrialLabels', 'differentPlanTrials'];

export const PLAN_SELECTOR_PAGE_KEY = 'planSelectorPage';
export const PAYMENT_PAGE_KEY = 'paymentPage';
export const BASE_KEY = 'base';
