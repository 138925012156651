import { makeStyles, createStyles } from '@material-ui/core/styles';
const imgURL = `${process.env.REACT_APP_CLOUDFRONT_URL}/accounts/minervapayment`;

export const useStyles = makeStyles((theme) =>
  createStyles({
    pageContainer: {
      backgroundImage: `url(${imgURL}/foohead-pattern.svg)`,
      backgroundRepeat: 'repeat',
      '& h1, h2, h3': {
        fontFamily: 'QuicksandBold',
      },
      '& h1': {
        color: 'rgba(33, 55, 91, 1)',
        fontSize: '28px',
        [theme.breakpoints.up('md')]: {
          fontSize: '48px',
        },
      },
      '& h3': {
        fontFamily: 'Montserrat',
        fontSize: '20px',
        lineHeight: '28px',
        color: 'rgba(33, 55, 91, 1)',
        '& .heading': {
          paddingTop: '65px',
          marginBottom: '25px',
        },
      },
    },
    containerWrapper: {
      backgroundColor: '#FFFFFF',
      [theme.breakpoints.up('md')]: {
        borderRadius: '20px',
        marginTop: '60px',
        marginBottom: '60px',
        padding: '0 60px',
      },
    },
    greetingHeader: {
      [theme.breakpoints.up('md')]: {
        marginTop: '60px',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '40px',
      },
    },
    stepContainer: {
      marginBottom: '30px',
    },
    contentContainer: {
      '& h1, h2, h3, p': {
        fontFamily: 'Montserrat',
      },
      '& p': {
        fontSize: '14px',
        lineHeight: '22px',
        marginTop: '15px',
        [theme.breakpoints.up('md')]: {
          fontSize: '18px',
        },
      },
      '& .stepTitle': {
        fontWeight: 'bold',
        fontFamily: 'Quicksand',
        fontSize: '24px',
        [theme.breakpoints.up('md')]: {
          fontSize: '36px',
        },
      },
      '& .store img': {
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
          width: '228px',
        },
      },
      marginBottom: '30px',
    },
    stepNumber: {
      '& .MuiAvatar-root': {
        fontSize: '32px',
        color: '#21375B',
        backgroundColor: '#FFCE00',
        fontWeight: '700',
        fontFamily: 'QuicksandBold',
      },
    },
    stepsDetailContainer: {
      [theme.breakpoints.down('sm')]: {
        maxWidth: '290px',
      },
      '& .MuiTypography-body1': {
        [theme.breakpoints.up('md')]: {
          fontSize: '1rem',
        },
      },
    },
    paymentImgContainer: {
      marginTop: '1rem',
      [theme.breakpoints.up('sm')]: {
        padding: '0 2rem 0 0',
      },
    },
    searchBar: {
      width: '228px',
      marginTop: '15px',
      height: '48px',
      border: '1px solid #E6E6E6',
      borderRadius: '4px',
      '& p': {
        fontSize: '1.2rem',
        fontWeight: '400',
        marginTop: '0px',
      },
    },
  })
);
