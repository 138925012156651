import React, { useState, lazy, Suspense, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ClassAccordion from './ClassAccordion';

const ManagePlan = lazy(() => import('./ManagePlanComponent'));
const ManageAccount = lazy(() => import('./ManageAccountComponent'));
const BillingHistory = lazy(() => import('./BillingHistoryComponent'));
const NotificationSettings = lazy(() => import('./NotificationSettingsComponent'));
const PlayInstructions = lazy(() => import('./PlayInstructionsComponent'));

const renderLoader = () => <h1>Loading</h1>;

export default function AccountTabComponent(props) {
  const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;
  const hasPlan = props.subDetails.plan || props.subDetails.classesPlan ? true : false;

  const hasTwoPlans = props.subDetails.plan && props.subDetails.classesPlan ? true : false;

  const [currentTab, setCurrentTab] = useState(hasPlan ? 1 : 0);

  const onChangeTabs = (tabIndex) => {
    if (tabIndex === currentTab) {
      return;
    }

    if (hasPlan && tabIndex === 0) {
      setCurrentTab(1);
    } else {
      setCurrentTab(tabIndex);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!props.loading && hasPlan) {
      setCurrentTab(1);
    }
  }, [props.loading]);

  return (
    <Tabs selectedIndex={currentTab} onSelect={(tabIndex) => onChangeTabs(tabIndex)}>
      <div className="grid-x grid-x-padding">
        <div className="sidebar-navigation medium-4">
          <TabList className="account-links">
            <Tab style={{ backgroundColor: '#f2f8fd' }}>
              <img className="tab-icon" src={`${imgURL}/accounts/credit_card_tabicon.svg`} alt="Manage plan icon" />
              <h4>Manage Plans</h4>
              {currentTab === 0 || currentTab === 1 || currentTab === 2 ? (
                <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
              ) : (
                <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
              )}
            </Tab>

            {props.subDetails.plan &&
              (hasTwoPlans ? (
                <Tab className={currentTab === 0 || currentTab === 1 || currentTab === 2 ? 'sub-show' : 'sub-noshow'}>
                  <h4 className="tab-subplan-names">{props.subDetails.plan.planName}</h4>
                  {currentTab === 1 ? (
                    <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
                  ) : (
                    <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
                  )}
                </Tab>
              ) : (
                <Tab className={currentTab === 0 || currentTab === 1 ? 'sub-show' : 'sub-noshow'}>
                  <h4 className="tab-subplan-names">{props.subDetails.plan.planName}</h4>
                  {currentTab === 1 ? (
                    <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
                  ) : (
                    <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
                  )}
                </Tab>
              ))}

            {props.subDetails.classesPlan &&
              (hasTwoPlans ? (
                <Tab className={currentTab === 0 || currentTab === 1 || currentTab === 2 ? 'sub-show' : 'sub-noshow'}>
                  <h4 className="tab-subplan-names">{props.subDetails.classesPlan.planName}</h4>
                  {currentTab === 2 ? (
                    <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
                  ) : (
                    <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
                  )}
                </Tab>
              ) : (
                <Tab className={currentTab === 0 || currentTab === 1 ? 'sub-show' : 'sub-noshow'}>
                  <h4 className="tab-subplan-names">{props.subDetails.classesPlan.planName}</h4>
                  {currentTab === 1 ? (
                    <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
                  ) : (
                    <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
                  )}
                </Tab>
              ))}

            <Tab>
              <img className="tab-icon" src={`${imgURL}/accounts/profile_tabicon.svg`} alt="Manage accounts icon" />
              <h4>Manage Account</h4>
              {currentTab === 3 ? (
                <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
              ) : (
                <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
              )}
            </Tab>
            <Tab>
              <img className="tab-icon" src={`${imgURL}/accounts/reciept_tabicon.svg`} alt="Billing history icon" />
              <h4>Billing History</h4>
              {currentTab === 4 ? (
                <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
              ) : (
                <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
              )}
            </Tab>
            <Tab>
              <img className="tab-icon" src={`${imgURL}/accounts/notifications_tabicon.svg`} alt="Notifications icon" />
              <h4>Notification Settings</h4>
              {currentTab === 5 ? (
                <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
              ) : (
                <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
              )}
            </Tab>
            <Tab>
              <img className="tab-icon" src={`${imgURL}/accounts/question_tabicon.svg`} alt="Play icon" />
              <h4>How to Play</h4>
              {currentTab === 6 ? (
                <img className="toggle-icon" src={`${imgURL}/accounts/minus_blue_tab.svg`} alt="current option" />
              ) : (
                <img className="toggle-icon" src={`${imgURL}/accounts/plus_dark_tab.svg`} alt="open option" />
              )}
            </Tab>
          </TabList>
        </div>
        <div className="account-content medium-8 small-6">
          <TabPanel id="managePlan">
            <div className="account-detail-item">
              <Suspense fallback={renderLoader()}>
                <ManagePlan
                  subDetails={props.subDetails}
                  annualPlan={props.annualPlan}
                  monthlyPlan={props.monthlyPlan}
                  annualSavings={props.annualSavings}
                  userId={props.user.objectId}
                  hasActiveSubscription={props.hasActiveSubscription}
                  hasExpirationDate={props.hasExpirationDate}
                  planExpiresAt={props.planExpiresAt}
                  canUpgrade={props.canUpgrade}
                />
              </Suspense>
            </div>
          </TabPanel>
          {props.subDetails.plan && (
            <TabPanel id="managePlan">
              <div className="account-detail-item">
                <Suspense fallback={renderLoader()}>
                  <ManagePlan
                    subDetails={props.subDetails}
                    annualPlan={props.annualPlan}
                    monthlyPlan={props.monthlyPlan}
                    annualSavings={props.annualSavings}
                    userId={props.user.objectId}
                    hasActiveSubscription={props.hasActiveSubscription}
                    hasExpirationDate={props.hasExpirationDate}
                    planExpiresAt={props.planExpiresAt}
                    canUpgrade={props.canUpgrade}
                  />
                </Suspense>
              </div>
            </TabPanel>
          )}
          {props.subDetails.classesPlan && (
            <TabPanel>
              <div className="account-detail-item">
                <Suspense fallback={renderLoader()}>
                  <ClassAccordion
                    subDetails={props.subDetails}
                    annualPlan={props.annualPlan}
                    monthlyPlan={props.monthlyPlan}
                    annualSavings={props.annualSavings}
                    userId={props.user.objectId}
                    hasActiveSubscription={props.hasActiveSubscription}
                    hasExpirationDate={props.hasExpirationDate}
                    planExpiresAt={props.planExpiresAt}
                    canUpgrade={props.canUpgrade}
                  />
                </Suspense>
              </div>
            </TabPanel>
          )}
          <TabPanel id="manageAccount">
            <div className="account-detail-item">
              <Suspense fallback={renderLoader()}>
                <ManageAccount subDetails={props.subDetails} hasActiveSubscription={props.hasActiveSubscription} />
              </Suspense>
            </div>
          </TabPanel>
          <TabPanel id="billingHistory">
            <Suspense fallback={renderLoader()}>
              <BillingHistory
                paymentHistory={props.paymentHistory}
                storeName={props.subDetails.plan?.store}
                hasActiveSubscription={props.hasActiveSubscription}
              />
            </Suspense>
          </TabPanel>
          <TabPanel id="notifications" className="notifications-container">
            <p>
              <span className="bold">Account email:</span>&nbsp; {props.subDetails.customerData?.email}
            </p>
            <Suspense fallback={renderLoader()}>
              <NotificationSettings />
            </Suspense>
          </TabPanel>
          <TabPanel id="howToPlay">
            <Suspense fallback={renderLoader()}>
              <PlayInstructions />
            </Suspense>
          </TabPanel>
        </div>
      </div>
    </Tabs>
  );
}
