import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';
import { subscribe } from '@learnwithhomer/begin-component-library';


export function useSubscribeToBeginHeaderEvents() {
    const subcribeHandler = (data) => {
        // console.log('data ➡', data);
        const event = data.event;
        delete data.event;
        mixpanel.track(event, data);
    }

    useEffect(() => {
        return subscribe(data => subcribeHandler(data), { replay: true });
    }, []);
}

export function useGetReferrerParams() {
    const [params, setParams] = useState({});

    useEffect(() => {
        const distinct_id_mp = mixpanel.get_distinct_id();
        const user_id_mp = mixpanel.get_property('$user_id');
        setParams({ distinct_id_mp, user_id_mp });
    }, []);

    // remove falsey values
    return Object.keys(params).reduce((acc, key) => {
        if (params[key]) {
            acc[key] = params[key];
        }
        return acc;
    }, {});
}