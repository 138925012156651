import { styled } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import ThemedMuiButton from '../../../ThemedMuiButton';
import ButtonContainer from '../../../ThemedMuiButton/ButtonContainer';
import { PageHeader, SelectPlanSection, SubHeader } from '../pageComponents';
import SubscriptionPlansContainerVariant from '../SubscriptionPlansContainer';
import {
  PremiumOndemandClassesContainer,
  SelectedPlanLegal,
  SelectPlanContainer,
  SelectPlanWidthContainer,
} from '../V3';
import SubscriptionPlanCard from '../V3/SubscriptionPlanCard';
import PlansComparison from './PlansComparison';

const PremiumVsStandardPlansContainer = styled(PremiumOndemandClassesContainer)(({ theme }) => ({
  maxWidth: '56.125rem',
  marginTop: '2rem',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    marginTop: '3.0625rem',
  },
}));

function PremiumVsStandardDesign({
  pageCopy,
  variant,
  planOptionsData,
  handleSelectPlan,
  selectedOffer,
  nextLocation,
  ctaLabel,
}) {
  const { header, subHeader, valueProps } = pageCopy;

  return (
    <SelectPlanSection>
      <SelectPlanContainer>
        <SelectPlanWidthContainer alignItems="center">
          <PageHeader>{header}</PageHeader>
          {subHeader && <SubHeader align="center">{subHeader}</SubHeader>}

          <PremiumVsStandardPlansContainer>
            <SubscriptionPlansContainerVariant variant={variant}>
              {planOptionsData.map(planData => {
                const {
                  plan: { planId },
                } = planData;
                return (
                  <SubscriptionPlanCard
                    key={planId}
                    selected={planId === selectedOffer?.planId}
                    onClick={() => handleSelectPlan(planId)}
                    {...planData}
                  />
                );
              })}
            </SubscriptionPlansContainerVariant>

            <PlansComparison planOptionsData={planOptionsData} valueProps={valueProps} />

            <ButtonContainer mt={{ xs: '1rem', md: '1.5rem' }}>
              <Link to={nextLocation}>
                <ThemedMuiButton color="primary2">{ctaLabel}</ThemedMuiButton>
              </Link>
            </ButtonContainer>

            {selectedOffer && <SelectedPlanLegal selectedOffer={selectedOffer} />}
          </PremiumVsStandardPlansContainer>
        </SelectPlanWidthContainer>
      </SelectPlanContainer>
    </SelectPlanSection>
  );
}

export default PremiumVsStandardDesign;
