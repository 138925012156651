import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

const useClassHeaderStyles = makeStyles((theme) => ({
  headerWrapper: {
    textAlign: 'center',
  },
  stepChip: {
    background: 'rgba(255, 245, 204, 1)',
    height: '31px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: '5px 15px',
    borderRadius: '100px',
    fontSize: '14px',
    fontFamily: 'QuicksandBold',
    margin: '0 auto',
  },
  heading: {
    color: '#21375B',
    margin: '15px 0',
    '& h2': {
      fontFamily: 'QuicksandBold',
    },
  },
  subHeading: {
    margin: '0 0 2rem 0',
    fontFamily: 'Montserrat',
    lineHeight: '28px',
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
}));

export default function ClassHeader({ items }) {
  const classes = useClassHeaderStyles();
  return items.map((item, i) => (
    <Grid container direction="column" className={classes.headerWrapper} key={i}>
      <Typography
        variant={i === 1 ? 'h2' : null}
        className={i === 0 ? classes.stepChip : i === 1 ? classes.heading : i === 2 ? classes.subHeading : ''}
      >
        {item}
      </Typography>
    </Grid>
  ));
}
