import React, { memo } from 'react';

import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

function HeaderInfo(props) {
  const classes = useStyles();

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Chip label="STEP 2 OF 3" className={classes.stepBadge} />
      <Typography variant="h1" className={classes.headerText}>
        Save payment info
      </Typography>
    </Grid>
  );
}

export default memo(HeaderInfo);
