import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    glitchContainer: {
      backgroundColor: 'white',
    },
    contentContainer: {
      backgroundColor: '#F2F8FD',
      padding: '0 20px',
    },
    awardsContainer: {
      marginTop: 20,
      marginBottom: 30,
    },
    statsContainer: {
      padding: '0 100px',
      marginBottom: 30,
    },
  })
);
