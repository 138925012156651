import Parse from 'parse';
import braintree from 'braintree-web';

Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_JS_KEY);
Parse.serverURL = process.env.REACT_APP_PARSE_URL;

export const createBraintreeInstance = (captchaToken) => { 
  return new Promise((resolve, reject) => {
    const params = {};
    if(captchaToken){
      params.captchaToken = captchaToken;
    }
    Parse.Cloud.run('GetClientToken', params)
    .then((authorization) => {
      braintree.client.create({
        authorization: authorization
      }, (error, clientInstance) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(clientInstance);
      });
    }).catch((error) => {
      reject(error);
    });
  });
};

export const createBraintreeHostedFields = (options, callback) => {
  return braintree.hostedFields.create(options, callback);
};
export const createPaypalInstance = (options, callback) => {
  braintree.paypal.create(options, callback);
};

export const createApplePayInstance = (options, callback) => {
  braintree.applePay.create(options, callback);
}

export const createGooglePayInstance = (options, callback) => {
  braintree.googlePayment.create(options, callback);
}
