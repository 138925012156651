/** 
 * Gateway handler for HubSpot API
 * All requests to HubSpot API are made through this gateway
 * @todo: create unit tests for this
 */

/**
 * Add Customer to HubSpot
 * @param {<{email:string,productId:string,productDuration:string}>} params Input parameters
 * @returns {*}
 */
export async function addCustomer(params) {
    return await fetch(process.env.REACT_APP_HUBSPOT_API, {
        method: 'POST',
        body: JSON.stringify(params)
    });
};
