// Redux
export const SET_QUIZ_QUESTIONS = 'SET_QUIZ_QUESTIONS';
export const SET_QUIZ_QUESTION_ANSWER = 'SET_QUIZ_QUESTION_ANSWER';
export const SET_EXTERNAL_ANSWERED_QUESTION_ID = 'SET_EXTERNAL_ANSWERED_QUESTION_ID';

// Question type mapping
export const TINKERER = 'TINKERER';
export const CREATIVE = 'CREATIVE';
export const PROBLEM_SOLVER = 'PROBLEM_SOLVER';
export const COLLABORATOR = 'COLLABORATOR';

export const FADE_TIMEOUT = 300;
