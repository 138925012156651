import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  legal: props => ({
    fontSize: '12px',
    lineHeight: '15px',
    color: '#8E9091',
    marginBottom: '32px',
    marginTop: '2px',
    textAlign: props.center ? 'center' : 'left',
    '& a': {
      fontWeight: 700,
      color: '#8E9091',
    },
  }),
});

function Legal({ center }) {
  const classes = useStyles({ center });

  return (
    <p className={classes.legal}>
      For more about how we use your information, see our{' '}
      <span>
        <a
          target="_blank"
          href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/privacy`}
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </span>
    </p>
  );
}

export default Legal;
