import { Box, Grid, styled } from '@material-ui/core';
import React from 'react';
import { getPlanTrialLabelData } from '../../../../planLabelData';
import { colors } from '../../../../theme/constants';
import { botClassName } from '../../../../utils/e2eBotUtils';
import { templateStringFormat } from '../../../../utils/stringUtils';
import CodeSparkMuiTypography from '../../CodeSparkMuiTypography';
import ThemedMuiButton from '../../ThemedMuiButton';
import ButtonContainer from '../../ThemedMuiButton/ButtonContainer';
import { BOT_CTA_BUTTON_NAME, createPlanBotClass } from './botUtils';

const PlanGridItem = styled(({ addTopMargin, ...props }) => <Grid {...props} />)(
  ({ theme, addTopMargin }) => ({
    marginTop: addTopMargin ? '3.5rem' : 'initial',
    [theme.breakpoints.down('sm')]: {
      marginTop: 'initial',
    },
  })
);

const PlanCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.primary.white,
  borderRadius: '0.625rem',
  color: colors.primary.grey5,
  overflow: 'hidden',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    height: '100%',
  },
}));

const PlanType = styled(Box)({
  backgroundColor: colors.primary.lightGrey,
  color: colors.primary.darkBlue,
  textAlign: 'center',
  padding: '0.5rem 0',
});

const PlanHighlight = styled(Box)(({ theme }) => ({
  backgroundColor: colors.secondary.yellow,
  textAlign: 'center',
  padding: '1rem 0',
}));

const PlanTypeText = styled(CodeSparkMuiTypography)({
  fontSize: '1.25rem',
  textTransform: 'uppercase',
});

const PlanBody = styled(Box)(({ theme }) => ({
  padding: '1rem 1rem 2rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    height: '100%',
  },
}));

const PlanTitle = styled(CodeSparkMuiTypography)({
  color: colors.primary.blue,
  fontSize: '1.5rem',
});

const PlanPrice = styled(CodeSparkMuiTypography)({
  fontSize: '1.25rem',
  marginTop: '1rem',
});

const BodyText = styled(CodeSparkMuiTypography)({
  marginTop: '1rem',
});

const PlanIncludesList = styled('ul')({
  margin: '0.5rem 0 0 1rem',
});

const ValueProp = styled('li')({
  marginTop: '0.5rem',
  '&::marker': {
    fontSize: '1.5rem',
    lineHeight: '1rem',
    color: colors.primary.pink5,
  },
});

const Subnote = styled(CodeSparkMuiTypography)({
  color: colors.primary.grey5,
});

export function SubscriptionPlanCardV2({
  flagText,
  isHighlight,
  title,
  priceDisplay,
  billingLegal,
  planIncludes,
  ctaLabel,
  planType,
  onClick,
  hasHighlightedPlan,
  plan,
}) {
  const addTopMargin = hasHighlightedPlan && !isHighlight;
  const ctaLabelFormatted = templateStringFormat(ctaLabel, plan);

  return (
    <PlanGridItem container item xs={12} md={4} addTopMargin={addTopMargin}>
      <PlanCard className={createPlanBotClass(plan)}>
        {isHighlight && (
          <PlanHighlight>
            <CodeSparkMuiTypography variant="h6">{flagText}</CodeSparkMuiTypography>
          </PlanHighlight>
        )}

        <PlanType>
          <PlanTypeText variant="h3">{planType || 'Basic'}</PlanTypeText>
        </PlanType>

        <PlanBody>
          <Box>
            <PlanTitle variant="h4">{title}</PlanTitle>

            <PlanPrice variant="h3">{priceDisplay}</PlanPrice>

            <BodyText variant="body3">{billingLegal}</BodyText>

            {planIncludes && (
              <>
                <BodyText variant="body3">Plan Includes</BodyText>

                <PlanIncludesList>
                  {planIncludes.map(({ value, subnote }, i) => {
                    return (
                      <ValueProp key={`plan_includes_${i}`}>
                        <CodeSparkMuiTypography variant="body3" component="span">
                          {value}
                        </CodeSparkMuiTypography>

                        {subnote && <Subnote variant="finePrint">{subnote}</Subnote>}
                      </ValueProp>
                    );
                  })}
                </PlanIncludesList>
              </>
            )}
          </Box>

          <ButtonContainer>
            <ThemedMuiButton
              color="primary2"
              onClick={onClick}
              className={botClassName(BOT_CTA_BUTTON_NAME)}
              fullWidth
            >
              {ctaLabelFormatted}
            </ThemedMuiButton>
          </ButtonContainer>
        </PlanBody>
      </PlanCard>
    </PlanGridItem>
  );
}

export default SubscriptionPlanCardV2;
