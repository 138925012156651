import React, { memo } from 'react';
import ClassAContainer from './classAContainer';

function PaymentInfo(props) {
  return (
    <React.Fragment>
      <ClassAContainer />
    </React.Fragment>
  );
}

export default memo(PaymentInfo);
