import { useFlags, useFlagsStatus } from '@unleash/proxy-client-react';
import React, { useState, useContext, useEffect, createContext, useCallback } from 'react';
import queryString from 'query-string';
import {
  COOKIES_KEY_PLAN_TAG,
  COOKIES_KEY_SELECTED_OFFER_ID,
  COOKIE_KEY_IS_EXPERIMENT_PLAN_TAG,
} from '../constants/cookies';
import { getEnabledExperiments, getVariantPayload } from '../featureFlags';
import { getJSONAnnualAndMonthlyPlans } from '../services/parse';
import { findPlanById, getHighestPricedPlan } from '../utils/planUtils';
import { useSendEventWhenFFReady } from '../hooks/useAnalyticsEvent';
import { getPageUrl } from '../utils/locationUtils';
import { cookies, setCrossDomainCookie } from '../utils/cookieUtils';
import { getAnalyticsPlanType } from '../services/analytics';
import { gtmEvent } from '../services/google-tag-manager';

const determineInitialSelectedOffer = (planOptions, initialOfferId) => {
  if (!planOptions || !planOptions.length) {
    return null;
  }

  if (initialOfferId) {
    const plan = planOptions.find(plan => plan.planId === initialOfferId);
    if (plan) {
      return plan;
    }
  }

  const highestPricedPlan = getHighestPricedPlan(planOptions);
  return highestPricedPlan;
};

function useSelectedOffer(planOptions) {
  const { selected_plan: selectedPlanQuery } = queryString.parse(window.location.search);
  const savedSelectedOfferId = cookies.get(COOKIES_KEY_SELECTED_OFFER_ID);
  // Use plan id from query or saved in cookies if available
  const initialOfferId = selectedPlanQuery || savedSelectedOfferId;

  const [selectedOfferId, setSelectedOfferId] = useState(initialOfferId);
  const sendEventWhenFFReady = useSendEventWhenFFReady();

  const selectedOffer = findPlanById(planOptions, selectedOfferId);

  const sendPlanSelectedEvent = useCallback(
    (planId, extraProps = {}) => {
      const offer = findPlanById(planOptions, planId) || {};
      const gtmEventData = {
        action: 'plan_selected',
        pageName: getPageUrl(window.location),
        plan_id: planId.planId || planId,
        subscriptionPlan: offer.subscriptionDuration,
      };
      if (offer.trialDurationDays) gtmEventData.trialLengthDays = offer.trialDurationDays;
      gtmEvent(gtmEventData);
      sendEventWhenFFReady(
        'web_plan_selected',
        {
          app: 'web-site',
          plan_type_selected: offer && getAnalyticsPlanType(offer),
          page_URL: getPageUrl(window.location),
          ...extraProps,
        },
        true
      );
    },
    [planOptions, sendEventWhenFFReady]
  );

  const setNewOffer = useCallback(planId => {
    setCrossDomainCookie(COOKIES_KEY_SELECTED_OFFER_ID, planId);
    setSelectedOfferId(planId);
  }, []);

  const setOfferAndSendAnalytics = useCallback(
    (planId, analyticsProps) => {
      setNewOffer(planId);
      sendPlanSelectedEvent(planId, analyticsProps);
    },
    [sendPlanSelectedEvent, setNewOffer]
  );

  useEffect(() => {
    // When experiment changes planTag, we should update the selected offer
    if (planOptions.length) {
      const initialSelectedOffer = determineInitialSelectedOffer(planOptions, selectedOfferId);
      setNewOffer(initialSelectedOffer.planId);
    }
  }, [planOptions, setNewOffer, selectedOfferId]);

  return {
    selectedOffer,
    setSelectedOffer: setOfferAndSendAnalytics,
    selectedOfferId,
    sendPlanSelectedEvent,
  };
}

// Setup plans depending on enabled a/b variant and planTag in variable payload.
// Due to fooaccounts being on separate subdomain, we are capturing and extra
// constant - COOKIE_KEY_IS_EXPERIMENT_PLAN_TAG, that will be used to determine if
// the saved planTag in the cookies is set by an experiment. When an experiment is
// turned off, this cookie will be deleted and the default plans should show.
// Should a user override the activePlanTag via query param, COOKIE_KEY_IS_EXPERIMENT_PLAN_TAG
// will not be set so it does not get wiped out. An experiment will always have overwrite the
// planTag though, if it exists
function useABPlans(setPlanTag, setAndPersistPlanTag) {
  const flags = useFlags();
  const { flagsReady } = useFlagsStatus();

  useEffect(() => {
    if (flagsReady) {
      const enabledExperiments = getEnabledExperiments(flags);

      let hasVariantPlanOverride = false;
      enabledExperiments.forEach(enabledExperiment => {
        const variantPayload = getVariantPayload(enabledExperiment.variant);

        if (variantPayload && variantPayload.planTag) {
          hasVariantPlanOverride = true;
          setAndPersistPlanTag(variantPayload.planTag);
          setCrossDomainCookie(COOKIE_KEY_IS_EXPERIMENT_PLAN_TAG, 'true');
        }
      });

      const isCookieExperimentPlanTag = !!cookies.get(COOKIE_KEY_IS_EXPERIMENT_PLAN_TAG);
      // Reset plans to default if they're not in a pricing experiment or if there is no override in cookies
      if (!hasVariantPlanOverride && isCookieExperimentPlanTag) {
        setPlanTag(DEFAULT_TAG);
        cookies.remove(COOKIE_KEY_IS_EXPERIMENT_PLAN_TAG);
      }
    }
  }, [flags, setPlanTag, setAndPersistPlanTag, flagsReady]);
}

export const SubscriptionPlanContext = createContext();

export function usePlan() {
  return useContext(SubscriptionPlanContext);
}

export const DEFAULT_TAG = 'default';
export function SubscriptionPlanProvider({ children }) {
  const { activePlanTag } = queryString.parse(window.location.search);
  const initialPlanTag = activePlanTag || cookies.get(COOKIES_KEY_PLAN_TAG) || DEFAULT_TAG;

  const [planTag, setPlanTag] = useState(initialPlanTag);
  const [{ isLoading, plans }, setPlans] = useState({
    isLoading: false,
    plans: [],
  });
  const selectedOfferProps = useSelectedOffer(plans);

  const updateAndResetPlanTag = useCallback(tag => {
    cookies.remove(COOKIES_KEY_PLAN_TAG);
    setPlanTag(tag);
  }, []);
  const updateAndPersistPlanTag = useCallback(tag => {
    setCrossDomainCookie(COOKIES_KEY_PLAN_TAG, tag);
    setPlanTag(tag);
  }, []);

  useEffect(() => {
    setPlans({
      isLoading: true,
      plans: [],
    });
  }, [planTag]);

  useEffect(() => {
    // Set the activePlanTag from query param to cookies so it can be persisted
    if (activePlanTag) {
      setCrossDomainCookie(COOKIES_KEY_PLAN_TAG, activePlanTag);
    }
  }, [activePlanTag]);

  useABPlans(updateAndResetPlanTag, updateAndPersistPlanTag);

  useEffect(() => {
    // If planTag is updated while the previous plans are still loading (async),
    // we want to prevent the previous data from being saved to state.
    // There is a race condition if the second request returns data back
    // before the first request and the plans are updated with wrong planTag data
    // For example: default plans are initally loaded, then experiment loads and changes the plan
    let isCanceled = false;

    const loadSubscriptionPlans = async () => {
      const params = { tag: planTag === DEFAULT_TAG ? null : planTag };
      const newPlans = await getJSONAnnualAndMonthlyPlans(params);

      if (!isCanceled) {
        setPlans({
          isLoading: false,
          plans: newPlans,
        });
      }
    };

    loadSubscriptionPlans();

    // Set the previous request to true to prevent it's data from being saved to state
    // using closure
    return () => {
      isCanceled = true;
    };
  }, [planTag]);

  return (
    <SubscriptionPlanContext.Provider
      value={{
        planTag,
        plans,
        isLoading,
        setPlanTag: updateAndResetPlanTag,
        setAndPersistPlanTag: updateAndPersistPlanTag,
        ...selectedOfferProps,
      }}
    >
      {children}
    </SubscriptionPlanContext.Provider>
  );
}
