import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import HeaderGreeting from './components/HeaderGreeting';
import Content from './components/Content';
import SignupCompleteLayout from './components/SignupCompleteLayout';
import { MinervaPaymentCompleteProvider } from './providers';
import { sendGTMEvent, sendPageAnalyticsEvent } from './providers/service';
import { useSelector } from 'react-redux';

function SignupComplete(props) {
  const selectedMinervaOfferId = useSelector((state) => state.appState.minervaSelectedClassId);
  const minervaOffer = useSelector((state) => state.appState.minervaSelectedClass);

  useEffect(() => {
    window.scrollTo(0, 0);

    sendPageAnalyticsEvent(selectedMinervaOfferId, minervaOffer);

    sendGTMEvent({
      deviceType: props.deviceInfo.device,
      deviceOS: props.deviceInfo.browser.os,
      pathName: props.pathname,
      selectedOffer: minervaOffer,
    });
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <MinervaPaymentCompleteProvider>
        <SignupCompleteLayout headerGreeting={<HeaderGreeting />} contentSlot={<Content />} />
      </MinervaPaymentCompleteProvider>
    </React.Fragment>
  );
}

export default withRouter(SignupComplete);
