import { Box, Fade } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FADE_TIMEOUT } from '../constants';

// Wraps components and handles fading when route changes only
// Wrapper component must be able to accept the Fade props as stated in material ui docs
function FadeWrapper({ render, onFade, initial = true, WrapperComponent = Box }) {
  const [visible, setVisible] = useState(initial);
  const currentArgsRef = useRef();

  const handleNextTransition = (...args) => {
    currentArgsRef.current = args;
    setVisible(false);
  };

  const handleExit = () => {
    onFade(...currentArgsRef.current);
    setVisible(true);
  };

  const props = {
    onFadeOut: handleNextTransition,
    visible,
  };

  return (
    <Fade timeout={FADE_TIMEOUT} in={visible} onExited={handleExit}>
      <WrapperComponent>{render(props)}</WrapperComponent>
    </Fade>
  );
}

FadeWrapper.propTypes = {
  render: PropTypes.elementType.isRequired,
  onFade: PropTypes.func.isRequired,
  initial: PropTypes.bool,
  WrapperComponent: PropTypes.elementType,
};

export default FadeWrapper;
