import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    expressCheckoutContainer: {
      '& .MuiTypography-body1': {
        fontSize: '1rem',
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        color: '#000000',
      },
    },
    paymentBtnContainer: {
      width: '100%',
      padding: '0 35px',
      whiteSpace: 'nowrap',
    },
    applePayBtn: {
      margin: '5px 0',
      backgroundColor: '#000000',
      color: 'white',
      '&:hover': {
        backgroundColor: '#282626',
      },
      '& .MuiButton-label': {
        fontFamily: 'Montserrat',
        fontSize: '1.2rem',
        textTransform: 'none',
      },
      [theme.breakpoints.down('md')]: {
        margin: '10px 0',
      },
    },
    googlePayBtn: {
      margin: '5px 0',
      backgroundColor: '#FFFFFF',
      color: '#5F6368',
      '& .MuiButton-label': {
        fontFamily: 'Montserrat',
        fontSize: '1.2rem',
        textTransform: 'none',
      },
      [theme.breakpoints.down('md')]: {
        margin: '10px 0',
      },
    },
    paypalIcon: {
      marginLeft: '14px',
    },
    paypalBtn: {
      borderRadius: '8px',
      margin: '5px 0',
      backgroundColor: '#0F2C77',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#0F2C77',
      },
      '& .MuiButton-label': {
        fontFamily: 'Montserrat',
        fontSize: '1.2rem',
        textTransform: 'none',
        [theme.breakpoints.down('xs')]: {
          fontSize: '0.9rem',
        },
      },
      [theme.breakpoints.down('md')]: {
        margin: '10px 0',
      },
    },
  })
);
