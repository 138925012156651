const MOBILE_KEY = 'mobile';
const DESKTOP_KEY = 'desktop';

const DEFAULT_ORDER_DESKTOP = {
  monthly: 0,
  annual: 1,
};

const DEFAULT_ORDER_MOBILE = {
  monthly: 1,
  annual: 0,
};

const PREMIUM_PLAN_ORDER = {
  annual_minerva_ondemand: 0,
  annual_minerva_ondemand_120: 0,
  annual: 1,
  monthly: 2,
};

const LIFETIME_PLAN_ORDER = {
  annual: 0,
  lifetime: 1,
  monthly: 2,
};

const experimentToOrderMapping = {
  thirdPlan: {
    [DESKTOP_KEY]: PREMIUM_PLAN_ORDER,
    [MOBILE_KEY]: PREMIUM_PLAN_ORDER,
  },
  lifetimePlan: {
    [DESKTOP_KEY]: LIFETIME_PLAN_ORDER,
    [MOBILE_KEY]: LIFETIME_PLAN_ORDER,
  },
  threeOndemandClasses: {
    [DESKTOP_KEY]: {
      minerva_quarterly_ondemand_35: 0,
      minerva_biannual_ondemand_65: 1,
      minerva_annual_ondemand_120_30trial: 2,
    },
    [MOBILE_KEY]: {
      minerva_quarterly_ondemand_35: 2,
      minerva_biannual_ondemand_65: 1,
      minerva_annual_ondemand_120_30trial: 0,
    },
  },
  redesignOndemandClasses: {
    [DESKTOP_KEY]: {
      Annual_7DayTrial_7999_restricted: 0,
      minerva_annual_ondemand_120_7trial: 1,
    },
    [MOBILE_KEY]: {
      Annual_7DayTrial_7999_restricted: 1,
      minerva_annual_ondemand_120_7trial: 0,
    },
  },
  premiumOndemandClasses: {
    [DESKTOP_KEY]: {
      minerva_annual_ondemand_100: 0,
      minerva_quarterly_ondemand_41: 1,
    },
    [MOBILE_KEY]: {
      minerva_annual_ondemand_100: 0,
      minerva_quarterly_ondemand_41: 1,
    },
  },
  premiumVsStandardDesign: {
    [DESKTOP_KEY]: {
      minerva_annual_ondemand_100: 0,
      quarterly_7trial_41_restricted: 1,
      minerva_24month_ondemand_100: 0,
      Annual_7DayTrial_6999_restricted: 1,
    },
    [MOBILE_KEY]: {
      minerva_annual_ondemand_100: 0,
      quarterly_7trial_41_restricted: 1,
      minerva_24month_ondemand_100: 0,
      Annual_7DayTrial_6999_restricted: 1,
    },
  },
  premiumOndemandClassesTrials: {
    [DESKTOP_KEY]: {
      minerva_annual_ondemand_90_7trial: 0,
      minerva_annual_ondemand_90_14trial: 0,
      minerva_monthly_ondemand_15_7trial: 1,
    },
    [MOBILE_KEY]: {
      minerva_annual_ondemand_90_7trial: 0,
      minerva_annual_ondemand_90_14trial: 0,
      minerva_monthly_ondemand_15_7trial: 1,
    },
  }
};

export const sortPlans = (plans, order) => {
  return plans.slice().sort((a, b) => order[a.planDataKey] - order[b.planDataKey]);
};
export const getPlanOrder = ({ isMobile, enabledExperiment }) => {
  const viewport = isMobile ? MOBILE_KEY : DESKTOP_KEY;

  if (enabledExperiment && experimentToOrderMapping[enabledExperiment]) {
    return experimentToOrderMapping[enabledExperiment][viewport];
  }

  return isMobile ? DEFAULT_ORDER_MOBILE : DEFAULT_ORDER_DESKTOP;
};
