import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { usePlan } from '../../../contexts/subscriptionPlans';
import SubscriptionBlockRedesign from './variants/Redesign';

function SubscriptionBlock(props) {
  const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;
  const {
    plans: planOptions,
    setSelectedOffer,
    selectedOfferId,
    selectedOffer,
    sendPlanSelectedEvent,
  } = usePlan();
  const [initialAnalyticsSent, setInitialAnalyticsSent] = useState(false);
  // CVR-921
  const planSelectorButtonsEnabled = useSelector(state => state.experiments.planSelectorSelectPlanButtons);
  // END CVR-921

  const selectPlanOption = (planId) => {
    setSelectedOffer(planId, {
      'page_selected': 'plan_selection',
    });
  }

  useEffect(() => {
    // When plan selector page first loads, send this event once for the preselected plan
    if (!initialAnalyticsSent && !planSelectorButtonsEnabled && selectedOfferId) {
      sendPlanSelectedEvent(selectedOfferId);
      setInitialAnalyticsSent(true);
    }
  }, [selectedOfferId, sendPlanSelectedEvent, initialAnalyticsSent, planSelectorButtonsEnabled]);

  if (props.useRedesign) {
    return (
      <SubscriptionBlockRedesign
        selectPlanOption={selectPlanOption}
        nextLocation={props.nextLocation}
        selectedOffer={selectedOffer}
        planOptions={planOptions}
      />
    )
  }

  return (
    <div id="select-plan-section" className="plan-choice-section">
      <div className="plan-choice-box grid-y">
        <div className="choice-header">
          <h2 className="text-center">Start your free trial today!</h2>
        </div>
        <div className="choice-blurb">
          <p className="text-center">Choose a plan to get your kids coding. Try codeSpark completely free for 7 days. Cancel anytime.</p>
        </div>
        <div className="subscription-option-cards grid-x grid-margin-x align-spaced text-center">
          {planOptions.map((plan, index) => (
            <button
              key={index}
              id={`plan-${plan.descriptionKey.toLowerCase()}`}
              className={`subcard ${plan.descriptionKey} ${selectedOfferId === plan.planId ? 'selected' : ''} small-12 medium-6`}
              onClick={() => selectPlanOption(plan.planId)}
            >
              <h2 className="option-title">{plan.descriptionKey} Plan</h2>
              <h2 className="monthly-price">
                {plan.descriptionKey === 'Annual' ? (plan.price / 12).toFixed(2) : plan.price.toFixed(2)}
                <span className="month">/mo</span>
              </h2>
              <p>
                {`${(plan.descriptionKey === 'Annual' ? `${plan.price} ` : '')}Billed ${plan.descriptionKey === 'Annual' ? 'Annually' : 'Monthly'}`}
              </p>
              {plan.descriptionKey === 'Annual' && <div className="savings-button">SAVE 33%</div>}
            </button>
          ))}
        </div>
        <Link to={props.nextLocation}>
          <div className="cta-button large">
            <button
              data-event-category="button"
              data-event-action="signup-01-choose-plan"
              data-event-label="Sign up for your free trial"
              data-on="click"
            >
              Sign up for your free trial
              <img className="basic-arrow" src={`${imgURL}/global/icons/basic_arrow_white.svg`} alt="Action arrow" />
            </button>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default SubscriptionBlock;