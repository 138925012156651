import { useEffect } from 'react';
import React from 'react';
import { Chip } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { trackPageMixpanel } from '../../../services/functions';
import '../MinervaSignup/MinervaSignup.scss';

function FreeClass() {
  useEffect(() => {
    trackPageMixpanel('Memberships v1.4 Page: Schedule');

    const sawyerWidget = document.getElementById('sawyer');

    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://hisawyer.com/embed/UTWDH8W6eEBYjG1r7a5XvILuR42T8Dpd.js';
    scriptTag.async = true;
    scriptTag.id = 'SA_UTWDH8W6eEBYjG1r7a5XvILuR42T8Dpd';
    scriptTag.setAttribute('data-sawyertools', 'sawyertools');
    sawyerWidget.appendChild(scriptTag);

    return () => {
      sawyerWidget.removeChild(scriptTag);
    };
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="minerva-signup-page" style={{ maxWidth: '100%' }}>
          <div className="stepBadge-container">
            <Chip label="STEP 2 OF 2" className="stepBadge" />
          </div>
          <div className="minerva-signup-title">
            <h2>
              Sign up for your
              <br className="break-line" /> free class
            </h2>
          </div>
          <div id="sawyer"></div>
        </div>
      </div>
    </>
  );
}

export default withRouter(FreeClass);
