import { capitalize } from 'lodash';

const DAY_INTERVAL = 'day';
const generateSharedTrialLabels = (quantity, interval = DAY_INTERVAL) => {
  const capitalizedInterval = capitalize(interval);
  return {
    trialQuantity: quantity,
    trialInterval: interval,
    trialIntervalCapitalize: capitalizedInterval,
    trialLabel: `${quantity}-${capitalizedInterval} FREE Trial`,
    trialLabelLower: `${quantity}-${interval} FREE trial`,
    freeTrialPeriod: `FREE ${quantity}-${interval} trial`,
  };
};

// Associated with the plan tag used for on pages before selects a plan
const planTagToLabelMapping = {
  default: {
    ...generateSharedTrialLabels(7, DAY_INTERVAL),
    trialTerm: 'week',
  },
  '30daytrial': {
    ...generateSharedTrialLabels(30, DAY_INTERVAL),
    trialTerm: 'month',
  },
};

const DEFAULT_PLAN_TAG = 'default';
export const getPlanTagTrialLabelData = planTag =>
  planTagToLabelMapping[planTag] || planTagToLabelMapping[DEFAULT_PLAN_TAG];

export const getPlanTrialLabelData = ({ trialDurationDays }) => {
  return generateSharedTrialLabels(trialDurationDays, DAY_INTERVAL);
};
