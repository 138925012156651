import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  // Increasing specificity to overwrite the input and label styling from the sass styling
  emailInputWrapper: {
    marginBottom: '16px',
    '& .MuiTextField-root': {
      // label styling
      '& .MuiInputLabel-root': {
        color: '#8E9091',

        '&.Mui-focused': {
          color: '#231F20',
        },

        '&.Mui-error': {
          color: theme.palette.error.main,
        },
      },

      // add gray border when focused unless it has error, then keep error color
      '& .Mui-focused': {
        '&:not(.Mui-error)': {
          color: '#231F20',

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#8E9091',
          },
        },
      },
    },
  },

  notchedOutline: {
    borderColor: '#8E9091',
  },
}));

function EmailTextInput(props) {
  const classes = useStyles();

  return (
    <div className={classes.emailInputWrapper}>
      <TextField
        name="email"
        type="email"
        label="Email"
        variant="outlined"
        autoComplete="email"
        fullWidth
        InputProps={{
          classes: {
            notchedOutline: classes.notchedOutline,
          },
        }}
        {...props}
      />
    </div>
  );
}

export default EmailTextInput;
