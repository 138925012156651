/**
 * Config variables for AddClassesPayment
 */

/**
 * Payment Info Items Header
 */
export const paymentInclusions = {
  'fourClass': {
    charge: '$10.00',
    chargeSubtext: '$54.99/month after 30 day promo',
    bottomText: '$54.99/month after 30-day promo period.',
    items: [
      {
        'item': '4 online classes per month',
        'price': {
          'amount': '$54.99',
          'charge': '$10.00',
          'isDiscounted': true,
        },
      },
      {
        'item': 'Coding activity sheets',
        'price': {
          'amount': 'INCLUDED',
          'isDiscounted': false,
        },
      },
    ],
  },
  'oneClass': {
    charge: '$1.00',
    chargeSubtext: '$14.99/month after 30 day promo',
    bottomText: '$14.99/month after 30-day promo period.',
    items: [
      {
        'item': '1 online class per month',
        'price': {
          'amount': '$14.99',
          'charge': '$1.00',
          'isDiscounted': true,
        },
      },
      {
        'item': 'Coding activity sheets',
        'price': {
          'amount': 'INCLUDED',
          'isDiscounted': false,
        },
      },
    ],
  },
};

/**
 * Field Styles
 */
export const fieldStyles = {
  label: {
    'font-size': '1rem',
  },
  input: {
    color: 'rgba(137, 137, 137, 0.9)',
    'font-size': '18px',
  },
  '::-webkit-input-placeholder': {
    color: 'rgba(137, 137, 137, 0.9)',
  },
  ':-moz-placeholder': {
    color: 'rgba(137, 137, 137, 0.9)',
  },
  '::-moz-placeholder': {
    color: 'rgba(137, 137, 137, 0.9)',
  },
  ':-ms-input-placeholder': {
    color: 'rgba(137, 137, 137, 0.9)',
  },
};

/**
 * Field Settings.
 */
export const fieldSettings = {
  cardholderName: {
    selector: '#cardholder-name',
    placeholder: 'Name on credit card',
  },
  number: {
    selector: '#card-number',
    placeholder: 'Card Number',
    maxlength: 19,
  },
  cvv: {
    selector: '#cvv',
    placeholder: 'CVV',
  },
  expirationDate: {
    selector: '#expiration-date',
    placeholder: 'MM/YY',
  },
  postalCode: {
    selector: '#postal-code',
    placeholder: 'ZIP',
  },
};

/**
 * Tokenized Error Messages
 */
export const tokenizedErrorMessages = {
  HOSTED_FIELDS_FIELDS_EMPTY: 'Please fill out the form.',
  HOSTED_FIELDS_FIELDS_INVALID:
    'Invalid card information. Please correct the error - if the decline persists, please contact your bank.',
  HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE: 'This payment method already exists.',
  HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED:
    'Invalid card information. Please correct the error - if the decline persists, please contact your bank.',
  PAYPAL_POPUP_CLOSED: 'Payment could not be completed since PayPal window closed.',
  HOSTED_FIELDS_FAILED_TOKENIZATION: 'An error seems to have occurred during payment processing. Please try again.',
  HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR:
    'An error seems to have occurred during payment processing. Please try again',
};

/**
 * Payment Error Codes and it's corresponding sessages
 */
export const errorCodes = {
  '2000': 'Your bank is unwilling to accept the transaction. Please contact your bank.',
  '2001': 'The account does not have sufficient funds to cover the transaction amount.',
  '2002':
    'The attempted transaction exceeds the limit of the account. Please contact your bank or use a different payment method.',
  '2003':
    'The attempted transaction exceeds the limit of the account. Please contact your bank or use a different payment method.',
  '2004': 'Card is expired. Please use a different payment method.',
  '2005':
    'Invalid payment method or made a typo. Please correct the error – if the decline persists, please contact your bank.',
  '2006':
    'Invalid payment method or made a typo. Please correct the error – if the decline persists, please contact your bank.',
  '2007': 'The submitted card number is not on file with the card-issuing bank. Please contact your bank.',
  '2008':
    'The submitted card number does not include the proper number of digits. Please correct the error – if the decline persists, please contact your bank.',
  '2009': 'The card number does not correlate to an existing card-issuing bank. Please contact your bank.',
  '2010':
    'Invalid security code or made a typo in their card information. Please correct the error – if the decline persists, please contact your bank.',
  '2011': 'Processor Declined. Please use an alternative payment method.',
  '2012': 'The card used has been reported as lost. Please contact your bank.',
  '2013': 'The card used has been reported as stolen. Please contact your bank.',
  '2014': 'There was an issue processing your card – Please contact your bank.',
  '2015': 'Your bank is declining the transaction. Please contact your bank or use a different payment method.',
  '2016': 'Payment declined. Duplicate transaction.',
  '2017':
    'You requested a cancellation of a single transaction – Please contact support@codespark.com for more information.',
  '2018':
    'You requested the cancellation of a recurring transaction or subscription – Please contact support@codespark.com for more information.',
  '2019': 'Your bank declined the transaction. Please contact your bank for more information.',
  '2020': 'Payment declined. Please contact your bank for more information.',
  '2021': 'Your bank is declining the transaction. Please contact your bank or use a different payment method.',
  '2022':
    'The submitted card has expired or been reported lost and a new card has been issued. Reach out to your customer to obtain updated card information.',
  '2023': 'Payment declined. Please contact support@codespark.com for more information.',
  '2024': 'Payment declined. Please contact support@codespark.com for more information.',
  '2025': 'Payment declined. Please contact support@codespark.com for more information.',
  '2026': 'Payment declined. Please contact support@codespark.com for more information.',
  '2027': 'Payment declined. Please contact your bank for more information.',
  '2028': 'Payment declined. Please contact support@codespark.com for more information.',
  '2029': 'There is a problem with the submitted card. Please use a different payment method.',
  '2030': 'Payment declined. Please contact support@codespark.com for more information.',
  '2031':
    'The cardholder’s bank does not support $0.00 card verifications. contact support@codespark.com with questions.',
  '2032': 'Surcharge amount not permitted on this card. Please use a different payment method.',
  '2033': 'An error occurred when communicating with the processor. Please contact your bank.',
  '2034': 'An error occurred and the intended transaction was not completed. Attempt the transaction again.',
  '2035': 'Payment declined. Please contact support@codespark.com for more information.',
  '2036': 'Payment declined. Please contact support@codespark.com for more information.',
  '2037': 'Processor declined. Please contact support@codespark.com for more information.',
  '2038': 'Your bank is unwilling to accept the transaction. Please contact your bank.',
  '2039':
    'The authorization code was not found or not provided. Please try again – if the decline persists, please contact your bank.',
  '2040': 'Processor declined. Please try again. If problem persists, please contact support@codespark.com.',
  '2041': 'The card used for this transaction requires customer approval – please contact your bank.',
  '2042': 'Processor declined. Please try again. If problem persists, please contact support@codespark.com.',
  '2043': 'The card-issuing bank will not allow this transaction. Please contact your bank.',
  '2044':
    'The card-issuing bank has declined this transaction. Please try again – if the decline persists, please contact your bank.',
  '2045': 'Processor declined. Please try again. If problem persists, please contact support@codespark.com.',
  '2046': 'Your bank is unwilling to accept the transaction. Please contact your bank or PayPal for more details.',
  '2047': 'Your card has been reported as lost or stolen. Please use a different payment method.',
  '2048': 'Payment declined. Please contact support@codespark.com for more information.',
  '2049': 'Payment declined. Please contact support@codespark.com for more information.',
  '2050': 'There is an issue with your card or bank. Please contact your bank or use a different payment method.',
  '2051':
    'There is an issue with your card or bank.  Please try again – if the decline persists, please use a different card or payment method.',
  '2053': 'The card used was reported lost or stolen. Please contact your bank or use a different payment method.',
  '2054': 'Refund declined. Please contact your bank for more information.',
  '2055': 'Payment declined. Please contact support@codespark.com for more information.',
  '2056': 'Payment declined. Please contact support@codespark.com for more information.',
  '2057': 'The customer will need to contact their issuing bank for more information.',
  '2058': "The attempted card can't be processed. Please use a different payment method.",
  '2059':
    'PayPal was unable to verify this transaction. Please contact PayPal for more information or use a different payment method.',
  '2060':
    'Both the AVS and CVV checks failed for this transaction. Please contact PayPal for more information or use a different payment method.',
  '2061':
    'There may be an issue with the your card or a temporary issue at the card-issuing bank. Please try again – if the decline persists, please use a different card or payment method.',
  '2062':
    'There may be an issue with the your card or a temporary issue at the card-issuing bank. Please try again – if the decline persists, please use a different card or payment method.',
  '2063': 'Processor does not accept this payment type. Please contact support@codespark.com.',
  '2064': 'Processor does not accept this currency. Please contact support@codespark.com.',
  '2065':
    "PayPal requires that refunds are issued within 180 days of the sale. This refund can't be successfully processed.",
  '2066':
    'Contact PayPal’s Support team to resolve this issue with your account. Then, you can attempt the transaction again.',
  '2067': 'The PayPal authorization is no longer valid.',
  '2068':
    "You'll need to contact PayPal’s Support team to resolve an issue with your account. Once resolved, you can attempt to process the transaction again.",
  '2069': 'Payment declined. Duplicate PayPal transaction.',
  '2070':
    'You have revoked authorization for this payment method. Please contact support@codespark.com or use a different payment method.',
  '2071': 'You have not finalized setup of their PayPal account. Please use a different payment method.',
  '2072': 'Invalid PayPal account.',
  '2073': "PayPal can't validate this transaction. contact support@codespark.com for more details.",
  '2074': 'Your payment method associated with your PayPal account was declined. Please a different payment method.',
  '2075':
    "Your PayPal account can't be used for transactions at this time. Please contact PayPal for more information or use a different payment method.",
  '2076': 'Processor declined. Please contact PayPal for more information or use a different payment method.',
  '2077':
    'PayPal has declined this transaction due to risk limitations. Please contact PayPal’s Support team to resolve this issue.',
  '2079': 'Please contact support@codespark.com to resolve an issue with your account.',
  '2081': 'Unsupported Pending Verification response from PayPal. Please contact support@codespark.com.',
  '2082':
    'This transaction requires the customer to be a resident of the same country as the merchant. Please use a different payment method.',
  '2083':
    'This transaction requires the payer to provide a valid phone number. Please contact PayPal or use a different payment method.',
  '2084': 'Processor Declined. Please complete your PayPal account information.',
  '2085': 'PayPal declined. Please contact support@codespark.com for help.',
  '2086':
    'Your PayPal account does not allow a transaction amount this large. Please contact PayPal to resolve this issue.',
  '2088': 'Currency is not currently supported. Please contact support@codespark.com.',
  '2092':
    "The processor is unable to provide a definitive answer about the customer's bank account. Please try a different US bank account verification method.",
  '2093': 'The PayPal payment method has either expired or been canceled.',
  '2094': 'Your integration is likely making PayPal calls out of sequence. Check the error response for more details.',
  '2095':
    'Once a PayPal transaction is partially refunded, all subsequent refunds must also be partial refunds for the remaining amount or less. Full refunds are not allowed after a PayPal transaction has been partially refunded.',
  '2096': "PayPal buyer account can't be the same as the seller account.",
  '2097': 'PayPal authorization amount is greater than the allowed limit on the order.',
  '2098': "The customer's bank is unwilling to accept the transaction.Please contact your bank.",
  '2099': "The customer's bank is unwilling to accept the transaction.Please contact your bank.",
  '3000': 'Processor declined. Please try again – if the decline persists, contact support@codespark.com.',
};
