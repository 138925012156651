import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';

import App from './containers/App';
import store from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
// Import CSS reset and Global Styles
import './global-styles.scss';

import ExperimentsSetup from './containers/ExperimentsSetup';
import { SubscriptionPlanProvider } from './contexts/subscriptionPlans';
import { FeatureFlagsProvider } from './featureFlags';
import { gtmEvent } from './services/google-tag-manager';

// Import Language Provider
// import LanguageProvider from 'containers/LanguageProvider';

// Import i18n messages
// import { translationMessages } from './i18n';

//init 3rd party tracking services
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayer: {},
};

if (process.env.NODE_ENV !== 'production') {
  tagManagerArgs['auth'] = 'cIbY-s0H-cms-MiYD9moEg';
  tagManagerArgs['preview'] = 'env-5';
}
TagManager.initialize(tagManagerArgs);
gtmEvent({ action: 'optimize.activate' });

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

let persistor = persistStore(store);

const render = (messages) => {
  ReactDOM.render(
    <FeatureFlagsProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <SubscriptionPlanProvider>
              <ExperimentsSetup>
              {/* <LanguageProvider messages={messages}> */}
                <App />
              {/* </LanguageProvider> */}
              </ExperimentsSetup>
            </SubscriptionPlanProvider>
          </Router>
        </PersistGate>
      </Provider>
    </FeatureFlagsProvider>,
    document.getElementById('root')
  );
};

render();

// hot-reload when component file changes
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept(App, render);
}

// run utm_last_touch code
function getQueryParam(url, param) {
  // Expects a raw URL
  param = param.replace(/[[]/, "\[").replace(/[]]/, "\]");
  const regexS = "[\?&]" + param + "=([^&#]*)";
  const regex = new RegExp(regexS);
  const results = regex.exec(url);
  if (results === null || (results && typeof (results[1]) !== 'string' && results[1].length)) {
    return '';
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
  }
}

function campaignParams() {
  const campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ')
  let kw = '';
  const params = {};
  const first_params = {};

  let index;
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      params[campaign_keywords[index] + ' [last touch]'] = kw;
    }
  }
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      first_params[campaign_keywords[index] + ' [first touch]'] = kw;
    }
  }

  mixpanel.people.set(params);
  mixpanel.people.set_once(first_params);
  mixpanel.register(params);
}

campaignParams();

// Hot reloadable translation json files
// if (module.hot) {
//   // modules.hot.accept does not accept dynamic dependencies,
//   // have to be constants at compile-time
//   module.hot.accept('./i18n', () => {
//     render(translationMessages);
//   });
// }

// Chunked polyfill for browsers without Intl support
// if (!window.Intl) {
//   (new Promise((resolve) => {
//     resolve(import('intl'));
//   }))
//     .then(() => Promise.all([
//       import('intl/locale-data/jsonp/en.js'),
//     ]))
//     .then(() => render(translationMessages))
//     .catch((err) => {
//       throw err;
//     });
// } else {
//   render(translationMessages);
// }


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
