import { makeStyles, createStyles } from '@material-ui/core/styles';

const imgURL = `${process.env.REACT_APP_CLOUDFRONT_URL}/accounts/minervapayment`;

export const useStyles = makeStyles((theme) =>
  createStyles({
    desktopPageContainer: {
      padding: '45px 176px',
      maxWidth: '1540px',
      margin: '0 auto',
      '& h1, h2, h3': {
        fontFamily: 'QuicksandBold',
      },
      '& h1': {
        color: '#21375B',
        fontSize: '2.65rem',
      },
      '& h3': {
        fontSize: '1.5rem',
        lineHeight: '1.85rem',
        '& .heading': {
          paddingTop: '65px',
          marginBottom: '25px',
        },
      },
    },
    desktopBackground: {
      backgroundImage: `url(${imgURL}/foohead-pattern.svg)`,
      backgroundRepeat: 'repeat',
    },
    pageContainer: {
      backgroundColor: '#F2F8FD',
      [theme.breakpoints.up('sm')]: { backgroundColor: 'white' },
      '& h1, h2, h3': {
        fontFamily: 'QuicksandBold',
      },
      '& h1': {
        color: '#21375B',
        fontSize: '2.65rem',
        [theme.breakpoints.down('sm')]: {
          fontSize: '28px',
        },
      },
      '& h3': {
        fontSize: '1.5rem',
        lineHeight: '1.85rem',
        '& .heading': {
          paddingTop: '65px',
          marginBottom: '25px',
        },
      },
    },
    containerWrapper: {
      backgroundColor: '#FFFFFF',
      [theme.breakpoints.down('xs')]: { padding: '20px' },
      [theme.breakpoints.up('lg')]: {
        borderRadius: '20px',
        marginTop: '60px',
        marginBottom: '60px',
        maxHeight: '960px',
        '&.MuiGrid-item': {
          padding: '60px',
        },
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: '60px',
      },
    },
  })
);
