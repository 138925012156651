import React, { memo } from 'react';
import { Typography, Divider, Grid, Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useStyles } from './styles';

function AwardsModule(props) {
  const classes = useStyles();
  const imgURL = `${process.env.REACT_APP_CLOUDFRONT_URL}/accounts/minervapayment`;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  function FooImg() {
    return <img src={`${imgURL}/image_Glitch_anticipation 1@2x.svg`} alt="" className={classes.fooImgContainer} />;
  }

  function Header() {
    return (
      <Typography className={classes.footerTitle}>Expert-guided online classes from the #1 kids coding app</Typography>
    );
  }

  function Rewards() {
    return (
      <Grid container spacing={'lg' ? 2 : 0} className={classes.awardContainer}>
        <Grid item xs={5} lg={12} className={classes.rewardImgContainer}>
          <img src={`${imgURL}/parents_choice_gold.svg`} alt="" />
        </Grid>
        <Grid item xs={7} lg={12}>
          <Typography className={classes.footerSubtitle}>Parents’ Choice Gold Award Winner</Typography>
        </Grid>
      </Grid>
    );
  }
  function TrustPilotReview() {
    return (
      <Grid container direction="column" spacing={1} className={classes.trustpilotWrapper}>
        <Grid item>
          <img src={`${imgURL}/trustpilot-Logo@2x.svg`} alt="trustpilot" className={classes.imgContainer} />
        </Grid>
        <Grid item>
          <img src={`${imgURL}/trustpilot-4.5star@2x.svg`} alt="star 4.5" className={classes.imgContainer} />
        </Grid>
      </Grid>
    );
  }

  function Achievements() {
    return (
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} sm={4} lg={12}>
          <Grid container direction="column" spacing={2}>
            <Grid item className={classes.numberContainer}>
              12.6M
            </Grid>
            <Grid item className={`${classes.footerSubtitle} ${classes.bottomSub}`}>
              games and stories created in 2021
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} lg={12}>
          <Grid container direction="column" spacing={2}>
            <Grid item className={classes.numberContainer}>
              55%
            </Grid>
            <Grid item className={`${classes.footerSubtitle} ${classes.bottomSub}`}>
              increase in problem-solving confidence
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} lg={12}>
          <Grid container direction="column" spacing={2}>
            <Grid item className={classes.numberContainer}>
              120K
            </Grid>
            <Grid item className={`${classes.footerSubtitle} ${classes.bottomSub}`}>
              educators worldwide use codeSpark
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="column" className={classes.footerContainer}>
      <FooImg />
      <Grid item>
        {isDesktop || isMobile ? (
          <Box className={classes.awardWrapper}>
            <Header />
            <Divider className={classes.divider} />
            <Rewards />
            <Achievements />
            <TrustPilotReview />
          </Box>
        ) : (
          <Box className={classes.awardWrapper}>
            <Header />
            <Divider className={classes.divider} />
            <Grid container className={classes.tabletContainer}>
              <Grid item sm={6}>
                <Rewards />
              </Grid>
              <Grid item sm={6}>
                <TrustPilotReview />
              </Grid>
            </Grid>
            <Achievements />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default memo(AwardsModule);
