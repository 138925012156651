import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './SignupComplete.scss';
import { trackPageMixpanel } from '../../../services/functions';
import { sendFacebookEvent} from '../../../services/facebook-capi';
import { usePageViewEvent } from '../../../hooks/useAnalyticsEvent';
import { gtmPageviewEvent } from '../../../services/google-tag-manager';

function SignupComplete(props) {
  const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    // grab variantID for google optimize from sessionStorage and pass it to mixpanel
    const variant = sessionStorage.getItem('variantId');
    trackPageMixpanel('WebPaymentsV3 Page: Checkout Complete', {
      'Optimize experiment variant': `${process.env.REACT_APP_EXPERIMENT_ID} - variant ${variant}`
    });
    sendFacebookEvent({
      event_name: 'Visit',
      event_source_url: window.location.href,
      custom_data: {screen: 'Checkout Complete'}
    });
    sendFacebookEvent({
      event_name: 'Onboarding Screen',
      event_source_url: window.location.href,
      custom_data: {screen: 'Checkout Complete'}
    });
    gtmPageviewEvent({
      pageName: 'WebPaymentsV3 Page: Checkout Complete',
      componentProps: {
        ...props,
        location: { pathname: props.pathname },
      },
    });
  }, [props]);

  usePageViewEvent('web_onboarding_screen_thank_you');

  return (
    <div className='signup-complete-container text-center'>
      <h3 className="heading">Thanks for enrolling in codeSpark Academy!</h3>
      <h1>Start playing in 3 easy steps:</h1>
      <div className="numbered-step"><h2>1</h2></div>
      <h2>Download the app</h2>
      <div className="app-store-badges">
        <a
          className="apple"
          href="https://itunes.apple.com/us/app/codespark-academy-foos-coding/id923441570?mt=8"
          target="_blank"
          rel="noopener noreferrer"
          data-event-category="app-download"
          data-event-action="signup-complete"
          data-event-label="ios"
          data-on="click"
        >
          <img src={`${imgURL}/global/icons/apple_store_button.svg`} alt="Apple app store link" />
        </a>
        <a
          className="google"
          href="http://play.google.com/store/apps/details?id=org.codespark.thefoos&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          rel="noopener noreferrer"
          data-event-category="app-download"
          data-event-action="signup-complete"
          data-event-label="android-google"
          data-on="click"
        >
          <img src={`${imgURL}/global/icons/google_store_button.svg`} alt="Google play link" />
        </a>
      </div>
      <p>Search “codeSpark” in the App Store, Google Play or Amazon Appstore. To play online, visit <a href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/play`}>codespark.com/play</a></p>
      <div className="numbered-step"><h2>2</h2></div>
      <h2>Sign in</h2>
      <p>Use your new account email & password to sign in</p>
      <div className="numbered-step"><h2>3</h2></div>
      <h2>Let your kids take over!</h2>
      <p>You’re all set to start learning with codeSpark Academy and the Foos!</p>
      <div className="image-container">
        <img src={`${imgURL}/accounts/landing/ipad-app-store.png`} alt="app store examples" />
      </div>
    </div>
  );
}

export default withRouter(SignupComplete);
