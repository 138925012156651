import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    classBContainer: {
        marginTop: '3rem',
        marginBottom: '2rem',
        '& p': {
            fontSize: '1.2rem',
            fontFamily: 'Montserrat',
        },
        '& h2': {
            fontSize: '2rem',
        }
    },
    classAContainer: {
        marginTop: '3rem',
        '& p': {
            fontSize: '1.2rem',
        },
        '& h2': {
            fontSize: '2rem',
        }
    },
    includedHeader: {
        textAlign: 'center',
    },
    includedList: {
        '& .MuiTypography-body2': {
            fontSize: '1.2rem',
            fontFamily: 'Montserrat',
        },
        '& .MuiListItemIcon-root': {
            minWidth: '40px',
        },
    },
    listItem: {
        paddingLeft: 0,
    },
    breakDown: {
        marginTop: '2rem',
        marginBottom: '2rem',
    },
    charge: {
        borderTop: '1px solid #818998',
        marginTop: '8px',
        '& .MuiTypography-body1': {
            fontWeight: '900',
        },
    },
    costContainer: {
        minHeight: '170px',
        padding: '30px 0 10px 0',
        '& .MuiTypography-body1': {
            fontSize: '1rem',
            fontFamily: 'Montserrat',
            [theme.breakpoints.down('xs')]: {
                fontSize: '0.8rem',
            },
        },
        '& .lineItem': {
            paddingRight: '30px',
            width: '100%',
        },
    },
    kidsImg: {
        width: '95%',
        borderRadius: '20px',
    },
    classBsubText: {
        marginTop: '2rem',
    },
    discountedPrice: {
        color: '#FF5454',
        textDecoration: 'line-through',
    },
    updatedPrice: {
        marginLeft: '8px',
    },
    paymentDiscount: {
        color: 'inherit',
    },
    chargeSubtext: {
        marginBottom: '20px',
        '& p.MuiTypography-body1': {
            margin: 'auto',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'Quicksand',
            fontWeight: '700',
            color: '#656565',
            textTransform: 'uppercase',
        },
    },
    // express checkout
    expressCheckoutContainer: {
        backgroundColor: '#F2F8FD',
        borderRadius: '14px',
        height: '100%',
        padding: '20px 0',
        '& .MuiTypography-body1': {
            fontSize: '1rem',
            fontFamily: 'Quicksand',
            fontWeight: 'bold',
            color: '#000000'
        }
    },
    paymentBtnContainer: {
        width: '100%',
        padding: '0 45px',
    },
    applePayBtn: {
        backgroundColor: '#000000',
        color: 'white',
        '&:hover': {
            backgroundColor: '#282626',
        },
        '& .MuiButton-label': {
            fontFamily: 'Montserrat',
            fontSize: '20px',
            textTransform: 'none',
        },
        [theme.breakpoints.down('md')]: {
            margin: '10px 0',
        },
    },
    googlePayBtn: {
        backgroundColor: '#FFFFFF',
        color: '#5F6368',
        '& .MuiButton-label': {
            fontFamily: 'Montserrat',
            fontSize: '20px',
            textTransform: 'none',
        },
        [theme.breakpoints.down('md')]: {
            margin: '10px 0',
        },
    },
    paypalBtn: {
        backgroundColor: '#0F2C77',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#0F2C77',
        },
        '& .MuiButton-label': {
            fontFamily: 'Montserrat',
            fontSize: '20px',
            textTransform: 'none',
        },
        [theme.breakpoints.down('md')]: {
            margin: '10px 0',
        },
    },
    timeIcon: {
        margin: '0 4px',
    },
    timer: {
        fontSize: 18,
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
    },
  }),
);
