import { createSelector } from 'reselect';

export const selectExperiments = state => state.experiments;
const selectExperimentVariants = (state, experiments) => experiments;

export const selectEnabledExperiment = createSelector(
  [selectExperiments, selectExperimentVariants],
  (allExperiments, experimentVariants) => {
    return experimentVariants.find(variant => !!allExperiments[variant]);
  }
);
