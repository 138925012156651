import React from 'react';
import '../MinervaLandingpage/ClassLandingpage.scss';

export default function AvailableCourses() {
  const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;

  return (
    <div className="courses">
      <h1 className="courses-title" style={{ color: 'rgba(2, 128, 224, 1)', fontWeight: 'bold', marginTop: '1rem' }}>
        Available Courses
      </h1>
      <div className="courses-wrapper">
        <div className="courses-container">
          <img src={`${imgURL}/accounts/classes/game-maker.jpg`} alt="courses" className="courses-image" />
          <div className="courses-content">
            <h2>Intro to Game Making</h2>
            <p>Students learn about commands, events, and game design to code a superhero game!</p>
            <div>
              <h4>CURRICULUM INCLUDES</h4>
              <div>
                <h5>
                  <span>&nbsp;Commands&nbsp;</span>
                  <span>&nbsp;Events&nbsp;</span>
                  <span>&nbsp;Debugging&nbsp;</span>
                </h5>
                <h5>
                  <span>&nbsp;Parameters&nbsp;</span>
                  <span>&nbsp;Game Design&nbsp;</span>
                </h5>
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <h4>GREAT FOR</h4>
              <div>
                <h5>
                  <span>Beginner</span>
                  <span>Intermediate</span>
                </h5>
                <h5>
                  <span>Ages 5-10</span>
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="courses-container">
          <img src={`${imgURL}/accounts/classes/story-building.jpg`} alt="courses" className="courses-image" />
          <div className="courses-content">
            <h2>Intro to Story Building</h2>
            <p>Design and code an interactive movie featuring multiple characters with sequences and events.</p>
            <div>
              <h4>CURRICULUM INCLUDES</h4>
              <div>
                <h5>
                  <span>&nbsp;Debugging&nbsp;</span>
                  <span>&nbsp;Events&nbsp;</span>
                  <span>&nbsp;Sequences&nbsp;</span>
                </h5>
                <h5>
                  <span>&nbsp;Storyboarding&nbsp;</span>
                </h5>
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <h4>GREAT FOR</h4>
              <div>
                <h5>
                  <span>&nbsp;Beginner&nbsp;</span>
                  <span>&nbsp;Ages 5-10&nbsp;</span>
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="courses-container">
          <img src={`${imgURL}/accounts/classes/intro-codespark.jpg`} alt="courses" className="courses-image" />
          <div className="courses-content">
            <h2>Intro to codeSpark</h2>
            <p>Learn programming fundamentals through game design and coding. Perfect for beginners!</p>
            <div>
              <h4>CURRICULUM INCLUDES</h4>
              <div>
                <h5>
                  <span>&nbsp;Events&nbsp;</span>
                  <span>&nbsp;Commands&nbsp;</span>
                  <span>&nbsp;Sequences&nbsp;</span>
                </h5>
                <h5>
                  <span>&nbsp;Game Design&nbsp;</span>
                </h5>
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <h4>GREAT FOR</h4>
              <div>
                <h5>
                  <span>&nbsp;Beginner&nbsp;</span>
                  <span>&nbsp;Ages 5-10&nbsp;</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
