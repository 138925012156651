import React, { useState } from 'react';
import { Box, Accordion as faqAccordion, AccordionSummary, AccordionDetails, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { styled } from '@material-ui/styles';
import { classQuestionsData } from '../data/classQuestionsData';
import CodeSparkMuiTypography from '../../../../components/elements/CodeSparkMuiTypography';

const useClassQuestionsStyles = makeStyles((theme) => ({
  faqWrapper: {
    backgroundColor: '#F2F8FD',
    padding: '90px 176px',
    [theme.breakpoints.down('sm')]: {
      padding: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '45px 15px',
    },
  },
  whiteBg: {
    backgroundColor: '#FFFFFF',
  },
  blueBg: {
    backgroundColor: '#F2F8FD',
  },
  faqHeader: {
    textAlign: 'center',
    marginBottom: '30px',
  },
  icon: {
    flexDirection: 'row-reverse',
    '& .MuiIconButton-edgeEnd': {
      marginRight: '1rem',
      [theme.breakpoints.down('sm')]: {
        marginRight: '0.3rem',
      },
    },
  },
  boxShadow: 'none',
}));

const ClassAccordionStyled = styled(faqAccordion)({
  borderBottom: '1px solid rgba(33, 55, 91, 0.3)',
  boxShadow: 'none',
  '&:first-child': {
    borderTop: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
});

const applyStylingPerUrl = () => {
  let urlString = window.location.href.split('/')[3];
  if (urlString === 'tryclasses') return true;
};

export default function ClassAccordion() {
  const [panelExpanded, setExpanded] = useState('1');

  const classes = useClassQuestionsStyles();

  const handleChange = (panel) => (e, isExpanded) => {
    setExpanded(panel);
  };

  return (
    <Box className={`${classes.faqWrapper} ${applyStylingPerUrl() && classes.whiteBg}`}>
      <CodeSparkMuiTypography variant="h2" className={classes.faqHeader} style={{ color: 'rgba(33, 55, 91, 1)' }}>
        Frequently Asked Questions
      </CodeSparkMuiTypography>
      {classQuestionsData.map((accordion) => {
        const { id, question, answer } = accordion;
        return (
          <ClassAccordionStyled
            expanded={panelExpanded === id}
            key={id}
            onChange={handleChange(id)}
            className={applyStylingPerUrl() ? classes.whiteBg : classes.blueBg}
          >
            <AccordionSummary expandIcon={panelExpanded === id ? <RemoveIcon /> : <AddIcon />} className={classes.icon}>
              <CodeSparkMuiTypography variant="h3" style={{ fontFamily: 'QuicksandBold' }}>
                {question}
              </CodeSparkMuiTypography>
            </AccordionSummary>
            <AccordionDetails>
              <CodeSparkMuiTypography variant="body2">{answer}</CodeSparkMuiTypography>
            </AccordionDetails>
          </ClassAccordionStyled>
        );
      })}
    </Box>
  );
}
