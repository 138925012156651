import React, { memo } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStyles } from './styles';
import { Box, Container, useMediaQuery, useTheme, Grid } from '@material-ui/core';

function AddClassesPaymentLayout(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <React.Fragment>
      <CssBaseline />
      {isDesktop && (
        <Box className={classes.desktopBackground}>
          <Grid container className={classes.desktopPageContainer} spacing={'lg' ? 4 : 1}>
            <Grid item className={classes.containerWrapper} lg={8}>
              {props.headerInfo}
              {props.paymentInfo}
              {props.paymentForm}
            </Grid>
            <Grid item lg={4}>
              {props.awardsModule}
            </Grid>
          </Grid>
        </Box>
      )}
      {isMobile && (
        <Box className={classes.pageContainer}>
          <Container maxWidth="md" className={classes.containerWrapper}>
            {props.headerInfo}
            {props.paymentInfo}
            {props.paymentForm}
          </Container>
          {props.awardsModule}
        </Box>
      )}
      {!isDesktop && !isMobile && (
        <Box className={classes.pageContainer}>
          <Container maxWidth="md" className={classes.containerWrapper}>
            {props.headerInfo}
            {props.paymentInfo}
            {props.paymentForm}
          </Container>
          {props.awardsModule}
        </Box>
      )}
    </React.Fragment>
  );
}

export default memo(AddClassesPaymentLayout);
