import React from 'react';
import { useSelector } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';
import {
  EmailLeadPageContainer,
  EmailLeadContainer,
  Legal,
  CtaButton,
  EmailTextInput,
  CheckboxOptIn,
} from './components';
import { useEmailLeadWall, useCheckboxOptIn } from './hooks';
import { gtmPageviewEvent } from '../../services/google-tag-manager';

import '../../components/forms/RegistrationForm/RegisterForm.scss';

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column-reverse',
    paddingTop: '16px',

    [theme.breakpoints.down('sm')]: {
      paddingTop: '32px',
      marginBottom: '16px',
    },
  },
  header: {
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  eyebrowHeader: {
    marginBottom: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  emailLeadContainer: {
    padding: '32px 90px',
    maxWidth: '527px',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px',
    },
  },
  formContainer: {
    marginTop: '16px',
  },
  subheader: {
    marginBottom: '16px',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    color: '#53565A !important',
  },
}));

function ChildHoldingTablet() {
  const classes = useStyles();

  const { email, handleNextClick, handleChange, error } = useEmailLeadWall();
  const { checkboxOptIn, handleCheckboxOptIn } = useCheckboxOptIn();
  const userCountry = useSelector((state) => state.appState.userCountryToSave);

  const nextButtonEnabled = !!email && !error;

  React.useEffect(() => {
      gtmPageviewEvent({
        pageName: 'email-lead-page',
      });
  }, []);

  return (
    <EmailLeadPageContainer>
      <EmailLeadContainer
        className={classes.emailLeadContainer}
        // use style to overwrite sass due to high specificity
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Box className="">
          <img
            src={`${process.env.REACT_APP_CLOUDFRONT_URL}/accounts/child_holding_tablet.png`}
            alt="Child holding tablet"
          />
        </Box>

        <Box className={classes.headerWrapper}>
          <h2 className={`text-center ${classes.header}`}>Let’s Get Started</h2>
          <h5 className={classes.eyebrowHeader}>Welcome to codeSpark</h5>
        </Box>

        <form className={classes.formContainer} onSubmit={handleNextClick}>
          <p className={classes.subheader}>First, what’s your email?</p>
          <EmailTextInput value={email} onChange={handleChange} error={!!error} helperText={error} />

          {userCountry !== 'US' && <CheckboxOptIn checkboxOptIn={checkboxOptIn} onChange={handleCheckboxOptIn} />}

          <Legal />

          <CtaButton disabled={!nextButtonEnabled}>Next</CtaButton>
        </form>
      </EmailLeadContainer>
    </EmailLeadPageContainer>
  );
}

export default ChildHoldingTablet;
