import React, { memo } from 'react';
import { Typography, Divider, Grid, Box } from '@material-ui/core';
import { useStyles } from './styles';
import { paymentInclusions } from '../../providers/constants';
import Countdown from 'react-countdown';
import CountdownClock from './CountdownClock';
import { useSelector } from 'react-redux';

function PaymentInfo() {
  const classes = useStyles();
  const selectedOffer = useSelector((state) => state.appState.minervaAddedClassId);
  const fourClass = JSON.parse(sessionStorage.getItem('fourClass'));
  let selectedClass;

  if (selectedOffer === fourClass?.planId) {
    selectedClass = paymentInclusions['fourClass'];
  } else {
    selectedClass = paymentInclusions['oneClass'];
  }

  return (
    <Box className={classes.paymentInfoContainer}>
      <Grid container spacing={2} className={classes.breakDown}>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.includedHeader}>
            What's Included
          </Typography>
          <Grid container justifyContent="flex-start" alignItems="center" className={classes.costContainer}>
            {selectedClass.items.map((item, index) => (
              <Grid className="lineItem" key={index}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item style={{ display: 'flex' }}>
                    <Typography variant="body1">&nbsp;{item.item}</Typography>
                    {index === 1 && <Countdown date={Date.now() + 599000} renderer={CountdownClock} />}
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">
                      <span className={item.price.isDiscounted ? classes.discountedPrice : ''}>
                        {item.price.amount}
                      </span>
                      <span>{item.price.charge}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}

            <Grid className={`${classes.charge} lineItem`}>
              <Divider />
              <br />
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1">Today's Charge</Typography>
                <Typography variant="body1">{selectedClass.charge}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.chargeSubtext}>
            <Typography variant="body1">{selectedClass.chargeSubtext}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(PaymentInfo);
