// Currently, material ui is being used without a theme all over the app so we need to keep this separate
// to prevent any issues

// Colors are not finalized but used often in many variants
export const colors = {
  primary: {
    darkBlue: '#21375b',
    neutralBlue: '#F2F8FD',
    lightGrey: '#f2f8fd',
    yellow: '#ffce00',
    orange: '#ff8654',
    green: '#43ac44',
    blue: '#0280e0',
    purple: '#8D52FA',
    pink: '#ff3dc8',
    white: '#ffffff',
    black: '#000000',

    purple5: '#6606C6',
    grey4: '#505762',
    grey5: '#22252A',
    grey2: '#C7CBD1',
    pink5: '#9F0573',
    blue1: '#F0F8FF',
    blue2: '#BADDF7',
  },
  // Modified colors used for some ctas
  secondary: {
    blue: '#0276cf',
    orange: '#ff9254',
    yellow: '#FFDB47',
    // an alternative grey that is occasionally used
    lightGrey: '#D2D2D2',
  },
};
