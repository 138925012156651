import { Button as MuiButton, createTheme, styled, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { colors } from '../../../theme/constants';

// There is no main theme provider so just creating one here for the cta buttons
const buttonsTheme = createTheme({
  palette: {
    primary: {
      main: colors.secondary.orange,
      contrastText: colors.primary.white,
      // prevent color change on hover
      dark: colors.secondary.orange,
    },
    secondary: {
      main: colors.primary.blue,
      contrastText: colors.primary.white,
      // prevent color change on hover
      dark: colors.primary.blue,
    },
    primary2: {
      main: colors.primary.purple5,
      contrastText: colors.primary.white,
      dark: colors.primary.purple5,
    },
  },
  overrides: {
    MuiButton: {
      // root is for the default and medium size
      root: {
        padding: '0.875rem 1.5rem',
        fontFamily: 'Quicksand',
        fontWeight: 'bold',
        fontSize: '1rem',
        lineHeight: '1.25rem',
        letterSpacing: '0.031rem',
        textTransform: 'none',
        borderRadius: '2.5rem',
      },
      outlined: {
        padding: '0.875rem 1.5rem',
        '&:hover': {
          backgroundColor: colors.primary.blue2,
        },
      },
      sizeLarge: {
        padding: '1.0625rem 1.75rem',
      },
      sizeSmall: {
        padding: '0.5625rem 1.125rem',
      },
      label: {
        fontFamily: 'inherit',
        fontWeight: 'inherit',
      },
    },
  },
});

const Button = styled(({ color, ...props }) => <MuiButton {...props} />)(
  ({ theme, color, variant }) => {
    const isContained = variant === 'contained';
    const isOutlined = variant === 'outlined';
    const colorTheme = theme.palette[color];

    return {
      color: isContained ? colorTheme.contrastText : colorTheme.main,
      backgroundColor: isContained ? colorTheme.main : 'transparent',
      border: isOutlined ? `2px solid ${colorTheme.main}` : 0,
      '&:hover': {
        // we do not have hover states for buttons yet
        backgroundColor: isContained ? colorTheme.main : 'transparent',
      },
    };
  }
);

function ThemedMuiButton({ children, ...props }) {
  return (
    <ThemeProvider theme={buttonsTheme}>
      <Button variant="contained" size="medium" color="primary" disableElevation {...props}>
        {children}
      </Button>
    </ThemeProvider>
  );
}

export default ThemedMuiButton;
