export const classQuestionsData = [
  {
    id: '1',
    question: 'How do I schedule classes?',
    answer:
      'After you complete your purchase, you will receive an email with instructions to complete class registration. You will receive a certain number of class credits to use towards courses every month.',
  },
  {
    id: '2',
    question: 'How frequently are classes held?',
    answer: 'Classes are held every week from Monday to Friday, with options ranging from 4pm to 7pm EST.',
  },
  {
    id: '3',
    question: 'What is a class like?',
    answer:
      'Each class is 45 minutes in length, taught by an experienced school teacher in the subject matter. To ensure a high quality experience, classes are in small groups of no more than 6 students. It is interactive, collaborative, and project based!',
  },
  {
    id: '4',
    question: 'What if we don’t like the classes?',
    answer:
      'If you are not enjoying the course, feel free to reach out to our customer support team classes@codespark.com at any time. We would be happy to change to another course or provide a refund!',
  },
];
