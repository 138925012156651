import { Box, Grid, makeStyles, styled } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import { botClassName } from '../../../../utils/e2eBotUtils';
import ThemedMuiButton from '../../ThemedMuiButton';
import ButtonContainer from '../../ThemedMuiButton/ButtonContainer';
import CodeSparkMuiTypography from '../../CodeSparkMuiTypography';
import { BOT_CTA_BUTTON_NAME, createPlanBotClass } from './botUtils';

const imgURL = `${process.env.REACT_APP_CLOUDFRONT_URL}/accounts`;

export const PlanCard = styled(({ cursor, ...props }) => <Box {...props} />)(
  ({ cursor }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    borderRadius: '0.625rem',
    zIndex: 1,
    position: 'relative',
    // use box-shadow to fix gapping between inside and border of card
    boxShadow: '0 0 0 .25rem rgba(0, 0, 0, 0)',
    height: '100%',
    color: '#22252A',
    cursor,
    '&.highlighted': {
      boxShadow: '0 0 0 .25rem #FFDB47',
    },
    '&.selected': {
      boxShadow: '0 0 0 .25rem #FF9254',
    },
  })
);

export const PlanCardBody = styled(Box)(({ theme }) => ({
  padding: '2rem 1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexGrow: 1,
  [theme.breakpoints.down('sm')]: {
    padding: '1.5rem 1rem',
  },
}));

export const PlanCardPrice = styled(CodeSparkMuiTypography)(({ theme }) => ({
  color: '#22252A',
  marginTop: '1rem',
  fontSize: '1.25rem',
  '& > span': {
    color: '#8D52FA',
    fontFamily: 'Quicksand',
    display: 'inline-block',
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: '0.5rem',
  },
}));

export const SavePercent = ({ percentChange }) => (
  <CodeSparkMuiTypography component="span" variant="h3">
    {'(save '}
    {percentChange}
    {')'}
  </CodeSparkMuiTypography>
);

export const PlanHeaderFlagWrapper = styled(Box)({
  backgroundColor: '#FFDB47',
  textAlign: 'center',
  padding: '1rem 0',
  color: '#22252A',
  borderTopLeftRadius: '0.625rem',
  borderTopRightRadius: '0.625rem',
});

export const PlanHeaderFlag = ({ flagText }) => (
  <PlanHeaderFlagWrapper>
    <CodeSparkMuiTypography variant="h6">{flagText}</CodeSparkMuiTypography>
  </PlanHeaderFlagWrapper>
);

const useSubscriptionPlanCardClasses = makeStyles(theme => ({
  subscriptionPlan: {
    position: 'relative',
  },
  textContent: {
    marginBottom: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  header: {
    color: '#0280E0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  bodyText: {
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5rem',
    },
  },
  billingLegalDefault: {
    color: '#53565A',
  },
  billingLegal: {
    height: '2.8125rem',
  },
  glitch: {
    position: 'absolute',
    top: '-3.625rem',
    right: 0,
    width: '6.0625rem',
    [theme.breakpoints.down('sm')]: {
      top: '-4.0625rem',
    },
  },
  flag: {
    position: 'absolute',
    top: '1.25rem',
    right: '.5rem',
    zIndex: 2,
    backgroundImage: `url(${imgURL}/plan_selector_flag.svg)`,
    backgroundRepeat: 'no-repeat',
    width: '6.625rem',
    textAlign: 'center',
    padding: '.6875rem 0',
    '& >span': {
      color: '#21375B',
      fontFamily: 'Quicksand',
      fontWeight: 'bold',
      fontSize: '.875rem',
      letterSpacing: '.0313rem',
    },
    [theme.breakpoints.down('sm')]: {
      right: 0,
    },
  },
}));

function SubscriptionPlanCard({
  onClick,
  selected,
  showGlitch,
  flagText,
  title,
  priceDisplay,
  trial,
  billingLegal,
  billingLegal2,
  percentChange,
  // CVR-921
  shouldUseSelectPlanButtons,
  // END CVR-921
  // CVR-942
  thirdPlanEnabled,
  // END CVR-942
  // CVR-956
  lifetimePlanEnabled,
  // END CVR-956
  includes,
  isHighlighted,
  plan,
  ctaLabel,
}) {
  const classes = useSubscriptionPlanCardClasses({
    shouldUseSelectPlanButtons,
  });

  // CVR-942
  const savedPercent =
    thirdPlanEnabled && parseFloat(percentChange) ? (
      <SavePercent percentChange={percentChange} />
    ) : (
      ''
    );
  // END CVR-942

  return (
    <Grid item xs={12} md={4} className={classes.subscriptionPlan}>
      <PlanCard
        className={classNames(createPlanBotClass(plan), {
          selected: selected && !shouldUseSelectPlanButtons,
          highlighted: isHighlighted,
        })}
        cursor={shouldUseSelectPlanButtons ? 'auto' : 'cursor'}
        onClick={!shouldUseSelectPlanButtons ? onClick : undefined}
      >
        {isHighlighted && <PlanHeaderFlag flagText={flagText} />}

        <PlanCardBody>
          <Box
            className={classNames({ [classes.textContent]: lifetimePlanEnabled && isHighlighted })}
          >
            <CodeSparkMuiTypography variant="h3" className={classes.header}>
              {title}
            </CodeSparkMuiTypography>
            <PlanCardPrice variant="h3">
              {priceDisplay} {savedPercent}
            </PlanCardPrice>

            {!thirdPlanEnabled && !lifetimePlanEnabled && trial && (
              <CodeSparkMuiTypography variant="body3" className={classes.bodyText} parseText>
                {trial}
              </CodeSparkMuiTypography>
            )}

            <CodeSparkMuiTypography
              variant="body3"
              className={classNames(classes.bodyText, {
                // default styling is for the control
                [classes.billingLegalDefault]: !lifetimePlanEnabled && !thirdPlanEnabled,
                [classes.billingLegal]: lifetimePlanEnabled,
              })}
              parseText
            >
              {lifetimePlanEnabled && billingLegal2 ? billingLegal2 : billingLegal}
            </CodeSparkMuiTypography>

            {includes && (
              <CodeSparkMuiTypography variant="body3" className={classes.bodyText}>
                {includes}
              </CodeSparkMuiTypography>
            )}

            {lifetimePlanEnabled && (
              <CodeSparkMuiTypography variant="body3" className={classes.bodyText} parseText>
                {trial ? trial : ''}
              </CodeSparkMuiTypography>
            )}
          </Box>

          {/* CVR-921 */}
          {shouldUseSelectPlanButtons && (
            <ButtonContainer mt="1.5rem">
              <ThemedMuiButton
                color="primary"
                onClick={onClick}
                className={botClassName(BOT_CTA_BUTTON_NAME)}
                fullWidth
              >
                Choose Plan
              </ThemedMuiButton>
            </ButtonContainer>
          )}
          {/* END CVR-921 */}
        </PlanCardBody>
      </PlanCard>

      {/* CVR-942 */}
      {flagText && !thirdPlanEnabled && !lifetimePlanEnabled && (
        <Box className={classes.flag}>
          <span>{flagText}</span>
        </Box>
      )}
      {/* END CVR-942 */}

      {showGlitch && (
        <Box className={classes.glitch}>
          <img src={`${imgURL}/glitch_2_thumbs_up.svg`} role="presentation" alt="Glitch icon" />
        </Box>
      )}
    </Grid>
  );
}

export default SubscriptionPlanCard;
