import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

const useClassFooterStyles = makeStyles((theme) => ({
  footerWrapper: {
    marginBottom: '5px',
  },
  content: {
    fontFamily: 'Gotham',
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'center',
  },
}));

export default function ClassFooter({ items }) {
  const classes = useClassFooterStyles();

  return items.map((item, i) => (
    <Grid container direction="column" spacing={0} className={classes.footerWrapper} key={i}>
      <Typography className={classes.content}>{item}</Typography>
    </Grid>
  ));
}
