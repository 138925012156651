import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    contentContainer: {
      backgroundColor: '#F2F8FD',
      padding: '0 20px',
    },
    awardsContainer: {
      paddingTop: 30,
      paddingBottom: 30,
    },
    item: {
      marginBottom: 30,
    },
  }),
);