import React, { lazy, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import __isEmpty from 'lodash/isEmpty';
import __upperFirst from 'lodash/upperFirst';
import mixpanel from 'mixpanel-browser';
import { format } from 'date-fns';

import './PromoV2Wrapper.scss';
import { notify, trackPageMixpanel } from '../../services/functions';
import {
  getWebOfferByPromo,
  getCouponCode,
  getCurrentUser,
  logout,
  getCurrentUserID,
  register,
  login,
  getUserSubscriptionDetails,
  redeemComp,
  redeemVolumeLicense
} from '../../services/parse';
import { savePromoCoupon,
  saveWebOfferCode,
  saveWebOffer,
  saveEmail,
  setPasswordErrorClass,
  setConfirmPasswordErrorClass,
  showUserNoUpgrade,
  setResubscribe
} from '../../redux/actions';
import { sendFacebookEvent } from '../../services/facebook-capi';
import { getAnalyticsOptInValue, sendAnalyticsEvent } from '../../services/analytics';
import { gtmPageviewEvent, gtmEvent } from '../../services/google-tag-manager';

const RegisterForm = lazy(() => import('./RegisterForm'));
const LoggedInForm = lazy(() => import('./LoggedInForm'));
const PromoSplashComponent = lazy(() => import('./PromoSplashComponent'));
const renderLoader = () => <h1>Loading</h1>;

const queryString = require('query-string');
const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;

class PromoV2 extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      errorPassword: '',
      errorConfirmPassword: '',
      password: '',
      confirmPassword: '',
      email: '',
      emailToSave: '',
      attributionSource: '',
      confirmTerms: null,
      promoCode: '',
      isLoggedIn: !__isEmpty(getCurrentUser()),
      webOffer: {},
      user: JSON.parse(getCurrentUser()),
      subscriptionDetails: {},
      isNoChargesCode: false,
      isValidCode: false,
      offersSignup: true,
      captchaToken:'',
    };

    this.state = this.initialState;
  }

  componentDidMount() {
    // remove any prior promo codes that might be stuck in the system
    this.props.savePromoCoupon('');
    gtmPageviewEvent({
      pageName: 'WebPaymentsV3 Page: Promo',
      componentProps: this.props,
      callback: () => {
        trackPageMixpanel('WebPaymentsV3 Page: Promo');
        sendFacebookEvent({
          event_name: 'Visit',
          event_source_url: window.location.href,
          custom_data: {screen: 'Promo'}
        });
      }
    });

    // send userID to gtm if user is loggedIn
    if (this.state.isLoggedIn) {
      const userId = getCurrentUserID();
      //Get user subscription data to judge where the user should go next
      getUserSubscriptionDetails(userId).then((user) => {
        this.setState({ subscriptionDetails: user });
      });
    }

    const user = JSON.parse(getCurrentUser());
    if (user) {
      this.setState({ email: user.email });
    }
    const parsedQuery = !__isEmpty(this.props.location.search) ? queryString.parse(this.props.location.search) : null;

    // grab any url params and store those in utmParmsObject to be passed in on checkout
    if (parsedQuery) {
      const paramsObj = {};
      paramsObj.attributionData = {};
      paramsObj.attributionData.utm = {};
      paramsObj.attributionData.utm['utm_campaign [last touch]'] =  parsedQuery.utm_campaign;
      paramsObj.attributionData.utm['utm_source [last touch]'] =  parsedQuery.utm_source;
      paramsObj.attributionData.utm['utm_medium [last touch]'] =  parsedQuery.utm_medium;
      paramsObj.attributionData.utm['utm_term [last touch]'] =  parsedQuery.utm_term;
      paramsObj.attributionData.utm['utm_content [last touch]'] =  parsedQuery.utm_content;
      // Iterable
      paramsObj.attributionData.iterable = {};
      paramsObj.attributionData.iterable.iterable_campaign = parsedQuery.iterable_campaign;
      paramsObj.attributionData.iterable.iterable_template = parsedQuery.iterable_template;
      sessionStorage.setItem('utmParamsObject', JSON.stringify(paramsObj));
      // not sure if attributionSource is different but want to make sure we get all the values that might come in
      sessionStorage.setItem('attributionSource', JSON.stringify(parsedQuery));
    } else {
      sessionStorage.removeItem('utmParamsObject');
      sessionStorage.removeItem('attributionSource');
    }

    // if a user is coming from a clicked url with email and promo in it,
    // redirect them straight to login, else go to register
    if (parsedQuery && parsedQuery.email) {
      const email = parsedQuery.email;
      // if someone is logged in with a different email log them out
      if (user && user.email !== email) {
        // log them out
        logout();
        this.setState({ isLoggedIn: false });
      }
    }
  }

  setPromoCode = (code) => {
    this.setState({ promoCode: code });
    this.getPromoCouponCode(code);
  }

  setEmail = (email) => {
    this.setState({ emailToSave: email });
    this.props.saveEmail(email);
  }

  setPasswordToSubmit = (value) => {
    this.setState({ password: value });
  }

  setConfirmedPassword = (value) => {
    this.setState({ confirmPassword: value });
  }

  handleCheckChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  }

  isCouponCode(code) {
    let validCode;
    if (code.toUpperCase().substr(0, 2) === 'CS') {
      validCode = true;
      this.props.savePromoCoupon(code);
    } else if (code.toUpperCase().substr(0, 2) === 'CV') {
      validCode = true;
    } else if (code.toUpperCase().substr(0, 4) === 'GIFT') {
      validCode = true;
    } else {
      validCode = false
    }

    return validCode;
  }

  getPromoCouponCode(promoCoupon) {
    if (promoCoupon === '') {
      document.getElementById('code-field').classList.remove('confirm-valid');
      document.getElementById('promo-code-register').classList.remove('has-error');
      document.getElementById('promo-code-error').classList.add('hide-error');
      this.props.saveWebOffer({});
      sessionStorage.removeItem('webOffer');
      return;
    }
    promoCoupon = promoCoupon || this.state.promoCode;
    // see if the promo or coupon exist
    if (this.isCouponCode(promoCoupon)) {
      // coupon code
      getCouponCode(promoCoupon).then((results) => {
        // data needs to be parsed separately depending if they have webOffer attached or just a campaign
        if (results.webOffer) {
          this.parseWebOffer(results.webOffer);
        } else {
          this.parseCompOffer(results);
        }

        if (results.isCouponValid) {
          this.setState({ isValidCode: true });
          if (results.campaign.has('compUntil') || results.campaign.has('compMonths')) {
            // comp account
            this.saveAndConfirmCode('Comp');
            this.setState({ isNoChargesCode: true });
          } else if (!__isEmpty(results.webOffer) && (this.state.webOffer.subscriptionType === 'volumeLicense')) {
            // volume license
            this.saveAndConfirmCode('volume-license');
            this.setState({ isNoChargesCode: true });
          } else if (!__isEmpty(results.webOffer) && this.state.webOffer.subscriptionType === 'gift') {
            // gift code
            this.saveAndConfirmCode('gift');
            this.setState({ isNoChargesCode: true });
          } else if (!__isEmpty(results.webOffer)) {
            // coupon with a price attached
            this.saveAndConfirmCode(results.webOffer.id);
            this.setState({ isNoChargesCode: false });
          } else {
            this.setState({ isValidCode: false });
            notify('error', 'Sorry, something went wrong. Please try again or contact us if this persists.', 5000);
            document.getElementById('code-field').classList.remove('confirm-valid');
            document.getElementById('promo-code-register').classList.add('has-error');
            return;
          }
          document.getElementById('promo-code-register').classList.remove('has-error');
          document.getElementById('promo-code-error').classList.add('hide-error');
        } else {
          this.setState({ isValidCode: false });
          trackPageMixpanel('WebPaymentsV3 Error: Invalid Promo Code');
          document.getElementById('code-field').classList.remove('confirm-valid');
          document.getElementById('promo-code-register').classList.add('has-error');
          document.getElementById('promo-code-error').classList.remove('hide-error');
          // remove code from store
          this.props.savePromoCoupon('');
        }
      }).catch((error) => {
        console.error(error);
      });
    } else {
      // promo code - discount and trial periods
      getWebOfferByPromo(promoCoupon).then((results) => {
        if (!__isEmpty(results)) {
          this.setState({ isValidCode: true });
          this.props.saveWebOffer(results);
          sessionStorage.setItem('webOffer', JSON.stringify(results));
          this.setState({ webOffer: results });
          this.saveAndConfirmCode(results.id);
          document.getElementById('promo-code-register').classList.remove('has-error');
          document.getElementById('promo-code-error').classList.add('hide-error');
        } else {
          this.setState({ isValidCode: false });
          trackPageMixpanel('WebPaymentsV3 Error: Invalid Promo Code');
          document.getElementById('code-field').classList.remove('confirm-valid');
          document.getElementById('promo-code-register').classList.add('has-error');
          document.getElementById('promo-code-error').classList.remove('hide-error');
        }
      }).catch((error) => {
        this.setState({ isValidCode: false });
        console.log(error);
      });
    }
  }

  // for comps and gifts we need to parse the webOffer that is returned from the server because it is a Parse.Object
  parseWebOffer(offer) {
    const parsedOffer = {
      id: offer.id,
      descriptionKey: offer.descriptionKey || offer.attributes?.descriptionKey,
      isActive: offer.isActive || offer.attributes?.isActive,
      isDefaultOffer: offer.isDefaultOffer || offer.attributes?.isDefaultOffer,
      offerSubtitle: offer.offerSubtitle || offer.attributes?.offerSubtitle,
      offerTitle: offer.offerTitle || offer.attributes?.offerTitle,
      originalPrice: offer.originalPrice || offer.attributes?.originalPrice,
      planId: offer.planId || offer.attributes?.planId,
      price: offer.price || offer.attributes?.price,
      subscriptionDuration: offer.subscriptionDuration || offer.attributes?.subscriptionDuration,
      subscriptionType: offer.subscriptionType || offer.attributes?.subscriptionType,
      trialDurationDays: offer.trialDurationDays || offer.attributes?.trialDurationDays,
      giftMonths: offer.giftMonths || offer.attributes?.giftMonths,
      plan: offer.plan || offer.attributes?.plan
    };

    if (parsedOffer.descriptionKey === 'Gift') {
      parsedOffer.offerSubtitle = 'Your gift membership includes:'

      if (parsedOffer.giftMonths === -1) {
        parsedOffer.offerTitle = "You've received a Lifetime Access gift!"
      } else {
        parsedOffer.offerTitle = `You've received a ${parsedOffer.giftMonths} Month gift!`;
      }
    }

    this.props.saveWebOffer(parsedOffer);
    sessionStorage.setItem('webOffer', JSON.stringify(parsedOffer));
    this.setState({ webOffer: parsedOffer });
  }

  // some comps don't have a webOffer but are attached to campaign so they don't have the same info
  parseCompOffer(offer) {
    const parsedOffer = {
      isBulkPurchaseRedemption: offer.isBulkPurchaseRedemption,
      isCouponCodeUsed: offer.isCouponCodeUsed,
      isCouponExpired: offer.isCouponExpired,
      isCouponValid: offer.isCouponValid,
      isLifetimeComp: offer.isLifetimeComp,
      planId: offer.campaign.get('webOfferId'),
      compMonths: offer.campaign.get('compMonths')
    }
    if (parsedOffer.compMonths) {
      if (parsedOffer.compMonths === -1) {
        parsedOffer.offerTitle = `Complimentary Lifetime access`;
      } else {
        parsedOffer.offerTitle = `Complimentary ${parsedOffer.compMonths} Month access`;
      }
    } else {
      parsedOffer.offerTitle = `Complimentary access until ${format(offer.campaign.get('compUntil'), 'MM/dd/yy')}`;
    }
    parsedOffer.offerSubtitle = 'Your membership includes:';

    this.props.saveWebOffer(parsedOffer);
    sessionStorage.setItem('webOffer', JSON.stringify(parsedOffer));
    this.setState({ webOffer: parsedOffer });
  }

  saveAndConfirmCode(code) {
    this.props.saveWebOfferCode(code);
    document.getElementById('code-field').classList.add('confirm-valid');
  }

  // registers a new user using a promo code
  registerUser = validForm => {
    if (__isEmpty(this.state.captchaToken) || this.state.captchaToken === null) {
      notify('error', 'Please complete the reCaptcha requirements', 5000);
      return;
    }

    //do a check for empty answers
    if (__isEmpty(this.props.emailToSave)) {
      notify('error', 'Please enter an email', 5000);
      return;
    }

    if (__isEmpty(this.state.password)) {
      notify('error', 'Please enter a password', 5000);
      return;
    }

    if (!validForm) return;

    if (__isEmpty(this.state.confirmPassword)) {
      notify('error', 'Please confirm your password', 5000);
      return;
    }

    if (this.state.password !== this.state.confirmPassword) {
      notify('error', 'Passwords do not match', 5000);
      return;
    }

    if (__isEmpty(this.state.promoCode)) {
      notify('error', 'Please enter a promo code to continue. If you do not have a promo code, click here', 5000, () => {
        this.props.history.push('/');
      });
      return;
    }

    if (!this.state.confirmTerms) {
      notify('error', 'We cannot register you without accepting our Terms and Privacy Policy', 5000);
      return;
    }

    if (!this.state.isValidCode) {
      return;
    }

    let utmParamsObject = sessionStorage.getItem('utmParamsObject');
    // example: "{\"attributionData\":{\"utm\":{},\"iterable\":{\"iterable_campaign\":\"1396648\",\"iterable_template\":\"1939065\"}}}"
    if(utmParamsObject) {
      utmParamsObject = JSON.parse(utmParamsObject);
    }

    register({
      username: this.state.emailToSave,
      password: this.state.confirmPassword,
      attributionSource: this.state.attributionSource,
      utmParamsObject: utmParamsObject,
      confirmPromoEmails: this.state.offersSignup,
      captchaToken: this.state.captchaToken,
    }).then((user) => {
      // check if this is comp or gift and bypass payment page
      if (this.state.isNoChargesCode) {
        this.redeemCompsOrGifts(user.id);
      } else {
        mixpanel.people.set_once('parseAccountId', user.id);
        mixpanel.alias(user.id);
        // sets the newly created user to Pretrial status
        mixpanel.people.set({ 'Account Status': 'Pretrial' });
        trackPageMixpanel('WebPaymentsV3 Action: Created User', {
          username: this.state.emailToSave,
          attributionSource: this.state.attributionSource
        });
        gtmEvent({
          action: 'login_created',
          pageName: 'WebPaymentsV3 Page: Promo',
          user_id: user.id,
        });
        sendAnalyticsEvent('web_login_created', {
          'app': 'web-site',
          'opted_into_comms': getAnalyticsOptInValue(this.state.offersSignup),
          'preticked_opt_in': getAnalyticsOptInValue(this.initialState.offersSignup),
        }, true);

        //save for the checkout page to combine with other data
        //Google Tag Manager
        // const gtmRegData = {
        //   action: 'parentRegistration',
        //   'userID': user.id,
        //   'emailAddress': this.state.emailToSave,
        //   'userType': 'parent',
        //   'subscriptionPlan': this.state.webOffer.subscriptionDuration,
        //   'subscriptionType': this.state.webOffer.subscriptionType === 'recurring' ? this.state.webOffer.subscriptionType : 'single payment',
        //   'subscriptionListPrice': this.state.webOffer.price,
        //   'trialLengthDays': this.state.webOffer.trialDurationDays
        // };
        // gtmEvent(gtmRegData);
        this.props.history.push('/promo-payment');
      }
    }).catch((response) => {
      console.log('response', response);
      trackPageMixpanel('WebPaymentsV3 Error: Registration Error', { Email: this.state.emailToSave });
      let error = !__isEmpty(response.data) ? response.data.error : response.message;
      notify('error', error, 5000);
    });
  }

  // if user has an account they can log in and purchase with promo code
  loginForm = () => {
    if (__isEmpty(this.state.promoCode)) {
      notify('error', 'Please enter a promo code to continue. If you do not have a promo code, click here', 5000, () => {
        this.props.history.push('/');
      });
      return;
    }
    // toggle gift user early resubscribe on/off
    const canGiftSubsResubEarly = true;

    login({
      username: this.state.emailToSave,
      password: this.state.password
    }).then((user) => { // user variable only returns id
      mixpanel.identify(user.id);
      const userID = user.id;
      //Get user subscription data to judge where the user should go next
      getUserSubscriptionDetails(user.id).then((user) => {
        let hasActiveSubscription = !user.isExpired && !__isEmpty(user.plan);

        if (hasActiveSubscription) {
          //user is trying to buy a subscription with an active subscription
          //make sure warning/help popup shows on account page
          this.props.showUserNoUpgrade(true);
          this.props.history.push('/my-account');
        // check if this is comp or gift and bypass payment page
        } else if (this.state.isNoChargesCode) {
          this.redeemCompsOrGifts(userID);
        } else {
          if (canGiftSubsResubEarly && user.isExpired === false && user.isGifted && !user.isLifetime) {
            // not expired and gifted and not lifetime gifted
            // let them through and we can set the subscription start to after they expire
            this.props.setResubscribe(true);
          }
          this.props.history.push('/promo-payment');
        }
      }).catch((error) => {
        //throw a hard fail so that users we can't get details for can't trick the system
        console.log(error);
        // this.removeFormProcessing();
        notify('error', 'Sorry, something went wrong. Please try again or contact us if this persists.', 5000);
        trackPageMixpanel('WebPaymentsV3 Error: Login', { username: this.props.emailToSave, notes: 'Error getting user sub data' });
      });
    }).catch((error) => {
      // this.removeFormProcessing();
      trackPageMixpanel('WebPaymentsV3 Error: Login', { username: this.props.emailToSave });
      notify('error', __upperFirst(error.message.replace('.', '')), 5000);
    });
  }

  // user is already logged in and wants to use a promo code
  submitForm = () => {
    if (__isEmpty(this.state.promoCode)) {
      notify('error', 'Please enter a promo code to continue. If you do not have a promo code, click here', 5000, () => {
        this.props.history.push('/');
      });
      return;
    }
    const user = this.state.subscriptionDetails;
    const canGiftSubsResubEarly = true;
    let hasActiveSubscription = !user.isExpired && !__isEmpty(user.plan);

    setTimeout(() => {
      if (this.state.isValidCode === false) {
        return false;
      }

      if (this.state.isNoChargesCode) {
        this.redeemCompsOrGifts(this.state.user.id);
      } else if (hasActiveSubscription) {
        //user is trying to buy a subscription with an active subscription
        //make sure warning/help popup shows on account page
        this.props.showUserNoUpgrade(true);
        this.props.history.push('/my-account');
      } else {
        if (canGiftSubsResubEarly && user.isExpired === false && user.isGifted && !user.isLifetime) {
          // not expired and gifted and not lifetime gifted
          // let them through and we can set the subscription start to after they expire
          this.props.setResubscribe(true);
        }
        this.props.history.push('/promo-payment');
      }
    }, 500);
  }

  // if user is redeeming a gift or a comp code, then we don't need to collect payment
  // and they can be routed to the completion page
  redeemCompsOrGifts(userID) {
    // check if logged in and set userID accordingly
    let userId;
    if (this.state.isLoggedIn) {
      userId = this.state.user.objectId;
    } else {
      userId = userID;
    }

    // redeem the comp code
    if (this.props.webOfferCode === 'Comp' || this.props.webOfferCode === 'gift') {
      redeemComp(userId, this.state.promoCode).then(() => {
        mixpanel.people.set_once('parseAccountId', userId);
        if (this.props.webOfferCode === 'gift') {
          // gtm
          gtmEvent({
            action: 'promoRedemption',
            'promoCode': this.state.webOffer.promoCode,
            'promoType': 'gift'
          });
          // mixpanel
          mixpanel.people.set({
            'Account Status': 'Active Gifted',
            email: this.state.email,
            isInTrial: false,
            productId: 'Gift', // server will fill this out I hope
            promoCode: this.state.webOffer.promoCode,
            subscriber: true,
            subscriptionStatus: 'active'
          });
        } else {
          // gtm
          gtmEvent({
            action: 'promoRedemption',
            'promoCode': this.state.webOffer.promoCode,
            'promoType': 'comp'
          });
          // mixpanel
          mixpanel.people.set({
            email: this.state.email,
            productId: 'Comp',
            promoCode: this.state.webOffer.promoCode,
            subscriber: false
          });
        }

        mixpanel.identify(userId);
        trackPageMixpanel('WebPaymentsV3 Action: Subscribe', { gift: this.props.webOfferCode === 'gift' });
        this.props.history.push('/promo-thankyou');
      }).catch((error) => {
        console.log('Error during redeeming comp/gift process', error);
        if (typeof error.message === 'string' && error.message.indexOf('already used') > -1) {
          return notify('error', 'Sorry, this code has already been used.', 5000);
        }

        if (typeof error.message === 'object' && !__isEmpty(error.message)) {
          if (error.message.message.indexOf('active sub')) {
            return notify('info', 'It seems your subscription hasn\'t expired yet. Please click here to view your account.', 0, () => {
              this.props.history.push('/my-account');
            });
          }
        }
        notify('error', 'There seems to be an error with redeeming this code. Please contact customer support.', 5000);
      });
    }

    // volume licensing payments
    if (this.props.webOfferCode === 'volume-license') {
      redeemVolumeLicense(userId, this.state.promoCode).then((results) => {
        mixpanel.people.set_once('parseAccountId', userID);
        mixpanel.people.set({
          email: this.state.email,
          productId: this.state.webOffer.planId,
          promoCode: this.state.webOffer.promoCode,
          subscriber: false
        });
        mixpanel.identify(userId);
        trackPageMixpanel('WebPaymentsV3 Action: Subscribe', { gift: this.props.webOfferCode === 'gift' });
        this.props.history.push('/promo-thankyou');
      }).catch((error) => {
        console.error('Volume license redemption error', error);
        if (typeof error.message === 'string' && error.message.indexOf('already used') > -1) {
          notify('error', 'Sorry, this code has already been used.', 5000);
        } else if (typeof error.message === 'object') {
          if (error.message.message.indexOf('active sub')) {
            notify('info', 'It seems your subscription hasn\'t expired yet. Please click here to view your account.', 0, () => {
              this.props.history.push('/my-account');
            });
          }
        }
        notify('error', 'There seems to be an error with redeeming this code. Please contact customer support.', 5000);
      });
    }
  }

  updateCaptcha = (value) => {
    if (value) {
      this.setState({ captchaToken: value });
    }
  }

  render() {
    return (
      <div id="promoV2" className="promo-container">
        <Helmet>
          <title>Redeem Promo Codes | codeSpark Academy</title>
        </Helmet>
        <div className="promo-splash grid-x">
          <Suspense fallback={renderLoader()}>
            <PromoSplashComponent webOffer={this.state.webOffer} />
          </Suspense>
          <div className="onboarding-section small-12 medium-12 large-6">
            <div className="step-progress-image">
              <img src={`${imgURL}/accounts/progress_steps.svg`} alt="progress line" />
            </div>
            <div className="form-block">
              {
                this.state.isLoggedIn ? (
                  <Suspense fallback={renderLoader()}>
                    <LoggedInForm
                      {...this.state}
                      setPromoCode={this.setPromoCode}
                      submitForm={this.submitForm}
                      location={this.props.location}
                      isValidCode={this.state.isValidCode}
                    />
                  </Suspense>
                ) : (
                  <Suspense fallback={renderLoader()}>
                    <RegisterForm
                      {...this.state}
                      location={this.props.location}
                      setEmail={this.setEmail}
                      setEmailToConfirm={this.setEmailToConfirm}
                      setPasswordToSubmit={this.setPasswordToSubmit}
                      setConfirmedPassword={this.setConfirmedPassword}
                      setPromoCode={this.setPromoCode}
                      handleCheckChange={this.handleCheckChange}
                      registerForm={this.registerUser}
                      loginForm={this.loginForm}
                      isValidCode={this.state.isValidCode}
                      updateCaptcha={this.updateCaptcha}
                    />
                  </Suspense>
                )
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  savePromoCoupon,
  saveWebOfferCode,
  saveWebOffer,
  saveEmail,
  setPasswordErrorClass,
  setConfirmPasswordErrorClass,
  showUserNoUpgrade,
  setResubscribe
};

const mapStateToProps = state => ({
  signupPopupOpen: state.appState.signupPopupOpen,
  emailToSave: state.appState.emailToSave,
  webOfferCode: state.appState.webOfferCode,
  referralToSave: state.appState.referralToSave
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromoV2));
