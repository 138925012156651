import React, { memo } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from '../../styles';
import { usePaymentComplete } from '../../providers/index';

function Register(props) {
  const classes = useStyles();
  const { selectedMinervaOfferId } = usePaymentComplete();

  return (
    <React.Fragment>
      {selectedMinervaOfferId === 'null' && (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          className={classes.stepContainer}
        >
          <Grid item className={classes.stepNumber}>
            <Avatar>3</Avatar>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Grid item className={classes.stepsDetailContainer}>
                <Typography variant="h3" className="stepTitle">
                  Register for a class
                </Typography>
                <Typography variant="body1">
                  In the next few hours, you will receive instructions on how to complete a class registration.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default memo(Register);
