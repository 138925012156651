import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import __includes from 'lodash/includes';
import __isEmpty from 'lodash/isEmpty';
import {
  createBraintreeInstance,
  createBraintreeHostedFields,
  createPaypalInstance,
} from '../../../../../services/braintree';
import { notify } from '../../../../../services/functions';
import {
  getCurrentUser,
  getMinervaClassAddOns,
} from '../../../../../services/parse';
import { processUserSubscription, determinePaymentError, sendMixPanelEvent } from './service';
import { sendFacebookEvent } from '../../../../../services/facebook-capi';
import { fieldStyles, fieldSettings } from './constants';
import { useSelector } from 'react-redux';

const AddClassesPaymentContext = React.createContext();
const PAYMENT_PAGE = 'Memberships v2.1 Page: Checkout';

export function usePayment() {
  return useContext(AddClassesPaymentContext);
}

export function AddClassesPaymentProvider({ children }) {
  const history = useHistory();
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState(null);
  const [payPalInstance, setPaypalInstance] = useState(null);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [braintreeInstance, setBraintreeInstance] = useState(null);

  const selectedMinervaOffer = useSelector((state) => state.appState.minervaAddedClassId);

  const setUpBraintreeHostedFields = async (clientInstance) => {
    await createBraintreeHostedFields(
      {
        client: clientInstance,
        styles: fieldStyles,
        fields: fieldSettings,
      },
      (hostedFieldsErr, hostedFieldsInstance) => {
        // transfer business logic to a dedicated file
        if (hostedFieldsErr) {
          console.log('Braintree hosted fields error', hostedFieldsErr);
          notify(
            'error',
            'Hmm. There seems to be a problem with loading the payment screen. Please refresh the page and try again.',
            3000
          );
          return;
        }

        hostedFieldsInstance.on('focus', (e) => {
          // get the parent and add the transition class
          e.fields[e.emittedBy].container.classList.add('focused');
        });

        hostedFieldsInstance.on('blur', (e) => {
          // get the parent and remove the transition class
          e.fields[e.emittedBy].container.classList.remove('focused');
        });

        // we want to show the card type's logo
        hostedFieldsInstance.on('cardTypeChange', (e) => {
          console.log(e);
          console.log(e.fields);
          console.log(e.cards);
          if (e.fields && e.cards && __includes(e.fields[e.emittedBy].container.classList, e.cards[0].type)) {
            e.fields[e.emittedBy].container.classList.remove(e.cards[0].type);
          }

          if (e.cards.length === 1) {
            e.fields[e.emittedBy].container.classList.add(e.cards[0].type);
          } else {
          }
        });

        setHostedFieldsInstance(hostedFieldsInstance);
      }
    );
  };

  const setUpPaypalInstance = async (clientInstance) => {
    createPaypalInstance(
      {
        client: clientInstance,
      },
      (paypalErr, paypalInstance) => {
        if (paypalErr) {
          console.log('Error with paypal', paypalErr);
          notify(
            'error',
            'Hmm. There seems to be a problem with loading PayPal. Please refresh the page and try again.',
            5000
          );
          return;
        }

        setPaypalInstance(paypalInstance);
      }
    );
  };

  const setupPaymentsInstance = async () => {
    const braintreeInstance = await createBraintreeInstance();
    setBraintreeInstance(braintreeInstance);
    await setUpBraintreeHostedFields(braintreeInstance);
    await setUpPaypalInstance(braintreeInstance);
  };

  const sendPayload = async (error, payload) => {
    if (error) {
      const errorMessage = determinePaymentError(error);
      notify('error', errorMessage, 5000);
      setIsProcessingPayment(false);
    }

    if (!error) {
      processUserSubscription({
        paymentMethodToken: payload.nonce,
        classAddOnId: selectedMinervaOffer,
      })
        .then((subscription) => {
          return sendFacebookEvent({
            event_name: 'StartTrial',
            event_source_url: window.location.href,
            custom_data: {
              'subscriptionID': subscription.id,
              timestamp: Math.floor(Date.now() / 1000),
              source: 'codespark',
              campaign: 'minerva',
            },
          });
        }).then(() => {
          const fourClass = JSON.parse(sessionStorage.getItem('fourClass'));
          setIsProcessingPayment(false);
          if (selectedMinervaOffer === fourClass.planId) {
            history.push('/addclasses-thankyou-semester');
          } else {
            history.push('/addclasses-thankyou-dropin');
          }
        })
        .catch((error) => {
          console.log(error);
          setIsProcessingPayment(false);
        });
    }
  };

  const processPayment = async (paymentMethod) => {
    sessionStorage.setItem('paymentMethod', paymentMethod);
    setIsProcessingPayment(true);

    if (paymentMethod === 'card') {
      hostedFieldsInstance.tokenize(sendPayload);
    }

    if (paymentMethod === 'paypal') {
      await payPalInstance.tokenize({ flow: 'vault' }, sendPayload);
    }
  };

  useEffect(() => {
    let selectedAddOnPlan;
    getMinervaClassAddOns().then((plans) => {
      selectedAddOnPlan = plans.find((x) => x.get('planId') === selectedMinervaOffer);
      if (selectedMinervaOffer) {
        sendMixPanelEvent(PAYMENT_PAGE, {
          productId: selectedMinervaOffer,
          productionDuration: selectedAddOnPlan?.attributes.subscriptionDuration,
        });
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    //performance.navigation.type != 1 checking if it is a page refresh
    //only route to /addclasses if no selected offer and it is NOT a page refresh
    if (!getCurrentUser() || (performance.navigation.type != 1 && __isEmpty(selectedMinervaOffer))) {
      history.replace('/addclasses');
    }

    setupPaymentsInstance();
  }, []);

  return (
    <AddClassesPaymentContext.Provider
      value={{
        processPayment,
        isProcessingPayment,
      }}
    >
      {children}
    </AddClassesPaymentContext.Provider>
  );
}
