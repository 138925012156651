import React from 'react';
import { makeStyles, FormControlLabel, Checkbox } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  emailOptIn: {
    '& span': {
      fontFamily: 'Montserrat',
      fontSize: '12px',
      color: 'rgb(142, 144, 145)',
      lineHeight: '15px',
      marginBottom: '0.5rem',
    },
  },
}));

export default function CheckboxOptIn({ checkboxOptIn, onChange }) {
  const classes = useStyles();

  return (
    <FormControlLabel
      label="Exclusive offers, giveaways from codeSpark, and other services that might interest me? Count me in!"
      control={<Checkbox checked={checkboxOptIn} onChange={onChange} />}
      className={classes.emailOptIn}
    />
  );
}
