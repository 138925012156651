import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import ThemedMuiButton from '../../../components/elements/ThemedMuiButton';
import CodeSparkMuiTypography from '../../../components/elements/CodeSparkMuiTypography';
import { getIntroData } from '../data/introData';
import NotFound from '../../NotFoundPage';
import { ContentWidth, QuizContentWrapper } from './Layout';
import FooImage from './FooImage';
import { usePageViewEvent } from '../../../hooks/useAnalyticsEvent';

const useStyles = makeStyles(theme => ({
  quizIntroWrapper: {
    backgroundColor: '#F0F8FF',
  },
  header: {
    color: '#015798',
    textAlign: 'left',
  },
  text: {
    marginTop: '2rem',
    marginBottom: '2rem',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      marginTop: '1rem',
    },
  },
  ctaContainer: {
    marginBottom: '2rem',
  },
}));

function QuizIntro({ variant, onFadeOut }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const introData = getIntroData(variant);

  usePageViewEvent('web_onboarding_get_started_screen');

  if (!introData) {
    return <NotFound />
  }

  const { header, body } = introData;
  const handleQuizStart = () => {
    onFadeOut(`${pathname}/questions/1`);
  };

  return (
    <QuizContentWrapper className={classes.quizIntroWrapper}>
      <ContentWidth>
        <CodeSparkMuiTypography variant="h2" className={classes.header}>
          {header}
        </CodeSparkMuiTypography>

        <CodeSparkMuiTypography className={classes.text}>{body}</CodeSparkMuiTypography>
      </ContentWidth>

      <Box className={classes.ctaContainer}>
        <ThemedMuiButton color="primary" onClick={handleQuizStart}>
          Start Quiz
        </ThemedMuiButton>
      </Box>

      <FooImage image="Foos_dancing.svg" maxWidth="30.375rem" />
    </QuizContentWrapper>
  );
}

QuizIntro.propTypes = {
  onFadeOut: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

export default QuizIntro;
