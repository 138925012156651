export const initialMinervaClassesState = {
  minervaSelectedClassId: '',
  minervaSelectedClass: {},
};

export const minervaClassesReducers = {
  SET_MINERVA_SELECTED_CLASS_ID: (state, action) => {
    state.minervaSelectedClassId = action.payload;
  },
  SET_MINERVA_SELECTED_CLASS: (state, action) => {
    state.minervaSelectedClass = action.payload;
  },
};
