import mixpanel from 'mixpanel-browser';
import { SubscribeToMinervaClasses, getCurrentParseUser } from '../../../../../services/parse';
import { tokenizedErrorMessages } from './constants';
import { notify } from '../../../../../services/functions';
import { addCustomer } from '../../../../../gateways/HubSpotApiGateway';

/**
 * Determine error from braintree
 * @param {*} tokenizeError
 * @returns {string}
 */
 export function determinePaymentError(tokenizeError) {
  if (tokenizedErrorMessages[tokenizeError.code]) {
    return tokenizedErrorMessages[tokenizeError.code];
  }

  return 'An error seems to have occurred during payment processing. Please try again.';
}

/**
 * Mix Panel event handler
 * @param {string} eventName
 * @param {*} eventData
 */
 export function sendMixPanelEvent(eventName, eventData) {
  mixpanel.track(eventName, eventData);
}

/**
 *
 * @param {object} user User Details
 * @param {string} offerObjectId Offer Object Id
 * @param {*} payload
 */
 export async function processUserSubscription({ paymentMethodToken, classAddOnId }) {
  try {
    let utmParamsObject = sessionStorage.getItem('utmParamsObject');

    if (utmParamsObject) {
      utmParamsObject = JSON.parse(utmParamsObject);
    }

    const subscription = await SubscribeToMinervaClasses({
      paymentMethodToken,
      classAddOnId,
    });
    const loggedInUser = getCurrentParseUser();
    if(loggedInUser){
      console.log('logged in user email', loggedInUser.get('email'));
      const hubspotData = {
        email: loggedInUser.get('email'),
        productId: classAddOnId,
        hs_lead_status: 'Subscribed',
      };
      console.log('hubspotData', hubspotData);
      await addCustomer(hubspotData);
    }
    return subscription;
  } catch (error) {
    notify('error', error.message);
  }
}