import React from 'react';
import { Divider, Grid, Hidden } from '@material-ui/core';

import {
  Stats,
  TrustPilot,
  ParentsChoiceAward,
  ContainerTitle,
  Glitch,
} from '../../common';
import { useStyles } from './styles';

function Tablet() {
  const classes = useStyles();
  return (
    <Hidden mdUp xsDown>
      <Glitch />
      <Grid className={classes.contentContainer}>
        <ContainerTitle />
        <Grid>
          <Divider />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.awardsContainer}
        >
          <Grid item>
            <ParentsChoiceAward />
          </Grid>
          <Grid item>
            <TrustPilot />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.statsContainer}
        >
          <Grid item>
            <Stats
              label={'12.6M'}
              description={'games and stories created in 2021'}
            />
          </Grid>
          <Grid item>
            <Stats
              label={'55%'}
              description={'increase in problem-solving confidence'}
            />
          </Grid>
          <Grid item>
            <Stats
              label={'120K'}
              description={'educators worldwide use codeSpark'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Hidden>
  );
}

export default Tablet;
