import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import __isEmpty from 'lodash/isEmpty';
import __upperFirst from 'lodash/upperFirst';
import mixpanel from 'mixpanel-browser';
import { Chip, Grid, Button } from '@material-ui/core';
import { getCurrentUser, login, getUserSubscriptionDetails } from '../../../services/parse';
import { notify } from '../../../services/functions';
import { showUserNoUpgrade, setResubscribe } from '../../../redux/actions';
import { sendAnalyticsEvent } from '../../../services/analytics';
import '../MinervaSignup/MinervaSignup.scss';
import { gtmPageviewEvent } from '../../../services/google-tag-manager';
import { isFeatureEnabled } from '../../../featureFlags';

const { trackPageMixpanel } = require('../../../services/functions');
class MinervaClassLogin extends React.Component {
  constructor(props) {
    super(props);

    if (!__isEmpty(getCurrentUser())) {
      this.props.history.push('/tryclasses-payment');
    }

    this.state = {
      email: props.emailToSave || '',
      password: '',
      user: {},
      showPassword: false,
      loading: false,
    };
    this.loginUser = this.loginUser.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }

  componentDidMount() {
    console.log(this.state.loading);
    gtmPageviewEvent({
      pageName: 'WebPaymentsV3 Page: Login',
      componentProps: this.props,
      callback: () => sendAnalyticsEvent('Memberships v2 Page: Login'),
    });

    const props = this.props.location;
    if (props.state !== undefined && props.state.fromRegHasEmail) {
      notify('info', 'It looks like you have an account. Log in below.', 5000);
    }
  }

  setEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  setPasswordToSubmit = (e) => {
    this.setState({ password: e.target.value });
  };

  submitForm(e) {
    e.preventDefault();
    this.loginUser();
  }

  loginUser() {
    // toggle gift user early resubscribe on/off
    const canGiftSubsResubEarly = true;
    this.setState({ loading: true });

    login({
      username: this.state.email,
      password: this.state.password,
    })
      .then((user) => {
        // user variable only returns id
        mixpanel.identify(user.id);
        this.setState({ user: user.attributes });

        //Get user subscription data to judge where the user should go next
        getUserSubscriptionDetails(user.id)
          .then((user) => {
            let hasActiveSubscription = !user.isExpired && !__isEmpty(user.plan);

            if (canGiftSubsResubEarly && user.isExpired === false && user.isGifted && !user.isLifetime) {
              // not expired and gifted and not lifetime gifted
              // let them through and we can set the subscription start to after they expire
              this.props.setResubscribe(true);
              this.goToNextPage(false);
            } else {
              // the next page will figure out if they can resubscribe or not
              this.setState({ loading: false }, () => {
                this.goToNextPage(hasActiveSubscription);
              });
            }
          })
          .catch((error) => {
            //throw a hard fail so that users we can't get details for can't trick the system
            console.log(error);
            notify('error', 'Sorry, something went wrong. Please try again or contact us if this persists.', 5000);
            trackPageMixpanel('WebPaymentsV3 Error: Login', {
              username: this.props.emailToSave,
              notes: 'Error getting user sub data',
            });
          });
      })
      .catch((error) => {
        trackPageMixpanel('WebPaymentsV3 Error: Login', { username: this.props.emailToSave });
        notify('error', __upperFirst(error.message.replace('.', '')), 5000);
      });
  }

  goToNextPage(hasActiveSubscription) {
    this.props.history.push('/tryclasses-payment');
  }

  showFormProcessing() {
    //change the button style - this is an isolated event
    document.getElementById('login-submit').classList.add('processing', 'alt-gray');
    document.getElementById('login-submit').getElementsByClassName('button')[0].value = 'PROCESSING...';
  }

  removeFormProcessing() {
    //return submit button back to normal state
    document.getElementById('login-submit').classList.remove('processing', 'alt-gray');
    document.getElementById('login-submit').getElementsByClassName('button')[0].value = 'Continue';
  }

  togglePassword() {
    this.setState({ showPassword: !this.state.showPassword });

    const field = document.getElementById('password-field');
    if (field.type === 'password') {
      field.type = 'text';
    } else {
      field.type = 'password';
    }
  }

  render() {
    const useNewResetPassword = isFeatureEnabled('codesparkUnification');
    const resetPasswordPath = useNewResetPassword ? '/request-password-reset' : '/reset-password';

    return (
      <div className="wrapper">
        <div className="minerva-signup-page">
          <div className="stepBadge-container">
            <Chip label="STEP 2 OF 4" className="stepBadge" />
          </div>
          <div className="minerva-signup-title">
            <h2>
              Log in to
              <br className="break-line" /> your account
            </h2>
          </div>
          <div className="minerva-login">
            Don&apos;t have an account yet? &nbsp;
            <Link to="/tryclasses-signup">Sign up</Link>
          </div>
          <div className="minerva-signup-form">
            <form onSubmit={(e) => this.submitForm(e)}>
              <div className="small-12 medium-6 input-fields">
                <div className="textbox">
                  <input
                    type="text"
                    name="email"
                    onChange={this.setEmail}
                    value={this.state.email}
                    placeholder="Email address"
                  />
                </div>
              </div>
              <div className="small-12 medium-6 input-fields">
                <div className="textbox">
                  <input
                    type="password"
                    name="password"
                    id="password-field"
                    onChange={this.setPasswordToSubmit}
                    placeholder="Password"
                  />
                  <span
                    id="password-field"
                    className={`field-icon ${this.state.showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}`}
                    onClick={this.togglePassword}
                  ></span>
                </div>
                <div className="input-helper">
                  <Link to={resetPasswordPath}>
                    <p>Forgot your password?</p>
                  </Link>
                </div>
              </div>
              <div>
                <Grid container spacing={3} className="bottom-buttons">
                  <Grid item xs={12} sm={6}>
                    <a href="/tryclasses-signup">
                      <Button variant="contained" fullWidth className="back-button bot-back-button">
                        <div className="button-text">BACK</div>
                      </Button>
                    </a>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      fullWidth
                      className="next-button bot-next-button"
                      onClick={(e) => this.submitForm(e)}
                    >
                      {this.state.loading && (
                        <i className="fa fa-refresh fa-spin" style={{ marginRight: '1rem', color: 'white' }} />
                      )}
                      <div className="button-text">NEXT</div>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </form>
          </div>
          <div className="terms-and-conditions">
            <a href={`${process.env.REACT_APP_CODESPARK_BASE_URL}/terms`}>Terms and Conditions</a>&nbsp;apply
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { showUserNoUpgrade, setResubscribe };

const mapStateToProps = (state) => ({
  emailToSave: state.appState.emailToSave,
  expectedNextPage: state.appState.expectedNextPage,
  promoCouponCode: state.appState.promoCouponCode,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MinervaClassLogin));
