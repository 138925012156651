import { COLLABORATOR, CREATIVE, PROBLEM_SOLVER, TINKERER } from '../constants';

const variant1SummaryData = {
  [TINKERER]: {
    // temporarily use query param to disable cloudfront cache until chris comes back
    image: 'Woz_blob_head.svg?v=2',
    description: 'Always seeking hands-on activities and exploration.',
    learningStrategy:
      'The Tinkerer learns best through experimentation, trial and error, and good-ole-fashioned elbow grease. They’ll build as they learn, and learn as they build!',
    modes: [
      { image: 'game_maker_mode.png', title: 'Game Maker Mode' },
      { image: 'adventure_game_mode.png', title: 'Adventure Game Mode' },
    ],
    modeBullets: [
      'Build crucial skills through exploration and self-guided, progressive puzzle games',
      'Flex skills to build games from scratch, or remix millions of kid-created games',
      'Design more complex games as skill level increases—the sky’s the limit!',
      'Play & learn (and compete in contests!) with other kid-coders in our safe, moderated online community',
    ],
  },
  [CREATIVE]: {
    image: 'Sarge_blob_head.svg?v=2',
    description: 'Loves to tell stories and has a great imagination.',
    learningStrategy:
      'The Creative learns best through storytelling, a supportive atmosphere, and hands-on practice. They chart their own path to coding success!',
    modes: [
      { image: 'story_mode.png', title: 'Story Mode' },
      { image: 'adventure_game_mode_2.png', title: 'Adventure Game Mode' },
    ],
    modeBullets: [
      'Build crucial coding skills through creative expression in Story Mode',
      'Cultivate story ideas from scratch or remix millions of kid-created games',
      'Design more complex stories & games as skill level increases—the sky’s the limit!',
      'Play & learn (and compete in contests!) with fellow kid-coders in our safe, moderated online community',
    ],
  },
  [PROBLEM_SOLVER]: {
    image: 'Gracie_blob_head.svg?v=2',
    description: 'Always wants to know why and comes up with solutions.',
    learningStrategy:
      'The Problem Solver learns best with their thinking cap on! They crave analysis and adventure in equal measure, and thrive with structure.',
    modes: [
      { image: 'code_puzzles.png', title: 'Code Puzzles' },
      { image: 'pet_puzzles.png', title: 'Pet Puzzles' },
    ],
    modeBullets: [
      'Build crucial coding skills through progressive, intuitive, self-guided puzzles',
      'Flex skills to build games from scratch, or remix millions of kid-created games',
      'Design more complex stories & games as skill level increases—the sky’s the limit!',
      'Play & learn (and compete in contests!) with fellow kid-coders in our safe, moderated online community',
    ],
  },
  [COLLABORATOR]: {
    image: 'Naomi_blob_head.svg',
    description: 'Loves to help and play with others.',
    learningStrategy:
      'The Collaborator learns best through interactive gameplay and engaging, scaffolded lessons. Collaborators will love building adventures for all to enjoy, and coding in a flash to win at Splash Clash!',
    modes: [
      { image: 'splash_clash.png', title: 'Splash Clash' },
      { image: 'adventure_game_mode.png', title: 'Adventure Game Mode' },
    ],
    modeBullets: [
      'Create games and troubleshoot bugs to build crucial coding skills',
      'Get social by challenging other kid-coders to a coding battle in Splash Clash',
      'Design more complex stories & games as skill level increases—the sky’s the limit!',
      'Play & learn (and compete in contests!) with fellow kid-coders in our safe, moderated online community',
    ],
  },
};

export default function getSummaryData(coderType) {
  return variant1SummaryData[coderType];
}

export const variant2SummaryData = {
  title: 'The #1 Learn-to-Code App for Kids 5—10',
  subtitle: 'We’re making critical skills accessible for kids everywhere',
  valueProps: [
    {
      header: 'Intuitive, word-free interface',
      value: 'Our patented technology lets kids learn to code before they even know how to read.',
    },
    {
      header: 'Perfect for girls and boys',
      value:
        'codeSpark is carefully designed to increase engagement in girls, and close the gender gap in STEM.',
    },
    {
      header: 'Trusted by 130,000+ educators',
      value:
        'Home subscriptions help us fund and integrate computer science in low-income schools.',
    },
  ],
};
