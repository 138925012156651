import React from 'react';

import { Divider, Grid, Hidden } from '@material-ui/core';
import { ContainerTitle, Glitch, ParentsChoiceAward, TrustPilot, Stats } from '../../common';
import { useStyles } from './styles';

function Mobile() {
  const classes = useStyles();
  return (
    <Hidden smUp>
      <Glitch />
      <Grid className={classes.contentContainer}>
        <ContainerTitle />
        <Grid>
          <Divider />
        </Grid>
        <Grid container direction="column" className={classes.awardsContainer}>
          <Grid item className={classes.item}>
            <ParentsChoiceAward />
          </Grid>
          <Grid item className={classes.item}>
            <Stats label={'12.6M'} description={'games and stories created in 2021'} />
          </Grid>
          <Grid item className={classes.item}>
            <Stats label={'55%'} description={'increase in problem-solving confidence'} />
          </Grid>
          <Grid item className={classes.item}>
            <Stats label={'120K'} description={'educators worldwide use codeSpark'} />
          </Grid>
          <Grid item className={classes.item}>
            <TrustPilot />
          </Grid>
        </Grid>
      </Grid>
    </Hidden>
  );
}

export default Mobile;
