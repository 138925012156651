import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    contentContainer: {
      backgroundColor: '#F2F8FD',
      borderRadius: '20px',
    },
    awardsContainer: {
      paddingTop: 30,
      paddingBottom: 30,
    },
    parentsLabel: {
      marginLeft: 10,
      fontSize: 14,
      fontFamily: 'Montserrat',
      maxWidth: '250px',
      textAlign: 'center',
    },
    item: {
      marginBottom: 30,
    },
    rightColumn: {
      [theme.breakpoints.down('1150')]: {
        maxWidth: '350px',
      },
    },
  })
);
