const { getCookieValue } = require('../utils/cookieUtils');

/**
 * Send an event to the codeSpark analytics API, which 
 * sends the request to the Facebook Conversions API
 * 
 * Note(s): 
 * You don't need to wait for the event to finish. If it fails it will try again.
 * The server will take care of appending some info, such as the user's client_ip_address and client_user_agent
 * 
 * TODO: should we only call this on production? Or I can catch that in Lambda
 * 
 * @param {string} details.event_name - event name  (required)
 * @param {string} details.event_source_url  - url that the user was on when the request was made (required)
 * @param {string} details.email  - request user's email (optional)
 * @param {*} details.custom_data  - anything else we want to pass, such as the screen that the user was on during a 'Visit' event (optional)
 * @returns {*}
 */
export const sendFacebookEvent = (details) => {
  if (window.location.hostname === 'localhost' || 
      window.location.hostname === '127.0.0.1') {
        return;
  }
  // anything custom to pass. Optional
  if (!details.custom_data) {
    details.custom_data = {}
  }

  // the url that was passed at time of request, 
  // note that it may include the query string
  let source_url = details.event_source_url;

  // fbp and fbc are Facebook cookies
  const fbp = getCookieValue('_fbp');
  if (fbp) {
    details.fbp = fbp;
  }
  const fbc = getCookieValue('_fbc');
  if (fbc) {
    details.fbc = fbc;
  }

  // overwrite event_source_url to be the url without the query string
  details.event_source_url = source_url.split('?')[0];
  // pass utm params 
  let utmParamsObject = sessionStorage.getItem('utmParamsObject');
  if (utmParamsObject) {
    utmParamsObject = JSON.parse(utmParamsObject);
    const attributionData = utmParamsObject.attributionData;
    if (attributionData.utm) {
      if (attributionData.utm['utm_source [last touch]']) {
        details.custom_data.utm_source = attributionData.utm['utm_source [last touch]'];
      }
      if (attributionData.utm['utm_medium [last touch]']) {
        details.custom_data.utm_medium = attributionData.utm['utm_medium [last touch]'];
      }
      if (attributionData.utm['utm_campaign [last touch]']) {
        details.custom_data.utm_campaign = attributionData.utm['utm_campaign [last touch]'];
      }
      if (attributionData.utm['utm_content [last touch]']) {
        details.custom_data.utm_content = attributionData.utm['utm_content [last touch]'];
      }
    }
  }
  return new Promise((resolve, reject) => {
    const apiURL = process.env.REACT_APP_FACEBOOK_EVENTS_API;
    fetch(apiURL, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(details),
    }).then((response) => {
      return resolve(response.json())
    }).catch((error) => {
      console.error('Facebook API error', error);
      return reject(error)
    });


  });
};

