import { createAction } from '@reduxjs/toolkit';
import {
  SET_EXTERNAL_ANSWERED_QUESTION_ID,
  SET_QUIZ_QUESTIONS,
  SET_QUIZ_QUESTION_ANSWER,
} from './constants';

export const setQuizQuestions = createAction(SET_QUIZ_QUESTIONS);
export const setQuizQuestionAnswer = createAction(SET_QUIZ_QUESTION_ANSWER);
export const setExternalAnsweredQuestionId = createAction(SET_EXTERNAL_ANSWERED_QUESTION_ID);
