import { Box, Grid, styled, CircularProgress } from '@material-ui/core';
import React from 'react';
import CodeSparkMuiTypography from '../../CodeSparkMuiTypography';

const imgURL = `${process.env.REACT_APP_CLOUDFRONT_URL}/accounts`;

export const Loader = ({ className, ...props }) => {
  return (
    <Box
      component="section"
      className={className}
      display="flex"
      justifyContent="center"
      color="#ffffff"
      {...props}
    >
      <CircularProgress color="inherit" aria-describedby="progress-bar" aria-busy />
    </Box>
  );
};

export const SelectPlanSection = styled(props => (
  <Box component="section" id="select-plan-section" {...props} />
))(({ theme }) => ({
  backgroundImage: `url(${imgURL}/plan_selector_bg_variant_desktop.png)`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  [theme.breakpoints.down('sm')]: {
    backgroundImage: `url(${imgURL}/plan_selector_bg_variant_mobile.png)`,
  },
}));

export const PageHeader = styled(props => <CodeSparkMuiTypography variant="h1" {...props} />)(
  ({ theme }) => ({
    color: '#ffffff',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '18rem',
    },
  })
);

export const SubHeader = styled(props => <CodeSparkMuiTypography variant="h5" {...props} />)(
  ({ theme, width }) => ({
    color: '#ffffff',
    marginTop: '2.375rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: width ?? '10rem',
      marginTop: '1rem',
    },
  })
);

export const SelectPlanContainer = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  padding: '4rem 11rem',
  maxWidth: '90rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: '3rem 1.5rem',
    maxWidth: '64rem',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2rem 1.5rem',
  },
}));

export const SelectPlanWidthContainer = styled(Box)(({ theme }) => ({
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    maxWidth: '18.4375rem',
  },
}));

export const SubscriptionPlansContainer = styled(({ desktop, mobile, ...props }) => (
  <Grid container spacing={4} {...props} />
))({
  marginTop: '1.5rem',
  justifyContent: 'center',
  borderRadius: '0.625rem',
});
