import React, { memo } from 'react';

import Grid from '@material-ui/core/Grid';
import Download from '../Steps/Download';
import Signin from '../Steps/Signin';
import TakeOver from '../Steps/TakeOver';
import Register from '../Steps/Register';
import { useStyles } from '../../styles';

function Content(props) {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.detailsContainer}
        >
            <Grid item className={classes.contentContainer}>
                <Download />
                <Signin />
                <Register />
                <TakeOver />
            </Grid>
        </Grid>
    )
};

export default memo(Content);