const introDataVariantMapping = {
  quizVariant1: {
    header: 'Let’s Crack the Code on Your Child’s Learning Style',
    body: 'codeSpark is designed to teach your child code through a unique mix of structured lessons, interactive puzzles & challenges, and creative space. Take this quiz to discover how your kid-coder will conquer coding best.',
  },
  quizVariant2: {
    header: 'Let’s Get a Read on Your Kid-Coder’s Learning Style',
    body: 'codeSpark is designed to teach your child code through interactive games, stories and puzzles. Take this quiz to discover how your kid-coder will conquer coding best.',
  },
};

export const getIntroData = variant => {
  return introDataVariantMapping[variant];
};
