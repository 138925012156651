import React, { lazy, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import __isEmpty from 'lodash/isEmpty';
import { Helmet } from 'react-helmet';
import mixpanel from 'mixpanel-browser';
import { gtmEvent, gtmPageviewEvent } from '../../services/google-tag-manager';
import './Home.scss';
import { getCurrentUser } from '../../services/parse';
import { sendFacebookEvent } from '../../services/facebook-capi';
import { connect } from 'react-redux';
import HomeRedesign from './variants/Redesign';
import { usePageViewEvent } from '../../hooks/useAnalyticsEvent';
const queryString = require('query-string');
const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;

const SubscriptionBlock = lazy(() => import('../../components/elements/SubscriptionBlock'));
const renderLoader = () => <h1>Loading</h1>;

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVariant: false,
      variant: '',
    };
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      if (window.google_optimize !== undefined) {
        const variant = window.google_optimize.get(process.env.REACT_APP_EXPERIMENT_ID);
        this.setState({ variant });
        sessionStorage.setItem('variantId', variant);

        mixpanel.track('WebPaymentsV3 Page: Choose Plan', {
          'Optimize experiment variant': `${process.env.REACT_APP_EXPERIMENT_ID} - variant ${variant}`,
        });

        clearInterval(this.intervalId);
      }
    }, 100);

    // send ProfitWell code snippet
    if (process.env.NODE_ENV === 'production') {
      const user = JSON.parse(getCurrentUser());
      if (!__isEmpty(user)) {
        gtmEvent({ action: 'start_pw', pw_user_email: user.email });
      } else {
        gtmEvent({ action: 'start_pw' });
      }
    }

    // opt out Google Analytics for test env
    if (process.env.NODE_ENV !== 'test') {
      gtmPageviewEvent({
        pageName: 'WebPaymentsV3 Page: Choose Plan',
        componentProps: this.props
      });
    }

    setTimeout(() => {
      sendFacebookEvent({
        event_name: 'Onboarding Start',
        event_source_url: window.location.href,
      });
      sendFacebookEvent({
        event_name: 'Onboarding Screen',
        event_source_url: window.location.href,
        custom_data: { screen: 'Home' },
      });
    }, 0);

    // grab any url params and store those in utmParmsObject to be passed in on checkout
    if (!__isEmpty(this.props.location.hash) || !__isEmpty(this.props.location.search)) {
      let parsed;
      // set parsed params based on whether the link includes a hash or just a ? (search) param
      if (!__isEmpty(this.props.location.hash)) {
        parsed = queryString.parse(this.props.location.hash);
      }

      if (!__isEmpty(this.props.location.search)) {
        parsed = queryString.parse(this.props.location.search);
      }

      const paramsObj = {};
      paramsObj.attributionData = {};
      paramsObj.attributionData.utm = {};
      paramsObj.attributionData.utm['utm_campaign [last touch]'] = parsed.utm_campaign;
      paramsObj.attributionData.utm['utm_source [last touch]'] = parsed.utm_source;
      paramsObj.attributionData.utm['utm_medium [last touch]'] = parsed.utm_medium;
      paramsObj.attributionData.utm['utm_term [last touch]'] = parsed.utm_term;
      paramsObj.attributionData.utm['utm_content [last touch]'] = parsed.utm_content;
      // Iterable
      paramsObj.attributionData.iterable = {};
      paramsObj.attributionData.iterable.iterable_campaign = parsed.iterable_campaign;
      paramsObj.attributionData.iterable.iterable_template = parsed.iterable_template;
      sessionStorage.setItem('utmParamsObject', JSON.stringify(paramsObj));
      // not sure if attributionSource is different but want to make sure we get all the values that might come in
      sessionStorage.setItem('attributionSource', JSON.stringify(parsed));
    } else {
      sessionStorage.removeItem('utmParamsObject');
      sessionStorage.removeItem('attributionSource');
    }

    window.scrollTo(0, 0);
  }

  render() {
    const nextLocation = this.props.receivedOptimizelyVariantEnabled ? '/signup-payment' : '/signup-register';
    const {
      experiments: { planSelectorPageRedesignVariant1, planSelectorPageRedesignVariant2 }
    } = this.props;

    if (planSelectorPageRedesignVariant1 || planSelectorPageRedesignVariant2) {
      return (
        <HomeRedesign
          nextLocation={nextLocation}
        />
      );
    }

    return (
      <div id="header" className="signup-plan">
        <Helmet>
          <title>Sign up | codeSpark Academy</title>
        </Helmet>
        <div className="hero-banner grid-y">
          <div className="banner-content">
            <h1>Give your kids screen time that you can feel good about</h1>
          </div>
          <a href="#select-plan-section">
            <div className="cta-button orange">
              {/*prettier-ignore*/}
              <button
                data-event-category="button"
                data-event-action="signup-01-header"
                data-event-label="Try codespark today"
                data-on="click"
              >
                Try codespark today!
                <img className="basic-arrow" src={`${imgURL}/global/icons/basic_arrow_white.svg`} alt="Action arrow" />
              </button>
            </div>
          </a>
          <div className="banner-image">
            <img src={`${imgURL}/accounts/landing/celebrating-girl-mob.jpg`} alt="banner" />
          </div>
        </div>
        <Suspense fallback={renderLoader()}>
          <SubscriptionBlock variantId={this.state.variant} nextLocation={nextLocation} />
        </Suspense>
        <div className="subscription-offering">
          <div className="content grid-x grid-padding-x">
            <div className="small-12 medium-6 align-self-middle">
              <h2>Every codeSpark activity stimulates fundamental learning concepts and valuable life skills</h2>
            </div>
            <div className="offering-list cell small-12 medium-6">
              <h6>Membership includes</h6>
              <ul>
                <li>
                  <h5>
                    <img src={`${imgURL}/global/icons/check-blue.svg`} alt="checkmark" />
                    Unlimited coding challenges
                  </h5>
                </li>
                <li>
                  <h5>
                    <img src={`${imgURL}/global/icons/check-blue.svg`} alt="checkmark" />
                    New skills EVERY WEEK
                  </h5>
                </li>
                <li>
                  <h5>
                    <img src={`${imgURL}/global/icons/check-blue.svg`} alt="checkmark" />
                    100s of educational puzzles
                  </h5>
                </li>
                <li>
                  <h5>
                    <img src={`${imgURL}/global/icons/check-blue.svg`} alt="checkmark" />
                    Unlimited access to creativity suite
                  </h5>
                </li>
                <li>
                  <h5>
                    <img src={`${imgURL}/global/icons/check-blue.svg`} alt="checkmark" />
                    Access for up to 3 children
                  </h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="character-images">
          <img src={`${imgURL}/accounts/landing/foos-on-app.png`} alt="Foos using app" />
        </div>
        <div id="mission-and-awards" className="mission-and-awards">
          <h1>We’re making critical skills accessible for kids everywhere</h1>
          <div className="value-prop-container grid-x grid-padding-x">
            <div className="block small-12 medium-4 cell">
              <img src={`${imgURL}/flagship/home-page/mission-interface.png`} alt="Mission Value" />
              <h3>Intuitive, word-free interface</h3>
              <p>Our patented technology lets kids learn to code before they even know how to read</p>
            </div>
            <div className="block small-12 medium-4 cell">
              <img src={`${imgURL}/flagship/home-page/mission-kids.png`} alt="Mission Value" />
              <h3>Perfect for girls and boys</h3>
              <p>Carefully designed to increase engagement in girls and close the gender gap in STEM</p>
            </div>
            <div className="block small-12 medium-4 cell">
              <img src={`${imgURL}/flagship/home-page/mission-school.png`} alt="Mission Value" />
              <h3>Subsidized for schools</h3>
              <p>Home subscriptions help us fund and integrate computer science in low-income schools around the world</p>
            </div>
          </div>
          <div className="awards-section">
            <div className="header">Awards & Certifications</div>
            <div className="grid-x grid-margin-x align-spaced align-middle">
              <img className="award-icon" alt="" src={`${imgURL}/flagship/teacher-landing/awards/AASL_Digital+Tools+Circle_2020.png`} />
              <img className="award-icon" alt="" src={`${imgURL}/flagship/teacher-landing/awards/brain-toy.png`} />
              <img className="award-icon" alt="KAPi Award" src={`${imgURL}/flagship/how-it-works/awards/kapi-winner-transparent.png`} />
              <img className="award-icon no-show" alt="" src={`${imgURL}/flagship/how-it-works/awards/parents_choice_gold.png`} />
              <a href="https://www.kidsafeseal.com/certifiedproducts/codespark_thefoos_app.html" target="_blank" rel="noopener noreferrer">
                <img
                  className="single-award"
                  alt="codeSpark Academy with The Foos - coding for kids is certified by the kidSAFE Seal Program."
                  src="https://www.kidsafeseal.com/sealimage/1730524102862329251/codespark_thefoos_app_large_whitetm.png"
                />
              </a>
            </div>
          </div>
          <a href="#select-plan-section">
            <div className="cta-button bottom-button">
              <button
                data-event-category="button"
                data-event-action="signup-01-mission-and-awards"
                data-event-label="Start your FREE 7 day trial today"
                data-on="click"
              >
                Start your FREE 7 day trial today!
                <img className="basic-arrow" src={`${imgURL}/global/icons/basic_arrow_white.svg`} alt="Action arrow" />
              </button>
            </div>
          </a>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { experiments } = state;

  return {
    experiments,
  }
}

const ConnectedComponent = connect(mapStateToProps)(Home);
export default withRouter((props) => {
  usePageViewEvent('web_onboarding_screen_choose_plan');

  return <ConnectedComponent {...props} />
});
