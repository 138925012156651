import React from 'react';
import { Box, List, ListItem, styled, useMediaQuery, useTheme } from '@material-ui/core';
import { colors } from '../../../../../theme/constants';
import CodeSparkMuiTypography from '../../../CodeSparkMuiTypography';
import { ValuePropIcon, ValuePropText } from '../../../ValueProp';
import chunk from 'lodash/chunk';

const imgURL = `${process.env.REACT_APP_CLOUDFRONT_URL}/global`;

const ValuePropsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '3rem 0 4rem',
  maxWidth: '51rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    width: 'initial',
    margin: '1rem 0 1.5rem',
  },
}));

const ValuePropColumn = styled(List)({
  flex: 1,
});

const ValuePropTypography = styled(CodeSparkMuiTypography)(({ theme }) => ({
  color: colors.primary.black,
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
  },
}));

const chunkItemsToColumns = (items, numOfColumns) => {
  const itemsPerColumn = Math.ceil(items.length / numOfColumns);

  return chunk(items, itemsPerColumn);
};

export default function PlansInclude({ valueProps }) {
  const valuePropsColumns = chunkItemsToColumns(valueProps, 2);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ValuePropsContainer>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'initial' }}
        justifyContent="center"
        width="100%"
      >
        {valuePropsColumns.map((columnValues, i) => {
          return (
            <ValuePropColumn key={`plan_selector_c${i}`} disablePadding>
              {columnValues.map((valueProp, j) => {
                return (
                  <ListItem key={`plan_selector_c${i}_value_prop_${j}`}>
                    <ValuePropIcon
                      icon={`${imgURL}/checkmark-updated-blue.svg`}
                      size={isMobile ? 'small' : 'medium'}
                    />

                    <ValuePropText>
                      <ValuePropTypography variant="body2">{valueProp}</ValuePropTypography>
                    </ValuePropText>
                  </ListItem>
                );
              })}
            </ValuePropColumn>
          );
        })}
      </Box>
    </ValuePropsContainer>
  );
}
