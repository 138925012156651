import React from 'react';
import { Helmet } from 'react-helmet';

import './NotFoundWrapper.scss';

const NotFound = (props) => (
  <div className="page not-found-container">
    <Helmet>
      <title>Page Not Found | codeSpark Academy</title>
    </Helmet>
    <h1 className="four0four-title">404</h1>
    <h3 className="four0four-subtitle">You found a Glitch!</h3>
    <div className="image-container">
      <img className="four0four-image" src={`${process.env.REACT_APP_CLOUDFRONT_URL}/global/404_glitch.png`} alt="Glitch character" />
    </div>
    <p className="four0four-desc">This means you are looking for a page that doesn&rsquo;t exist.</p>
  </div>
);

export default NotFound;
