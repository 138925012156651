import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import __isEmpty from 'lodash/isEmpty';
import __toLower from 'lodash/toLower';
import { saveEmail, saveCheckboxOptIn } from '../../redux/actions';
import { sendAnalyticsEvent } from '../../services/analytics';
import { trackUserEvent } from '../../services/parse';
import { selectEnabledExperiment } from '../ExperimentsSetup/selectors';
import { usePageViewEvent } from '../../hooks/useAnalyticsEvent';
import { isUS } from '../../utils/geoIPUtils';
import { gtmEvent } from '../../services/google-tag-manager';

const INVALID_EMAIL_ERROR = 'Please enter a valid email address';

export function useEmailValidation() {
  const [{ email, error }, setValues] = useState({
    email: '',
    error: null,
  });

  const validateEmail = (emailInput) => {
    // validation code taken from the forms/RegistrationForm component
    //make sure it's a valid email address with regex
    const emailExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (__isEmpty(emailInput) || !emailExp.test(__toLower(emailInput))) {
      setValues((prevState) => ({
        ...prevState,
        error: INVALID_EMAIL_ERROR,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
      error: null,
    }));
    validateEmail(value);
  };

  return {
    email,
    error,
    handleChange,
  };
}

export function useEmailLeadWall() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { email, ...emailProps } = useEmailValidation();
  const optInStatus = useSelector((state) => state.appState.checkboxOptInToSave);
  const userCountry = useSelector((state) => state.appState.userCountryToSave);

  // CVR-770
  const quizVariants = ['quizVariant1', 'quizVariant2', 'quizHomepage'];
  const enabledQuiz = useSelector((state) =>
    selectEnabledExperiment(state, quizVariants)
  );
  // END CVR-770

  usePageViewEvent('web_onboarding_screen_lead_email');

  const handleNextClick = (e) => {
    const isUSCountryCode = isUS(userCountry);
    e.preventDefault();
    dispatch(saveEmail(email));

    if (isUSCountryCode) {
      dispatch(saveCheckboxOptIn(true));
    }
    if (isUSCountryCode || (!isUSCountryCode && optInStatus)) {
      trackUserEvent(email, 'lead_email', {
        subscription_platform: 'Web',
        email,
        optInStatus,
      });
    }
    gtmEvent({
      action: 'lead_email_created',
      pageName: 'email_lead_wall',
    });
    sendAnalyticsEvent(
      'web_lead_email_created',
      {
        app: 'web-site',
        opted_into_comms: optInStatus,
        preticked_opt_in: optInStatus,
        lead_email: email,
      },
      true
    );

    // CVR-875
    let nextPage = '/choose-plan';

    if (enabledQuiz) {
      nextPage = enabledQuiz === 'quizHomepage' ? '/quiz/questions/1' : '/quiz';
    }
    // End CVR-875
    history.push(nextPage);
  };

  return {
    ...emailProps,
    email,
    optInStatus,
    handleNextClick,
  };
}

export function useCheckboxOptIn() {
  const dispatch = useDispatch();
  const initialCheckboxOptInStatus = useSelector(
    (state) => state.appState.checkboxOptInToSave
  );
  const [checkboxOptIn, setCheckboxOptIn] = useState(
    initialCheckboxOptInStatus
  );

  const handleCheckboxOptIn = () => {
    setCheckboxOptIn(!checkboxOptIn);
    dispatch(saveCheckboxOptIn(!checkboxOptIn));
  };

  return { checkboxOptIn, handleCheckboxOptIn };
}
