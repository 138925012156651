import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    paymentInfoContainer: {
      '& p': {
        fontSize: '1.2rem',
      },
      '& h2': {
        fontSize: '2rem',
        [theme.breakpoints.down('sm')]: {
          fontSize: '20px',
        },
      },
    },
    includedHeader: {
      textAlign: 'center',
    },
    breakDown: {
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    charge: {
      '& .MuiTypography-body1': {
        fontWeight: '900',
      },
    },
    costContainer: {
      minHeight: '170px',
      padding: '30px 0 10px 0',
      '& .MuiTypography-body1': {
        fontSize: '1rem',
        fontFamily: 'Montserrat',
        [theme.breakpoints.down('xs')]: {
          fontSize: '0.8rem',
        },
      },
      '& .lineItem': {
        width: '100%',
      },
    },
    discountedPrice: {
      color: '#FF5454',
      textDecoration: 'line-through',
      marginRight: '0.5rem',
    },
    chargeSubtext: {
      marginBottom: '20px',
      '& p.MuiTypography-body1': {
        margin: 'auto',
        textAlign: 'center',
        fontSize: '14px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
        color: '#656565',
        textTransform: 'uppercase',
      },
    },
  })
);
