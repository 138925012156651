import SubscriptionPlanCard from './SubscriptionPlanCard';
import { SubscriptionPlanCardV2 } from './RedesignV2';
import { generateVariantData } from '../../../../utils/experimentUtils';

const variantToDataMapping = {
  base: {
    header: 'Start Your Free Trial',
    subHeader: 'No commitment. Cancel anytime.',
    valueProps: [],
    ctaLabel: 'Start Free Trial',
  },
  // CVR-942
  thirdPlan: {
    header: 'Start Your Free 7 Day Trial!',
    subHeader: 'No commitment. Cancel anytime.',
    valueProps: [],
    ctaLabel: 'Start Free Trial',
  },
  // CVR-942
  // CVR-956
  lifetimePlan: {
    header: 'Choose Your Plan',
    subHeader: '',
    valueProps: [],
    ctaLabel: 'Choose Plan',
  },
  // END CVR-956
  threeOndemandClasses: {
    header: 'Choose Your Plan',
    subHeader:
      '<span>Save on longer term plans.</span> <span>No commitment. Cancel anytime.</span>',
    valueProps: [],
    ctaLabel: 'Choose Plan',
  },
  redesignOndemandClasses: {
    header: 'Choose Your Plan',
    subHeader: '',
    valueProps: [],
    ctaLabel: 'Start Your {trialDurationDays}-Day Free Trial',
  },
  premiumOndemandClasses: {
    header: 'Start Your Free 7-Day Trial!',
    subHeader: 'No commitment. Cancel anytime.',
    valueProps: [
      '12 pre-recorded video lessons',
      'Digital workbook',
      'Parent portal',
      'Unlimited access to codeSpark app',
      'Up to 3 child app profiles',
      'Progress reports',
    ],
    ctaLabel: 'Start Your 7-day Free Trial',
  },
  premiumVsStandardDesign: {
    header: 'Start Your Free 7-Day Trial!',
    subHeader: 'No commitment. Cancel anytime.',
    valueProps: [
      {
        text: 'Unlimited access to codeSpark app',
        key: 'access',
      },
      {
        text: 'Digital workbook',
        key: 'workbook',
      },
      {
        text: '12 self-paced lessons',
        key: 'selfPacedLessons',
      },
      {
        text: 'Parent portal',
        key: 'parentPortal',
      },
      {
        text: 'Progress reports',
        key: 'progressReports',
      },
      {
        text: 'Up to 3 child app profiles',
        key: 'multipleChildProfiles',
      },
    ],
    ctaLabel: 'Start Your 7-day Free Trial',
  },
  premiumOndemandClassesTrials: {
    header: 'Start Your Free 7-Day Trial!',
    subHeader: 'No commitment. Cancel anytime.',
    valueProps: [
      'New Skills EVERY Week',
      '100s of educational puzzles',
      'Kids can create their own games',
      '12 self-paced lessons from a teacher',
      'Progress reports for parents',
      'Access for up to 3 children',
    ],
    ctaLabel: 'Start Your Free Trial',
  },
};

export const getPageCopy = generateVariantData(variantToDataMapping);

const planComponentToVariantMapping = {
  base: SubscriptionPlanCard,
  redesignOndemandClasses: SubscriptionPlanCardV2,
};

export const getSubscriptionPlanComponent = generateVariantData(planComponentToVariantMapping);
