import { Grid, Chip, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';

function Stats(props) {
  const classes = useStyles();
  const { label, description } = props;
  return (
    <Grid container direction="column">
      <Grid container justifyContent="center">
        <Chip
          size="medium"
          className={classes.chip}
          label={<Typography className={classes.number}>{label}</Typography>}
        />
      </Grid>
      <Grid container justifyContent="center">
        <Typography className={classes.description} variant="body1">
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Stats;
