import React, { lazy, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import __isEmpty from 'lodash/isEmpty';
import __last from 'lodash/last';

import { updatePaymentMethod } from '../../services/parse';
import { notify, trackPageMixpanel } from '../../services/functions';
import { SubscriptionPlanContext } from '../../contexts/subscriptionPlans';

const BillingHistory = lazy(() => import('./BillingHistoryComponent'));
const PaymentForm = lazy(() => import('../../components/forms/PaymentForm'));

const renderLoader = () => <h1>Loading</h1>;

const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;

class ClassAccordion extends React.Component {
  static contextType = SubscriptionPlanContext;

  constructor(props) {
    super(props);

    this.state = {
      annualSavingsWithPlan: 0,
      selectedPlan: 'annual',
      planExpiresAt: {},
      storeName: '',
      cancelledExpDate: null,
    };

    this.updatePaymentOnly = this.updatePaymentOnly.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  editPlan = () => {
    document.getElementById('editPlanModule').classList.add('show-options');
    document.getElementById('planButton').classList.add('hide');
    this.calculatePlanAnnualSavings();
  };

  cancelPlanUpgrade = () => {
    document.getElementById('editPlanModule').classList.remove('show-options');
    document.getElementById('planButton').classList.remove('hide');
  };

  toggleStatus = (value) => {
    if (value === true) {
      document.getElementById('editStatusModule').classList.add('show-options');
      document.getElementById('statusButton').classList.add('hide');
    }

    if (value === false) {
      document.getElementById('editStatusModule').classList.remove('show-options');
      document.getElementById('statusButton').classList.remove('hide');
    }
  };

  confirmPlanCancellation = () => {
    document.getElementById('cancelPlan').classList.add('hide');
    document.getElementById('goBack').classList.add('hide');
    document.getElementById('confirmCancellationBlock').classList.add('show-contents');
  };

  cancelPlanCancellation = () => {
    document.getElementById('cancelPlan').classList.remove('hide');
    document.getElementById('goBack').classList.remove('hide');
    document.getElementById('confirmCancellationBlock').classList.remove('show-contents');
    this.toggleStatus(false);
  };

  editPaymentDetails = () => {
    document.getElementById('editPaymentButton').classList.add('hide');
    document.getElementById('editPayment').classList.add('show-contents');
  };

  setStoreName = () => {
    const store = this.props.subDetails.classesPlan?.store.split(/(?=[A-Z])/);

    if (this.props.subDetails.classesPlan?.store === 'SamsClub') {
      return `${store[0]} ${store[1]}`;
    } else {
      return store[0];
    }
  };

  cancelPlan = () => {
    this.cancelPlanCancellation();
    // Cancel class only plan
    if (this.props.subDetails.classesPlan) {
      sessionStorage.setItem('cancelClass', 'cancelClass');
    }
    this.props.history.push({
      pathname: '/dont-go/1',
      state: {
        detail: 'cancelClass',
      },
    });
  };

  updatePaymentOnly(payload, userId) {
    document.getElementById('infoContainer').classList.add('blurry');
    updatePaymentMethod({
      userId: userId,
      nonce: payload.nonce,
    })
      .then((results) => {
        if (!results.errors) {
          trackPageMixpanel('WebMyAccount Action: Update Payment');
          window.location.reload();
        } else {
          trackPageMixpanel('WebMyAccount Error: Update Payment - Failed');
          notify('error', `There was an error updating your payment: ${results.message}`, 5000);
        }
      })
      .catch((error) => {
        // this.removeFormProcessing();
        notify('error', 'Sorry, there has been a problem updating your payment', 5000);
        console.error('Updating payment error', error);
      });
  }

  userHadPriorAccounts() {
    const allSubscriptions = !__isEmpty(this.props.subDetails.paymentInfo)
      ? this.props.subDetails.paymentInfo.subscriptions
      : null;

    if (allSubscriptions !== null && allSubscriptions.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getLastBillingDate() {
    const allSubscriptions = !__isEmpty(this.props.subDetails.paymentInfo)
      ? this.props.subDetails.paymentInfo.subscriptions
      : null;

    if (allSubscriptions === null || allSubscriptions.length === 0) {
      return null;
    }

    return new Date(__last(allSubscriptions).nextBillingDate);
  }

  selectPlan(plan) {
    this.setState({ selectedPlan: plan });
  }

  selectNewPlan(plan) {
    this.setState({ selectedPlan: plan });
  }

  subscribe() {
    const { setSelectedOffer } = this.context;

    if (this.state.selectedPlan === null) {
      notify('error', 'Please select a plan before proceeding', 5000);
      return;
    }

    if (this.state.selectedPlan === 'annual') {
      setSelectedOffer(this.props.annualPlan.planId);
    }

    if (this.state.selectedPlan === 'monthly') {
      setSelectedOffer(this.props.monthlyPlan.planId);
    }

    trackPageMixpanel('WebMyAccount Action: Subscribe');
    this.props.history.push('/signup-payment');
  }

  render() {
    return (
      <div>
        <p>
          Plan name<span className="right">{this.props.subDetails.classesPlan.planName}</span>
        </p>
        <hr />
        <p>
          Description<span className="right">{this.props.subDetails.classesPlan.planDescription} </span>
        </p>
        <hr />
        <p>
          Plan Duration
          <span className="right capitalize">Monthly</span>
        </p>
        <hr />
        <div>
          <p>
            Price
            <span className="right">${this.props.subDetails.classesPlan.activeSubscription?.price} USD</span>
          </p>
          <hr />
        </div>

        <div>
          <p>
            Renews on
            <span className="right">
              {new Date(this.props.subDetails.classesPlan.activeSubscription?.nextBillingDate).toLocaleDateString()}
            </span>
          </p>
          <hr />
        </div>

        <p>
          Plan Status
          <span className="right">Active</span>
        </p>
        <button id="statusButton" className="right" onClick={() => this.toggleStatus(true)}>
          Edit
          <img src={`${imgURL}/accounts/down_arrow_icon.svg`} alt="down arrow" />
        </button>

        <div id="editStatusModule" className="edit-status-module">
          {this.props.subDetails.classesPlan?.store === 'WebStore' ? (
            <div className="right">
              <button id="cancelPlan" className="cancel-plan" onClick={this.confirmPlanCancellation}>
                Cancel plan
                <img src={`${imgURL}/accounts/semantic_x_icon.svg`} alt="x icon" />
              </button>
              <button id="goBack" className="go-back-action" onClick={this.cancelPlanCancellation}>
                Go back
                <img src={`${imgURL}/accounts/semantic_checkmark_icon.svg`} alt="check icon" />
              </button>
            </div>
          ) : (
            <Suspense fallback={renderLoader()}>
              <BillingHistory storeName={this.props.subDetails.classesPlan?.store} />
            </Suspense>
          )}
        </div>
        <div id="confirmCancellationBlock" className="confirm-cancel-block">
          <p>
            Are you sure you want to cancel your plan? Your child will no longer have access to learn with codeSpark.
          </p>
          <div className="right">
            <button className="red-cancel" onClick={this.cancelPlan}>
              Yes, cancel my plan
              <img src={`${imgURL}/accounts/semantic_checkmark_icon.svg`} alt="check icon" />
            </button>
            <button className="green-confirm" onClick={this.cancelPlanCancellation}>
              No, go back
              <img src={`${imgURL}/accounts/semantic_x_icon.svg`} alt="x icon" />
            </button>
          </div>
        </div>
        <hr />

        <div>
          <p>
            Purchase Store
            <span className="right">{this.setStoreName()}</span>
          </p>
          <hr />
        </div>

        {!__isEmpty(this.props.subDetails.paymentInfo) && (
          <div>
            <div className="grid-x">
              <div className="small-12 medium-3">Payment Method</div>
              {this.props.subDetails.paymentInfo?.paymentType === 'Paypal' ? (
                <div className="small-12 medium-9 cc-info">Paypal</div>
              ) : (
                <div className="small-12 medium-9 cc-info">
                  {this.props.subDetails.paymentInfo?.cardType} xxxx xxxx xxxx{' '}
                  {this.props.subDetails.paymentInfo?.last4}
                </div>
              )}
            </div>
            <p className="exp-date">{this.props.subDetails.paymentInfo?.expirationDate}</p>

            <button id="editPaymentButton" className="right" onClick={this.editPaymentDetails}>
              Edit
              <img src={`${imgURL}/accounts/down_arrow_icon.svg`} alt="down arrow" />
            </button>

            <div id="editPayment" className="edit-payment">
              <Suspense fallback={renderLoader()}>
                <PaymentForm
                  isUpdate={true}
                  paymentDetails={this.props.subDetails.paymentInfo}
                  updatePaymentInfo={this.updatePaymentOnly}
                  paymentCCText="Enter new credit card info"
                  paymentPaypalText="Other Payment Options"
                />
              </Suspense>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ClassAccordion);
