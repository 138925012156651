import React, { memo } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Magnifier from '../../../../../lib/icons/magnifier.svg';

import { useStyles } from '../../styles';
const imgURL = process.env.REACT_APP_CLOUDFRONT_URL;

function Download(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
      className={classes.stepContainer}
    >
      <Grid item className={classes.stepNumber}>
        <Avatar>1</Avatar>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item className={classes.stepsDetailContainer}>
            <Typography variant="h3" className="stepTitle">
              Download the app
            </Typography>
            <Typography variant="body1">
              Click on the app store link below to download the app for your platform
            </Typography>
            <Grid container className={classes.paymentImgContainer} spacing={1}>
              <Grid item xs={12} sm={5}>
                <a
                  className="store bot-appleDownloadButton"
                  href="https://itunes.apple.com/us/app/codespark-academy-foos-coding/id923441570?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-event-category="app-download"
                  data-event-action="signup-complete"
                  data-event-label="ios"
                  data-on="click"
                >
                  <img src={`${imgURL}/accounts/minervaV2/apple-store.png`} alt="Apple app store link" />
                </a>
              </Grid>
              <Grid item xs={12} sm={5}>
                <a
                  className="store bot-googleDownloadButton"
                  href="http://play.google.com/store/apps/details?id=org.codespark.thefoos&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-event-category="app-download"
                  data-event-action="signup-complete"
                  data-event-label="android-google"
                  data-on="click"
                >
                  <img src={`${imgURL}/accounts/minervaV2/google-play.png`} alt="Google play link" />
                </a>
              </Grid>
            </Grid>
            <Typography variant="body1">Or in the Apple App Store, Google Play or Amazon Appstore, search</Typography>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.searchBar}
              spacing={2}
            >
              <Grid item>
                <img src={Magnifier} alt="magnifier" />
              </Grid>
              <Grid>
                <Typography variant="body1">codeSpark</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(Download);
